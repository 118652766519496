import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";

interface DeletePlateProps {
  plateId: number;
}

export const DeletePlate = ({ plateId }: DeletePlateProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  useEffect(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeletePlate")}
        loading={false}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={() => null}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
