import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { OrganizationFlat } from "types/Organization";
import { DeleteButton } from "../../DeleteButton/DeleteButton";
import { FormValues } from "./EditUserForm";

interface RemoveUserOrganizationProps {
  organization: OrganizationFlat;
  onDelete: (organizationId: string) => void;
}

export const RemoveUserOrganization = ({
  organization,
  onDelete,
}: RemoveUserOrganizationProps): JSX.Element => {
  const context = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const {
    Dialog: DeleteOrgDialog,
    openDialog: openDeleteOrgDialog,
    dialogProps: deleteOrgDialogProps,
    closeDialog: closeDeleteOrgDialog,
  } = useAppDialog();

  const {
    Dialog: CannotDeleteDialog,
    openDialog: openCannotDeleteDialog,
    dialogProps: deleteCannotDeleteDialogProps,
  } = useAppDialog();

  const onDeleteClick = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();

      if (context.values.organizations.length > 1) {
        openDeleteOrgDialog();
      } else {
        openCannotDeleteDialog();
      }
    },
    [
      openDeleteOrgDialog,
      context.values.organizations.length,
      openCannotDeleteDialog,
    ]
  );

  const onDeleteConfirm = useCallback(() => {
    const filteredOrgs = context.values.organizations.filter(
      (mappedOrganization) => mappedOrganization.id !== organization.id
    );

    context.setFieldValue(
      "groups",
      context.values.groups.filter(
        (group) => group.organization?.id !== organization.id
      )
    );

    context.setFieldValue("organizations", filteredOrgs);
    onDelete(filteredOrgs[0].id);
    closeDeleteOrgDialog();
  }, [context, organization, onDelete, closeDeleteOrgDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <DeleteOrgDialog
        title={t("DeleteOrganization")}
        loading={false}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...deleteOrgDialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </DeleteOrgDialog>

      <CannotDeleteDialog
        title={t("UserMustHaveOrg")}
        loading={false}
        buttonColor="primary"
        actionText={t("Delete")}
        onSubmit={() => {
          //
        }}
        renderActions={false}
        maxWidth="xs"
        {...deleteCannotDeleteDialogProps}
      />
    </>
  );
};
