import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardProps as MuiCardProps,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps as MuiCardMediaProps,
} from "@mui/material";

import { VehicleAngle } from "../VehicleAngleClaim";

type VehicleAngleCardProps = MuiCardProps & {
  renderImage: VehicleAngle;
  minAngleSelectWidth: number;
  sxCardMediaProps: MuiCardMediaProps;
  index?: number;
  disableActionArea?: boolean;
  onImageClick?(image: VehicleAngle): void;
};

export const VehicleAngleCard = ({
  renderImage,
  minAngleSelectWidth,
  sx,
  index = -1,
  disableActionArea = false,
  sxCardMediaProps,
  onImageClick = () => null,
  ...props
}: VehicleAngleCardProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedAngle, setSelectedAngle] = useState(renderImage.angle);
  const [checked, setChecked] = useState(renderImage.checked);

  const handleAngleChange = (event: SelectChangeEvent) => {
    setSelectedAngle(event.target.value as string);
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked as boolean);
  };

  return (
    <Card {...props}>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Checkbox checked={checked} onChange={handleCheckboxChange} />
      </Stack>
      <CardActionArea
        disabled={disableActionArea}
        onClick={() => onImageClick(renderImage)}
      >
        <CardMedia
          component="img"
          image={renderImage.url}
          alt={`vehicle-angle-${index}`}
          sx={{ ...sxCardMediaProps }}
        />
      </CardActionArea>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <CardActions>
          {/* TODO: Change by custom Select element when Api is available to integrate it with formik context */}
          <FormControl
            fullWidth
            sx={{ minWidth: minAngleSelectWidth, marginLeft: 4 }}
          >
            <InputLabel id="vehicle-angle-label">{t("Angle")}</InputLabel>
            <Select
              labelId="vehicle-angle-label"
              id="vehicle-angle-select"
              label={t("Angle")}
              inputProps={{
                id: "vehicle-angle-input",
                "data-testid": "vehicle-angle-select",
              }}
              onChange={handleAngleChange}
              value={selectedAngle}
            >
              <MenuItem value="unknown">{t("Unknown").toUpperCase()}</MenuItem>
              <MenuItem value="front">{t("Front")}</MenuItem>
              <MenuItem value="rear">{t("Rear")}</MenuItem>
              <MenuItem value="left">{t("Left")}</MenuItem>
              <MenuItem value="right">{t("Right")}</MenuItem>
            </Select>
          </FormControl>
        </CardActions>
      </Stack>
    </Card>
  );
};
