import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { useAuthApi } from "./useApi";

export const useGetProducts = () => {
  const getProducts = useAuthApi<null, any>("get", endpoints.UTILITY_PRODUCTS);
  const { isLoading, data } = useQuery([QueryKeys.GET_PRODUCTS], () =>
    getProducts()
  );

  return { isLoading, utilityProducts: data?.data ?? [] };
};
