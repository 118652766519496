import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { object, string } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { useUpdateReyReyBasicSettings } from "hooks/useUpdateReyReyBasicSettings";
import { ReyAndReyProduct } from "types/Product";

interface BasicProps {
  product: ReyAndReyProduct;
}

export const Basic = ({ product }: BasicProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, onUpdateBasicSettings } = useUpdateReyReyBasicSettings();

  const onSubmit = useCallback(
    (values) => {
      return onUpdateBasicSettings(values);
    },
    [onUpdateBasicSettings]
  );

  const initialValues = useMemo(
    () => ({
      cif: product.cif,
      dealerNumber: product.dealerNumber,
      areaNumber: product.areaNumber,
      storeNumber: product.storeNumber,
      productType: "REY_N_REY",
    }),
    [product]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        cif: string().required(t("Required")),
        dealerNumber: string().required(t("Required")),
        areaNumber: string().required(t("Required")),
        storeNumber: string().required(t("Required")),
      }),
    [t]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <FormGridContainer>
          <Grid item xs={12}>
            <TextField label={t("RRSystemNumber")} name="cif" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("PPSYSID")} name="dealerNumber" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("StoreNumber")} name="storeNumber" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("BranchNumber")} name="areaNumber" fullWidth />
          </Grid>
        </FormGridContainer>
        <Stack alignItems="flex-end">
          <LoadingButton
            color="primary"
            loading={isLoading}
            variant="contained"
            disableElevation
            type="submit"
            data-testid="submit-button"
          >
            {t("Save")}
          </LoadingButton>
        </Stack>
      </Form>
    </Formik>
  );
};
