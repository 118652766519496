import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { endpoints } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface useDeleteProductDriverPoolProps {
  organizationId: string;
  driverPoolId: string;
}

export const useDeleteProductDriverPool = ({
  organizationId,
  driverPoolId,
}: useDeleteProductDriverPoolProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const deleteDriverPool = useAuthApi(
    "delete",
    // TODO change to actual endpoint
    endpoints.ORGANIZATION_GROUP(organizationId, driverPoolId)
  );

  const { isSuccess, isLoading, mutate } = useMutation(
    ["deleteDriverPool", { organizationId, driverPoolId }],
    () => deleteDriverPool(),
    {
      // onSuccess: () => {
      // TODO: refetch query or set data depending on endpoint response
      // },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ??
          "Delete Product Driver Pool unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Delete Product Driver Pool", "ERROR");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    deleteDriverPool: mutate,
  };
};
