// import { rest } from "msw";
// import { endpoints } from "constants/constants";
// import { mockedOrganizationProducts } from "../api-responses/organizationProducts";

// const url = (path: string) => `${process.env.REACT_APP_API_BASE_PATH}${path}`;

// const DELAY = 2000;

export const handlers = [
  // rest.get(
  //   url(endpoints.ORGANIZATION_PRODUCTS(":organizationId")),
  //   async (_, res, ctx) => {
  //     return res(
  //       ctx.delay(DELAY),
  //       ctx.status(200),
  //       ctx.json(mockedOrganizationProducts)
  //     );
  //   }
  // ),
  // rest.patch(
  //   url(endpoints.ORGANIZATION_GROUP(":organizationId", ":groupId")),
  //   async (_, res, ctx) => {
  //     return res(ctx.delay(DELAY), ctx.status(200), ctx.json({ mocked: true }));
  //   }
  // ),
  // rest.delete(
  //   url(endpoints.ORGANIZATION_GROUP(":organizationId", ":groupId")),
  //   async (_, res, ctx) => {
  //     return res(ctx.delay(DELAY), ctx.status(200), ctx.json({ mocked: true }));
  //   }
  // ),
  // rest.delete(url(endpoints.ACTOR(":actorId")), async (_, res, ctx) => {
  //   return res(ctx.delay(DELAY), ctx.status(200), ctx.json({ mocked: true }));
  // }),
];
