import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";

import { AppHeader } from "components/AppHeader/AppHeader";
import { RequireAuth } from "navigation/RequireAuth";
import { StyledBox } from "./Dashboard.styles";

export const Dashboard = () => {
  return (
    <RequireAuth>
      <AppHeader />
      <Container maxWidth="xl">
        <StyledBox>
          <Outlet />
        </StyledBox>
      </Container>
    </RequireAuth>
  );
};
