import { useCallback, useMemo } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { useField } from "formik";

interface TagsInputProps {
  name: string;
  label: string;
}

export const TagsInput = ({ name, label }: TagsInputProps): JSX.Element => {
  const [{ onBlur, value }, { error, touched }, { setValue }] = useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const onChange = useCallback((_, value) => setValue(value), [setValue]);

  return (
    <Autocomplete
      multiple
      id={name}
      autoComplete
      onBlur={onBlur}
      options={[]}
      value={value}
      onChange={onChange}
      freeSolo
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip label={option} {...getTagProps({ index })} key={option} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasError}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: "do-not-autofill",
          }}
          helperText={hasError ? error : " "}
        />
      )}
    />
  );
};
