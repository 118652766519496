import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js/min";
import { array, object, string } from "yup";
import { ObjectShape } from "yup/lib/object";

import { hoursOfOperationSchema } from "components/OrganizationOverview/EditHoursOfOperation/validation";
import {
  organizationDetailRequest,
  useGetOrganizationsFlat,
} from "./useGetOrganizationsFlat";
import { useSelectedOrganization } from "./useSelectedOrganization";

type FormId =
  | "EditOrganization"
  | "EditHoursOfOperation"
  | "AddressContact"
  | "AddOrganization"
  | "OrganizationGroupPermissions"
  | "EditOrganizationGroup";

export const useValidationSchema = (formId: FormId) => {
  const { t } = useTranslation();
  const { organizationsFlat } = useGetOrganizationsFlat(
    organizationDetailRequest
  );
  const { selectedOrganizationId } = useSelectedOrganization();

  const organizationName = useMemo(
    () => ({
      name: string()
        .required(t("Required"))
        .test(
          "organization-duplicated-name",
          t("NameDuplicated"),
          (value = "") => {
            const lowerCaseValue = value.trim().toLowerCase();
            return !organizationsFlat.some(
              (organization) =>
                organization.name.toLowerCase() === lowerCaseValue
            );
          }
        ),
    }),
    [t, organizationsFlat]
  );

  const editOrganizationName = useMemo(
    () => ({
      name: string()
        .required(t("Required"))
        .test(
          "organization-duplicated-name",
          t("NameDuplicated"),
          (value = "") => {
            const lowerCaseValue = value.trim().toLowerCase();
            return !organizationsFlat.some(
              (organization) =>
                organization.name.toLowerCase() === lowerCaseValue &&
                selectedOrganizationId !== organization.id
            );
          }
        ),
    }),
    [t, organizationsFlat, selectedOrganizationId]
  );

  const addressContactFields = useMemo(
    () => ({
      address: string().required(t("Required")),
      address2: string(),
      city: string().required(t("Required")),
      state: object().shape({
        name: string().required(t("Required")),
      }),
      zip: string().required(t("Required")),
      firstname: string().required(t("Required")),
      lastname: string().required(t("Required")),
      phone: object().shape({
        phoneNumber: string()
          .required(t("Required"))
          .when("countryCode", (countryCode: CountryCode) => {
            return string().test(
              "phone-test",
              t("InvalidPhoneNumber"),
              (value = "") => {
                if (value === "") return false;
                return isValidPhoneNumber(value, countryCode);
              }
            );
          }),
      }),
      email: string().required(t("Required")).email(t("EmailValidationError")),
    }),
    [t]
  );
  return useMemo(() => {
    const schemas: Record<FormId, ObjectShape> = {
      EditOrganization: {
        ...hoursOfOperationSchema,
        ...editOrganizationName,
      },
      EditHoursOfOperation: {
        ...hoursOfOperationSchema,
      },
      AddressContact: {
        ...addressContactFields,
      },
      AddOrganization: {
        ...hoursOfOperationSchema,
        ...organizationName,
        Billing: object().shape({ ...addressContactFields }),
        Location: object().shape({ ...addressContactFields }),
      },
      OrganizationGroupPermissions: {
        permissions: array().min(1, t("OnePermissionRequired")),
      },
      EditOrganizationGroup: {
        groupName: string().required(t("Required")),
      },
    } as const;

    return object().shape(schemas[formId]);
  }, [formId, t, addressContactFields, organizationName, editOrganizationName]);
};
