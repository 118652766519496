import { format, parse } from "date-fns";

import { DayOfWeek, daysOfWeek } from "constants/constants";
import { OrganizationFull } from "types/Organization";

const API_TIME_FORMAT = "HH:mm";

export type DayTimes = { start: number | null; end: number | null };
export type DaySchedule = Record<DayOfWeek, DayTimes[]>;

export interface HourOfOperationValues {
  hoursOfOperation: DaySchedule;
}

export const parseHour = (hours: string) =>
  // parse(hours, "HH:mm:ss", new Date());
  parse(hours, "HH:mm:ss", new Date());

export const emptyOpenScheduleState = (): DaySchedule =>
  daysOfWeek.reduce(
    (acc, dayOfWeek) => ({
      ...acc,
      [dayOfWeek]: [],
    }),
    {} as DaySchedule
  );

export const scheduleToForm = (
  openSchedule: DaySchedule | null
): DaySchedule => {
  if (!openSchedule) return emptyOpenScheduleState();

  return Object.keys(openSchedule).reduce((acc, dayOfWeek) => {
    return {
      ...acc,
      [dayOfWeek]: [
        {
          start: openSchedule[dayOfWeek][0]?.start
            ? parseHour(openSchedule[dayOfWeek][0]?.start)
            : null,
          end: openSchedule[dayOfWeek][0]?.end
            ? parseHour(openSchedule[dayOfWeek][0]?.end)
            : null,
        },
      ],
    };
  }, {} as DaySchedule);
};

export const getInitialValues = (data: OrganizationFull) => {
  const { hoursOfOperation } = data;
  return {
    hoursOfOperation: scheduleToForm(hoursOfOperation),
  };
};

export const formToSchedule = (
  values: HourOfOperationValues,
  timezone?: string
): DaySchedule => {
  const openSchedule = Object.keys(values.hoursOfOperation).reduce(
    (days, dayOfWeek) => {
      const { start, end } = values.hoursOfOperation[dayOfWeek][0] ?? {
        start: null,
        end: null,
      };
      return {
        ...days,
        [dayOfWeek]:
          start && end
            ? [
                {
                  start: format(start, API_TIME_FORMAT),
                  end: format(end, API_TIME_FORMAT),
                  // timezone,
                },
              ]
            : [],
      };
    },
    {} as DaySchedule
  );
  return {
    ...openSchedule,
  };
};
