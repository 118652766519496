import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";

import { useAppDialog } from "../../../../hooks/useAppDialog";
import { useAppSnackbar } from "../../../../hooks/useAppSnackbar";
import useForcePasswordChange from "../../../../hooks/useForcePasswordChange";

interface Props {
  userId: string;
}

const SetForcePasswordChange = ({ userId }: Props) => {
  const { t } = useTranslation();

  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();

  const { setSnackbarData } = useAppSnackbar();

  const { doForcePasswordChange, isPasswordChangeLoading } =
    useForcePasswordChange();

  const onSubmit = async () => {
    doForcePasswordChange({ userIds: [userId] }).then(() => {
      closeDialog();

      setSnackbarData({
        severity: "success",
        message: t("PasswordForcingSuccessful"),
      });
    });
  };

  return (
    <>
      <Button onClick={openDialog}>{t("ForcePasswordChange")}</Button>
      <Dialog
        title={t("ForcePasswordChange")}
        {...dialogProps}
        actionText={t("Confirm")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isPasswordChangeLoading}
        maxWidth="xs"
      >
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          {t("SureToForceChangePass")}
        </Typography>
      </Dialog>
    </>
  );
};

export default SetForcePasswordChange;
