import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Analytics,
  BusinessCenter,
  CreditCard,
  DirectionsCar,
  DoNotDisturbAlt,
  Smartphone,
  WorkspacePremium,
} from "@mui/icons-material";
import LibraryAdd from "@mui/icons-material/LibraryAdd";
import Person from "@mui/icons-material/Person";
import { Grid, List, ListItemIcon, ListItemText } from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { Certifications } from "./Certifications";
import { Contact } from "./Contact";
import { Devices } from "./Devices/Devices";
import { Exclusions } from "./Exclusions";
import { LicensePlates } from "./LicensePlates/LicensePlates";
import { Payment } from "./Payment/Payment";
import { Registration } from "./Registration/Registration";
import { Reports } from "./Reports";
import { UserSkills } from "./UserSkills";

const tabsIndex = [
  "Registration",
  "Contact",
  "Skills",
  "Certifications",
  "Devices",
  "Exclusions",
  "Payment",
  "Reports",
  "LicensePlates",
] as const;

type Tabs = typeof tabsIndex[number];

export const Actors = (): JSX.Element => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<Tabs>("Skills");

  const onTabChange = useCallback(
    (selectedTab: Tabs) => {
      setActiveTab(selectedTab);
    },
    [setActiveTab]
  );
  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <List sx={{ py: 0, overflow: "auto" }}>
          <ListItemButtonWithDialog
            selected={activeTab === "Registration"}
            onClick={() => onTabChange("Registration")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <LibraryAdd fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Registration")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Contact"}
            onClick={() => onTabChange("Contact")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Contact")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Skills"}
            onClick={() => onTabChange("Skills")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <BusinessCenter fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Skills")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Certifications"}
            onClick={() => onTabChange("Certifications")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <WorkspacePremium fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Certifications")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Devices"}
            onClick={() => onTabChange("Devices")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Smartphone fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Devices")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Exclusions"}
            onClick={() => onTabChange("Exclusions")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <DoNotDisturbAlt fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Exclusions")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Payment"}
            onClick={() => onTabChange("Payment")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <CreditCard fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Payment")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "Reports"}
            onClick={() => onTabChange("Reports")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <Analytics fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("Reports")} />
          </ListItemButtonWithDialog>
          <ListItemButtonWithDialog
            selected={activeTab === "LicensePlates"}
            onClick={() => onTabChange("LicensePlates")}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <DirectionsCar fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("LicensePlates")} />
          </ListItemButtonWithDialog>
        </List>
      </Grid>
      <Grid item xs>
        {activeTab === "Registration" && <Registration />}
        {activeTab === "Contact" && <Contact />}
        {activeTab === "Skills" && <UserSkills />}
        {activeTab === "Exclusions" && <Exclusions />}
        {activeTab === "Certifications" && <Certifications />}
        {activeTab === "Devices" && <Devices />}
        {activeTab === "Payment" && <Payment />}
        {activeTab === "Reports" && <Reports />}
        {activeTab === "LicensePlates" && <LicensePlates />}
      </Grid>
    </Grid>
  );
};
