import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

type UseAddUsersToOrganizationProps = {
  organizationId: string;
};

export type SecurityGroupUser = {
  userId: string;
  securityGroupId: string;
};

export interface AddUsersToOrganizationData {
  securityGroupUsers: SecurityGroupUser[];
}

export const useAddUsersToOrganization = ({
  organizationId,
}: UseAddUsersToOrganizationProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const assignUsersToOrganization = useAuthApi<
    AddUsersToOrganizationData,
    unknown
  >("post", endpoints.ORGANIZATION_USERS_ASSIGN(organizationId));

  const { isError, isLoading, mutate, isSuccess } = useMutation(
    assignUsersToOrganization,
    {
      mutationKey: "addUsersToOrganization",
      onSuccess: () => {
        const message = t("UserAdded");
        setSnackbarData({ severity: "success", message });
        auditFormEvent(message, "Add User", "FORM_SENT");
        return queryClient.invalidateQueries([
          QueryKeys.GET_USERS,
          "organization",
          organizationId,
        ]);
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ??
          "Assign users to Organization unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Add User", "ERROR");
      },
    }
  );

  const putOrganizationUsers = (data: AddUsersToOrganizationData) =>
    mutate(data);

  return {
    isLoading,
    isError,
    isSuccess,
    putOrganizationUsers,
  };
};
