import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetFeatureFlagsCollectionNameResponse } from "types/FeatureFlag";
import { useAuthApi } from "./useApi";

interface UseGetUtilityFeatureFlagsApplicationCollectionNameProps {
  collectionName: string;
}

export const useGetUtilityFeatureFlagsApplicationCollectionName = ({
  collectionName,
}: UseGetUtilityFeatureFlagsApplicationCollectionNameProps) => {
  const getFeatureFlagsApplicationCollectionName = useAuthApi<
    never,
    GetFeatureFlagsCollectionNameResponse
  >(
    "get",
    endpoints.UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION(collectionName)
  );

  const { isLoading, data } = useQuery(
    [QueryKeys.GET_FEATURE_FLAGS, collectionName],
    () => getFeatureFlagsApplicationCollectionName()
  );

  return {
    isLoading,
    featureFlags: data?.data.featureFlags ?? [],
  };
};
