import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";

import { DeciderSelect } from "common/components/DeciderSelect/DeciderSelect";
import { FormControlLabelSwitch } from "common/components/FormControlLabel/FormControlLabelSwitch";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { GreyBox } from "styles/App.styles";

export interface FormValues {
  name: string;
  description: string;
  enabled: boolean;
  deciderClassPath: string | null;
  userDecider: boolean;
}

interface FeatureFlagFieldsProps {
  collectionName: string;
}

export const FeatureFlagFields = ({
  collectionName,
}: FeatureFlagFieldsProps): JSX.Element => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  return (
    <FormGridContainer>
      <Grid item xs={12}>
        <TextField name="name" fullWidth label={t("Name")} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          multiline
          name="description"
          fullWidth
          label={t("Description")}
          rows={3}
        />
      </Grid>
      <Grid item xs={12}>
        <GreyBox elevation={0}>
          <FormControlLabelSwitch
            name="userDecider"
            label="User Decider"
            labelPlacement="end"
            sx={{ mb: 2 }}
          />
          <DeciderSelect
            collectionName={collectionName}
            disabled={!values.userDecider}
            label={t("Decider")}
            name="deciderClassPath"
            fullWidth
          />
        </GreyBox>
      </Grid>
      <Grid
        item
        xs={12}
        pt={2}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <FormControlLabelSwitch
          name="enabled"
          label={t("Enabled")}
          labelPlacement="start"
        />
      </Grid>
    </FormGridContainer>
  );
};
