import { Fragment, MouseEvent, useCallback, useState } from "react";
import { Folder, FolderOpen } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useFormikContext } from "formik";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { FormValues, skill } from "../EditUserForm";
import { UserSkillDescription } from "./UserSkillDescription";

export const UserSkills = (): JSX.Element => {
  const { values } = useFormikContext<FormValues>();
  const [selectedSkill, setSelectedSkill] = useState<skill>();
  const [expandedIndex, setExpandedIndex] = useState<Array<number>>([0]);
  const toggleExpand = useCallback((event: MouseEvent, index: number) => {
    event.stopPropagation();
    setExpandedIndex((isExpanded) =>
      isExpanded.includes(index)
        ? isExpanded.filter((ele) => ele !== index)
        : [index, ...isExpanded]
    );
  }, []);

  return (
    <Grid container>
      <Grid item xs="auto">
        <List sx={{ py: 0, overflow: "auto" }}>
          {values.skills.map((skill, index) => (
            <Fragment key={skill.categoryName}>
              <ListItemButtonWithDialog
                key={skill.categoryName}
                selected={skill.categoryName === selectedSkill?.name}
                divider={index !== values.skills.length ?? 0 - 1}
                onClick={(event) => toggleExpand(event, index)}
              >
                <ListItemIcon sx={{ minWidth: "36px" }}>
                  {expandedIndex.includes(index) ? (
                    <FolderOpen fontSize="small" />
                  ) : (
                    <Folder fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText sx={{ mr: 2 }}>{skill.categoryName}</ListItemText>
              </ListItemButtonWithDialog>
              <Collapse in={expandedIndex.includes(index)} timeout="auto">
                <List sx={{ py: 0, overflow: "auto" }}>
                  {skill.children.length > 0
                    ? skill.children.map((child, childIndex) => (
                        <Fragment key={child.categoryName}>
                          <ListItemButtonWithDialog
                            key={child.categoryName}
                            selected={
                              child.categoryName === selectedSkill?.name
                            }
                            divider={index !== values.skills.length ?? 0 - 1}
                            onClick={(event) =>
                              toggleExpand(event, index + childIndex + 1)
                            }
                            sx={{ ml: 2 }}
                          >
                            <ListItemIcon sx={{ minWidth: "36px" }}>
                              {expandedIndex.includes(
                                index + childIndex + 1
                              ) ? (
                                <FolderOpen fontSize="small" />
                              ) : (
                                <Folder fontSize="small" />
                              )}
                            </ListItemIcon>
                            <ListItemText>{child.categoryName}</ListItemText>
                          </ListItemButtonWithDialog>
                          <Collapse
                            in={expandedIndex.includes(index + childIndex + 1)}
                            timeout="auto"
                          >
                            <List sx={{ py: 0, overflow: "auto" }}>
                              {child.categoryValues.map(
                                (grandChild, grandChildIndex) => (
                                  <Fragment key={grandChild.categoryName}>
                                    <ListItemButtonWithDialog
                                      key={grandChild.categoryName}
                                      selected={
                                        grandChild.categoryName ===
                                        selectedSkill?.name
                                      }
                                      divider={
                                        index !== values.skills.length ?? 0 - 1
                                      }
                                      onClick={(event) =>
                                        toggleExpand(
                                          event,
                                          index +
                                            childIndex +
                                            grandChildIndex +
                                            2
                                        )
                                      }
                                      sx={{ ml: 4 }}
                                    >
                                      <ListItemIcon sx={{ minWidth: "36px" }}>
                                        {expandedIndex.includes(
                                          index +
                                            childIndex +
                                            grandChildIndex +
                                            2
                                        ) ? (
                                          <FolderOpen fontSize="small" />
                                        ) : (
                                          <Folder fontSize="small" />
                                        )}
                                      </ListItemIcon>
                                      <ListItemText>
                                        {grandChild.categoryName}
                                      </ListItemText>
                                    </ListItemButtonWithDialog>
                                    <Collapse
                                      in={expandedIndex.includes(
                                        index + childIndex + grandChildIndex + 2
                                      )}
                                      timeout="auto"
                                    >
                                      <List sx={{ py: 0, overflow: "auto" }}>
                                        {grandChild.categoryValues.map(
                                          (vehicle, vehicleIndex) => (
                                            <ListItemButtonWithDialog
                                              key={vehicle.name}
                                              selected={
                                                vehicle.name ===
                                                selectedSkill?.name
                                              }
                                              divider={
                                                vehicleIndex !==
                                                values.skills.length - 1
                                              }
                                              onClick={() =>
                                                setSelectedSkill(vehicle)
                                              }
                                              sx={{ ml: 6 }}
                                            >
                                              <Checkbox
                                                checked={vehicle.checked}
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  vehicle.checked =
                                                    !vehicle.checked;
                                                }}
                                              />
                                              <ListItemText>
                                                {vehicle.name}
                                              </ListItemText>
                                            </ListItemButtonWithDialog>
                                          )
                                        )}
                                      </List>
                                    </Collapse>
                                  </Fragment>
                                )
                              )}
                            </List>
                          </Collapse>
                        </Fragment>
                      ))
                    : null}
                  {skill.categoryValues.map((skillData, skillIndex) => (
                    <ListItemButtonWithDialog
                      key={skillData.name}
                      selected={skillData.name === selectedSkill?.name}
                      divider={skillIndex !== values.skills.length - 1}
                      onClick={() => setSelectedSkill(skillData)}
                    >
                      <Checkbox
                        checked={skillData.checked}
                        onClick={(event) => {
                          event.preventDefault();
                          skillData.checked = !skillData.checked;
                        }}
                      />
                      <ListItemText sx={{ mr: 2 }}>
                        {skillData.name}
                      </ListItemText>
                    </ListItemButtonWithDialog>
                  ))}
                </List>
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Grid>
      <Grid item xs sx={{ marginLeft: "1%" }}>
        {selectedSkill && (
          <UserSkillDescription selectedSkill={selectedSkill} />
        )}
      </Grid>
    </Grid>
  );
};
