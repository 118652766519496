import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { getCountries } from "libphonenumber-js/min";

import { FormGridContainer } from "../FormGridContainer/FormGridContainer";
import { Select } from "../Select/Select";
import { TextField } from "../TextField/TextField";

interface PhoneInputProps {
  readOnly?: boolean;
  name: string;
}

export const PhoneInput = ({
  readOnly = false,
  name,
}: PhoneInputProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormGridContainer>
      <Grid item xs={3.5}>
        <Select
          label={t("Phone")}
          name={`${name}.countryCode`}
          fullWidth
          inputProps={{
            readOnly,
          }}
        >
          {getCountries().map((countryCode) => (
            <MenuItem key={countryCode} value={countryCode}>
              {countryCode}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={8.5}>
        <TextField
          name={`${name}.phoneNumber`}
          inputProps={{
            readOnly,
          }}
          fullWidth
        />
      </Grid>
    </FormGridContainer>
  );
};
