import { RefObject, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { FormikProps } from "formik";

import {
  DriversLicenseAddForm,
  DriversLicenseValues,
} from "./DriversLicenseAddForm";
import { MedicalCardAddForm, MedicalCardValues } from "./MedicalCardAddForm";
import {
  MedicalWasteDriversLicenseAddForm,
  MedicalWasteDriversLicenseValues,
} from "./MedicalWasteDriversLicenseAddForm";

interface AddCertificationFormsProps {
  onSubmit: (
    values:
      | DriversLicenseValues
      | MedicalCardValues
      | MedicalWasteDriversLicenseValues
  ) => void;
  formRef: RefObject<
    FormikProps<
      | DriversLicenseValues
      | MedicalCardValues
      | MedicalWasteDriversLicenseValues
    >
  >;
}

export const AddCertificationForms = ({
  formRef,
  onSubmit,
}: AddCertificationFormsProps): JSX.Element => {
  const { t } = useTranslation();
  const [type, setType] = useState("DRIVERS_LICENSE");
  const onChange = useCallback((event) => setType(event.target.value), []);

  return (
    <>
      <FormControl
        fullWidth
        size="small"
        sx={{
          ".MuiOutlinedInput-root": { mb: 0 },
        }}
      >
        <InputLabel id="certification-type">{t("Type")}</InputLabel>
        <Select
          label={t("Type")}
          id="certification-type"
          value={type}
          onChange={onChange}
          sx={{ mb: 2 }}
        >
          <MenuItem value="DRIVERS_LICENSE">{t("DRIVERS_LICENSE")}</MenuItem>
          <MenuItem value="MEDICAL_CARD">{t("MEDICAL_CARD")}</MenuItem>
          <MenuItem value="MEDICAL_WASTE_DRIVERS_LICENSE">
            {t("MEDICAL_WASTE_DRIVERS_LICENSE")}
          </MenuItem>
        </Select>
        <FormHelperText> </FormHelperText>
      </FormControl>
      {type === "DRIVERS_LICENSE" && (
        <DriversLicenseAddForm
          formRef={formRef as RefObject<FormikProps<DriversLicenseValues>>}
          onSubmit={onSubmit}
        />
      )}
      {type === "MEDICAL_CARD" && (
        <MedicalCardAddForm
          formRef={formRef as RefObject<FormikProps<MedicalCardValues>>}
          onSubmit={onSubmit}
        />
      )}
      {type === "MEDICAL_WASTE_DRIVERS_LICENSE" && (
        <MedicalWasteDriversLicenseAddForm
          formRef={
            formRef as RefObject<FormikProps<MedicalWasteDriversLicenseValues>>
          }
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
