import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  InputAdornment,
  Paper,
  Skeleton,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridRowId,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import debounce from "lodash.debounce";

import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { OrganizationLocation } from "types/Location";
import { AddLocation } from "./AddLocation/AddLocation";
import { DeleteLocation } from "./DeleteLocation";
import { EditLocation } from "./EditLocation/EditLocation";
import { General } from "./LocationTabs/General";
import { Instructions } from "./LocationTabs/Instructions";
import { MapTab } from "./LocationTabs/Map";
import { Padding } from "./LocationTabs/Padding";

interface LocationPanelProps {
  locations: Array<OrganizationLocation>;
  setSearch: (value: string) => void;
  loading: boolean;
}

const tabs = ["General", "Map", "Padding", "Instructions"] as const;
export type TabValue = typeof tabs[number];

export const LocationsPanel = ({
  locations,
  setSearch,
  loading,
}: LocationPanelProps): JSX.Element => {
  const [selectedLocationModel, setSelectedLocationModel] = useState<
    Array<GridRowId>
  >([""]);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useTabValue<TabValue>("General");

  const columns = useMemo(
    (): GridColumns => [
      {
        field: "label",
        headerName: t("Name"),
        flex: 2,
      },
      {
        field: "address",
        headerName: t("Address"),
        flex: 2,
        valueGetter: (params: GridValueGetterParams<OrganizationLocation>) =>
          `${params.row.address.street1} ${params.row.address.street2 ?? ``}`,
      },
      {
        field: "city",
        headerName: t("City"),
        flex: 1,
        valueGetter: (params: GridValueGetterParams<OrganizationLocation>) =>
          params.row.address.city,
      },
      {
        field: "state",
        headerName: t("State"),
        flex: 1,
        valueGetter: (params: GridValueGetterParams<OrganizationLocation>) =>
          params.row.address.state.name,
      },
      {
        field: "zip",
        headerName: t("Zip"),
        flex: 1,
        valueGetter: (params: GridValueGetterParams<OrganizationLocation>) =>
          params.row.address.postalCode,
      },
      {
        field: "edit",
        headerName: "",
        sortable: false,
        align: "right",
        width: 100,
        renderCell: (params: GridRenderCellParams<OrganizationLocation>) => {
          return (
            <Box sx={{ ml: "auto" }}>
              <EditLocation locationId={params.row.id} />
              <DeleteLocation locationId={params.row.id} />
            </Box>
          );
        },
      },
    ],
    [t]
  );

  const onSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [setSearch]
  );

  const selectedLocation = useMemo(() => {
    return locations.find(
      (location) => location.id === selectedLocationModel[0]
    );
  }, [selectedLocationModel, locations]);

  useEffect(() => {
    setSelectedLocationModel([locations[0]?.id ?? ""]);
    // eslint-disable-next-line
  }, []);

  return (
    <Stack sx={{ height: "100%", paddingX: 2, pt: 2 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="row" alignItems="center">
          {loading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <Typography>
              {t("Locations", { count: locations.length ?? 0 })}
            </Typography>
          )}
          <AddLocation disabled={loading} />
        </Stack>
        <Stack direction="row" alignContent="center">
          <Box>
            <TextField
              type="search"
              placeholder={t("StartTypingToSearch")}
              hiddenLabel
              fullWidth
              label={t("SearchLocation")}
              sx={{ mr: 2, pt: 0, width: 300 }}
              onChange={debounce(onSearchChange, 300)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <Box sx={{ flex: 1, paddingY: 2 }}>
        <Paper elevation={2} sx={{ height: "100%" }}>
          <DataGrid
            columns={columns}
            rows={locations}
            loading={loading}
            selectionModel={selectedLocationModel}
            onSelectionModelChange={setSelectedLocationModel}
            hideFooter
            disableSelectionOnClick={false}
            sx={{
              ".MuiDataGrid-cell:focus": {
                outline: "unset",
              },
            }}
          />
        </Paper>
      </Box>
      {selectedLocation && (
        <Stack sx={{ flex: 2, overflow: "hidden" }}>
          <TabContext value={tabValue}>
            <Tabs onChange={setTabValue}>
              <Tab value="General" label={t("General")} />
              <Tab value="Map" label={t("Map")} />
              <Tab value="Padding" label={t("Padding")} />
              <Tab
                value="Instructions"
                label={t("Instructions")}
                disabled={!selectedLocation?.locationNotes}
              />
            </Tabs>
            <TabPanel value="General">
              <General location={selectedLocation} />
            </TabPanel>
            <TabPanel value="Map">
              <MapTab location={selectedLocation} />
            </TabPanel>
            <TabPanel value="Padding">
              <Padding location={selectedLocation} />
            </TabPanel>
            <TabPanel value="Instructions">
              <Instructions location={selectedLocation} />
            </TabPanel>
          </TabContext>
        </Stack>
      )}
    </Stack>
  );
};
