import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUserResponse } from "types/Api";
import { useAuthApi } from "./useApi";

export const useGetLoggedUser = () => {
  const { data: userId } = useQuery<string>([QueryKeys.LOGGED_ID], {
    refetchOnMount: false,
  });
  const getUser = useAuthApi<never, GetUserResponse>(
    "get",
    endpoints.USER(userId as string)
  );

  const { isLoading, data } = useQuery(
    [QueryKeys.LOGGED_USER],
    () => getUser(),
    {
      enabled: Boolean(userId),
    }
  );

  return {
    isLoading,
    loggedUser: data?.data?.user,
    currentLocale: data?.data?.request?.locale?.slice(0, 2) || "en",
  };
};
