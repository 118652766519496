import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteUserFromOrganizationGroup } from "hooks/useDeleteUserFromOrganizationGroup";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { DeleteButton } from "../DeleteButton/DeleteButton";

interface DeleteUserFromOrganizationGroupProps {
  userId: string;
  groupId: string;
}

export const DeleteUserFromOrganizationGroup = ({
  userId,
  groupId,
}: DeleteUserFromOrganizationGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();

  const { isLoading, isSuccess, onDeleteUser } =
    useDeleteUserFromOrganizationGroup({
      organizationId: selectedOrganizationId as string,
      groupId,
    });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    onDeleteUser([userId]);
  }, [onDeleteUser, userId]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteUser")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
