import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { AddGroupData } from "types/Group";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UsePostOrganizationGroupProps {
  organizationId: string;
}

export const usePostOrganizationGroup = ({
  organizationId,
}: UsePostOrganizationGroupProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();

  const postOrganizationGroup = useAuthApi(
    "post",
    endpoints.ORGANIZATION_GROUP(organizationId, "")
  );

  const { isLoading, isSuccess, mutate } = useMutation(postOrganizationGroup, {
    mutationKey: "postOrganizationGroup",
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION,
        organizationId,
      ]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Post Organization Group unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add Organization Group", "ERROR");
    },
  });

  const addGroup = (data: AddGroupData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    addGroup,
  };
};
