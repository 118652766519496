import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Box,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { useResetPassword } from "hooks/useResetPassword";
import { paths } from "navigation/paths";
import { Background, BackgroundGrid, LoginWidget } from "../Login/Login.styles";
import { RESET_PARAM } from "../Login/LoginForm/LoginForm";

const SEARCH_PARAM_TOKEN = "token";

export const ResetPassword = (): JSX.Element => {
  const { isLoading, isError, isSuccess, onResetPassword, regex, error } =
    useResetPassword();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = useMemo(
    () => searchParams.get(SEARCH_PARAM_TOKEN),
    [searchParams]
  );

  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({ password: "", confirmPassword: "" }),
    []
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        password: string()
          .required(t("Required"))
          .matches(regex, t("InvalidPassword")),
        confirmPassword: string()
          .required(t("Required"))
          .oneOf([ref("password"), null], t("PasswordsMustMatch")),
      }),
    [t, regex]
  );

  const onSubmit = useCallback(
    (values) => {
      onResetPassword({
        newPassword: values.password,
        passwordToken: window.encodeURIComponent(token as string),
      });
    },
    [token, onResetPassword]
  );

  useEffect(() => {
    if (isSuccess)
      navigate(`${paths.LOGIN}?${RESET_PARAM}=true`, { replace: true });
  }, [isSuccess, navigate]);

  return (
    <Background>
      <LoginWidget elevation={6}>
        <Grid container>
          <BackgroundGrid item xs={4} />
          <Grid item xs={8} sx={{ p: 7.5 }}>
            <Typography variant="h4">{t("ResetPassword")}</Typography>
            {token && !isSuccess && !isError && <Box sx={{ pt: 7.5 }} />}
            {!token && (
              <Alert variant="filled" severity="error" sx={{ marginY: 2 }}>
                <AlertTitle>{t("NoTokenTitle")}</AlertTitle>
                <Typography>{t("NoTokenCopy")}</Typography>
              </Alert>
            )}
            {isError && (
              <Alert variant="filled" severity="error" sx={{ marginY: 2 }}>
                <AlertTitle>{t("Error")}</AlertTitle>
                {t(
                  error.response?.data
                    .message as unknown as TemplateStringsArray
                )}
              </Alert>
            )}
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              <Form>
                <FormGridContainer>
                  <Grid item xs={12}>
                    <FormHelperText sx={{ mt: 1, pb: 2 }}>
                      {t("PasswordRules")}
                    </FormHelperText>
                    <TextField
                      label={t("Password")}
                      fullWidth
                      id="password"
                      name="password"
                      type="password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={t("ConfirmPassword")}
                      fullWidth
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                    />
                  </Grid>
                </FormGridContainer>
                <LoadingButton
                  sx={{ mt: 7.5 }}
                  size="medium"
                  color="primary"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                  type="submit"
                  data-testid="submit-button"
                  disabled={!token}
                >
                  {t("Reset")}
                </LoadingButton>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </LoginWidget>
    </Background>
  );
};
