import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useUpdateOrganization } from "hooks/useUpdateOrganization";
import { AddressContactForm } from "../EditLocation/AddressContactForm";
import { AddressContactValues } from "../EditLocation/formUtils";

interface EditBillingProps {
  disabled: boolean;
}

export const EditBilling = ({ disabled }: EditBillingProps) => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading, updateOrganization, isSuccess } = useUpdateOrganization({
    id: selectedOrganizationId as string,
  });
  const formRef = useRef<FormikProps<AddressContactValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton onClick={openDialog} disabled={disabled} name="edit button" />
      <Dialog
        {...dialogProps}
        title={t("BillingAddressContact")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
      >
        <AddressContactForm
          type="Billing"
          formRef={formRef}
          onSubmit={updateOrganization}
        />
      </Dialog>
    </>
  );
};
