import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { useField } from "formik";

import {
  organizationDetailRequest,
  useGetOrganizationsFlat,
} from "hooks/useGetOrganizationsFlat";
import { HierarchyOrganization, OrganizationFlat } from "types/Organization";
import { createHierarchy } from "utils/organizationHierarchy";

export type HierarchyOrganizationOption = HierarchyOrganization & {
  layer: number;
  disabled?: boolean;
};

export const createOrderedFlatFromHierarchy = (
  organizations: Array<HierarchyOrganization>,
  layer: number
): Array<HierarchyOrganizationOption> =>
  organizations.reduce((acc, org) => {
    return [
      ...acc,
      { ...org, layer },
      ...createOrderedFlatFromHierarchy(org.children, layer + 1),
    ];
  }, [] as Array<HierarchyOrganizationOption>);

export const getOrganizationOptions = (
  data: Array<OrganizationFlat>,
  filterIds: Array<string> = []
) => {
  const orderedOrganizations = createOrderedFlatFromHierarchy(
    createHierarchy(data || []),
    0
  );

  return orderedOrganizations.map((organization) => ({
    ...organization,
    disabled: filterIds.includes(organization.id),
  }));
};

interface OrganizationSelectProps {
  filterIds?: Array<string>;
  name: string;
  label: string;
  multiple?: boolean;
  readOnly?: boolean;
  currentOrganizationsIds?: string[];
}

export const OrganizationSelect = ({
  filterIds,
  name,
  label,
  readOnly = false,
  multiple = false,
  currentOrganizationsIds = [],
}: OrganizationSelectProps) => {
  const { t } = useTranslation();
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const { organizationsFlat, isLoading } = useGetOrganizationsFlat(
    organizationDetailRequest
  );
  const organizations = useMemo(
    () => getOrganizationOptions(organizationsFlat, filterIds),
    [organizationsFlat, filterIds]
  );
  const onChange = useCallback((_, value) => setValue(value), [setValue]);

  const getOptionLabel = useCallback(
    (option) =>
      `${Array(option.layer ?? 0)
        .fill("-")
        .join("")} ${option?.name}` ?? "",

    []
  );

  return (
    <Autocomplete
      multiple={multiple}
      id={name}
      autoComplete
      options={organizations}
      disableClearable
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      loading={isLoading}
      loadingText={t("Loading")}
      onBlur={onBlur}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionDisabled={(option) =>
        option.disabled ||
        (currentOrganizationsIds && currentOrganizationsIds.includes(option.id))
      }
      readOnly={readOnly}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          name={name}
          label={label}
          fullWidth
          error={hasError}
          helperText={hasError ? error : " "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "do-not-autofill",
          }}
        />
      )}
    />
  );
};
