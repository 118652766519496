import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import FolderOpen from "@mui/icons-material/FolderOpen";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";

import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { Tabs } from "common/components/Tabs/Tabs";
import { useGetOrganizationGroups } from "hooks/useGetOrganizationGroups";
import { SecurityGroup } from "types/Group";
import { OrganizationFlat } from "types/Organization";
import { FormValues } from "./EditUserForm";

interface OrganizationGroupsProps {
  selectedOrganization: OrganizationFlat;
}

export const OrganizationGroups = ({
  selectedOrganization,
}: OrganizationGroupsProps): JSX.Element => {
  const context = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { groups, isLoading } = useGetOrganizationGroups({
    organizationId: selectedOrganization?.id,
    params: {
      outputRoles: false,
      outputPermissions: false,
    },
  });
  const setCurrentAsDefault = useCallback(
    (_, checked: boolean) => {
      checked &&
        context.setFieldValue("defaultOrganization", selectedOrganization);
    },
    [context, selectedOrganization]
  );

  const groupsForSelectedOrganization = useMemo(
    () =>
      context?.values?.groups?.filter(
        (group) => group?.organization?.id === selectedOrganization?.id
      ) ?? [],
    [context?.values?.groups, selectedOrganization]
  );

  const groupsIds = useMemo(
    () => groupsForSelectedOrganization.map((group) => group?.id),
    [groupsForSelectedOrganization]
  );

  const onChange = useCallback(
    (group: SecurityGroup, checked: boolean) => {
      const newGroups =
        checked && context?.values?.groups
          ? [...context.values.groups, group]
          : context?.values?.groups.filter(
              (mappedGroup) => mappedGroup?.id !== group?.id
            );
      context.setFieldValue("groups", newGroups);
    },
    [context]
  );

  const groupedGroupsWithOrganization: Array<[string, Array<SecurityGroup>]> =
    useMemo(
      () =>
        Array.from(
          groups.reduce((acc, group) => {
            if (acc.has(group?.applicationLabel)) {
              acc.set(group.applicationLabel, [
                ...acc.get(group.applicationLabel),
                { ...group, organization: selectedOrganization },
              ]);
            } else {
              acc.set(group?.applicationLabel, [
                { ...group, organization: selectedOrganization },
              ]);
            }
            return acc;
          }, new Map())
        ),
      [groups, selectedOrganization]
    );

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <Typography variant="h6">{selectedOrganization?.name}</Typography>
          <Typography variant="subtitle2">
            {selectedOrganization?.id}
          </Typography>
        </Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={
                context.values?.defaultOrganization?.id ===
                selectedOrganization?.id
              }
              onChange={setCurrentAsDefault}
            />
          }
          label={t("DefaultOrganization")}
          name="isDefaultOrganization"
        />
      </Stack>
      <TabContext value="Groups">
        <Tabs>
          <Tab label={t("Groups")} value="Groups" />
          <Tab label={t("WorkHours")} value="WorkHours" disabled />
          <Tab label={t("Notifications")} value="Notifications" disabled />
          <Tab label={t("Preferences")} value="Preferences" disabled />
        </Tabs>
        <TabPanel value="Groups">
          {isLoading ? (
            <ListSkeleton width={300} />
          ) : (
            <FormControl component="fieldset" fullWidth sx={{ mt: 2 }}>
              <RadioGroup name="selectedGroups" sx={{ flexDirection: "row" }}>
                {groupedGroupsWithOrganization?.map(([name, groups]) => (
                  <Box key={name} mb={4} width="100%">
                    <Stack direction="row" alignItems="center">
                      <FolderOpen fontSize="small" />
                      <Typography sx={{ ml: 2 }}>{name}</Typography>
                    </Stack>
                    <Box pl={4}>
                      {groups.map((group) => (
                        <FormControlLabel
                          control={<Checkbox />}
                          value={group}
                          key={group?.id}
                          label={group?.name}
                          checked={groupsIds.includes(group?.id)}
                          sx={{ width: `calc(${100 / 4}% - 16px)` }}
                          onChange={(_, checked) => onChange(group, checked)}
                        />
                      ))}
                    </Box>
                  </Box>
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </TabPanel>
      </TabContext>
    </>
  );
};
