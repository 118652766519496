import { useGetOrganizationGroupUsers } from "hooks/useGetOrganizationGroupUsers";
import { useUsersDataGrid } from "hooks/useUsersDataGrid";
import { SecurityGroup } from "types/Group";

interface OrganizationGroupUsersProps {
  organizationId: string;
  group: SecurityGroup;
}

export const OrganizationGroupUsers = ({
  organizationId,
  group,
}: OrganizationGroupUsersProps): JSX.Element => {
  const { page, pageSize, search, UsersDataGrid, usersDataGridProps } =
    useUsersDataGrid();

  const { isLoading, data } = useGetOrganizationGroupUsers({
    organizationId,
    groupId: group.id,
    params: {
      s: search,
      startRecord: page * pageSize,
      pageSize,
      outputOrganizations: false,
      outputGroups: false,
    },
  });

  return (
    <UsersDataGrid
      addUser="organization"
      deleteUser="group"
      isLoading={isLoading}
      group={group}
      data={data}
      {...usersDataGridProps}
    />
  );
};
