import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { FeatureFlagData } from "types/FeatureFlag";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseUpdateUtilityFeatureFlagApplicationCollectionNameProp {
  collectionName: string;
  featureFlagName: string;
}

export const useUpdateUtilityFeatureFlagApplicationCollectionName = ({
  collectionName,
  featureFlagName,
}: UseUpdateUtilityFeatureFlagApplicationCollectionNameProp) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const updateFeatureFlag = useAuthApi<FeatureFlagData, unknown>(
    "put",
    endpoints.UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION_FLAG_NAME(
      collectionName,
      featureFlagName
    )
  );
  const { isLoading, isSuccess, mutateAsync } = useMutation(updateFeatureFlag, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.GET_FEATURE_FLAGS,
        collectionName,
      ]);
      auditFormEvent(
        "Feature Flag Updated",
        "Update Feature Flag",
        "FORM_SENT"
      );
      setSnackbarData({
        severity: "success",
        message: t("FeatureFlagUpdated"),
      });
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Update Feature Flag unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Update Feature Flag", "ERROR");
    },
  });

  return {
    isLoading,
    isSuccess,
    onUpdateFeatureFlag: mutateAsync,
  };
};
