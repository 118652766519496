import Logo from "assets/images/HeaderLogo/logo_skaiAdmin.png";

export const AppLogo = (): JSX.Element => {
  return (
    <img
      src={Logo}
      height={"29px"}
      alt="logo"
      style={{
        objectFit: "contain",
      }}
    />
  );
};
