import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";

import { useGetLoggedUser } from "hooks/useGetLoggedUser";
import { useLogout } from "hooks/useLogout";
import { EditUser } from "../UsersDataGrid/EditUser/EditUser";

export const HeaderButtons = (): JSX.Element => {
  const { t } = useTranslation();
  const { loggedUser, isLoading } = useGetLoggedUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [enabled, setEnabled] = useState(false);
  const { isLoading: isLogginOut } = useLogout({ enabled });
  const disabled = useMemo(
    () => isLoading || isLogginOut,
    [isLoading, isLogginOut]
  );

  const onNotificationsClick = useCallback(() => {
    // TODO
  }, []);
  const onProfileClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const onMenuClose = useCallback(() => setAnchorEl(null), []);

  const fullName = useMemo(
    () => (loggedUser ? `${loggedUser.firstName} ${loggedUser.lastName}` : ""),

    [loggedUser]
  );

  const onLogout = useCallback(() => {
    setEnabled(true);
    setAnchorEl(null);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <IconButton onClick={onNotificationsClick} sx={{ mr: 1 }} size="medium">
        <NotificationsIcon />
      </IconButton>
      <IconButton onClick={onProfileClick} data-testid="profile-button">
        <Avatar
          src={loggedUser?.profilePhotoUrl}
          alt={fullName}
          sx={{ width: 32, height: 32 }}
        />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        }}
      >
        <MenuItem>
          <Avatar src={loggedUser?.profilePhotoUrl} alt={fullName} />
          {fullName}
        </MenuItem>
        <Divider />
        <EditUser menu userId={loggedUser?.id as string} disabled={disabled} />
        <MenuItem onClick={onLogout} disabled={disabled}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t("Logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
};
