import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateUtilityFeatureFlagApplicationCollectionName } from "hooks/useUpdateUtilityFeatureFlagApplicationCollectionName";
import { DeciderDataItem, FeatureFlag } from "types/FeatureFlag";
import {
  AddDataItemValueForm,
  FormValues,
} from "./AddDataItemValueForm/AddDataItemValueForm";
interface AddDataItemValueProps {
  collectionName: string;
  featureFlag: FeatureFlag;
  dataItem: DeciderDataItem;
}
export const AddDataItemValue = ({
  collectionName,
  featureFlag,
  dataItem,
}: AddDataItemValueProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onUpdateFeatureFlag } =
    useUpdateUtilityFeatureFlagApplicationCollectionName({
      collectionName,
      featureFlagName: featureFlag.name,
    });
  const formRef = useRef<FormikProps<FormValues>>(null);
  const onUpdate = (values: FormValues) => {
    const updateValue = {
      ...featureFlag,
      applicationCollectionName: collectionName,
      deciderData: {
        ...featureFlag.deciderData,
        [dataItem.key]: [
          ...(featureFlag.deciderData[dataItem.key] ?? []),
          values.name,
        ],
      },
    };
    return onUpdateFeatureFlag(updateValue);
  };
  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton variant="contained" sx={{ ml: 2 }} onClick={openDialog}>
        {t("Add")}
      </AddButton>
      <Dialog
        title={t("AddValue")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="sm"
        {...dialogProps}
      >
        <AddDataItemValueForm formRef={formRef} onSubmit={onUpdate} />
      </Dialog>
    </>
  );
};
