import { Box } from "@mui/material";

import { useGetOrganizationUsers } from "hooks/useGetOrganizationUsers";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useUsersDataGrid } from "hooks/useUsersDataGrid";

export const Users = (): JSX.Element => {
  const { page, pageSize, search, UsersDataGrid, usersDataGridProps } =
    useUsersDataGrid();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading, usersResponse } = useGetOrganizationUsers({
    organizationId: selectedOrganizationId as string,
    params: {
      s: search,
      startRecord: page * pageSize,
      pageSize,
      outputOrganizations: true,
      outputGroups: true,
    },
  });

  return (
    <Box sx={{ flex: 1, p: 2, display: "flex" }}>
      <UsersDataGrid
        addUser="organization"
        deleteUser="organization"
        isLoading={isLoading}
        data={usersResponse}
        {...usersDataGridProps}
      />
    </Box>
  );
};
