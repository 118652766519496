import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { TextField } from "common/components/TextField/TextField";
import { DriverPool } from "types/Product";

export interface AddDriverPoolValues {
  name: string;
}

interface ProductDriverPoolAddProps {
  formRef: RefObject<FormikProps<AddDriverPoolValues>>;
  onSubmit: (values: AddDriverPoolValues) => void;
  driverPools: Array<DriverPool>;
}

export const ProductDriverPoolAddForm = ({
  formRef,
  driverPools,
}: ProductDriverPoolAddProps): JSX.Element => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string()
          .required(t("Required"))
          .test("pool-duplicated-name", t("NameDuplicated"), (value = "") => {
            const lowerCaseValue = value.trim().toLowerCase();
            return !driverPools.some(
              (pool) => pool.name.toLowerCase() === lowerCaseValue
            );
          }),
      }),
    [t, driverPools]
  );

  const initialValues = useMemo(
    () => ({
      name: "",
    }),
    []
  );
  const onFormSubmit = () => {
    Promise.resolve();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      <Form>
        <TextField name="name" fullWidth label={t("Name")} />
      </Form>
    </Formik>
  );
};
