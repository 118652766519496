import { parse, parseISO } from "date-fns";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { Timezone } from "types/Organization";

export const convertServerDateStringToUnix = (timeUTC: string) =>
  new Date(timeUTC).getTime();

export const hourToTimezonedDate = (
  hour: string,
  timezone: Timezone
): Date | number => zonedTimeToUtc(`1970-01-01 ${hour}`, timezone.timezone);

export const hourToUserTimezonedHour = (
  hour: string,
  timezone: Timezone
): string => {
  const utcDate = hourToTimezonedDate(hour, timezone);
  return format(utcDate, "hh:mmaa");
};

export const militaryToRegular = (hour: string) => {
  return format(parse(hour, "HH:mm:ss", new Date()), "hh:mmaa");
};

export const formatISODate = (date: string) => {
  const parsed = parseISO(date);
  return format(utcToZonedTime(parsed, "UTC"), "MM/dd/yyyy", {
    timeZone: "UTC",
  });
};
