import { useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { Marker } from "mapbox-gl";

import { useAppMap } from "hooks/useAppMap";
import { OrganizationLocation } from "types/Location";

interface MapTabProps {
  location: OrganizationLocation;
}

export const MapTab = ({
  location: { gpsLocation },
}: MapTabProps): JSX.Element => {
  const theme = useTheme();
  const { map, container } = useAppMap();
  const marker = useRef<Marker>();

  useEffect(() => {
    if (!map.current) return;
    marker.current?.remove();

    marker.current = new Marker({ color: theme.palette.primary.main })
      .setLngLat([gpsLocation.longitude, gpsLocation.latitude])
      .addTo(map.current);

    map.current.setCenter(marker.current.getLngLat());
  }, [gpsLocation, map, theme]);

  return <Box ref={container} sx={{ flex: 1 }} />;
};
