import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import { AddButton } from "common/components/AddButton/AddButton";
import { StatusChip } from "components/StatusChip/StatusChip";
import { useGetActors } from "hooks/useGetActors";
import { DriverPoolActorDelete } from "./DriverPoolActorDelete";

interface ProductDriverPoolActorsProps {
  actorIds: Array<string>;
}

export const ProductDriverPoolActors = ({
  actorIds,
}: ProductDriverPoolActorsProps): JSX.Element => {
  const { isLoading, actors } = useGetActors({ actorIds });
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: "name",
      headerName: t("Name"),
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.details.firstName} ${params.row.details.lastName}`,
      flex: 1,
    },
    {
      field: "status",
      headerName: t("Status"),
      width: 120,
      align: "center",
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatusChip status={value} />
      ),
    },
    {
      field: "mobilePhone",
      headerName: t("Phone"),
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.details.mobilePhone}`,
    },
    {
      field: "email",
      headerName: t("Email"),
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.details.email}`,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      width: 30,
      align: "right",
      // eslint-disable-next-line react/display-name
      renderCell: (params) => <DriverPoolActorDelete actorId={params.row.id} />,
    },
  ];

  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs="auto">
          <Typography>{t("Drivers", { count: 1 })}</Typography>
        </Grid>
        <Grid item xs>
          <TextField
            fullWidth
            placeholder={t("StartTypingToSearch").toUpperCase()}
            hiddenLabel
            type="search"
            sx={{ pt: 0 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item width="106px">
          <AddButton variant="contained">{t("Assign")}</AddButton>
        </Grid>
      </Grid>
      {/* TODO: check if it looks good */}
      <Paper elevation={2} sx={{ flex: 1 }}>
        <DataGrid
          columns={columns}
          loading={isLoading}
          rows={actors}
          hideFooter
          // experimentalFeatures={{ preventCommitWhileValidating: true }}
        />
      </Paper>
    </Stack>
  );
};
