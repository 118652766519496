import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { OrganizationLocation } from "types/Location";

interface PaddingProps {
  location: OrganizationLocation;
}

export const Padding = ({ location }: PaddingProps): JSX.Element => {
  // TODO: Set padding from API
  const { t } = useTranslation();
  return (
    <Box
      paddingY={2}
      pl={2}
      sx={{ overflow: "auto", height: "100%", maxHeight: "100%" }}
    >
      {" "}
      <Typography pb={1}>
        {t("PickupTimeBuffer")}: {t("Minutes", { count: 10 })}
      </Typography>
      <Typography pb={1}>
        {t("DropoffTimeBuffer")}: {t("Minutes", { count: 10 })}
      </Typography>
      <Typography variant="h6" pb={1}>
        {t("DriverPaddings")}
      </Typography>{" "}
      <Box pl={2}>
        <Typography pb={1}>
          {t("ApplyAbove")}: {t("Drivers", { count: 1 })}
        </Typography>
        <Typography pb={1}>
          {t("PickupTimeBuffer")}: {t("Minutes", { count: 10 })}
        </Typography>
        <Typography pb={1}>
          {t("DropoffTimeBuffer")}: {t("Minutes", { count: 10 })}
        </Typography>
      </Box>
    </Box>
  );
};
