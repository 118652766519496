import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { useAuthApi } from "./useApi";
import { useSelectedOrganization } from "./useSelectedOrganization";

export const useSelectedOrganizationProducts = () => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const getSelectedOrganizationProducts = useAuthApi<null, any>(
    "get",
    endpoints.ORGANIZATION_PRODUCTS(selectedOrganizationId as string)
  );

  const { isLoading, data } = useQuery(
    [QueryKeys.GET_ORGANIZATION_PRODUCTS, selectedOrganizationId],
    () => getSelectedOrganizationProducts()
  );

  return {
    isLoading,
    products: data?.data.productConfig.products ?? [],
    configId: data?.data.productConfig.id ?? "",
  };
};
