import { ChangeEvent, useCallback, useState } from "react";

import {
  UsersDataGrid,
  UsersDataGridProps,
} from "common/components/UsersDataGrid/UsersDataGrid";

export const useUsersDataGrid = () => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const onPageSizeChange = useCallback((pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  }, []);

  const onPageChange = useCallback((page) => {
    setPage(page);
  }, []);

  const onSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setSearch(event.target.value);
  }, []);

  const usersDataGridProps: Pick<
    UsersDataGridProps,
    | "pageSize"
    | "onPageSizeChange"
    | "onPageChange"
    | "onSearchChange"
    | "showAddUserModal"
    | "setShowAddUserModal"
  > = {
    pageSize,
    onPageChange,
    onPageSizeChange,
    showAddUserModal,
    setShowAddUserModal,
    onSearchChange,
  };

  return {
    page,
    pageSize,
    search,
    UsersDataGrid,
    usersDataGridProps,
  };
};
