import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useAppDialog } from "hooks/useAppDialog";
import { useGetUser } from "../../../../../hooks/useGetUser";
import useImpersonateUser from "./useImpersonateUser";

interface Props {
  userId: string;
}

type AppOptionsTypes = "SKAI_VISION";

const ImpersonateUser = ({ userId }: Props) => {
  const { Dialog, dialogProps, openDialog } = useAppDialog();
  const selectedApp: AppOptionsTypes = "SKAI_VISION";
  const appsOptions: AppOptionsTypes[] = ["SKAI_VISION"];

  const textAreaEl = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const { user } = useGetUser({ userId });

  const { newPassword, createNewUserCredentials, isLoading } =
    useImpersonateUser({
      userId,
      applicationName: selectedApp,
    });

  useEffect(() => {
    createNewUserCredentials();
  }, [createNewUserCredentials]);

  const redirectToExternalApp = useCallback(() => {
    let currEnv = "";
    const currentPath = window.location.href;
    if (currentPath.includes("localhost") || currentPath.includes("dev")) {
      currEnv = ".dev";
    } else if (currentPath.includes("qa")) {
      currEnv = ".qa";
    }

    const encodedEmail = encodeURIComponent(user?.email);
    const encodedPassword = encodeURIComponent(newPassword as string);

    window.open(
      `https://vision${currEnv}.skaivision.net/login?username=${encodedEmail}&password=${encodedPassword}`
    );
  }, [user?.email, newPassword]);

  const onSubmit = () => {
    redirectToExternalApp();
  };

  const [blockImpersonation, setBlockImpersonation] = useState(false);

  const openDialogHandler = () => {
    const editUserSelectedId = sessionStorage.getItem("editUserSelectedId");

    const wasMatchFound = user.securityGroups?.some((securityGroup) => {
      return (
        securityGroup?.organization?.id === editUserSelectedId &&
        securityGroup.application === "SKAI_VISION"
      );
    });

    setBlockImpersonation(!wasMatchFound);
    openDialog();
  };

  return (
    <>
      <Button onClick={openDialogHandler}>{t("LoginAsUser")}</Button>
      <Dialog
        title={t("LoginAsUser")}
        {...dialogProps}
        actionText={t("GoToLogin")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        renderActions={!blockImpersonation}
        maxWidth="xs"
      >
        {blockImpersonation &&
          'One of the "SKAI Vision" group checkboxes needs to be checked to be able to impersonate this user.'}
        {!blockImpersonation && (
          <Grid container spacing={2}>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography>{t("Application")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  select
                  sx={{
                    width: "100%",
                  }}
                  value={selectedApp}
                >
                  {appsOptions.map((appName) => (
                    <MenuItem key={appName} value={appName}>
                      {appName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography>{t("Username")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography>{user?.email}</Typography>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item xs={3}>
                <Typography>{t("Password")}</Typography>
              </Grid>
              <Grid item xs={9}>
                <Stack direction="row">
                  <textarea
                    defaultValue={newPassword}
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                    ref={textAreaEl}
                    disabled
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default ImpersonateUser;
