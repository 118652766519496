import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateUtilityFeatureFlagApplicationCollectionName } from "hooks/useUpdateUtilityFeatureFlagApplicationCollectionName";
import { FeatureFlag, FeatureFlagData } from "types/FeatureFlag";
import { RowType } from "../FeatureFlagPanel/FeatureFlagPanel";

interface DeleteDataItemValueProps {
  row: RowType;
  featureFlag: FeatureFlag;
  collectionName: string;
}

export const DeleteDataItemValue = ({
  row,
  featureFlag,
  collectionName,
}: DeleteDataItemValueProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onUpdateFeatureFlag } =
    useUpdateUtilityFeatureFlagApplicationCollectionName({
      collectionName,
      featureFlagName: featureFlag.name,
    });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    const data: FeatureFlagData = {
      ...featureFlag,
      applicationCollectionName: collectionName,
      deciderData: {
        ...featureFlag.deciderData,
        [row.key]: [
          ...featureFlag.deciderData[row.key].slice(0, row.index),
          ...featureFlag.deciderData[row.key].slice(row.index + 1),
        ],
      },
    };
    return onUpdateFeatureFlag(data);
  }, [onUpdateFeatureFlag, featureFlag, row, collectionName]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteDataItemValue")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
