import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Box, Button, Paper } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";

interface DataGridRowValues {
  id: number;
  account: string;
  jobId: string;
  priority: string;
  items: number;
  created: Date;
  assigned: Date | null;
  deadline: Date;
  assignedTo: string | null;
  status: string;
}

interface TurkPipelineDataGridProps {
  rows: Array<DataGridRowValues>;
}

export const TurkPipelineDataGrid = ({
  rows,
}: TurkPipelineDataGridProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClaimClick = useCallback((jobId) => navigate(jobId), [navigate]);

  const columns: GridColumns = [
    {
      field: "id",
      hide: true,
      hideable: false,
    },
    {
      field: "account",
      headerName: t("Account"),
      flex: 0.8,
      hideable: false,
      sortable: false,
    },
    {
      field: "jobId",
      headerName: t("JobId"),
      flex: 0.8,
      hideable: false,
      sortable: false,
    },
    {
      field: "priority",
      headerName: t("Priority"),
      flex: 0.8,
      hideable: false,
      sortable: false,
    },
    {
      field: "items",
      headerName: t("Items"),
      flex: 0.5,
      hideable: false,
      sortable: false,
    },
    {
      field: "created",
      headerName: t("Created"),
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: "assigned",
      headerName: t("Assigned"),
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: "deadline",
      headerName: t("Deadline"),
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: "assignedTo",
      headerName: t("AssignedTo"),
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      hideable: false,
      sortable: false,
    },
    {
      field: "claim",
      headerName: "",
      sortable: false,
      hideable: false,
      align: "right",
      width: 84, // TODO: check i18n width
      renderCell: (params: GridRenderCellParams<unknown, DataGridRowValues>) =>
        params.row.status === "NOT STARTED" ? (
          <Button variant="text" onClick={() => onClaimClick(params.row.jobId)}>
            {t("Claim").toUpperCase()}
          </Button>
        ) : null,
    },
  ];

  return (
    <Box sx={{ display: "flex", flex: 1, minHeight: 500, pb: 2 }}>
      <Paper elevation={2} sx={{ flex: 1 }}>
        <DataGrid columns={columns} rows={rows} pageSize={25} />
      </Paper>
    </Box>
  );
};
