import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { PostNewUsers } from "types/User";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

export const usePostUser = () => {
  const queryClient = useQueryClient();
  const postUser = useAuthApi<PostNewUsers, unknown>("post", endpoints.USERS);
  const { t } = useTranslation();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();

  const { isLoading, mutate, isSuccess } = useMutation(postUser, {
    onSuccess: () => {
      const message = t("UserAdded");
      setSnackbarData({ severity: "success", message });
      auditFormEvent(message, "Add User", "FORM_SENT");
      queryClient.invalidateQueries([QueryKeys.GET_USERS, "app"]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message = data?.response?.data?.message ?? t("AddUserUnknownError");
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add User", "ERROR");
    },
  });

  const onPostUser = (data: PostNewUsers) => mutate(data);

  return {
    onPostUser,
    isLoading,
    isSuccess,
  };
};
