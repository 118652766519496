import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import {
  GetOrganizationProducts,
  ServerError,
  UpdateBasicSettingsData,
} from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";

export const useUpdateReyReyBasicSettings = () => {
  const { t } = useTranslation();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const { selectedOrganizationId } = useSelectedOrganization();
  const updateBasicSettings = useAuthApi<
    UpdateBasicSettingsData,
    GetOrganizationProducts
  >(
    "put",
    endpoints.UPDATE_REYREY_BASIC_SETTINGS(selectedOrganizationId as string)
  );

  const { isLoading, isSuccess, mutate } = useMutation(updateBasicSettings, {
    mutationKey: "UpdateReyReyBasicSettings",
    onSuccess: (data) => {
      queryClient.setQueryData(
        [QueryKeys.GET_ORGANIZATION_PRODUCTS, selectedOrganizationId],
        data
      );
      auditFormEvent(
        "Settings Updated",
        "Update ReyRey Basic Settings",
        "FORM_SENT"
      );
      setSnackbarData({ severity: "success", message: t("SettingsUpdated") });
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Update ReyRey Basic Settings unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Update ReyRey Basic Settings", "ERROR");
    },
  });

  const onUpdateBasicSettings = (data: UpdateBasicSettingsData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    onUpdateBasicSettings,
  };
};
