import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  DirectionsCar,
  Flare,
  Person,
  ReceiptLong,
  Search,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

import { BigIconButtonWithNotification } from "components/BigIconButtonWithNotification/BigIconButtonWithNotification";
import { turkPipelinesPaths } from "navigation/paths";
import { GreyBox } from "styles/App.styles";

export const TurkPipelinesMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onTurkPipelineElementClick = useCallback(
    (turkPipelinesPath) => navigate(turkPipelinesPath),
    [navigate]
  );

  return (
    <Stack flex={1}>
      <Stack mb={4}>
        <Typography variant="h6" mb={2}>
          {t("DocumentReview")}
        </Typography>
        <GreyBox elevation={0}>
          <Stack direction="row" spacing={2}>
            <BigIconButtonWithNotification
              label={t("ReceiptsReview")}
              onClick={() =>
                onTurkPipelineElementClick(turkPipelinesPaths.RECEIPTS_REVIEW)
              }
              Icon={ReceiptLong}
            />
            <BigIconButtonWithNotification
              label={t("ActorReview")}
              onClick={() =>
                onTurkPipelineElementClick(turkPipelinesPaths.ACTOR_REVIEW)
              }
              Icon={Person}
            />
            <BigIconButtonWithNotification
              label={t("ImageQuality")}
              onClick={() =>
                onTurkPipelineElementClick(turkPipelinesPaths.IMAGE_QUALITY)
              }
              Icon={Flare}
            />
          </Stack>
        </GreyBox>
      </Stack>
      <Stack mb={4}>
        <Typography variant="h6" mb={2}>
          {t("VehicleInspection")}
        </Typography>
        <GreyBox elevation={0}>
          <Stack direction="row" spacing={2}>
            <BigIconButtonWithNotification
              label={t("VehicleAngle")}
              onClick={() =>
                onTurkPipelineElementClick(turkPipelinesPaths.VEHICLE_ANGLE)
              }
              Icon={DirectionsCar}
            />
            <BigIconButtonWithNotification
              label={t("BeforeAfter")}
              onClick={() =>
                onTurkPipelineElementClick(turkPipelinesPaths.BEFORE_AFTER)
              }
              Icon={Search}
            />
          </Stack>
        </GreyBox>
      </Stack>
    </Stack>
  );
};
