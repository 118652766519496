import AddIcon from "@mui/icons-material/Add";
import { Button, ButtonProps } from "@mui/material";

export const AddButton = ({ children, ...props }: ButtonProps): JSX.Element => {
  return (
    <Button {...props} startIcon={<AddIcon />}>
      {children}
    </Button>
  );
};
