import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import { BackgroundCheck } from "./Registration";

interface BackgroundDetailsProps {
  details: BackgroundCheck;
}

export const BackgroundDetails = ({
  details,
}: BackgroundDetailsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Stack direction="row" spacing={4} ml={2}>
        <Stack spacing={2}>
          <Typography noWrap justifyContent="flex-end" variant="subtitle2">
            {`${t("IssueDate")}:`}
          </Typography>
          <Typography noWrap justifyContent="flex-end" variant="subtitle2">
            {`${t("Provider")}:`}
          </Typography>
          <Typography noWrap justifyContent="flex-end" variant="subtitle2">
            {`${t("Package")}:`}
          </Typography>
          <Typography noWrap justifyContent="flex-end" variant="subtitle2">
            {`${t("Reference")}:`}
          </Typography>
        </Stack>
        <Stack spacing={2} justifyContent="flex-start">
          <Typography noWrap overflow="unset" variant="subtitle2">
            {`${details.created}`}
          </Typography>
          <Typography noWrap overflow="unset" variant="subtitle2">
            {`${details.provider}`}
          </Typography>
          <Typography noWrap overflow="unset" variant="subtitle2">
            {`${details.package}`}
          </Typography>
          <Typography noWrap overflow="unset" variant="subtitle2">
            {`${details.id}`}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};
