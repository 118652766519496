import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { endpoints, QueryKeys } from "constants/constants";
import { organizationsPaths, paths } from "navigation/paths";
import { AuthenticateResponse, LoginData } from "types/Api";
import { clearAllTokens, setAuthTokens } from "utils/authLocalStorage";
import { setOnLocalStorage } from "utils/localStorage";
import { validateDefaultOrg } from "utils/validateDefaultOrg";
import { useAuthApi } from "./useApi";

export const useLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const authenticate = useAuthApi<LoginData, AuthenticateResponse>(
    "post",
    endpoints.AUTHENTICATE
  );

  const onFailedAuth = () => {
    clearAllTokens();
  };

  const { isError, isLoading, mutate, isSuccess } = useMutation(authenticate, {
    mutationKey: "authenticateMutation",
    onSuccess: ({ data }) => {
      const { user, allowedContexts, ...tokens } = data;
      const defaultOrg = validateDefaultOrg(allowedContexts);
      return setAuthTokens(data)
        .then(() => {
          // if user was redirected to the login from somewhere
          const from =
            (location.state as { from: Location })?.from?.pathname ||
            `/${paths.ORGANIZATIONS}/${defaultOrg.id}/${organizationsPaths.OVERVIEW}`;

          setOnLocalStorage("username", data.user.username);
          queryClient.setQueryData([QueryKeys.TOKENS], tokens);
          queryClient.setQueryData([QueryKeys.LOGGED_ID], user.id);
          navigate(from, { replace: true });
        })
        .catch(onFailedAuth);
    },
    onError: onFailedAuth,
  });

  const onLogin = (data: LoginData) => mutate(data);

  return {
    onLogin,
    isError,
    isSuccess,
    isLoading,
  };
};
