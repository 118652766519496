import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { DriverPool } from "types/Product";
import {
  AddDriverPoolValues,
  ProductDriverPoolAddForm,
} from "./ProductDriverPoolAddForm";

interface ProductDriverPoolAddProps {
  disabled?: boolean;
  driverPools: Array<DriverPool>;
}

export const ProductDriverPoolAdd = ({
  disabled = false,
  driverPools,
}: ProductDriverPoolAddProps): JSX.Element => {
  const { Dialog, dialogProps, openDialog } = useAppDialog();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<AddDriverPoolValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  // TODO: implement API endpoint request
  const addPool = () => Promise.resolve();

  return (
    <>
      <AddButton variant="contained" onClick={openDialog} disabled={disabled}>
        {t("AddPool")}
      </AddButton>
      <Dialog
        title={t("AddPool")}
        loading={false}
        actionText={t("Create")}
        onSubmit={onSubmit}
        maxWidth="xs"
        {...dialogProps}
      >
        <ProductDriverPoolAddForm
          formRef={formRef}
          onSubmit={addPool}
          driverPools={driverPools}
        />
      </Dialog>
    </>
  );
};
