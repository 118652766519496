import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useOrganizationAddProduct } from "hooks/useOrganizationAddProduct";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import {
  AddProductValues,
  OrganizationAddProductForm,
} from "./OrganizationAddProductForm";

interface OrganizationAddProductProps {
  disabled: boolean;
}

export const OrganizationAddProduct = ({
  disabled,
}: OrganizationAddProductProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<AddProductValues>>(null);
  const { isLoading, isSuccess, onAddProduct } = useOrganizationAddProduct({
    organizationId: selectedOrganizationId as string,
  });

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton variant="contained" onClick={openDialog} disabled={disabled}>
        {t("AddProduct")}
      </AddButton>
      <Dialog
        title={t("AddProduct")}
        loading={isLoading}
        actionText={t("Create")}
        onSubmit={onSubmit}
        maxWidth="xs"
        {...dialogProps}
      >
        <OrganizationAddProductForm formRef={formRef} onSubmit={onAddProduct} />
      </Dialog>
    </>
  );
};
