import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useOrganizationDeleteProduct } from "hooks/useOrganizationDeleteProduct";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";

interface OrganizationProductDeleteProps {
  productType: string;
}

export const OrganizationProductDelete = ({
  productType,
}: OrganizationProductDeleteProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { onDeleteProduct, isSuccess, isLoading } =
    useOrganizationDeleteProduct({
      organizationId: selectedOrganizationId as string,
    });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );
  const onDeleteConfirm = useCallback(() => {
    onDeleteProduct([productType]);
  }, [onDeleteProduct, productType]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteProductTitle")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
