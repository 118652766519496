import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
} from "@mui/material";

import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { Tabs } from "common/components/Tabs/Tabs";
import {
  PeopleMovementProduct,
  Product,
  ReyAndReyProduct,
  VehicleMovementProduct,
} from "types/Product";
import { Basic } from "./Basic/Basic";
import { DefaultProduct } from "./DefaultProduct/DefaultProduct";
import { ProductCommissions } from "./ProductCommissions/ProductCommissions";
import { ProductDriverPool } from "./ProductDriverPool/ProductDriverPool";
import { ProductFee } from "./ProductFee/ProductFee";
import { ProductIntegration } from "./ProductIntegrations/ProductIntegration";
import { ProductLocation } from "./ProductLocation/ProductLocation";
import { ProductManagedServices } from "./ProductManagedServices/ProductManagedServices";
import { ProductPeopleMovement } from "./ProductPeopleMovement/ProductPeopleMovement";
import { ProductRateStructure } from "./ProductRateStructure/ProductRateStructure";
import { SettingIcon } from "./SettingIcon";

interface ProductSettingsProps {
  product: Product;
}

const settings = {
  REY_N_REY: ["BASIC"],
  VEHICLE_MOVEMENT: ["DRIVER_POOL"],
  PEOPLE_MOVEMENT: ["BUILD_ME"],
};

export const ProductSettings = ({
  product,
}: ProductSettingsProps): JSX.Element => {
  const { t } = useTranslation();
  const [selectedSettingType, setSelectedSettingType] = useState("");

  useEffect(() => {
    if (settings[product.productType]) {
      setSelectedSettingType(settings[product.productType][0] ?? "");
    } else {
      setSelectedSettingType("NOT_DEFINED");
    }
  }, [product]);

  return (
    <Stack sx={{ height: "100%" }}>
      <TabContext value="Settings">
        <Tabs>
          <Tab label={t("Settings")} value="Settings" />
        </Tabs>
        <TabPanel value="Settings">
          <Grid container spacing={2} sx={{ pt: 2, flex: 1 }}>
            <Grid item xs="auto">
              {product && settings[product.productType] ? (
                <List sx={{ py: 0 }}>
                  {settings[product.productType].map((setting) => (
                    <ListItemButton
                      selected={setting === selectedSettingType}
                      key={setting}
                      onClick={() => setSelectedSettingType(setting)}
                    >
                      <ListItemIcon sx={{ minWidth: "36px" }}>
                        <SettingIcon type={setting} />
                      </ListItemIcon>
                      <ListItemText>
                        {t(setting as unknown as TemplateStringsArray)}
                      </ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              ) : (
                <ListSkeleton width={160} withDivider />
              )}
            </Grid>
            <Grid item sx={{ flex: 1, display: "flex" }} xs>
              {selectedSettingType === "COMMISSION" && <ProductCommissions />}
              {selectedSettingType === "DRIVER_POOL" && (
                <ProductDriverPool
                  product={product as VehicleMovementProduct}
                />
              )}
              {selectedSettingType === "FEE" && <ProductFee />}
              {selectedSettingType === "INTEGRATION" && <ProductIntegration />}
              {selectedSettingType === "LOCATIONS" && <ProductLocation />}
              {selectedSettingType === "MANAGED_SERVICES" && (
                <ProductManagedServices />
              )}
              {selectedSettingType === "RATE_STRUCTURE" && (
                <ProductRateStructure />
              )}
              {selectedSettingType === "BASIC" && (
                <Basic product={product as ReyAndReyProduct} />
              )}
              {selectedSettingType === "BUILD_ME" && ( // TODO: Change value when it is defined
                <ProductPeopleMovement
                  product={product as PeopleMovementProduct}
                />
              )}
              {selectedSettingType === "NOT_DEFINED" && ( // TODO: Default component when setting is not found
                <DefaultProduct />
              )}
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </Stack>
  );
};
