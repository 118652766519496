import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationResponse, ServerError } from "types/Api";
import { OrganizationUpdateParams } from "types/Organization";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { organizationDetailRequest } from "./useGetOrganizationsFlat";
import { usePostAudit } from "./usePostAudit";

interface useUpdateOrganizationProps {
  id: string;
}

export const useUpdateOrganization = ({ id }: useUpdateOrganizationProps) => {
  const { t } = useTranslation();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const updateOrganization = useAuthApi<
    Partial<OrganizationUpdateParams>,
    GetOrganizationResponse
  >("patch", endpoints.ORGANIZATION(id));
  const queryClient = useQueryClient();

  const { isError, isLoading, mutate, isSuccess } = useMutation(
    updateOrganization,
    {
      mutationKey: "updateOrganization",
      onSuccess: (data, variables) => {
        if (variables?.name || variables?.parentId) {
          queryClient.invalidateQueries([
            QueryKeys.GET_ORGANIZATIONS_FLAT,
            organizationDetailRequest.params,
          ]);
        }
        queryClient.setQueriesData([QueryKeys.GET_ORGANIZATION, id], data);
        auditFormEvent("Settings Updated", "Add Organization", "FORM_SENT");
        setSnackbarData({
          severity: "success",
          message: t("OrganizationUpdated"),
        });
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ?? "Patch Organization unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Add Organization", "ERROR");
      },
    }
  );

  const update = (data: Partial<OrganizationUpdateParams>) => {
    mutate(data);
  };

  return {
    isLoading,
    isError,
    isSuccess,
    updateOrganization: update,
  };
};
