import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
  SwitchProps,
} from "@mui/material";
import { useField } from "formik";

import { Switch } from "../Switch/Switch";

type FormControlLabelProps = Omit<
  MuiFormControlLabelProps & {
    switchProps?: SwitchProps;
    name: string;
    readOnly?: boolean;
  },
  "control"
>;

export const FormControlLabelSwitch = ({
  switchProps,
  ...props
}: FormControlLabelProps): JSX.Element => {
  const [field] = useField(props.name);
  return (
    <MuiFormControlLabel
      {...props}
      sx={{ ...props.sx, pointerEvents: props.readOnly ? "none" : "all" }}
      {...field}
      control={
        <Switch
          checked={field.value}
          {...switchProps}
          readOnly={props.readOnly ?? false}
          inputProps={{
            ...(switchProps?.inputProps ?? {}),
            readOnly: props.readOnly ?? false,
          }}
        />
      }
    />
  );
};
