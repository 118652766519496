import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";
import { Skeleton } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import {
  organizationDetailRequest,
  useGetOrganizationsFlat,
} from "hooks/useGetOrganizationsFlat";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { organizationsPaths, paths } from "navigation/paths";
import { OrganizationFlat } from "types/Organization";

interface Breadcrumb {
  label: string;
  id: string;
}

interface OrganizationsBreadCrumbsProps {
  maxItems?: number;
}

const organizationLinkOverview = (id: string) =>
  `${paths.DASHBOARD}${paths.ORGANIZATIONS}/${id}/${organizationsPaths.OVERVIEW}`;

export const OrganizationsBreadcrumbs = ({
  maxItems = undefined,
}: OrganizationsBreadCrumbsProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const { organizationsFlat, isLoading } = useGetOrganizationsFlat(
    organizationDetailRequest
  );
  const selectedOrganization = useMemo(() => {
    return organizationsFlat.find(
      (organization) => organization.id === selectedOrganizationId
    );
  }, [organizationsFlat, selectedOrganizationId]);

  const breadcrumbs = useMemo(() => {
    const list: Array<Breadcrumb> = [];
    const recursive = (
      organization: OrganizationFlat | null
    ): Array<Breadcrumb> => {
      if (!organization) return list;
      list.push({ label: organization.name, id: organization.id });
      return recursive(organization?.parent ?? null);
    };
    return recursive(selectedOrganization?.parent ?? null).reverse();
  }, [selectedOrganization]);

  if (isLoading) return <Skeleton variant="text" sx={{ my: 1 }} width={300} />;

  return (
    <Breadcrumbs
      maxItems={maxItems}
      aria-label="breadcrumb"
      sx={{ py: 1, fontSize: "0.75rem" }}
    >
      {breadcrumbs.map(({ label, id }) => (
        <Link
          to={organizationLinkOverview(id)}
          key={id}
          component={RouterLink}
          underline="none"
        >
          <Typography
            sx={{
              color: "primary.light",
              textTransform: "uppercase",
              fontSize: "0.75rem",
            }}
          >
            {label}
          </Typography>
        </Link>
      ))}
      <Typography
        sx={{
          color: "text.primary",
          textTransform: "uppercase",
          fontSize: "0.75rem",
        }}
      >
        {selectedOrganization?.name}
      </Typography>
    </Breadcrumbs>
  );
};
