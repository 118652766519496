import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { useFormikContext } from "formik";

import { Select } from "common/components/Select/Select";
import { TextField } from "common/components/TextField/TextField";
import { FormValues } from "../../EditUserForm";
import { DeleteEarning } from "./DeleteEarning";
import { EditEarning } from "./EditEarning";

export interface Earning {
  date: Date;
  type: string;
  id: string;
  status: string;
  amount: number;
}

export const Payment = (): JSX.Element => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FormValues>();
  const earningColumns: GridColumns = [
    {
      field: "date",
      headerName: t("Date"),
      flex: 1,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("Type"),
      flex: 1,
      sortable: false,
    },
    {
      field: "id",
      headerName: t("Id").toUpperCase(),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("Status"),
      flex: 1,
      sortable: false,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      flex: 0.5,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Earning>) => {
        return `$${params.value}`;
      },
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      align: "right",
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<Earning>) => (
        <Box sx={{ ml: "auto" }}>
          <EditEarning earning={params.row as Earning} />
          <DeleteEarning earningId={params.row.id} />
        </Box>
      ),
    },
  ];

  const depositColumns: GridColumns = [
    {
      field: "date",
      headerName: t("Date"),
      flex: 1,
      sortable: false,
    },
    {
      field: "id",
      headerName: t("Id").toUpperCase(),
      flex: 1,
      sortable: false,
    },
    {
      field: "amount",
      headerName: t("Amount"),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Earning>) => {
        return `$${params.value}`;
      },
    },
  ];

  const earningRows: Array<Earning> = [
    {
      date: new Date(2022, 1, 1),
      type: "ITINERARY",
      id: "23892943F3343",
      status: "PENDING",
      amount: 250,
    },
    {
      date: new Date(2022, 1, 2),
      type: "ITINERARY",
      id: "24892943F3343",
      status: "PENDING",
      amount: 350,
    },
  ];

  const depositRows = [
    {
      date: "2022-01-01",
      id: "234982347234",
      amount: 250,
    },
  ];

  return (
    <Grid container>
      <Grid item xs="auto">
        <Box sx={{ height: 250, border: "3px solid grey", m: 4 }}>
          <Stack spacing={1} m={2}>
            <Select name="payment.paymentMethod" label={t("PaymentMethod")}>
              <MenuItem value={"ACH"}>{"ACH"}</MenuItem>
            </Select>
            <TextField name="payment.bankAccount" label={t("BankAccount")} />
            <TextField name="payment.bankRouting" label={t("BankRouting")} />
          </Stack>
        </Box>
        <Box sx={{ height: 250, border: "3px solid grey", m: 4 }}>
          <Typography
            noWrap
            justifyContent="flex-end"
            variant="subtitle2"
            m={1}
          >
            {`${t("PexCard").toUpperCase()}:`}
          </Typography>
          <Stack direction="row" spacing={4} m={2}>
            <Stack spacing={2}>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("PexId").toUpperCase()}:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("PexCard")} #:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("IssueDate")}:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("Status")}:`}
              </Typography>
            </Stack>
            <Stack spacing={2} justifyContent="flex-start">
              <Typography noWrap overflow="unset" variant="subtitle2">
                {values.payment.pexCard.pexId}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {values.payment.pexCard.pexCard}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {values.payment.pexCard.issueDate.toLocaleDateString()}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {values.payment.pexCard.status}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" mr={1}>
            <Button variant="outlined">{t("Disabled").toUpperCase()}</Button>
            <Button variant="contained">{t("Revoke").toUpperCase()}</Button>
          </Stack>
        </Box>
      </Grid>
      <Grid item xs>
        <Typography noWrap overflow="unset" variant="h6">
          {t("Earnings")}
        </Typography>
        <Box sx={{ height: 500, pt: 2, pb: 4, display: "flex" }}>
          <Paper elevation={2} sx={{ flex: 1 }}>
            <DataGrid rows={earningRows} columns={earningColumns} />
          </Paper>
        </Box>
        <Typography noWrap overflow="unset" variant="h6">
          {t("Deposits")}
        </Typography>
        <Box sx={{ height: 400, display: "flex", pt: 2 }}>
          <Paper elevation={2} sx={{ flex: 1 }}>
            <DataGrid rows={depositRows} columns={depositColumns} />
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};
