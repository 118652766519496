import { useCallback, useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { usePostUtilityFeatureFlagApplicationCollectionName } from "hooks/usePostUtilityFeatureFlagApplicationCollectionName";
import { FormValues } from "../FeatureFlagFields/FeatureFlagFields";
import { AddFeatureFlagForm } from "./AddFeatureFlagForm";

interface AddFeatureFlagProps {
  disabled: boolean;
  collectionName: string;
}

export const AddFeatureFlag = ({
  disabled,
  collectionName,
}: AddFeatureFlagProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onPostFeatureFlag } =
    usePostUtilityFeatureFlagApplicationCollectionName({ collectionName });
  const formRef = useRef<FormikProps<FormValues>>(null);

  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton
        sx={{ ml: 2 }}
        disabled={disabled}
        variant="contained"
        onClick={openDialog}
      >
        {t("Add")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("CreateFeatureFlag")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="sm"
      >
        <AddFeatureFlagForm
          formRef={formRef}
          onSubmit={onPostFeatureFlag}
          collectionName={collectionName}
        />
      </Dialog>
    </>
  );
};
