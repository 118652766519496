import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationLocationResponse } from "types/Api";
import { useAuthApi } from "./useApi";
import { useSelectedOrganization } from "./useSelectedOrganization";

interface UseGetSelectedOrganizationLocationProps {
  locationId: string;
}

export const useGetSelectedOrganizationLocation = ({
  locationId,
}: UseGetSelectedOrganizationLocationProps) => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const getLocation = useAuthApi<never, GetOrganizationLocationResponse>(
    "get",
    endpoints.ORGANIZATION_LOCATION(
      selectedOrganizationId as string,
      locationId
    )
  );

  const { isLoading, data } = useQuery(
    [QueryKeys.GET_ORGANIZATION_LOCATIONS, selectedOrganizationId, locationId],
    () => getLocation()
  );

  return {
    isLoading,
    location: data?.data.data,
  };
};
