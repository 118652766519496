import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { GppGood } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FormikProps, useFormikContext } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { FormValues } from "../../EditUserForm";
import {
  BackgroundCheckValues,
  NewBackgroundCheckForm,
} from "./NewBackgroundCheckForm";

export const NewBackgroundCheck = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<BackgroundCheckValues>>(null);

  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  const addNewBackgroundCheck = useCallback(
    (certification) => {
      setFieldValue("certifications", [
        ...values.certifications,
        certification,
      ]);
      closeDialog();
    },
    [setFieldValue, values, closeDialog]
  );

  return (
    <>
      <Button
        variant="contained"
        startIcon={<GppGood fontSize="small" />}
        onClick={openDialog}
      >
        {t("NewBackgroundCheck")}
      </Button>
      <Dialog
        {...dialogProps}
        title={t("NewBackgroundCheck")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Submit")}
        maxWidth="xs"
      >
        <NewBackgroundCheckForm
          formRef={formRef}
          onSubmit={addNewBackgroundCheck}
        />
      </Dialog>
    </>
  );
};
