import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";

import { AddPlates } from "./AddPlates";
import { DeletePlate } from "./DeletePlate";

interface Plate {
  id: number;
  issuedDate: string;
  plateNumber: string;
  country: string;
  state: string;
  expirationDate: string;
}

export const LicensePlates = (): JSX.Element => {
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "issuedDate",
      headerName: t("IssueDate"),
      flex: 1,
      sortable: false,
    },
    {
      field: "plateNumber",
      headerName: t("PlateNumber"),
      flex: 1,
      sortable: false,
    },
    {
      field: "country",
      headerName: t("Country"),
      flex: 1,
      sortable: false,
    },
    {
      field: "state",
      headerName: t("StateProvince"),
      flex: 1,
      sortable: false,
    },
    {
      field: "expirationDate",
      headerName: t("ExpirationDate"),
      flex: 1,
      sortable: false,
    },
    {
      field: "delete",
      headerName: "",
      sortable: false,
      align: "right",
      width: 100,
      renderCell: (params: GridRenderCellParams<Plate>) => (
        <Box sx={{ ml: "auto" }}>
          <DeletePlate plateId={params.row.id} />
        </Box>
      ),
    },
  ];

  const rows: Array<Plate> = [
    {
      id: 0,
      issuedDate: "2022-01-01",
      plateNumber: "3ATM3",
      country: "united States of America",
      state: "GA",
      expirationDate: "2022-12-15",
    },
    {
      id: 1,
      issuedDate: "2022-01-01",
      plateNumber: "BYBYOFCR",
      country: "United States of America",
      state: "KS",
      expirationDate: "2022-12-15",
    },
  ];
  return (
    <Grid container>
      <Grid item xs={12}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2, pb: 2 }}
        >
          <Typography noWrap overflow="unset" mr={2}>
            {t("TotalPlates")}: {rows.length}
          </Typography>
          <AddPlates />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ height: 500, display: "flex" }}>
          <Paper elevation={2} sx={{ flex: 1 }}>
            <DataGrid columns={columns} rows={rows} />
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};
