import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationResponse, ServerError } from "types/Api";
import { OrganizationUpdateParams } from "types/Organization";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { organizationDetailRequest } from "./useGetOrganizationsFlat";
import { usePostAudit } from "./usePostAudit";

export const usePostOrganization = () => {
  const { setSnackbarData } = useAppSnackbar();
  const queryClient = useQueryClient();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const postOrganization = useAuthApi<
    Partial<OrganizationUpdateParams>,
    GetOrganizationResponse
  >("post", endpoints.ORGANIZATION(""));

  const { isError, isLoading, mutate, isSuccess } = useMutation(
    postOrganization,
    {
      mutationKey: "postOrganization",
      onSuccess: () => {
        auditFormEvent("Organization Added", "Add Organization", "FORM_SENT");
        return queryClient.invalidateQueries([
          QueryKeys.GET_ORGANIZATIONS_FLAT,
          organizationDetailRequest.params,
        ]);
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ?? "Post Organization unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Add Organization", "ERROR");
      },
    }
  );

  const post = (data: Partial<OrganizationUpdateParams>) => mutate(data);

  return {
    isLoading,
    isError,
    isSuccess,
    postOrganization: post,
  };
};
