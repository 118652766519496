import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteUtilityFeatureFlag } from "hooks/useDeleteUtilityFeatureFlag";

interface DeleteFeatureFlagProps {
  featureFlagName: string;
  collectionName: string;
  onDeleted: (featureFlagName: string) => void;
}

export const DeleteFeatureFlag = ({
  featureFlagName,
  collectionName,
  onDeleted,
}: DeleteFeatureFlagProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { isSuccess, isLoading, onDeleteFeatureFlag } =
    useDeleteUtilityFeatureFlag({
      featureFlagName,
      collectionName,
    });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    onDeleteFeatureFlag().then(() => {
      return onDeleted(featureFlagName);
    });
  }, [onDeleteFeatureFlag, onDeleted, featureFlagName]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteFeatureFlag")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
