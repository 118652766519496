import { useMemo } from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { enUS, esES } from "@mui/material/locale";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";

import { useSetupApi } from "hooks/useApi";
import { useAppSnackbar } from "hooks/useAppSnackbar";
import { useGetLoggedUser } from "hooks/useGetLoggedUser";
import { Navigation } from "navigation/Navigation";
import { theme } from "styles/appTheme";

const getSuportedLocale = (locale: string) => {
  const locales = {
    en: enUS,
    es: esES,
  };
  return locales[locale] ?? enUS;
};

export const App = (): JSX.Element => {
  useSetupApi();

  const { AppSnackbar, appSnackbarProps } = useAppSnackbar();
  const { currentLocale } = useGetLoggedUser();

  const themeWithLocale = useMemo(
    () => createTheme(theme, getSuportedLocale(currentLocale)),
    [currentLocale]
  );

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={themeWithLocale}>
        <CssBaseline />
        <Navigation />
        <AppSnackbar {...appSnackbarProps} />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
