import { useMutation } from "react-query";

import { endpoints } from "../constants/constants";
import { useAuthApi } from "./useApi";

interface RequestData {
  userIds: string[];
}

const useForcePasswordChange = () => {
  const setRequiredPassChange = useAuthApi<RequestData, unknown>(
    "post",
    endpoints.REQUIRED_PASSWORD_CHANGE
  );

  const { isLoading, mutateAsync } = useMutation(setRequiredPassChange);

  const executeRequest = async (userIds: RequestData) => {
    return await mutateAsync(userIds);
  };

  return {
    doForcePasswordChange: executeRequest,
    isPasswordChangeLoading: isLoading,
  };
};

export default useForcePasswordChange;
