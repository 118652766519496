import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { DatePicker } from "common/components/DatePicker/DatePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";

interface MedicalCardFieldsProps {
  namePrefix?: string;
}

export const MedicalCardFields = ({
  namePrefix = "",
}: MedicalCardFieldsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FormGridContainer>
      <Grid item xs={6}>
        <TextField
          name={`${namePrefix}nationalRegistryNumber`}
          label={t("NationalRegistryNumber")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          name={`${namePrefix}expirationDateUTC`}
          label={t("ExpirationDate")}
          inputFormat={t("DateFormat")}
        />
      </Grid>
    </FormGridContainer>
  );
};
