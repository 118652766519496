import { RefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, InputAdornment, MenuItem } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

import { CurrencySelect } from "common/components/CurrencySelect/CurrencySelect";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { LocaleSelect } from "common/components/LocaleSelect/LocaleSelect";
import { OrganizationSelect } from "common/components/OrganizationSelect/OrganizationSelect";
import { Select } from "common/components/Select/Select";
import { TextField } from "common/components/TextField/TextField";
import { CURRENCY_DEFAULT, LOCALE_DEFAULT } from "constants/constants";
import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useValidationSchema } from "hooks/useValidationSchema";
import {
  OrganizationFlat,
  OrganizationUpdateParams,
  Type,
} from "types/Organization";
import {
  emptyOpenScheduleState,
  formToSchedule,
  HourOfOperationValues,
  scheduleToForm,
} from "../EditHoursOfOperation/formUtils";
import { HoursOfOperationFormFields } from "../EditHoursOfOperation/HoursOfOperationFormFields";

export interface EditOrganizationValues extends HourOfOperationValues {
  name: string;
  parentId: OrganizationFlat | null;
  websiteUrl: string;
  type: Type;
  currency: string;
  locale: string;
}

interface EditOrganizationFormProps {
  formRef: RefObject<FormikProps<EditOrganizationValues>>;
  onSubmit: (
    values: Pick<
      OrganizationUpdateParams,
      | "name"
      | "parentId"
      | "websiteUrl"
      | "type"
      | "currency"
      | "locale"
      | "hoursOfOperation"
    >
  ) => void;
}

export const EditOrganizationForm = ({
  formRef,
  onSubmit,
}: EditOrganizationFormProps) => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema("EditOrganization");
  const { selectedOrganizationId } = useSelectedOrganization();
  const { data: organizationData } = useGetOrganization({
    id: selectedOrganizationId as string,
  });

  const initialValues: EditOrganizationValues = useMemo(
    () => ({
      name: organizationData?.name ?? "",
      parentId: organizationData?.parent ?? null,
      websiteUrl: organizationData?.websiteUrl ?? "",
      type: organizationData?.type ?? "ROOFTOP",
      currency: organizationData?.currency ?? CURRENCY_DEFAULT,
      locale: organizationData?.locale ?? LOCALE_DEFAULT,
      hoursOfOperation: organizationData?.productConfig?.products[0]
        .hoursOfOperation
        ? scheduleToForm(
            organizationData?.productConfig.products[0].hoursOfOperation
          )
        : emptyOpenScheduleState(),
    }),
    [organizationData]
  );

  const onFormSubmit = useCallback(
    ({
      name,
      parentId,
      websiteUrl,
      type,
      currency,
      locale,
      hoursOfOperation,
    }: EditOrganizationValues) => {
      hoursOfOperation = formToSchedule({
        hoursOfOperation: hoursOfOperation,
      }) as any;
      hoursOfOperation["zoneId"] =
        organizationData?.mailingAddress?.timezone?.zoneId ?? "America/Chicago";

      return onSubmit({
        name,
        websiteUrl,
        type,
        currency,
        locale,
        parentId: parentId?.id ?? null,
        hoursOfOperation,
      });
    },
    [onSubmit]
  );

  return (
    <Formik
      onSubmit={onFormSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      <Form>
        <FormGridContainer>
          <Grid xs={4} item>
            <TextField label={t("Name")} fullWidth name="name" />
          </Grid>
          <Grid xs={4} item>
            <Select name="type" label={t("Type")} fullWidth>
              <MenuItem value="ROOFTOP">{t("Rooftop")}</MenuItem>
              <MenuItem value="GROUP">{t("Group")}</MenuItem>
            </Select>
          </Grid>
          <Grid xs={4} item>
            <OrganizationSelect
              name="parentId"
              filterIds={[selectedOrganizationId as string]}
              label={t("Parent")}
            />
          </Grid>
          <Grid xs={4} item>
            <TextField
              label={t("Website")}
              fullWidth
              name="websiteUrl"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">https://</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid xs={4} item>
            <LocaleSelect name="locale" fullWidth label={t("Locale")} />
          </Grid>
          <Grid xs={4} item>
            <CurrencySelect name="currency" fullWidth label={t("Currency")} />
          </Grid>

          <Grid xs={12} item>
            <HoursOfOperationFormFields name="hoursOfOperation" />
          </Grid>
        </FormGridContainer>
      </Form>
    </Formik>
  );
};