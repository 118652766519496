import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { usePostSelectedOrganizationLocation } from "hooks/usePostSelectedOrganizationLocation";
import { FormValues } from "../Forms/formUtils";
import { AddLocationForm } from "./AddLocationForm/AddLocationForm";

interface AddLocationProps {
  disabled?: boolean;
}
export const AddLocation = ({
  disabled = false,
}: AddLocationProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onPostLocation } =
    usePostSelectedOrganizationLocation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton
        variant="contained"
        onClick={openDialog}
        disabled={disabled}
        sx={{ ml: 2 }}
      >
        {t("AddLocation")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("NewLocation")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="lg"
      >
        <AddLocationForm formRef={formRef} onSubmit={onPostLocation} />
      </Dialog>
    </>
  );
};
