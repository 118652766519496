import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Grid,
  List,
  ListItemText,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";

import { DateTimePicker } from "common/components/DateTimePicker/DateTimePicker";
import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { TextField } from "common/components/TextField/TextField";
import { useGetUsers } from "hooks/useGetUsers";
import { exclusion, FormValues } from "../EditUserForm";

export const Exclusions = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, handleChange, setFieldValue } =
    useFormikContext<FormValues>();
  const [selectedOrganization, setSelectedOrganization] = useState<exclusion>();
  const [selectedIndex, setSelectedIndex] = useState<Array<number>>([]);
  const [search, setSearch] = useState("");
  const { isLoading, usersResponse } = useGetUsers({
    params: {
      s: search,
      startRecord: 0,
      pageSize: 20,
      outputOrganizations: false,
      outputGroups: false,
    },
  });

  const renderChildren = (exclusion: Array<exclusion>, margin: number) => (
    <List sx={{ py: 0, overflow: "auto" }}>
      {exclusion.map((exclusion, index) => (
        <Fragment key={index}>
          <ListItemButtonWithDialog
            key={exclusion.organizationId}
            selected={
              exclusion.organizationName ===
              selectedOrganization?.organizationName
            }
            divider={index !== values.exclusions.length - 1}
            onClick={() => {
              setSelectedOrganization(exclusion);
              setSelectedIndex([...selectedIndex, index]);
            }}
            sx={{ ml: margin }}
          >
            <Checkbox
              name={`${exclusion.organizationId}.checked`}
              onChange={handleChange}
            />
            <ListItemText>{exclusion.organizationName}</ListItemText>
          </ListItemButtonWithDialog>
          <Collapse in={true} timeout="auto">
            {exclusion.children.length > 0
              ? renderChildren(exclusion.children, margin + 2)
              : null}
          </Collapse>
        </Fragment>
      ))}
    </List>
  );

  return (
    <Grid container>
      <Grid item xs="auto">
        <List sx={{ py: 0, overflow: "auto" }}>
          {values.exclusions.map((exclusion, index) => (
            <Fragment key={index}>
              <ListItemButtonWithDialog
                key={exclusion.organizationId}
                selected={
                  exclusion.organizationName ===
                  selectedOrganization?.organizationName
                }
                divider={index !== values.exclusions.length - 1}
                onClick={() => setSelectedOrganization(exclusion)}
              >
                <Checkbox
                  name={`${exclusion.organizationId}.checked`}
                  onChange={handleChange}
                />
                <ListItemText>{exclusion.organizationName}</ListItemText>
              </ListItemButtonWithDialog>
              <Collapse in={true} timeout="auto">
                {exclusion.children.length > 0
                  ? renderChildren(exclusion.children, 2)
                  : null}
              </Collapse>
            </Fragment>
          ))}
        </List>
      </Grid>
      <Grid item xs sx={{ marginLeft: "1%" }}>
        {selectedOrganization && (
          <>
            <Typography mb={2} variant="h6">
              {selectedOrganization.organizationName}
            </Typography>
            <Box width={"35%"}>
              <DateTimePicker
                name={`${selectedOrganization.organizationId}.exclusionDate`}
                label={t("ExclusionDate")}
              />
              <Autocomplete
                id="excludedBy"
                autoComplete
                options={usersResponse?.matches ?? []}
                autoSelect
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                isOptionEqualToValue={(option, value) =>
                  `${option.firstName} ${option.lastName}` ===
                  `${value.firstName} ${value.lastName}`
                }
                onInputChange={(_, newInputValue) => {
                  setSearch(newInputValue);
                }}
                onChange={(_, value) =>
                  setFieldValue(
                    `${selectedOrganization.organizationId}.excludedBy`,
                    value
                  )
                }
                loading={isLoading}
                loadingText={t("Loading")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name={`${selectedOrganization.organizationId}.excludedBy`}
                    label={t("ExcludedBy")}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "do-not-autofill",
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                name={`${selectedOrganization.organizationId}.reason`}
                label={t("Reason")}
                multiline
                rows={20}
                fullWidth
              />
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};
