import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { organizationsPaths, paths } from "navigation/paths";
import { Organization } from "types/Organization";
import { getFromLocalStorage } from "utils/localStorage";
import { validateDefaultOrg } from "utils/validateDefaultOrg";

export const IndexRedirection = () => {
  const allowedOrganizations = getFromLocalStorage(
    "allowedOrganizations"
  ) as Array<Organization>;

  const defaultOrg = validateDefaultOrg(allowedOrganizations);
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/${paths.ORGANIZATIONS}/${defaultOrg.id}/${organizationsPaths.OVERVIEW}`
    );
  }, [defaultOrg.id, navigate]);

  return null;
};
