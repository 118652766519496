import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseDeleteUserFromOrganizationGroupProps {
  groupId: string;
  organizationId: string;
}

export const useDeleteUserFromOrganizationGroup = ({
  groupId,
  organizationId,
}: UseDeleteUserFromOrganizationGroupProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const deleteUserFromGroup = useAuthApi<Array<string>, unknown>(
    "delete",
    endpoints.ORGANIZATION_GROUP_USERS(organizationId, groupId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(deleteUserFromGroup, {
    onSuccess: () => {
      return queryClient.invalidateQueries([
        QueryKeys.GET_USERS,
        "organization",
        organizationId,
      ]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Delete User from Group unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Delete User from Group", "ERROR");
    },
  });

  const onDeleteUser = (data: Array<string>) => mutate(data);

  return {
    isLoading,
    isSuccess,
    onDeleteUser: onDeleteUser,
  };
};
