import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormikContext } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { FormValues as UserFormValues } from "../../EditUserForm";
import { AddTagForm, FormValues } from "./AddTagForm";

export const AddTag = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<UserFormValues>();
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<FormValues>>(null);

  const addToUser = useCallback(
    ({ tags }) => {
      const newTags = new Set([...values.tags, ...tags]);
      setFieldValue("tags", Array.from(newTags));
      closeDialog();
    },
    [closeDialog, setFieldValue, values.tags]
  );

  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  return (
    <>
      <AddButton variant="contained" onClick={openDialog}>
        {t("AddTag")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("AddTag")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="md"
      >
        <AddTagForm formRef={formRef} onSubmit={addToUser} />
      </Dialog>
    </>
  );
};
