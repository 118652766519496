import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { useField } from "formik";

import { useGetTimezones } from "hooks/useGetTimezones";
import { Timezone } from "types/Organization";
import { DEFAULT_TIME_ZONE } from "../../../constants/constants";

interface TimezoneSelectProps {
  name: string;
  label: string;
  readOnly?: boolean;
  fullWidth?: boolean;
}

export const TimezoneSelect = (props: TimezoneSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, timezones } = useGetTimezones();
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(
    props.name
  );
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const getOptionLabel = useCallback(
    (option: Timezone) =>
      `${option?.zoneId} (GMT ${option?.offsetAsSeconds > 0 ? "+" : ""}${
        option?.offsetAsSeconds / 60
      } hrs)`,
    []
  );

  const onChange = useCallback(
    (_, value: Timezone) => setValue(value),
    [setValue]
  );

  const isOptionEqualToValue = useCallback(
    (option: Timezone, value: Timezone) => option?.zoneId === value?.zoneId,
    []
  );

  useEffect(() => {
    if (!value) {
      setValue(value ?? DEFAULT_TIME_ZONE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Autocomplete
      id={props?.name}
      onBlur={onBlur}
      value={value}
      disableClearable
      getOptionLabel={getOptionLabel}
      options={timezones ?? []}
      onChange={onChange}
      loading={isLoading}
      loadingText={t("Loading")}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props?.name}
          label={props?.label}
          fullWidth
          error={hasError}
          helperText={hasError ? error : " "}
          inputProps={{
            ...params?.inputProps,
            autoComplete: "do-not-autofill",
          }}
        />
      )}
    />
  );
};
