import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUtilityCurrenciesResponse } from "../types/Api";
import { useAuthApi } from "./useApi";

export const useGetUtilityCurrencies = () => {
  const getCurrencies = useAuthApi<never, GetUtilityCurrenciesResponse>(
    "get",
    endpoints.UTILITY_CURRENCIES
  );

  const { isLoading, data } = useQuery([QueryKeys.GET_CURRENCIES], () =>
    getCurrencies()
  );

  return {
    isLoading,
    currencies: data?.data?.currencies ?? [],
  };
};
