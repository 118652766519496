import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { PhoneInput } from "common/components/PhoneInput/PhoneInput";
import { StateSelect } from "common/components/StateSelect/StateSelect";
import { TagsInput } from "common/components/TagsInput/TagsInput";
import { TextField } from "common/components/TextField/TextField";

export const GeneralTab = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FormGridContainer sx={{ pt: 2 }}>
      <Grid item xs={6}>
        <TextField name="general.label" label={t("Name")} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <PhoneInput name="general.destinationPhone" />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="general.address.street1"
          label={t("Address")}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="general.address.street2"
          label={t("Address2")}
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <StateSelect
          name="general.address.state"
          label={t("State")}
          countryCode="USA"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField name="general.address.city" label={t("City")} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="general.address.postalCode"
          label={t("Zip")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TagsInput name="general.tags" label={t("TagsAndPressEnter")} />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="general.generalInstructions"
          label={t("GeneralInstructions")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="general.pickupInstructions"
          label={t("PickupInstructions")}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="general.dropoffInstructions"
          label={t("DropoffInstructions")}
          fullWidth
        />
      </Grid>
    </FormGridContainer>
  );
};
