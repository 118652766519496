import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { DatePicker } from "common/components/DatePicker/DatePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";

export const Contact = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FormGridContainer>
      <Grid item xs={4}>
        <TextField fullWidth name="contact.nickName" label={t("Nickname")} />
      </Grid>
      <Grid item xs={4}>
        <DatePicker name="contact.dateOfBith" label={t("DateOfBirth")} />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="contact.age"
          label={t("Age")}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name="contact.ssn"
          label={t("Ssn").toUpperCase()}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField fullWidth name="contact.gender" label={t("Gender")} />
      </Grid>
      <Grid item xs={4}>
        <TextField fullWidth name="contact.metroArea" label={t("MetroArea")} />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="contact.mailingAddress"
          label={t("MailingAddress")}
          multiline
          fullWidth
          rows={5}
        />
      </Grid>
    </FormGridContainer>
  );
};
