import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { InputAdornment, MenuItem } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { number, object, string } from "yup";

import { DatePicker } from "common/components/DatePicker/DatePicker";
import { Select } from "common/components/Select/Select";
import { TextField } from "common/components/TextField/TextField";
import { Earning } from "./Payment";

export interface EarningValues {
  date: Date | null;
  type: string;
  id: string;
  status: string;
  amount: number;
}

interface EditEarningFormProps {
  formRef: RefObject<FormikProps<EarningValues>>;
  onSubmit: (values: EarningValues) => void;
  earning: Earning;
}

export const EditEarningForm = ({
  formRef,
  onSubmit,
  earning,
}: EditEarningFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: EarningValues = useMemo(
    () => ({
      date: earning.date,
      type: earning.type,
      id: earning.id,
      status: earning.status,
      amount: earning.amount,
    }),
    [earning]
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        date: string().required(t("Required")).nullable(true),
        type: string().oneOf(["ITINERARY"]).required(t("Required")),
        id: string().required(t("Required")),
        status: string()
          .oneOf(["PENDING", "COMPLETED"])
          .required(t("Required")),
        amount: number()
          .required(t("Required"))
          .typeError(t("ErrorNumber"))
          .min(0, t("MinValue")),
      }),
    [t]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <TextField name="id" label={t("Id").toUpperCase()} fullWidth disabled />
        <DatePicker name={"date"} label={t("Date")} />
        <Select name={"type"} label={t("Type")} size={"small"} fullWidth>
          <MenuItem value="ITINERARY">{t("Itinerary").toUpperCase()}</MenuItem>
        </Select>
        <Select name={"status"} label={t("Status")} size={"small"} fullWidth>
          <MenuItem value="PENDING">{t("Pending").toUpperCase()}</MenuItem>
        </Select>
        <TextField
          name="amount"
          label={t("Amount")}
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Form>
    </Formik>
  );
};
