import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";

import { TagsInput } from "common/components/TagsInput/TagsInput";

export interface FormValues {
  tags: Array<string>;
}

interface AddTagFormProps {
  formRef: RefObject<FormikProps<FormValues>>;
  onSubmit: (values: FormValues) => void;
}

export const AddTagForm = ({
  formRef,
  onSubmit,
}: AddTagFormProps): JSX.Element => {
  const initialValues = useMemo(() => ({ tags: [] }), []);
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      onSubmit={onSubmit}
    >
      <Form>
        <TagsInput name="tags" label={t("Tags")} />
      </Form>
    </Formik>
  );
};
