import { RefObject, useCallback, useMemo } from "react";
import { LinearProgress } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

import { useGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagName } from "hooks/useGetUtilityApplicationCollectionNameFeatureFlagName";
import { FeatureFlag, FeatureFlagData } from "types/FeatureFlag";
import {
  FeatureFlagFields,
  FormValues,
} from "../../FeatureFlagFields/FeatureFlagFields";
import { useValidationSchema } from "../../FeatureFlagFields/useValidationSchema";

interface EditFeatureFlagFormProps {
  collectionName: string;
  featureFlagName: string;
  formRef: RefObject<FormikProps<FormValues>>;
  onSubmit: (values: FeatureFlagData) => Promise<unknown>;
  onUpdate: (newName: string) => void;
}

export const EditFeatureFlagForm = ({
  collectionName,
  featureFlagName,
  formRef,
  onSubmit,
  onUpdate,
}: EditFeatureFlagFormProps): JSX.Element => {
  const { isLoading, featureFlag } =
    useGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagName({
      collectionName,
      featureFlagName,
    });
  const validationSchema = useValidationSchema();
  const initialValues: FormValues = useMemo(
    () => ({
      name: featureFlag?.name ?? "",
      description: featureFlag?.description ?? "",
      enabled: featureFlag?.enabled ?? false,
      deciderClassPath: featureFlag?.deciderClassPath ?? "",
      userDecider: Boolean(featureFlag?.deciderClassPath),
    }),
    [featureFlag]
  );

  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const { userDecider, ...rest } = values;
      return onSubmit({
        ...(featureFlag as FeatureFlag),
        ...rest,
        deciderClassPath: userDecider ? rest.deciderClassPath : null,
        applicationCollectionName: collectionName,
      }).then(() => {
        onUpdate(values.name);
      });
    },
    [onSubmit, featureFlag, collectionName, onUpdate]
  );

  if (isLoading) return <LinearProgress />;

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
    >
      <Form>
        <FeatureFlagFields collectionName={collectionName} />
      </Form>
    </Formik>
  );
};
