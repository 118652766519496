import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteUserFromOrganization } from "hooks/useDeleteUserFromOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { DeleteButton } from "../DeleteButton/DeleteButton";

interface DeleteUserFromOrganizationProps {
  userId: string;
}

export const DeleteUserFromOrganization = ({
  userId,
}: DeleteUserFromOrganizationProps): JSX.Element => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onDeleteUser } = useDeleteUserFromOrganization({
    organizationId: selectedOrganizationId as string,
  });

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  const onDeleteConfirm = useCallback(() => {
    return onDeleteUser(userId);
  }, [onDeleteUser, userId]);

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteUser")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
