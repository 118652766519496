import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Chip } from "@mui/material";

import { Status } from "types/common";

export const StatusChip = ({ status }: { status: Status }): JSX.Element => {
  const { t } = useTranslation();
  const color = useMemo(() => {
    const colors: Record<Status, "info" | "success" | "error"> = {
      INACTIVE: "info",
      ACTIVE: "success",
      UNKNOWN: "info",
      DELETED: "error",
    };
    return colors[status];
  }, [status]);
  const variant = useMemo(
    () => (status === "ACTIVE" ? "filled" : "outlined"),
    [status]
  );
  return (
    <Chip
      label={t(status)}
      color={color}
      sx={{ paddingX: 1 }}
      variant={variant}
    />
  );
};
