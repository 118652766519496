import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useAuthApi } from "../../../hooks/useApi";
import { useGetLoggedUser } from "../../../hooks/useGetLoggedUser";

const useEditPhoto = (userId: string) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState<any>(null);
  const [confirmDeleteImg, setConfirmDeleteImg] = useState<boolean>(false);
  const { loggedUser } = useGetLoggedUser();

  const invalidateLoggedUser = () => {
    if (loggedUser?.id === userId) {
      queryClient.invalidateQueries("loggedUser");
    }
  };

  const baseUrl = useMemo(() => {
    return `/users/${userId}/photo`;
  }, [userId]);

  const savePhotoToMutate = useAuthApi<never, any>("patch", baseUrl, {
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  const deletePhotoToMutate = useAuthApi<never, any>("delete", baseUrl);

  const { mutateAsync: mutateToSave } = useMutation(() => {
    return formData
      ? savePhotoToMutate().then(() => {
          invalidateLoggedUser();
        })
      : Promise.resolve();
  });

  const { mutateAsync: mutateToDelete } = useMutation(() => {
    return deletePhotoToMutate().then(() => {
      invalidateLoggedUser();
    });
  });

  return {
    loadPhotoForEndpoint: setFormData,
    savePhotoToServer: mutateToSave,
    deletePhotoFromServer: mutateToDelete,
    confirmDeleteImg,
    setConfirmDeleteImg,
  };
};

export default useEditPhoto;
