import { Box, Grid, Stack } from "@mui/material";

import { OrganizationPanel } from "components/OrganizationPanel/OrganizationPanel";
import { OrganizationsBreadcrumbs } from "components/OrganizationsBreadcrumbs/OrganizationsBreadcrumbs";
import { OrganizationsSidebar } from "components/OrganizationsSidebar/OrganizationsSidebar";

export const Organizations = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        mb: 2,
        overflow: "hidden",
      }}
    >
      <Stack
        sx={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <OrganizationsBreadcrumbs />
        <Grid
          container
          sx={{ flex: 1, maxHeight: "100%", overflow: "hidden" }}
          columnSpacing={2}
        >
          <Grid item width={350} sx={{ maxHeight: "100%", display: "flex" }}>
            <OrganizationsSidebar />
          </Grid>
          <Grid
            item
            xs
            sx={{
              maxHeight: "100%",
              display: "flex",
              overflow: "hidden",
              flexWrap: "nowrap",
            }}
          >
            <OrganizationPanel />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};
