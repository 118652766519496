import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
} from "@mui/material";

import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { useGetUtilityFeatureFlags } from "hooks/useGetUtilityFeatureFlags";
import { Application } from "types/FeatureFlag";

interface ApplicationsListProps {
  selected?: Application;
  setSelected: (application: Application) => void;
}

export const ApplicationsList = ({
  selected,
  setSelected,
}: ApplicationsListProps): JSX.Element => {
  const { applications, isLoading } = useGetUtilityFeatureFlags();
  useEffect(() => {
    if (applications.length > 0) {
      setSelected(applications[0]);
    }
  }, [applications, setSelected]);

  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        pl={2}
        justifyContent="space-between"
      >
        {isLoading ? (
          <Skeleton variant="text" width={90} />
        ) : (
          <>
            {t("Applications", {
              count: applications.length,
            })}
          </>
        )}
      </Stack>
      {isLoading ? (
        <ListSkeleton width={150} withDivider />
      ) : (
        <List>
          {applications.map((application, index) => (
            <ListItemButton
              key={application.collectionName}
              divider={index !== applications.length - 1}
              selected={application.collectionName === selected?.collectionName}
              onClick={() => setSelected(application)}
            >
              <ListItemText>{application.label}</ListItemText>
            </ListItemButton>
          ))}
        </List>
      )}
    </>
  );
};
