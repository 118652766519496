import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  List,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { OrganizationAddProduct } from "components/OrganizationAddProduct/OrganizationAddProduct";
import { OrganizationProductDelete } from "components/OrganizationProductDelete/OrganizationProductDelete";
import { useSelectedOrganizationProducts } from "hooks/useGetSelectedOrganizationProducts";
import { ProductSettings } from "./ProductSettings/ProductSettings";

export const Products = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectedProductType, setSelectedProductType] = useState("");
  const { isLoading, products } = useSelectedOrganizationProducts();

  const selectedProduct = useMemo(
    () =>
      products.find((product) => product.productType === selectedProductType),
    [products, selectedProductType]
  );

  useEffect(() => {
    if (!isLoading) {
      products.length && setSelectedProductType(products[0].productType);
    }
    // eslint-disable-next-line
  }, [isLoading, products.length]);

  return (
    <Grid container spacing={2} sx={{ flex: 1, display: "flex", pt: 2 }}>
      <Grid item xs="auto">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2, pb: 2 }}
        >
          <Typography sx={{ pr: 2 }}>
            {isLoading ? (
              <Skeleton variant="text" width={80} />
            ) : (
              <>{t("Products", { count: products.length })}</>
            )}
          </Typography>
          <OrganizationAddProduct disabled={isLoading} />
        </Stack>
        {isLoading ? (
          <ListSkeleton withDivider width={200} />
        ) : (
          <List sx={{ py: 0 }}>
            {products.map((product, index) => (
              <ListItemButtonWithDialog
                key={product.productType}
                selected={product.productType === selectedProductType}
                divider={index !== products.length - 1}
                onClick={() => setSelectedProductType(product.productType)}
              >
                <ListItemText sx={{ mr: 2 }}>
                  {t(product.productType as unknown as TemplateStringsArray)}
                </ListItemText>
                <OrganizationProductDelete productType={product.productType} />
              </ListItemButtonWithDialog>
            ))}
          </List>
        )}
      </Grid>
      <Grid item xs>
        {((products.length && !isLoading) || isLoading) && (
          <Stack
            direction="column"
            sx={{
              pr: 2,
              width: "100%",
              height: "100%",
            }}
          >
            <Stack direction="row" alignItems="center">
              {isLoading ? (
                <Skeleton variant="text" width={300} />
              ) : (
                <Typography variant="body2">
                  <span style={{ textTransform: "uppercase" }}>
                    {t(
                      selectedProduct?.productType as unknown as TemplateStringsArray
                    )}
                  </span>
                  : {selectedProduct?.productType}
                </Typography>
              )}
            </Stack>
            {selectedProduct && (
              <ProductSettings
                product={selectedProduct}
                key={selectedProductType}
              />
            )}
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};
