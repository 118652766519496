import { HoursOfOperationFormFields } from "components/OrganizationOverview/EditHoursOfOperation/HoursOfOperationFormFields";
import { GreyBox } from "styles/App.styles";

export const OpenScheduleTab = (): JSX.Element => {
  return (
    <GreyBox elevation={0} sx={{ mt: 2 }}>
      <HoursOfOperationFormFields name="openSchedule" />
    </GreyBox>
  );
};
