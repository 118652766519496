import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateUtilityFeatureFlagApplicationCollectionName } from "hooks/useUpdateUtilityFeatureFlagApplicationCollectionName";
import { FormValues } from "../FeatureFlagFields/FeatureFlagFields";
import { EditFeatureFlagForm } from "./EditFeatureFlagForm/EditFeatureFlagForm";

interface EditFeatureFlagProps {
  collectionName: string;
  featureFlagName: string;
  onUpdate: (newName: string) => void;
}

export const EditFeatureFlag = ({
  collectionName,
  featureFlagName,
  onUpdate,
}: EditFeatureFlagProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onUpdateFeatureFlag } =
    useUpdateUtilityFeatureFlagApplicationCollectionName({
      collectionName,
      featureFlagName,
    });
  const formRef = useRef<FormikProps<FormValues>>(null);
  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton onClick={openDialog} />
      <Dialog
        title={t("EditFeatureFlag")}
        {...dialogProps}
        actionText={t("Save")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        maxWidth="sm"
      >
        <EditFeatureFlagForm
          collectionName={collectionName}
          featureFlagName={featureFlagName}
          formRef={formRef}
          onSubmit={onUpdateFeatureFlag}
          onUpdate={onUpdate}
        />
      </Dialog>
    </>
  );
};
