import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material/TextField/TextField";

import { TextField } from "../TextField/TextField";

type TextFieldProps = MuiTextFieldProps & {
  name: string;
  readOnly?: boolean;
};
const PasswordField = ({ readOnly = false, ...props }: TextFieldProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const isHiddenToggleHandler = () => {
    setIsHidden(!isHidden);
  };

  return (
    <TextField
      {...props}
      label={props.label}
      name={props.name}
      type={isHidden ? "password" : "text"}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            onClick={isHiddenToggleHandler}
            sx={{ cursor: "pointer" }}
          >
            {isHidden ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
