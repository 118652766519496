import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps, useFormikContext } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { FormValues } from "../EditUserForm";
import { AddCertificationForms } from "./AddCertificationForms/AddCertificationForms";
import { DriversLicenseValues } from "./AddCertificationForms/DriversLicenseAddForm";
import { MedicalCardValues } from "./AddCertificationForms/MedicalCardAddForm";
import { MedicalWasteDriversLicenseValues } from "./AddCertificationForms/MedicalWasteDriversLicenseAddForm";

type Certification =
  | DriversLicenseValues
  | MedicalCardValues
  | MedicalWasteDriversLicenseValues;

export const AddCertification = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<Certification>>(null);

  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  const addToUserCertifications = useCallback(
    (certification: Certification) => {
      setFieldValue("certifications", [
        ...values.certifications,
        certification,
      ]);
      closeDialog();
    },
    [setFieldValue, values, closeDialog]
  );

  return (
    <>
      <AddButton variant="contained" onClick={openDialog}>
        {t("AddCertification")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("AddCertification")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="md"
      >
        <AddCertificationForms
          formRef={formRef}
          onSubmit={addToUserCertifications}
        />
      </Dialog>
    </>
  );
};
