import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { FeatureFlagData } from "types/FeatureFlag";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UsePostUtilityFeatureFlagApplicationCollectionNameProps {
  collectionName: string;
}

export const usePostUtilityFeatureFlagApplicationCollectionName = ({
  collectionName,
}: UsePostUtilityFeatureFlagApplicationCollectionNameProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const postFeatureFlag = useAuthApi<FeatureFlagData, unknown>(
    "post",
    endpoints.UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION(collectionName)
  );

  const { isLoading, isSuccess, mutate } = useMutation(postFeatureFlag, {
    onSuccess: () => {
      auditFormEvent("Feature Flag Added", "Add Feature Flag", "FORM_SENT");
      setSnackbarData({ severity: "success", message: t("FeatureFlagAdded") });
      return queryClient.invalidateQueries([
        QueryKeys.GET_FEATURE_FLAGS,
        collectionName,
      ]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Add Feature Flag Unknown Error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add Feature Flag", "ERROR");
    },
  });

  const onPostFeatureFlag = (
    data: Omit<FeatureFlagData, "applicationCollectionName">
  ) => mutate({ ...data, applicationCollectionName: collectionName });

  return {
    isLoading,
    isSuccess,
    onPostFeatureFlag,
  };
};
