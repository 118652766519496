export enum Breakpoints {
  SM = 600,
  MD = 1025,
  LG = 1280,
  XL = 1920,
  XXL = 2560,
}

export const CURRENCY_DEFAULT = "USD";
export const LOCALE_DEFAULT = "en_US";
export const COUNTRY_CODE_DEFAULT = "US";
export const COUNTRY_NAME_DEFAULT = "United States of America";
export const SUPPORTED_LANGUAGES = ["en", "es"];
export const ANY_COLLECTION = "*";
export const DEFAULT_USER_PASSWORD = "Just4Skaivision";
export const DEFAULT_TIME_ZONE = {
  label: "Eastern Standard Time",
  offsetAsSeconds: -240,
  timezone: "US/Eastern",
  zoneId: "US/Eastern",
};
export const DEFAULT_STATUS = "INACTIVE";

export enum RouteConstants {
  APP_ROOT = "/",
  ACCOUNT = "/account",
  ERROR = "/error",
  HOME = "/home",
  LOGIN = "/login",
  ORGANIZATIONS = "/organizations",
  PREFERENCES = "/preferences",
  SETTINGS = "/settings",
  USERS = "/users",
}

export enum QueryKeys {
  GET_ACTORS = "getActors",
  GET_APPLICATIONS = "getApplications",
  GET_COUNTRIES = "getCountries",
  GET_CURRENCIES = "getCurrencies",
  GET_FEATURE_FLAGS = "getFeatureFlags",
  GET_LOCALES = "getLocales",
  GET_ORGANIZATION = "getOrganization",
  GET_ORGANIZATION_API_TOKENS = "getOrganizationApiTokens",
  GET_ORGANIZATION_GROUPS = "getOrganizationGroups",
  GET_ORGANIZATION_LOCATIONS = "getOrganizationLocations",
  GET_ORGANIZATION_PRODUCTS = "getOrganizationProducts",
  GET_ORGANIZATIONS_FLAT = "getOrganizationsFlat",
  GET_PERMISSIONS = "getPermissions",
  GET_PRODUCTS = "getProducts",
  GET_ROLES = "getRoles",
  GET_STATES = "getStates",
  GET_TIMEZONES = "getTimezones",
  GET_USERS = "getUsers",
  LOGGED_ID = "loggedId",
  LOGGED_USER = "loggedUser",
  LOGOUT = "logout",
  SELECTED_ORGANIZATION = "selectedOrganization",
  SETTINGS = "settings",
  SNACKBAR = "snackbar",
  TOKENS = "tokens",
  USER = "user",
  USER_ID = "userId",
}

export const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as const;

export type DayOfWeek = typeof daysOfWeek[number];

export const SKAI_ORGANIZATION_ID = "Eezr2ajZGXqcPhKxiNk2GQ";

export const endpoints = {
  ACTOR: (id: string) => `/actor/${id}`,
  AUDIT: "/audit",
  AUTHENTICATE: "/rest/authenticate",
  GET_ACTORS: "/actors/get",
  LOGOUT: "rest/authenticate/logout",
  ORGANIZATION: (id: string) => `/orgs/${id}`,
  ORGANIZATION_API_TOKEN: (organizationId: string, tokenId: string) =>
    `/orgs/${organizationId}/apitokens/${tokenId}`,
  ORGANIZATION_API_TOKENS: (organizationId: string) =>
    `/orgs/${organizationId}/apitokens`,
  ORGANIZATION_CREATE_API_TOKENS: "/orgs/apitokens",
  ORGANIZATION_EDIT_GROUP: (groupId: string) =>
    `/orgs/securityGroups/${groupId}`,
  ORGANIZATION_GROUP: (organizationId: string, groupId: string) =>
    `/orgs/${organizationId}/securityGroups/${groupId}`,
  ORGANIZATION_GROUP_USERS: (organizationId: string, groupId: string) =>
    `/orgs/${organizationId}/securityGroups/${groupId}/users`,
  ORGANIZATION_GROUPS: (id: string) => `/orgs/${id}/securityGroups`,

  ORGANIZATION_LOCATION: (organizationId: string, locationId: string) =>
    `/orgs/${organizationId}/resources/locations/${locationId}`,
  ORGANIZATION_LOCATIONS: (organizationId: string) =>
    `/orgs/${organizationId}/resources/locations`,
  ORGANIZATION_PRODUCT_ASSIGN: (id: string) => `/orgs/${id}/products/assign`,
  ORGANIZATION_PRODUCT_UNASSIGN: (id: string) =>
    `/orgs/${id}/products/unassign`,
  ORGANIZATION_PRODUCTS: (id: string) => `/orgs/${id}/products`,
  ORGANIZATION_USERS: (id: string) => `/orgs/${id}/users`,
  ORGANIZATION_USERS_ASSIGN: (id: string) => `/orgs/${id}/users/assign`,
  ORGANIZATION_USERS_UNASSIGN: (organizationId: string) =>
    `/orgs/${organizationId}/users/unassign`,
  ORGANIZATIONS_FLAT: "/orgs/flat",
  RESET_PASSWORD: "rest/authenticate/resetPassword",
  SEND_RESET_PASSWORD: "rest/authenticate/sendResetPassword",
  UPDATE_REYREY_BASIC_SETTINGS: (organizationId: string) =>
    `/orgs/${organizationId}/products/reyrey`,
  USER: (userId: string) => `/users/${userId}`,
  USER_SEND_EMAIL: (userId: string) =>
    `/users/${userId}/security/sendPasswordReset`,
  USER_SET_PASSWORD: (userId: string) =>
    `/users/${userId}/security/changePassword`,
  REQUIRED_PASSWORD_CHANGE: "/users/requirePasswordChange",
  FORCE_LOGOUT: (userId: string) => `/users/${userId}/forceUserLogout`,
  USER_IMPERSONATE: (userId: string, applicationName: string) =>
    `/users/${userId}/impersonate?application=${applicationName}`,
  USERS: "/users",
  UTILITY_APPLICATIONS: "/utility/applications",
  UTILITY_COUNTRIES: "/utility/countries",
  UTILITY_FEATURE_FLAGS: `/utility/featureflags`,
  UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION: (collectionName: string) =>
    `/utility/featureflags/${collectionName}`,
  UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION_FLAG_NAME: (
    collectionName: string,
    featureFlagName: string
  ) => `/utility/featureflags/${collectionName}/${featureFlagName}`,
  UTILITY_PERMISSIONS: "/utility/permissions",
  UTILITY_PRODUCTS: "/utility/products",
  UTILITY_ROLES: "/utility/roles",
  UTILITY_STATES: "/utility/regions",
  UTILITY_TIMEZONES: "/utility/timezones",
  UTILITY_LOCALES: "/utility/locales",
  UTILITY_CURRENCIES: "/utility/currencies",
  UTILITY_SETTINGS: "/utility/settings",
};

export enum FeatureFlagEnum {
  SHOW_IMPERSONATE = "SHOW_IMPERSONATE",
  SHOW_ORG_EXPORT = "SHOW_ORG_EXPORT",
  SHOW_ORG_IMPORT = "SHOW_ORG_IMPORT",
  SHOW_USER_EXPORT = "SHOW_USER_EXPORT",
  SHOW_USER_IMPORT = "SHOW_USER_IMPORT",
  TEST_ALWAYS_ON = "TEST_ALWAYS_ON",
}

export const permissions = {
  ALL_TENANTS_VIEW: "ALL_TENANTS_VIEW",
  ALL_TENANT_ORGGROUP_VIEW: "ALL_TENANT_ORGGROUP_VIEW",
  ALLOW_HARD_DELETE: "ALLOW_HARD_DELETE",
  API_ACCESS: "API_ACCESS",
  APITOKEN_CREATE: "APITOKEN_CREATE",
  APITOKEN_DELETE: "APITOKEN_DELETE",
  APITOKEN_UPDATE: "APITOKEN_UPDATE",
  APITOKEN_VIEW: "APITOKEN_VIEW",
  APP_CACHE_UPDATE: "APP_CACHE_UPDATE",
  FEATUREFLAG_CREATE: "FEATUREFLAG_CREATE",
  FEATUREFLAG_DELETE: "FEATUREFLAG_DELETE",
  FEATUREFLAG_UPDATE: "FEATUREFLAG_UPDATE",
  FEATUREFLAG_VIEW: "FEATUREFLAG_VIEW",
  LOCATION_CREATE: "LOCATION_CREATE",
  LOCATION_DELETE: "LOCATION_DELETE",
  LOCATION_LIST_CREATE: "LOCATION_LIST_CREATE",
  LOCATION_LIST_MANAGE: "LOCATION_LIST_MANAGE",
  LOCATION_UPDATE: "LOCATION_UPDATE",
  LOCATION_VIEW: "LOCATION_VIEW",
  ORGGROUP_CREATE: "ORGGROUP_CREATE",
  ORGGROUP_DELETE: "ORGGROUP_DELETE",
  ORGGROUP_UPDATE: "ORGGROUP_UPDATE",
  ORGGROUP_VIEW: "ORGGROUP_VIEW",
  ORGS_CREATE: "ORGS_CREATE",
  ORGS_DELETE: "ORGS_DELETE",
  ORGS_UPDATE: "ORGS_UPDATE",
  ORGS_VIEW: "ORGS_VIEW",
  ORGUSER_CREATE: "ORGUSER_CREATE",
  ORGUSER_DELETE: "ORGUSER_DELETE",
  ORGUSER_IMPERSONATE: "ORGUSER_IMPERSONATE",
  ORGUSER_UPDATE: "ORGUSER_UPDATE",
  ORGUSER_VIEW: "ORGUSER_VIEW",
  SUPER_ADMIN: "SUPER_ADMIN",
  USERS_CREATE: "USERS_CREATE",
  USERS_DELETE: "USERS_DELETE",
  USERS_IMPERSONATE: "USERS_IMPERSONATE",
  USERS_SELF_UPDATE: "USERS_SELF_UPDATE",
  USERS_UPDATE: "USERS_UPDATE",
  USERS_VIEW: "USERS_VIEW",
  UTILITY_VIEW: "UTILITY_VIEW",
};