import { Alert, AlertColor, Snackbar } from "@mui/material";

interface AppSnackbarProps {
  severity?: AlertColor;
  message?: string;
  open: boolean;
}

export const AppSnackbar = ({ severity, message, open }: AppSnackbarProps) => {
  return (
    <Snackbar open={open}>
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};
