import { Ref, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { array, object } from "yup";

import { OrganizationFlat } from "types/Organization";
import { OrganizationSelect } from "../../OrganizationSelect/OrganizationSelect";

export interface DialogFormValues {
  organizations: Array<OrganizationFlat>;
}

interface AddOrganizationFormProps {
  onSubmit: (organizations: Array<OrganizationFlat>) => void;
  formRef: Ref<FormikProps<DialogFormValues>>;
  currentOrganizationsIds?: string[];
}

export const AddOrganizationForm = ({
  onSubmit,
  formRef,
  currentOrganizationsIds = [],
}: AddOrganizationFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      organizations: [],
    }),
    []
  );

  const onFormSubmit = useCallback(
    (values) => {
      return onSubmit(values.organizations);
    },
    [onSubmit]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        organizations: array().min(1, t("Required")),
      }),
    [t]
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <OrganizationSelect
          multiple
          name="organizations"
          label={t("AddOrganization")}
          currentOrganizationsIds={currentOrganizationsIds}
        />
      </Form>
    </Formik>
  );
};
