import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { CountrySelect } from "common/components/CountrySelect/CountrySelect";
import { DatePicker } from "common/components/DatePicker/DatePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { StateSelect } from "common/components/StateSelect/StateSelect";
import { TextField } from "common/components/TextField/TextField";
import { Locality } from "types/Organization";

export interface PlateValues {
  plateNumber: string;
  country: Locality | null;
  state: Locality | null;
  issuedDate: string | null;
  expirationDate: string | null;
}

interface AddPlateFormProps {
  formRef: RefObject<FormikProps<PlateValues>>;
  onSubmit: (values: PlateValues) => void;
}

export const AddPlateForm = ({
  formRef,
  onSubmit,
}: AddPlateFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: PlateValues = useMemo(
    () => ({
      plateNumber: "",
      country: null,
      state: null,
      issuedDate: null,
      expirationDate: null,
    }),
    []
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        plateNumber: string().required(t("Required")),
        country: object().required(t("Required")).nullable(true),
        state: object().required(t("Required")).nullable(true),
        issuedDate: string().required(t("Required")).nullable(true),
        expirationDate: string().required(t("Required")).nullable(true),
      }),
    [t]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {({ values }) => (
        <Form>
          <FormGridContainer>
            <Grid item xs={4}>
              <TextField
                name="plateNumber"
                label={t("PlateNumber")}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <CountrySelect name="country" label={t("Country")} />
            </Grid>
            <Grid item xs={4}>
              <StateSelect
                name="state"
                label={t("StateProvince")}
                countryCode={values.country?.shortName}
              />
            </Grid>
            <Grid item xs={4}>
              <DatePicker name="issuedDate" label={t("IssueDate")} />
            </Grid>
            <Grid item xs={4}>
              <DatePicker name="expirationDate" label={t("ExpirationDate")} />
            </Grid>
          </FormGridContainer>
        </Form>
      )}
    </Formik>
  );
};
