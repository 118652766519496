import {
  Badge,
  Button,
  ButtonProps,
  Paper,
  Stack,
  SvgIconTypeMap,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

type BigIconButtonWithNotificationProps = ButtonProps & {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  notifications?: number;
};

export const BigIconButtonWithNotification = ({
  label,
  Icon,
  notifications,
  ...buttonProps
}: BigIconButtonWithNotificationProps): JSX.Element => {
  return (
    <Badge badgeContent={notifications} color="primary">
      <Paper elevation={0}>
        <Button
          variant="outlined"
          disableElevation
          sx={{ width: "170px", ...buttonProps.sx }}
          {...buttonProps}
        >
          <Stack alignItems="center">
            <Icon fontSize="large" sx={{ m: 1 }} />
            <Typography mb={1}>{label}</Typography>
          </Stack>
        </Button>
      </Paper>
    </Badge>
  );
};
