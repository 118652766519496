import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useUpdateOrganization } from "hooks/useUpdateOrganization";
import { AddressContactForm } from "./AddressContactForm";
import { AddressContactValues } from "./formUtils";

interface EditLocationProps {
  disabled: boolean;
}

export const EditLocation = ({ disabled }: EditLocationProps) => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading, updateOrganization, isSuccess } = useUpdateOrganization({
    id: selectedOrganizationId as string,
  });
  const formRef = useRef<FormikProps<AddressContactValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton onClick={openDialog} disabled={disabled} />
      <Dialog
        {...dialogProps}
        title={t("LocationAddressContact")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
      >
        <AddressContactForm
          type="Location"
          formRef={formRef}
          onSubmit={updateOrganization}
        />
      </Dialog>
    </>
  );
};
