import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { useAuthApi } from "./useApi";

export interface APITokenPermissions {
  id: string;
  name: string;
  label: string;
  type: string;
}

export const useGetPermissions = (applicationName: string) => {
  const getPermissions = useAuthApi<
    null,
    {
      permissions: APITokenPermissions[];
    }
  >("get", endpoints.UTILITY_PERMISSIONS, { params: { applicationName } });

  const { isLoading, data } = useQuery(
    [QueryKeys.GET_PERMISSIONS, applicationName],
    () => (applicationName ? getPermissions() : null)
  );

  return {
    isLoading,
    permissions: data?.data.permissions ?? [],
  };
};
