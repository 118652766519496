import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetGroupsRequestParams } from "types/Api";
import { SecurityGroup } from "../types/Group";
import { useAuthApi } from "./useApi";

type useGetOrganizationGroupsProps = GetGroupsRequestParams & {
  organizationId: string;
};

export const useGetOrganizationGroups = ({
  organizationId,
  params,
}: useGetOrganizationGroupsProps) => {
  const getOrganizationGroups = useAuthApi<never, Array<SecurityGroup>>(
    "get",
    endpoints.ORGANIZATION_GROUPS(organizationId),
    { params }
  );

  const { isLoading, data, isSuccess } = useQuery(
    [QueryKeys.GET_ORGANIZATION_GROUPS, organizationId, { ...params }],
    () => getOrganizationGroups()
  );

  return {
    isLoading,
    isSuccess,
    groups: data?.data ?? [],
  };
};
