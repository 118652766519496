import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { usePostOrganizationApiToken } from "hooks/usePostOrganizationApiToken";
import { AddTokenForm, FormValues } from "./AddTokenForm";

interface OrganizationaddTokenProps {
  disabled: boolean;
}

export const OrganizationAddToken = ({
  disabled,
}: OrganizationaddTokenProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, postOrganizationApiToken } =
    usePostOrganizationApiToken();
  const formRef = useRef<FormikProps<FormValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton variant="contained" onClick={openDialog} disabled={disabled}>
        {t("AddToken")}
      </AddButton>
      <Dialog
        title={t("AddToken")}
        loading={isLoading}
        actionText={t("Create")}
        onSubmit={onSubmit}
        maxWidth="md"
        {...dialogProps}
      >
        <AddTokenForm formRef={formRef} onSubmit={postOrganizationApiToken} />
      </Dialog>
    </>
  );
};
