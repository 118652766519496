import { rest, setupWorker } from "msw";

import { handlers } from "./server-handlers";

const worker = setupWorker(...handlers);

const maybeStartMSW = () => {
  if (process.env.REACT_APP_MOCK_ENDPOINTS === "enabled") {
    worker.start({
      onUnhandledRequest: "bypass",
    });
    console.log("🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥");
    console.log("🟥🟥🟥 [MSW] MOCKING ENABLED 🟥🟥🟥");
    console.log("🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥🟥");
  }
};

export { worker, rest, maybeStartMSW };
