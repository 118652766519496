import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUsersRequestParams, GetUsersResponse } from "types/Api";
import { useAuthApi } from "./useApi";

export const useGetUsers = ({ params }: GetUsersRequestParams) => {
  const getUsers = useAuthApi<never, GetUsersResponse>("get", endpoints.USERS, {
    params,
  });

  const { isLoading, data, isSuccess } = useQuery(
    [QueryKeys.GET_USERS, "app", { ...params }],
    () => getUsers()
  );

  return {
    isLoading,
    isSuccess,
    usersResponse: data?.data,
  };
};
