import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseDeleteUserFromOrganizationProps {
  organizationId: string;
}

interface UnassignUserRequest {
  securityGroupUsers: [
    {
      userId: string;
    }
  ];
}

export const useDeleteUserFromOrganization = ({
  organizationId,
}: UseDeleteUserFromOrganizationProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const deleteUserFromOrganization = useAuthApi<UnassignUserRequest, any>(
    "post",
    endpoints.ORGANIZATION_USERS_UNASSIGN(organizationId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(
    deleteUserFromOrganization,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries([
          QueryKeys.GET_USERS,
          "organization",
          organizationId,
        ]);
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ??
          "Delete User from Organization unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Delete User from Organization", "ERROR");
      },
    }
  );

  const onDeleteUser = (userId: string) =>
    mutate({
      securityGroupUsers: [{ userId: userId }],
    });

  return {
    isLoading,
    isSuccess,
    onDeleteUser: onDeleteUser,
  };
};
