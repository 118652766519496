import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetActorsRequestData, GetActorsResponse } from "types/Api";
import { useAuthApi } from "./useApi";

interface UseGetActorsProps {
  actorIds: Array<string>;
}

export const useGetActors = (props: UseGetActorsProps) => {
  const getActors = useAuthApi<GetActorsRequestData, GetActorsResponse>(
    "post",
    endpoints.GET_ACTORS
  );
  const { isLoading, data } = useQuery(
    [QueryKeys.GET_ACTORS, ...props.actorIds],
    () => getActors(props.actorIds)
  );

  return {
    isLoading,
    actors: data?.data.data.filter((actor) => actor) ?? [],
  };
};
