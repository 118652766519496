import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { EditGroupData } from "types/Group";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseUpdateOrganizationProps {
  organizationId: string;
  groupId: string;
}

export const useUpdateOrganizationGroup = ({
  organizationId,
  groupId,
}: UseUpdateOrganizationProps) => {
  const { t } = useTranslation();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const patchOrganizationGroup = useAuthApi(
    "patch",
    endpoints.ORGANIZATION_EDIT_GROUP(groupId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(patchOrganizationGroup, {
    mutationKey: "UpdateOrganizationGroup",
    onError: (data: AxiosError<ServerError>) => {
      const message = data.response?.data.message ?? t("PathGroupUnknownError");
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Path Organization Group", "ERROR");
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION,
        organizationId,
      ]);
      const message = t("GroupUpdated");
      setSnackbarData({ severity: "success", message });
      auditErrorEvent(message, "Path Organization Group", "FORM_SENT");
    },
  });

  const updateGroup = (data: Partial<EditGroupData>) =>
    mutate({ id: groupId, ...data });

  return {
    isLoading,
    isSuccess,
    updateGroup,
  };
};
