import { Outlet } from "react-router";
import { Paper, Stack } from "@mui/material";

import { ToolsBreadcrumbs } from "components/ToolsBreadcrumbs/ToolsBreadcrumbs";

export const Tools = (): JSX.Element => (
  <Stack sx={{ flex: 1, overflow: "hidden" }}>
    <ToolsBreadcrumbs />
    <Paper
      sx={{
        p: 2,
        pb: 0,
        mb: 2,
        width: "100%",
        display: "flex",
        flex: 1,
        maxHeight: "100%",
        height: "100%",
        minHeight: "100%",
        overflow: "hidden",
      }}
      elevation={0}
    >
      <Outlet />
    </Paper>
  </Stack>
);
