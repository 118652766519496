import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useField } from "formik";

import { useGetProducts } from "hooks/useGetProducts";
import { useSelectedOrganizationProducts } from "hooks/useGetSelectedOrganizationProducts";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface UtilityProductSelectProps {
  name: string;
}

export const UtilityProductSelect = ({
  name,
}: UtilityProductSelectProps): JSX.Element => {
  const [{ onBlur }, { touched, error }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const { isLoading: isLoadingProducts, products } =
    useSelectedOrganizationProducts();
  const { isLoading: isLoadingUtilityProducts, utilityProducts } =
    useGetProducts();
  const isLoading = useMemo(
    () => isLoadingProducts || isLoadingUtilityProducts,
    [isLoadingProducts, isLoadingUtilityProducts]
  );
  const filterTypes = useMemo(
    () => products.map((product) => product.productType),
    [products]
  );
  const availableProducts = useMemo(() => {
    if (utilityProducts["products"]) {
      return utilityProducts["products"]?.filter(
        (product) => !filterTypes.includes(product)
      );
    }

    return [];
  }, [filterTypes, utilityProducts]);

  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const onChange = useCallback(
    (_, selecteds: any) => {
      setValue(selecteds);
    },
    [setValue]
  );

  return (
    <FormControl error={hasError} fullWidth sx={{ pt: 0 }}>
      <Autocomplete
        loading={isLoading}
        onBlur={onBlur}
        id={name}
        multiple
        getOptionLabel={t}
        options={availableProducts}
        disableCloseOnSelect
        onChange={onChange}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {t(option as unknown as TemplateStringsArray)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label={t("Products")}
            placeholder={t("AddProduct")}
            inputProps={{
              ...params.inputProps,
              autoComplete: "do-not-autofill",
            }}
          />
        )}
      />
      <FormHelperText>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
