import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { PhoneInput } from "common/components/PhoneInput/PhoneInput";
import { TextField } from "common/components/TextField/TextField";

export const ContactTab = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormGridContainer sx={{ pt: 2 }}>
      <Grid item xs={6}>
        <TextField name="contact.firstName" label={t("FirstName")} fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField name="contact.lastName" label={t("LastName")} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextField name="contact.jobTitle" label={t("JobTitle")} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <TextField name="contact.workEmail" label={t("Email")} fullWidth />
      </Grid>
      <Grid item xs={4}>
        <PhoneInput name="contact.mobilePhone" />
      </Grid>
    </FormGridContainer>
  );
};
