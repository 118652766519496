import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import Axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { paths } from "navigation/paths";
import { AuthenticateResponse } from "types/Api";
import { clearAllTokens, setAuthTokens } from "utils/authLocalStorage";
import { AUTH_ERROR_PARAM } from "../pages/Login/LoginForm/LoginForm";
import { useAuth } from "./useAuth";

type UseApiMethod = "get" | "post" | "put" | "delete" | "patch";

const locale = window?.navigator?.language || "en-US";

const getAuthHeaders = (
  token?: string,
  sessionId?: string
): AxiosRequestHeaders => ({
  Authorization: `Bearer ${token}`,
  ...(sessionId ? { "x-sessionId": sessionId } : {}),
  locale,
});

const baseUrlInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
});

const authUrlInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_AUTH_PATH,
});

const axiosInstanceWithInterceptor = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
});

export const useApi = <RequestDataType, ResponseType>(
  method: UseApiMethod,
  url: string,
  config: AxiosRequestConfig<RequestDataType> = {}
) => {
  return (
    data?: RequestDataType,
    headers?: AxiosRequestHeaders
  ): AxiosPromise<ResponseType> =>
    baseUrlInstance({
      method,
      url,
      ...{ data },
      ...{ headers },
      ...config,
    });
};

export const useAuthBaseApi = <RequestDataType, ResponseType>(
  method: UseApiMethod,
  url: string,
  config: AxiosRequestConfig<RequestDataType> = {}
) => {
  return (
    data?: RequestDataType,
    headers?: AxiosRequestHeaders
  ): AxiosPromise<ResponseType> =>
    authUrlInstance({
      method,
      url,
      ...{ data },
      ...{ headers },
      ...config,
    });
};

export const useAuthApi = <RequestDataType, ResponseType>(
  method: UseApiMethod,
  url: string,
  config: AxiosRequestConfig<RequestDataType> = {}
) => {
  const { getToken, getSessionId } = useAuth();
  return (data?: RequestDataType): AxiosPromise<ResponseType> =>
    axiosInstanceWithInterceptor({
      url,
      method,
      ...{ data },
      ...config,
      headers: {
        ...config.headers,
        ...getAuthHeaders(getToken(), getSessionId()),
      },
    });
};

export const useSetupApi = () => {
  const navigate = useNavigate();
  const refreshRequest = useRef<null | Promise<
    AxiosResponse<AuthenticateResponse>
  >>(null);
  const { getRefreshToken, getSessionId } = useAuth();
  const refreshTokenApi = useAuthApi<never, AuthenticateResponse>(
    "get",
    endpoints.AUTHENTICATE
  );
  const queryClient = useQueryClient();
  useEffect(() => {
    axiosInstanceWithInterceptor.interceptors.response.use(
      (response) => response,
      (error) => {
        const requestConfig = error.config;
        if (error.response) {
          if (error.response.status === 401 && !requestConfig.retry) {
            requestConfig.retry = true;
            if (!refreshRequest.current) {
              refreshRequest.current = refreshTokenApi(
                undefined,
                // eslint-disable-next-line
                // @ts-ignore
                getAuthHeaders(getRefreshToken(), getSessionId())
              );
            }
            return refreshRequest.current
              .then(({ data }) => {
                refreshRequest.current = null;
                return setAuthTokens(data).then(() => {
                  queryClient.setQueryData([QueryKeys.TOKENS], {
                    expirationDate: data.expirationDate,
                    token: data.token,
                    sessionId: data.sessionId,
                    refreshToken: data.refreshToken,
                    currentOrganization: data?.allowedContexts[0],
                    allowedOrganizations: data?.allowedContexts,
                  });
                  requestConfig.headers = getAuthHeaders(
                    data.token,
                    data.sessionId
                  );
                  return axiosInstanceWithInterceptor(requestConfig);
                });
              })
              .catch((error) => {
                console.error("ERROR refresh token failed", error);
                queryClient.setQueryData([QueryKeys.TOKENS], null);
                queryClient.setQueryData([QueryKeys.LOGGED_USER], null);
                queryClient.setQueryData([QueryKeys.LOGGED_ID], null);
                navigate(`${paths.LOGIN}?${AUTH_ERROR_PARAM}=true`);
                clearAllTokens();
                return Promise.reject(error);
              });
          }
        }
        return Promise.reject(error);
      }
    );
  }, [
    queryClient,
    refreshTokenApi,
    refreshRequest,
    navigate,
    getRefreshToken,
    getSessionId,
  ]);
};
