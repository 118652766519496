import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, List, ListItemText, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { FormValues } from "../EditUserForm";
import { AddCertification } from "./AddCertification";
import { DriversLicenseFields } from "./AddCertificationForms/DriversLicenseFields";
import { MedicalCardFields } from "./AddCertificationForms/MedicalCardFields";
import { MedicalWasteTransportLicenseFields } from "./AddCertificationForms/MedicalWasteTransportLicenseFields";
import { DeleteCertification } from "./DeleteCertification";

export const Certifications = (): JSX.Element => {
  const [selectedCertificationIndex, setSelectedCertificationIndex] =
    useState(0);
  const { values } = useFormikContext<FormValues>();
  const { t } = useTranslation();

  const onClick = useCallback(
    (index: number) => {
      setSelectedCertificationIndex(index);
    },
    [setSelectedCertificationIndex]
  );

  const onDelete = useCallback(
    (index: number) => {
      if (index === selectedCertificationIndex) {
        setSelectedCertificationIndex(0);
      }
    },
    [selectedCertificationIndex, setSelectedCertificationIndex]
  );

  const selectedCertification = useMemo(
    () => values.certifications[selectedCertificationIndex],
    [selectedCertificationIndex, values.certifications]
  );

  return (
    <>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              {t("Certifications", { count: values.certifications.length })}
            </Typography>
            <AddCertification />
          </Stack>
          <List sx={{ py: 0, overflow: "auto" }}>
            {values.certifications.map((certification, index) => (
              <ListItemButtonWithDialog
                divider
                selected={selectedCertificationIndex === index}
                key={`certification-${index}`}
                onClick={() => onClick(index)}
              >
                <ListItemText>{t(certification.certType)}</ListItemText>
                <DeleteCertification index={index} onDelete={onDelete} />
              </ListItemButtonWithDialog>
            ))}
          </List>
        </Stack>
      </Grid>
      <Grid item xs>
        {selectedCertification && (
          <>
            <Typography>{t(selectedCertification.certType)}</Typography>
            {selectedCertification.certType === "MEDICAL_CARD" && (
              <MedicalCardFields
                namePrefix={`certifications.${selectedCertificationIndex}.`}
              />
            )}
            {selectedCertification.certType ===
              "MEDICAL_WASTE_DRIVERS_LICENSE" && (
              <MedicalWasteTransportLicenseFields
                namePrefix={`certifications.${selectedCertificationIndex}.`}
              />
            )}
            {selectedCertification.certType === "DRIVERS_LICENSE" && (
              <DriversLicenseFields
                namePrefix={`certifications.${selectedCertificationIndex}.`}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
};
