import { useCallback, useState } from "react";

import { AppDialog } from "common/components/AppDialog/AppDialog";

export const useAppDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);

  const dialogProps = {
    open,
    closeDialog,
  };

  return {
    openDialog,
    closeDialog,
    dialogProps,
    Dialog: AppDialog,
  };
};
