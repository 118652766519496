import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useField } from "formik";

import { useGetUtilityApps } from "hooks/useGetUtilityApps";

type AppSelectProps = SelectProps & {
  name: string;
};

export const AppSelect = ({ name, ...props }: AppSelectProps): JSX.Element => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const { apps, isLoading } = useGetUtilityApps();
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const onChange = useCallback(
    (_, child) => {
      const id = child.props.value;
      setValue(id ? apps.find((app) => app.id === id) : null);
    },
    [apps, setValue]
  );

  return (
    <FormControl error={hasError} size={props.size} fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <Select
        {...props}
        label={props.label}
        value={value ? value.id : ""}
        onBlur={onBlur}
        displayEmpty
        disabled={props.disabled || isLoading}
        onChange={onChange}
        renderValue={
          isLoading
            ? () => (
                <>
                  <CircularProgress size={16} sx={{ marginRight: 2 }} />
                  {t("Loading")}
                </>
              )
            : undefined
        }
      >
        {apps?.map((app) => (
          <MenuItem key={app.id} value={app.id}>
            {app.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
