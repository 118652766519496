import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import { useFormikContext } from "formik";

import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { ContactTab } from "../AddLocation/AddLocationForm/ContactTab";
import { GeneralTab } from "../AddLocation/AddLocationForm/GeneralTab";
import { OpenScheduleTab } from "../AddLocation/AddLocationForm/OpenScheduleTab";
import { FormValues, TabValue } from "./formUtils";

export const LocationFields = () => {
  const { errors } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useTabValue<TabValue>("General");

  return (
    <TabContext value={tabValue}>
      <Tabs onChange={setTabValue}>
        <Tab
          value="General"
          icon={errors.general ? <ErrorOutlineIcon color="error" /> : undefined}
          iconPosition="end"
          label={t("General")}
        />
        <Tab
          icon={errors.contact ? <ErrorOutlineIcon color="error" /> : undefined}
          iconPosition="end"
          value="Contact"
          label={t("Contact")}
        />
        <Tab
          value="OpenSchedule"
          icon={
            errors.hoursOfOperation ? (
              <ErrorOutlineIcon fontSize="small" color="error" />
            ) : undefined
          }
          iconPosition="end"
          label={t("OpenSchedule")}
        />
      </Tabs>
      <TabPanel value="General">
        <GeneralTab />
      </TabPanel>
      <TabPanel value="Contact">
        <ContactTab />
      </TabPanel>
      <TabPanel value="OpenSchedule">
        <OpenScheduleTab />
      </TabPanel>
    </TabContext>
  );
};
