import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";

import { useAppDialog } from "hooks/useAppDialog";
import { BackgroundDetails } from "./BackgroundDetails";
import { NewBackgroundCheck } from "./NewBackgroundCheck";

export interface BackgroundCheck {
  id: string;
  created: string;
  provider: string;
  package: string;
  providerStatus: string;
  packageStatus: string;
  lastUpdate: string;
}

export const Registration = (): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps } = useAppDialog();

  const columns: GridColumns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "event",
      headerName: t("Event"),
      flex: 1,
      sortable: false,
    },
    {
      field: "timestamp",
      headerName: t("Timestamp"),
      flex: 1,
      sortable: false,
    },
  ];
  const rows = [
    {
      id: 0,
      event: "Registration Completed",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 1,
      event: "Training Completed",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 2,
      event: "First Trip",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 3,
      event: "Last Background Check",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 4,
      event: "Last Login",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 5,
      event: "Last Trip",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 6,
      event: "Last Offer Sent",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 7,
      event: "Last Communication",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 8,
      event: "Last call",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 9,
      event: "Last Message",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 10,
      event: "Second Trip",
      timestamp: "2022-01-01 11:00:00",
    },
    {
      id: 11,
      event: "Training Completed",
      timestamp: "2022-01-01 11:00:00",
    },
  ];
  const columns2: GridColumns = [
    {
      field: "id",
      headerName: t("Id").toUpperCase(),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<unknown, BackgroundCheck>) => (
        <Button variant="text" onClick={openDialog}>
          {params.row.id}
        </Button>
      ),
    },
    {
      field: "created",
      headerName: t("Created"),
      flex: 2,
      sortable: false,
    },
    {
      field: "provider",
      headerName: t("Provider"),
      flex: 1,
      sortable: false,
    },
    {
      field: "package",
      headerName: t("Package"),
      flex: 1,
      sortable: false,
    },
    {
      field: "providerStatus",
      headerName: t("ProviderStatus"),
      flex: 1,
      sortable: false,
    },
    {
      field: "packageStatus",
      headerName: t("PackageStatus"),
      flex: 1,
      sortable: false,
    },
    {
      field: "lastUpdate",
      headerName: t("LastUpdate"),
      flex: 2,
      sortable: false,
    },
  ];

  const rows2: Array<BackgroundCheck> = [
    {
      id: "A234AC3",
      created: "2022-04-11 14:00:00",
      provider: "Checkr",
      package: "Background",
      providerStatus: "COMPLETE",
      packageStatus: "CLEAR",
      lastUpdate: "2022-04-11 15:00:00",
    },
    {
      id: "343CF33",
      created: "2021-04-11 14:00:00",
      provider: "Checkr",
      package: "Background",
      providerStatus: "COMPLETE",
      packageStatus: "CLEAR",
      lastUpdate: "2021-04-11 15:00:00",
    },
  ];
  const [selectedBackgroundCheck, setSelectedBackgroundCheck] =
    useState<BackgroundCheck>(rows2[0]);

  const handleChange = useCallback(({ row }) => {
    setSelectedBackgroundCheck(row);
  }, []);

  return (
    <Grid container>
      <Grid item xs="auto" sx={{ mr: "4%" }}>
        <Box sx={{ height: 200, mb: 2 }}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-end"
            spacing={4}
          >
            <Typography noWrap overflow="unset" mr={2}>
              {t("RegistrationStep")}
            </Typography>
            <Typography noWrap overflow="unset" mr={2}>
              {t("Registered").toUpperCase()}
            </Typography>
          </Stack>
        </Box>
        <Typography noWrap overflow="unset" mr={2} variant="h6">
          {t("CriticalEvents")}
        </Typography>
        <Box sx={{ height: 400, display: "flex", pt: 2 }}>
          <Paper sx={{ flex: 1 }} elevation={2}>
            <DataGrid columns={columns} rows={rows} />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs>
        <Box sx={{ height: 200, border: "3px solid grey", mb: 1 }}>
          <Typography
            justifyContent="flex-start"
            mb={2}
            ml={4}
            variant="subtitle2"
          >
            {t("BackgroundCheck")}
          </Typography>
          <Stack direction="row" spacing={4} ml={2}>
            <Stack spacing={2}>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("Provider")}:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("Id").toUpperCase()}:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("Status")}:`}
              </Typography>
              <Typography noWrap justifyContent="flex-end" variant="subtitle2">
                {`${t("LastUpdate")}:`}
              </Typography>
            </Stack>
            <Stack spacing={2} justifyContent="flex-start">
              <Typography noWrap overflow="unset" variant="subtitle2">
                {`${selectedBackgroundCheck?.provider}`}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {`${selectedBackgroundCheck?.id}`}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {`${selectedBackgroundCheck?.providerStatus} / 
                    ${selectedBackgroundCheck?.packageStatus}`}
              </Typography>
              <Typography noWrap overflow="unset" variant="subtitle2">
                {`${selectedBackgroundCheck?.lastUpdate}`}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2, pb: 2 }}
        >
          <Typography noWrap overflow="unset" mr={2} variant="h6">
            {t("PastBackgroundCheck")}
          </Typography>
          <NewBackgroundCheck />
        </Stack>
        <Box sx={{ height: 400, display: "flex" }}>
          <Paper elevation={2} sx={{ flex: 1 }}>
            <DataGrid
              columns={columns2}
              rows={rows2}
              editMode="row"
              onRowClick={handleChange}
            />
          </Paper>
        </Box>
      </Grid>
      <Dialog
        {...dialogProps}
        title={t("BackgroundDetails")}
        loading={false}
        actionText={t("Assign")}
        renderActions={false}
        onSubmit={() => null}
        maxWidth="xs"
      >
        <BackgroundDetails details={selectedBackgroundCheck} />
      </Dialog>
    </Grid>
  );
};
