import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { Locality } from "types/Organization";
import { MedicalWasteTransportLicenseFields } from "./MedicalWasteTransportLicenseFields";

export interface MedicalWasteDriversLicenseValues {
  certType: "MEDICAL_WASTE_DRIVERS_LICENSE";
  licenseNumber: string;
  expirationDate: string | null;
  issueDate: string | null;
  issueState: Locality | null;
  classification: string;
}

interface MedicalWasteDriversLicenseProps {
  formRef: RefObject<FormikProps<MedicalWasteDriversLicenseValues>>;
  onSubmit: (values: MedicalWasteDriversLicenseValues) => void;
}

export const MedicalWasteDriversLicenseAddForm = ({
  formRef,
  onSubmit,
}: MedicalWasteDriversLicenseProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: MedicalWasteDriversLicenseValues = useMemo(
    () => ({
      certType: "MEDICAL_WASTE_DRIVERS_LICENSE",
      licenseNumber: "",
      expirationDate: null,
      issueDate: null,
      issueState: null,
      classification: "A",
    }),
    []
  );
  const validationSchema = useMemo(
    () =>
      object().shape({
        licenseNumber: string().required(t("Required")),
        expirationDate: string().required(t("Required")).nullable(true),
        issueDate: string().required(t("Required")).nullable(true),
        issueState: object().required(t("Required")).nullable(true),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <MedicalWasteTransportLicenseFields />
      </Form>
    </Formik>
  );
};
