import { useQuery } from "react-query";
import { Navigate, useLocation } from "react-router-dom";

import { QueryKeys } from "constants/constants";
import { WithChildren } from "types/common";
import { paths } from "./paths";

export const RequireAuth = ({ children }: WithChildren): JSX.Element => {
  const { data } = useQuery([QueryKeys.TOKENS], { refetchOnMount: false });
  const location = useLocation();
  if (!data) return <Navigate to={paths.LOGIN} state={{ from: location }} />;
  return <>{children}</>;
};
