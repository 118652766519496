import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";

export const NoRowsOverlay = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <InfoIcon fontSize="large" color="primary" sx={{ mb: 1 }} />
      <Typography variant="h5">{t("NoRelatedContent")}</Typography>
    </Box>
  );
};
