import { useEffect, useRef } from "react";
import { Box, useTheme } from "@mui/material";
import { Marker } from "mapbox-gl";

import { useAppMap } from "hooks/useAppMap";

export const CurrentLocation = (): JSX.Element => {
  const theme = useTheme();
  const { map, container } = useAppMap();
  const marker = useRef<Marker>();

  useEffect(() => {
    if (!map.current) return;
    marker.current?.remove();

    marker.current = new Marker({ color: theme.palette.primary.main })
      .setLngLat([-84.69907529999999, 33.4355535])
      .addTo(map.current);
    map.current.setCenter(marker.current.getLngLat());
  }, [map, theme]);

  return <Box ref={container} height={500}></Box>;
};
