import { useTranslation } from "react-i18next";
import { DirectionsCar, FolderOpen, Login } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";

export const Reports = (): JSX.Element => {
  const { t } = useTranslation();
  const reportData = {
    rides: 750,
    cancels: 0,
    noShows: 1,
    accidents: 0,
    lastLogin: new Date(2022, 4, 11, 11, 45, 12),
  };

  return (
    <Grid container>
      <Grid item xs="auto">
        <List sx={{ py: 0, overflow: "auto", mr: 5 }}>
          <ListItemButtonWithDialog selected={true} onClick={() => null}>
            <ListItemIcon sx={{ minWidth: "36px" }}>
              <FolderOpen fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ mr: 2 }}>{t("Metrics")}</ListItemText>
          </ListItemButtonWithDialog>
        </List>
      </Grid>
      <Grid item xs>
        <Stack direction="row" spacing={8}>
          <Box
            sx={{
              height: 120,
              width: 120,
              border: "2px solid grey",
              borderRadius: 5,
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="h6">{reportData.rides}</Typography>
              <DirectionsCar fontSize="large" />
              <Typography>{t("Rides")}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              height: 120,
              width: 120,
              border: "2px solid grey",
              borderRadius: 5,
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="h6">{reportData.cancels}</Typography>
              <DirectionsCar fontSize="large" />
              <Typography>{t("Cancels")}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              height: 120,
              width: 120,
              border: "2px solid grey",
              borderRadius: 5,
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="h6">{reportData.noShows}</Typography>
              <DirectionsCar fontSize="large" />
              <Typography>{t("NoShows")}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              height: 120,
              width: 120,
              border: "2px solid grey",
              borderRadius: 5,
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="h6">{reportData.accidents}</Typography>
              <DirectionsCar fontSize="large" />
              <Typography>{t("Accidents")}</Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              height: 120,
              width: 150,
              border: "2px solid grey",
              borderRadius: 5,
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Typography variant="subtitle2" align="center">
                {reportData.lastLogin.toUTCString()}
              </Typography>
              <Login fontSize="large" />
              <Typography>{t("LastLogin")}</Typography>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};
