import { useCallback, useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AlertColor } from "@mui/material";

import { AppSnackbar } from "common/components/AppSnackbar/AppSnackbar";
import { QueryKeys } from "constants/constants";

type SnackBarData = {
  severity: AlertColor;
  message: string;
} | null;

export const useAppSnackbar = () => {
  const queryClient = useQueryClient();
  const timeoutRef = useRef<number>();
  const setSnackbarData = useCallback(
    (snackbar: SnackBarData) =>
      queryClient.setQueryData<SnackBarData>([QueryKeys.SNACKBAR], snackbar),
    [queryClient]
  );
  const { data } = useQuery<SnackBarData>([QueryKeys.SNACKBAR], {
    refetchOnMount: false,
  });

  useEffect(() => {
    if (data) {
      timeoutRef.current = window.setTimeout(() => setSnackbarData(null), 6000);
    }
  }, [data, setSnackbarData]);

  useEffect(
    () => () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    },
    []
  );

  const appSnackbarProps = {
    open: Boolean(data),
    ...(data ? data : {}),
  };

  return {
    AppSnackbar,
    appSnackbarProps,
    setSnackbarData,
  };
};
