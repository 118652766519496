import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

export const useValidationSchema = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required(t("Required")),
        deciderClassPath: string().when("userDecider", (userDecider: boolean) =>
          userDecider ? string().required(t("Required")) : string()
        ),
      }),
    [t]
  );
  return validationSchema;
};
