import { useCallback, useMemo } from "react";
import { TextField } from "@mui/material";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers";
import { useField } from "formik";

type DatePickerProps = Omit<
  MuiDatePickerProps<unknown, unknown>, // TODO: Check this
  "value" | "onChange" | "renderInput"
> & {
  name: string;
};

export const DatePicker = ({
  name,
  ...props
}: DatePickerProps): JSX.Element => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const onChange = useCallback(
    (date) => {
      setValue(date);
    },
    [setValue]
  );

  return (
    <MuiDatePicker
      {...props}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={hasError}
          onBlur={onBlur}
          helperText={hasError ? error : " "}
        />
      )}
    />
  );
};
