import parsePhoneNumberFromString, {
  CountryCode,
  parsePhoneNumber,
} from "libphonenumber-js/min";

import { COUNTRY_CODE_DEFAULT } from "constants/constants";

interface MobilePhone {
  number: string;
}

export interface Phone {
  countryCode: CountryCode;
  phoneNumber: string;
}

export const parsePhoneString = (mobilePhone?: MobilePhone): Phone => {
  const parsedPhoneNumber = mobilePhone?.number
    ? parsePhoneNumberFromString(mobilePhone.number)
    : undefined;

  return {
    countryCode: parsedPhoneNumber?.country ?? COUNTRY_CODE_DEFAULT,
    phoneNumber: parsedPhoneNumber?.nationalNumber ?? "",
  };
};

export const phoneNumberToString = (phone: Phone) => {
  return parsePhoneNumber(phone.phoneNumber, phone.countryCode).number;
};
