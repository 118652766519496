import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUsersRequestParams, GetUsersResponse } from "types/Api";
import { useAuthApi } from "./useApi";

type UseGetOrganizationGroupUserProps = GetUsersRequestParams & {
  organizationId: string;
  groupId: string;
};

export const useGetOrganizationGroupUsers = ({
  organizationId,
  groupId,
  params,
}: UseGetOrganizationGroupUserProps) => {
  const getOrganizationUsers = useAuthApi<never, GetUsersResponse>(
    "get",
    endpoints.ORGANIZATION_GROUP_USERS(organizationId, groupId),
    {
      params,
    }
  );

  const { data, isLoading, isError, isSuccess } = useQuery(
    [
      QueryKeys.GET_USERS,
      "organization",
      organizationId,
      "group",
      groupId,
      { ...params },
    ],
    () => getOrganizationUsers()
  );

  return {
    isLoading,
    isError,
    isSuccess,
    usersResponse: data?.data?.matches,
    data: data?.data,
  };
};
