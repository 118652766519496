import { useMutation } from "react-query";

import { endpoints } from "constants/constants";
import { ForgotPasswordData } from "types/Api";
import { setOnLocalStorage } from "utils/localStorage";
import { useAuthBaseApi } from "./useApi";

export const useForgotPassword = () => {
  const sendResetPassword = useAuthBaseApi<ForgotPasswordData, void>(
    "post",
    endpoints.SEND_RESET_PASSWORD
  );

  const { isError, isLoading, isSuccess, mutate } = useMutation(
    sendResetPassword,
    {
      onMutate: (args) => {
        args?.email && setOnLocalStorage("username", args.email);
      },
    }
  );

  const onResetPassword = (data: ForgotPasswordData) => mutate(data);

  return { isError, isLoading, isSuccess, onResetPassword };
};
