import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Typography } from "@mui/material";
import { format } from "date-fns";

import { EditBilling } from "components/OrganizationOverview/EditBilling/EditBilling";
import { EditLocation } from "components/OrganizationOverview/EditLocation/EditLocation";
import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { OverviewField } from "./OverviewField";

export const Overview = (): JSX.Element => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { data, isLoading } = useGetOrganization({
    id: selectedOrganizationId as string,
  });

  const currentTime = useMemo(() => format(new Date(), "hh:mm a"), []);

  return (
    <Box sx={{ overflow: "auto" }}>
      <Grid container spacing={2} p={2} pb={0}>
        <Grid item xs={6}>
          <Box pb={2}>
            <Typography variant="h6">
              {t("BillingAddressContact")} <EditBilling disabled={isLoading} />
            </Typography>
            <OverviewField
              title={t("Address")}
              body={data?.billingAddress?.formattedAddress || ""}
            />
            <OverviewField
              title={t("Contact")}
              body={
                data?.billingContact
                  ? `${data?.billingContact?.firstName} ${data?.billingContact?.lastName}`
                  : ""
              }
            />
            <OverviewField
              title={t("JobTitle")}
              body={data?.billingContact?.jobTitle || ""}
            />
            <OverviewField
              title={t("Phone")}
              body={
                data?.billingContact.mobilePhone?.number ||
                data?.billingContact.workPhone?.number ||
                ""
              }
            />
            <OverviewField
              title={t("Email")}
              body={data?.billingContact.workEmail || ""}
              href={`mailto:${data?.billingContact.workEmail}`}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <OverviewField title={t("CurrentTime")} body={currentTime} />
              </Grid>
              <Grid item xs={6}>
                <OverviewField
                  title={t("TimeZone")}
                  body={data?.billingAddress.timezone?.label || ""}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box pb={2}>
            <Typography variant="h6">
              {t("LocationAddressContact")}{" "}
              <EditLocation disabled={isLoading} />
            </Typography>
            <OverviewField
              title={t("Address")}
              body={data?.mailingAddress?.formattedAddress || ""}
            />
            <OverviewField
              title={t("Contact")}
              body={
                data?.mailingContact
                  ? `${data?.mailingContact?.firstName} ${data?.mailingContact?.lastName}`
                  : ""
              }
            />
            <OverviewField
              title={t("JobTitle")}
              body={data?.mailingContact?.jobTitle || ""}
            />
            <OverviewField
              title={t("Phone")}
              body={
                data?.mailingContact?.mobilePhone?.number ||
                data?.mailingContact?.workPhone?.number ||
                ""
              }
            />
            <OverviewField
              title={t("Email")}
              body={data?.mailingContact?.workEmail || ""}
              href={`mailto:${data?.mailingContact?.workEmail}`}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <OverviewField title={t("CurrentTime")} body={currentTime} />
              </Grid>
              <Grid item xs={6}>
                <OverviewField
                  title={t("TimeZone")}
                  body={data?.mailingAddress?.timezone?.label || ""}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <OverviewField title={t("Website")} body={data?.websiteUrl || ""} />
          <OverviewField title={t("Locale")} body={data?.locale || ""} />
          <OverviewField title={t("Currency")} body={data?.currency || ""} />
        </Grid>
      </Grid>
    </Box>
  );
};
