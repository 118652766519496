import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useUpdateOrganization } from "hooks/useUpdateOrganization";
import {
  EditOrganizationForm,
  EditOrganizationValues,
} from "./EditOrganizationForm";

interface EditOrganizationProps {
  disabled: boolean;
}

export const EditOrganization = ({ disabled }: EditOrganizationProps) => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const formRef = useRef<FormikProps<EditOrganizationValues>>(null);
  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading, updateOrganization, isSuccess } = useUpdateOrganization({
    id: selectedOrganizationId as string,
  });

  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton
        onClick={openDialog}
        disabled={disabled}
        name="edit organization button"
      />
      <Dialog
        {...dialogProps}
        title={t("EditOrganization")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="md"
      >
        <EditOrganizationForm onSubmit={updateOrganization} formRef={formRef} />
      </Dialog>
    </>
  );
};
