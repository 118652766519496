import { AuthenticateResponse } from "types/Api";
import { convertServerDateStringToUnix } from "./datetime";
import {
  clearFromLocalStorage,
  getFromLocalStorage,
  isSetOnLocalStorage,
  setOnLocalStorage,
} from "./localStorage";

export const isTokenExpired = (): boolean =>
  getFromLocalStorage("authBearerTokenExpiration")
    ? Date.now() > Number(getFromLocalStorage("authBearerTokenExpiration"))
    : true;

export const isSessionActive = (): boolean =>
  isSetOnLocalStorage("authBearerToken") &&
  isSetOnLocalStorage("authRefreshToken") &&
  !isTokenExpired();

export const setAuthTokens = ({
  token,
  refreshToken,
  expirationDate,
  user,
  sessionId,
  allowedContexts,
}: AuthenticateResponse): Promise<void> => {
  if (token) {
    setOnLocalStorage("authBearerToken", token);
  } else {
    return Promise.reject(new Error("bearer token not found"));
  }

  if (refreshToken) {
    setOnLocalStorage("authRefreshToken", refreshToken);
  } else {
    return Promise.reject(new Error("refresh token not found"));
  }
  if (user && user.id) {
    setOnLocalStorage("userId", user.id);
  } else {
    return Promise.reject(new Error("user not found"));
  }

  if (sessionId) {
    setOnLocalStorage("sessionId", sessionId);
  } else {
    return Promise.reject(new Error("sessionId not found"));
  }

  if (expirationDate) {
    setOnLocalStorage(
      "authBearerTokenExpiration",
      convertServerDateStringToUnix(expirationDate).toString()
    );
  } else {
    return Promise.reject(new Error("token expiration not found"));
  }

  if (allowedContexts[0]) {
    setOnLocalStorage("currentOrganization", allowedContexts[0]);
  } else {
    return Promise.reject(new Error("currentOrganization not found"));
  }

  if (allowedContexts) {
    setOnLocalStorage("allowedOrganizations", allowedContexts);
  } else {
    return Promise.reject(new Error("allowedOrganizations not found"));
  }

  if (isTokenExpired())
    return Promise.reject(new Error("token has already expired"));

  return Promise.resolve();
};

export const clearAllTokens = (): void => {
  clearFromLocalStorage("authBearerToken");
  clearFromLocalStorage("authRefreshToken");
  clearFromLocalStorage("authBearerTokenExpiration");
  clearFromLocalStorage("userId");
  clearFromLocalStorage("currentOrganization");
  clearFromLocalStorage("allowedOrganizations");
};
