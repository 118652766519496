import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { Select } from "common/components/Select/Select";
import { TextField } from "common/components/TextField/TextField";

export interface BackgroundCheckValues {
  provider: string | null;
  package: string | null;
  reference: string;
}

interface NewBackgroundCheckFormProps {
  formRef: RefObject<FormikProps<BackgroundCheckValues>>;
  onSubmit: (values: BackgroundCheckValues) => void;
}
export const NewBackgroundCheckForm = ({
  formRef,
  onSubmit,
}: NewBackgroundCheckFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: BackgroundCheckValues = useMemo(
    () => ({
      provider: null,
      package: null,
      reference: "",
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        provider: string().required(t("Required")).nullable(true),
        package: string().required(t("Required")).nullable(true),
        reference: string().required(t("Required")),
      }),
    [t]
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <FormGridContainer>
          <Grid item xs={12}>
            <Select name={"provider"} label={t("Provider")} fullWidth>
              <MenuItem value={"Checkr"}>{"Checkr"}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select name={"package"} label={t("Package")} fullWidth>
              <MenuItem value={"Background"}>{"Background"}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField name="reference" label={t("Reference")} fullWidth />
          </Grid>
        </FormGridContainer>
      </Form>
    </Formik>
  );
};
