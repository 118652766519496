import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { useAddUsersToOrganization } from "hooks/useAddUsersToOrganization";
import { useAppDialog } from "hooks/useAppDialog";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import {
  AddOrganizationUserForm,
  AddOrganizationUserFormValues,
} from "pages/Organizations/Users/AddOrganizationUserForm";
import { SecurityGroup } from "types/Group";
import { AddButton } from "../AddButton/AddButton";

interface AddUserToOrganizationProps {
  group?: SecurityGroup;
}

export const AddUserToOrganization = ({
  group,
}: AddUserToOrganizationProps): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const formRef = useRef<FormikProps<AddOrganizationUserFormValues>>(null);
  const { selectedOrganizationId } = useSelectedOrganization();
  const { putOrganizationUsers, isSuccess, isLoading } =
    useAddUsersToOrganization({
      organizationId: selectedOrganizationId as string,
    });

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton variant="contained" onClick={openDialog}>
        {t("AddUser")}
      </AddButton>
      <Dialog
        title={t("AddOrganizationUser").toUpperCase()}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="md"
        {...dialogProps}
      >
        <AddOrganizationUserForm
          formRef={formRef}
          group={group}
          onSubmit={putOrganizationUsers}
        />
      </Dialog>
    </>
  );
};
