import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Grid,
  List,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { AddOrganization } from "../AddOrganization";
import { FormValues } from "../EditUserForm";
import { OrganizationGroups } from "../OrganizationGroups";
import { RemoveUserOrganization } from "../RemoveUserOrganization";

export const AccountMembership = (): JSX.Element => {
  const { t } = useTranslation();
  const { values, errors, initialValues } = useFormikContext<FormValues>();
  const [selectedOrganizationId, setSelectedOrganizationId] =
    useState<string>("");
  const onAddOrganization = useCallback((organizationId: string) => {
    setSelectedOrganizationId(organizationId);
  }, []);
  const checkSelectedOnDelete = useCallback((organizationId: string) => {
    setSelectedOrganizationId(organizationId);
  }, []);
  const selectedOrganization = useMemo(
    () =>
      values?.organizations?.find(
        (organization) => organization?.id === selectedOrganizationId
      ),
    [selectedOrganizationId, values?.organizations]
  );

  useEffect(() => {
    setSelectedOrganizationId(values.organizations[0]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { error: organizationsError }] = useField("organizations");

  useEffect(() => {
    sessionStorage.setItem("editUserSelectedId", selectedOrganizationId);
  }, [selectedOrganizationId]);

  const currentOrganizationsIds = useMemo(() => {
    let orgIds: string[] = [];
    if (values && values.organizations) {
      orgIds = values.organizations.map((org) => {
        return org.id;
      });
    }
    return orgIds;
  }, [values]);

  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              {t("Organizations", {
                count: values.organizations.length,
              })}
            </Typography>
            <AddOrganization
              onAddOrganization={onAddOrganization}
              currentOrganizationsIds={currentOrganizationsIds}
            />
          </Stack>
          {organizationsError && (
            <span
              style={{
                fontSize: "14px",
                width: "100%",
                color: "red",
                fontWeight: "300",
                marginBottom: "10px",
              }}
            >
              {organizationsError}
            </span>
          )}

          <List sx={{ py: 0, overflow: "auto" }}>
            {values.organizations.map((organization) => {
              const hasError = (
                errors?.organizations as unknown as Record<string, string>
              )?.[organization?.id];
              return (
                <ListItemButtonWithDialog
                  key={organization.id}
                  selected={organization?.id === selectedOrganization?.id}
                  divider
                  onClick={() => setSelectedOrganizationId(organization?.id)}
                >
                  <ListItemText
                    sx={{
                      mr: 2,
                      color: (theme) =>
                        hasError ? theme.palette.error.main : undefined,
                    }}
                  >
                    {organization?.name}{" "}
                    {hasError && (
                      <Tooltip title={hasError} placement="top">
                        <ErrorIcon color="error" fontSize="small" />
                      </Tooltip>
                    )}
                  </ListItemText>
                  {organization?.id !== values?.defaultOrganization?.id && (
                    <RemoveUserOrganization
                      organization={organization}
                      onDelete={checkSelectedOnDelete}
                    />
                  )}
                </ListItemButtonWithDialog>
              );
            })}
          </List>
        </Stack>
      </Grid>
      <Grid item xs>
        {selectedOrganization && (
          <OrganizationGroups selectedOrganization={selectedOrganization} />
        )}
      </Grid>
    </Grid>
  );
};

export const AccountMembershipLoading = (): JSX.Element => {
  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              <Skeleton variant="text" width={160} />
            </Typography>
          </Stack>
          <ListSkeleton withDivider width={300} />
        </Stack>
      </Grid>
      <Grid item xs>
        <ListSkeleton width={300} />
      </Grid>
    </Grid>
  );
};
