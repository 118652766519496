import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { endpoints } from "constants/constants";
import { ServerError, SetUserPasswordRequestData } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseUserSetPasswordProps {
  userId: string;
}

export const useUserSetPassword = ({ userId }: UseUserSetPasswordProps) => {
  const { t } = useTranslation();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const setUserPassword = useAuthApi<SetUserPasswordRequestData, unknown>(
    "post",
    endpoints.USER_SET_PASSWORD(userId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(setUserPassword, {
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "User Set Password unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "User Set Password", "ERROR");
    },
    onSuccess: () => {
      auditFormEvent("Settings Updated", "User Set Password", "FORM_SENT");
      setSnackbarData({
        severity: "success",
        message: t("NewPasswordSet"),
      });
    },
  });

  const onSetPassword = (data: SetUserPasswordRequestData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    onSetPassword,
  };
};
