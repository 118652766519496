import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteOrganizationGroup } from "hooks/useDeleteOrganizationGroup";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";

interface OrganizationGroupDeleteProps {
  groupId: string;
}

export const OrganizationGroupDelete = ({
  groupId,
}: OrganizationGroupDeleteProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { deleteGroup, isSuccess, isLoading } = useDeleteOrganizationGroup({
    organizationId: selectedOrganizationId as string,
    groupId,
  });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );
  const onDeleteConfirm = useCallback(() => {
    deleteGroup();
  }, [deleteGroup]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteGroupTitle")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
