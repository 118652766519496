import { SyntheticEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel as MuiFormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { FormControlLabelSwitch } from "common/components/FormControlLabel/FormControlLabelSwitch";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { PhoneInput } from "common/components/PhoneInput/PhoneInput";
import { StateSelect } from "common/components/StateSelect/StateSelect";
import { TextField } from "common/components/TextField/TextField";
import { AddressContactType } from "types/common";
import { TimezoneSelect } from "../../../common/components/TimezoneSelect/TimezoneSelect";
import { Fields } from "./formUtils";
import { SetSameAsBilling } from "./SetSameAsBilling";

interface AddressContactFormFieldsProps {
  type: AddressContactType;
  sameAsBilling: boolean;
  onChangeSameAsBilling: (_: SyntheticEvent, value: boolean) => void;
  billingValues: Fields;
  needsReset: boolean;
}

export const AddressContactFormFields = ({
  type,
  sameAsBilling,
  onChangeSameAsBilling,
  billingValues,
  needsReset,
}: AddressContactFormFieldsProps): JSX.Element => {
  const { t } = useTranslation();
  const isBilling = useMemo(() => type === "Billing", [type]);
  const addressTitle = useMemo(
    () => (isBilling ? t("BillingAddress") : t("LocationAddress")),
    [isBilling, t]
  );
  const contactTitle = useMemo(
    () => (isBilling ? t("BillingContact") : t("LocationContact")),
    [isBilling, t]
  );
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ pb: 2 }}
      >
        <Typography variant="body2">{addressTitle}</Typography>
        {!isBilling && (
          <>
            <MuiFormControlLabel
              control={<Checkbox checked={sameAsBilling} />}
              label={t("SameAsBilling")}
              name="billing"
              value={sameAsBilling}
              onChange={onChangeSameAsBilling}
            />
            <SetSameAsBilling
              billingValues={billingValues}
              sameAsBilling={sameAsBilling}
              needsReset={needsReset}
            />
          </>
        )}
      </Stack>
      <FormGridContainer>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("Address")}
            name={`${type}.address`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("Address2")}
            name={`${type}.address2`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("City")}
            name={`${type}.city`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <StateSelect
            readOnly={sameAsBilling}
            name={`${type}.state`}
            fullWidth
            label={t("State")}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("Zip")}
            name={`${type}.zip`}
            fullWidth
          />
        </Grid>
      </FormGridContainer>
      <Typography
        variant="body2"
        sx={{ textTransform: "uppercase", paddingBottom: 2, paddingTop: 0 }}
      >
        {contactTitle}
      </Typography>
      <FormGridContainer>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("FirstName")}
            name={`${type}.firstname`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("LastName")}
            name={`${type}.lastname`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("JobTitle")}
            name={`${type}.jobTitle`}
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TimezoneSelect
            readOnly={sameAsBilling}
            name={`${type}.timezone`}
            label="Time zone"
          />
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={8.5}>
              <PhoneInput name={`${type}.phone`} readOnly={sameAsBilling} />
            </Grid>
            <Grid item xs={3.5}>
              <FormControlLabelSwitch
                name={`${type}.allowSMS`}
                label={t("AllowSMS")}
                labelPlacement="top"
                readOnly={sameAsBilling}
                sx={{
                  ".MuiFormControlLabel-label": {
                    color: (theme) => theme.palette.text.secondary,
                    transform: "scale(0.75)",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <TextField
            readOnly={sameAsBilling}
            label={t("Email")}
            name={`${type}.email`}
            fullWidth
          />
        </Grid>
      </FormGridContainer>
    </>
  );
};
