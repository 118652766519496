import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { TextField } from "common/components/TextField/TextField";

export interface FormValues {
  name: string;
}

interface AddDataItemValueFormProps {
  formRef: RefObject<FormikProps<FormValues>>;
  onSubmit: (values: FormValues) => void;
}

export const AddDataItemValueForm = ({
  formRef,
  onSubmit,
}: AddDataItemValueFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues = useMemo(() => ({ name: "" }), []);
  const validationSchema = useMemo(
    () =>
      object().shape({
        name: string().required(t("Required")),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      <Form>
        <TextField name="name" fullWidth label={t("Value")} />
      </Form>
    </Formik>
  );
};
