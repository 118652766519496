import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, GridColumns, GridRenderCellParams } from "@mui/x-data-grid";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { EditButton } from "common/components/EditButton/EditButton";
import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { permissions } from "constants/constants";
import { useGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagName } from "hooks/useGetUtilityApplicationCollectionNameFeatureFlagName";
import { useGetUtilityFeatureFlags } from "hooks/useGetUtilityFeatureFlags";
import { Application, DeciderDataItem, FeatureFlag } from "types/FeatureFlag";
import { AddDataItemValue } from "../AddDataItemValue/AddDataItemValue";
import { DeleteDataItemValue } from "../DeleteDataItemValue/DeleteDataItemValue";
import { EditDataItemValue } from "../EditDataItemValue/EditDataItemValue";
import { EditFeatureFlag } from "../EditFeatureFlag/EditFeatureFlag";

export interface RowType {
  index: number;
  key: string;
  id: string;
  value: string;
}

interface FeatureFlagPanelProps {
  featureFlagName: string;
  application: Application;
  setSelectedFeatureFlagName: (newName: string) => void;
}

export const FeatureFlagPanel = ({
  featureFlagName,
  application,
  setSelectedFeatureFlagName,
}: FeatureFlagPanelProps): JSX.Element => {
  const { featureFlag, isLoading } =
    useGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagName({
      collectionName: application.collectionName,
      featureFlagName,
    });
  const [selected, setSelected] = useState<DeciderDataItem>();
  const { deciders } = useGetUtilityFeatureFlags();
  const { t } = useTranslation();
  const decider = useMemo(
    () =>
      deciders.find(
        (decider) => featureFlag?.deciderClassPath === decider.classPath
      ),
    [deciders, featureFlag]
  );
  const dataItems = useMemo(() => decider?.deciderDataItems ?? [], [decider]);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "value",
        headerName: t("Values"),
        flex: 1,
        sortable: false,
      },
      {
        field: "edit",
        headerName: "",
        sortable: false,
        align: "right",
        width: 100,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Box sx={{ ml: "auto" }}>
              <FeatureGate
                name={permissions.FEATUREFLAG_UPDATE}
                fallback={<EditButton disabled />}
              >
                <EditDataItemValue
                  collectionName={application.collectionName}
                  featureFlag={featureFlag as FeatureFlag}
                  row={params.row}
                />
              </FeatureGate>
              <FeatureGate
                name={permissions.FEATUREFLAG_UPDATE}
                fallback={<DeleteButton disabled />}
              >
                <DeleteDataItemValue
                  collectionName={application.collectionName}
                  featureFlag={featureFlag as FeatureFlag}
                  row={params.row}
                />
              </FeatureGate>
            </Box>
          );
        },
      },
    ],
    [t, application, featureFlag]
  );

  const rows: Array<RowType> = useMemo(
    () =>
      (featureFlag?.deciderData?.[selected?.key ?? 0] ?? []).map(
        (data: string, index) => ({
          index,
          key: selected?.key as string,
          id: `${selected?.key}-${index}`,
          value: data,
        })
      ),
    [featureFlag, selected]
  );

  const onUpdateFeatureFlag = useCallback(
    (newName: string) => {
      if (newName !== featureFlagName) {
        setSelectedFeatureFlagName(newName);
      }
    },
    [featureFlagName, setSelectedFeatureFlagName]
  );

  useEffect(() => {
    featureFlagName && setSelected(dataItems[0]);
  }, [featureFlagName, dataItems]);

  if (isLoading)
    return (
      <Stack height="100%">
        <Stack direction="row">
          <Skeleton variant="text" width="300px" />
          <EditButton disabled />
        </Stack>
        <Stack alignItems="flex-end">
          <Checkbox disabled />
          <Skeleton variant="text" width="100%" />
        </Stack>
        <Divider sx={{ my: 2 }} />
      </Stack>
    );

  return (
    <Stack height="100%">
      <Stack direction="row">
        <Typography variant="h6">{featureFlag?.name}</Typography>
        <FeatureGate
          name={permissions.FEATUREFLAG_UPDATE}
          fallback={<EditButton disabled />}
        >
          <EditFeatureFlag
            featureFlagName={featureFlagName}
            collectionName={application.collectionName}
            onUpdate={onUpdateFeatureFlag}
          />
        </FeatureGate>
      </Stack>
      <Stack
        direction="row"
        justifyContent={decider ? "space-between" : "flex-end"}
        alignItems="center"
      >
        {decider && (
          <Typography>
            {t("Decider")}: {decider?.label}
          </Typography>
        )}
        <FormControlLabel
          value="start"
          sx={{ alignSelf: "flex-end" }}
          control={
            <Checkbox readOnly checked={featureFlag?.enabled} color="success" />
          }
          label={featureFlag?.enabled ? t("Enabled") : t("Disabled")}
          labelPlacement="start"
        />
      </Stack>
      <Typography>{featureFlag?.description ?? ""}</Typography>
      <Divider sx={{ my: 2 }} />
      {decider && (
        <Grid container spacing={2} flex={1}>
          <Grid item width="auto">
            <Stack
              direction="row"
              alignItems="center"
              pl={2}
              justifyContent="space-between"
            >
              <Typography>{t("DataItems")}</Typography>
            </Stack>
            <List>
              {dataItems.map((dataItem, index) => (
                <ListItemButton
                  key={dataItem.key}
                  divider={index !== dataItems.length - 1}
                  selected={selected?.key === dataItem.key}
                  onClick={() => setSelected(dataItem)}
                >
                  <ListItemText>{dataItem.key}</ListItemText>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid item xs>
            {selected && (
              <Stack sx={{ height: "100%" }}>
                <Stack direction="row" justifyContent="flex-end">
                  <AddDataItemValue
                    collectionName={application.collectionName}
                    featureFlag={featureFlag as FeatureFlag}
                    dataItem={selected}
                  />
                </Stack>
                <Box sx={{ flex: 1, paddingY: 2 }}>
                  <Paper elevation={2} sx={{ height: "100%" }}>
                    <DataGrid hideFooter rows={rows} columns={columns} />
                  </Paper>
                </Box>
              </Stack>
            )}
          </Grid>
        </Grid>
      )}
    </Stack>
  );
};
