import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField } from "@mui/material";
import { useField } from "formik";

import { useGetStates } from "hooks/useGetStates";
import { Locality } from "types/Organization";

type StateSelectProps = {
  name: string;
  label: string;
  readOnly?: boolean;
  fullWidth?: boolean;
  countryCode?: string;
};

export const StateSelect = (props: StateSelectProps): JSX.Element => {
  const { t } = useTranslation();
  const { states, isLoading } = useGetStates(props.countryCode);
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(
    props.name
  );
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const getOptionLabel = useCallback(
    (option: Locality) =>
      option.shortName && option.name
        ? `${option.shortName} - ${option.name}`
        : "",
    []
  );

  const onChange = useCallback((_, value) => setValue(value), [setValue]);

  const isOptionEqualToValue = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  const options = useMemo(() => states?.map((item) => item) ?? [], [states]);

  return (
    <Autocomplete
      id={props.name}
      autoComplete
      onBlur={onBlur}
      options={options}
      disableClearable
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      loading={isLoading}
      loadingText={t("Loading")}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <TextField
          {...params}
          name={props.name}
          label={props.label}
          fullWidth
          error={hasError}
          helperText={hasError ? (error as any).name : " "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "do-not-autofill",
          }}
        />
      )}
    />
  );
};
