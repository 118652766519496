import { useEffect } from "react";
import { useFormikContext } from "formik";

import { Fields } from "./formUtils";

interface SetSameAsBillingProps {
  billingValues: Fields;
  sameAsBilling: boolean;
  needsReset: boolean;
}

export const SetSameAsBilling = ({
  billingValues,
  sameAsBilling,
  needsReset,
}: SetSameAsBillingProps): null => {
  const { setFieldValue, resetForm } = useFormikContext();

  useEffect(() => {
    if (sameAsBilling) {
      setFieldValue("Location", billingValues, false);
    } else {
      if (needsReset) resetForm();
    }
  }, [billingValues, resetForm, setFieldValue, sameAsBilling, needsReset]);

  return null;
};
