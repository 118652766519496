import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUsersRequestParams, GetUsersResponse } from "types/Api";
import { useAuthApi } from "./useApi";

type useGetOrganizationUserProps = GetUsersRequestParams & {
  organizationId: string;
};

export const useGetOrganizationUsers = ({
  organizationId,
  params,
}: useGetOrganizationUserProps) => {
  const getOrganizationUsers = useAuthApi<never, GetUsersResponse>(
    "get",
    endpoints.ORGANIZATION_USERS(organizationId),
    { params }
  );

  const { data, isLoading, isError, isSuccess } = useQuery(
    [QueryKeys.GET_USERS, "organization", organizationId, { ...params }],
    () => getOrganizationUsers()
  );

  return {
    isLoading,
    isError,
    isSuccess,
    usersResponse: data?.data,
  };
};
