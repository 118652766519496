import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

import { EditButton } from "common/components/EditButton/EditButton";

export const ProductFee = (): JSX.Element => {
  const { t } = useTranslation();
  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("PropertyName"),
        flex: 1,
        sortable: false,
      },
      {
        field: "value",
        flex: 1,
        headerName: t("PropertyValue"),
        sortable: false,
      },
      {
        field: "actions",
        width: 30,
        headerName: "",
        sortable: false,
        // eslint-disable-next-line react/display-name
        renderCell: () => <EditButton />,
      },
    ],
    [t]
  );

  // TODO: implement actual data
  const rows = [];

  return (
    <Box sx={{ marginTop: "-7px", width: "100%", display: "flex", flex: 1 }}>
      <Paper elevation={2} sx={{ flex: 1 }}>
        <DataGrid columns={columns} rows={rows} hideFooter />
      </Paper>
    </Box>
  );
};
