import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useField } from "formik";

import { SecurityGroup } from "types/Group";
import useOrgSecurityGroups from "../../../hooks/useOrgSecurityGroups";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type GroupsMultiSelectProps = {
  initialGroup?: Array<SecurityGroup>;
  organizationId: string;
  name: string;
};

export const GroupsMultiSelect = ({
  initialGroup = [],
  organizationId,
  name,
}: GroupsMultiSelectProps): JSX.Element => {
  const { t } = useTranslation();

  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);
  const { isLoading, data } = useOrgSecurityGroups(organizationId);

  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const onChangeGroup = useCallback(
    (_, selectedGroups: Array<SecurityGroup>) => {
      setValue([
        ...initialGroup,
        ...selectedGroups.filter(
          (mappedGroup) => initialGroup[0]?.id !== mappedGroup.id
        ),
      ]);
    },
    [setValue, initialGroup]
  );
  return (
    <Autocomplete
      multiple
      fullWidth
      loading={isLoading}
      value={value}
      sx={{
        ".MuiFormControl-root": {
          marginTop: "4px",
        },
      }}
      options={
        data?.data.sort((a, b) => (a?.application < b?.application ? -1 : 1)) ??
        []
      }
      getOptionLabel={(option) => option?.name}
      disableCloseOnSelect
      onBlur={onBlur}
      onChange={onChangeGroup}
      groupBy={(option) => option?.application}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={`${option?.application}/ ${option?.name}`}
            {...getTagProps({ index })}
            key={option.id}
            {...(initialGroup[0]?.id === option?.id && {
              onDelete: undefined,
            })}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option?.name}
        </li>
      )}
      renderInput={(params) => (
        <FormControl fullWidth error={hasError}>
          <TextField
            {...params}
            error={hasError}
            label={t("Groups")}
            placeholder={t("AddGroup")}
            inputProps={{
              ...params?.inputProps,
              autoComplete: "do-not-autofill",
            }}
          />
          <FormHelperText error>{hasError ? error : " "}</FormHelperText>
        </FormControl>
      )}
    />
  );
};
