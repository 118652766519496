import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@mui/material";

type AppDialogProps = DialogProps & {
  loading: boolean;
  title: string;
  actionText: string;
  closeDialog: () => void;
  buttonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  onSubmit: () => unknown;
  renderFooter?: () => JSX.Element;
  renderActions?: boolean;
};

export const AppDialog = ({
  children,
  loading,
  open,
  closeDialog,
  title,
  onSubmit,
  actionText,
  renderFooter,
  maxWidth = "lg",
  buttonColor = "primary",
  renderActions = true,
}: AppDialogProps) => {
  const { t } = useTranslation();

  const clickCancel = useCallback(
    (event) => {
      event.stopPropagation();
      return closeDialog();
    },
    [closeDialog]
  );

  const clickAction = useCallback(
    (event) => {
      event.stopPropagation();
      return onSubmit();
    },
    [onSubmit]
  );

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      onBackdropClick={closeDialog}
      open={open}
      data-testid="app-dialog"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={clickCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {renderActions && (
        <DialogActions>
          {renderFooter && (
            <Box sx={{ width: "100%", mx: 2 }}>{renderFooter()}</Box>
          )}
          <Button
            onClick={clickCancel}
            color="primary"
            size="medium"
            variant="outlined"
            sx={{ width: 200 }}
          >
            {t("Cancel")}
          </Button>
          <LoadingButton
            onClick={clickAction}
            size="medium"
            color={buttonColor}
            variant="contained"
            loading={loading}
            sx={{ width: 200 }}
          >
            {actionText}
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};
