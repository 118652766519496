import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { List, ListItemText, Skeleton, Stack } from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { permissions } from "constants/constants";
import { useGetUtilityFeatureFlagsApplicationCollectionName } from "hooks/useGetUtilityFeatureFlagsApplicationCollectionName";
import { Application } from "types/FeatureFlag";
import { AddFeatureFlag } from "../AddFeatureFlag/AddFeatureFlag";
import { DeleteFeatureFlag } from "../DeleteFeatureFlag/DeleteFeatureFlag";

interface FeatureFlagsListProps {
  application: Application;
  selected?: string;
  setSelected: (featureFlag: string) => void;
}

export const FeatureFlagsList = ({
  application,
  selected,
  setSelected,
}: FeatureFlagsListProps): JSX.Element => {
  const { t } = useTranslation();
  const { isLoading, featureFlags } =
    useGetUtilityFeatureFlagsApplicationCollectionName({
      collectionName: application.collectionName,
    });

  const onDeleted = useCallback(
    (featureFlagName: string) => {
      if (selected === featureFlagName) setSelected(featureFlags[0]?.name);
    },
    [featureFlags, selected, setSelected]
  );

  useEffect(() => {
    !selected && setSelected(featureFlags[0]?.name);
  }, [featureFlags, setSelected, selected]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        pl={2}
        justifyContent="space-between"
      >
        {isLoading ? (
          <Skeleton variant="text" width={90} />
        ) : (
          <>
            {t("FeatureFlags", {
              count: featureFlags.length,
            })}
          </>
        )}
        <FeatureGate name={permissions.FEATUREFLAG_CREATE}>
          <AddFeatureFlag
            collectionName={application.collectionName}
            disabled={isLoading}
          />
        </FeatureGate>
      </Stack>
      {isLoading ? (
        <ListSkeleton width={150} withDivider />
      ) : (
        <List>
          {featureFlags.map((featureFlag, index) => (
            <ListItemButtonWithDialog
              key={featureFlag.name}
              divider={index !== featureFlags.length - 1}
              selected={selected === featureFlag.name}
              onClick={() => setSelected(featureFlag.name)}
            >
              <ListItemText>{featureFlag.name}</ListItemText>
              <FeatureGate name={permissions.FEATUREFLAG_DELETE}>
                <DeleteFeatureFlag
                  featureFlagName={featureFlag.name}
                  collectionName={application.collectionName}
                  onDeleted={onDeleted}
                />
              </FeatureGate>
            </ListItemButtonWithDialog>
          ))}
        </List>
      )}
    </>
  );
};
