import { RefObject, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, InputLabel } from "@mui/material";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { array, object, string } from "yup";

import { AppSelect } from "common/components/AppSelect/AppSelect";
import { CheckboxGroup } from "common/components/CheckboxGroup/CheckboxGroup";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { PermissionsField } from "common/components/PermissionsField/PermissionsField";
import { TextField } from "common/components/TextField/TextField";
import { useGetOrganization } from "hooks/useGetOrganization";
import { Application } from "hooks/useGetUtilityApps";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { AddGroupData, GroupType, SecurityGroup } from "types/Group";

const UpdatePermissions = ({
  cloneFrom,
  groups,
}: {
  cloneFrom: Array<string>;
  groups: Array<SecurityGroup>;
}) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    const permissions = groups.reduce((acc, group) => {
      if (cloneFrom.includes(group.id)) {
        return [
          ...acc,
          ...group.roles.reduce((idAcc, role) => {
            return [...idAcc, ...(role.permissions ?? [])];
          }, [] as string[]),
        ];
      } else {
        return acc;
      }
    }, [] as string[]);
    setFieldValue("permissions", permissions);
  }, [groups, cloneFrom, setFieldValue]);

  return null;
};

export interface AddGroupValues {
  application: null | Application;
  groupName: string;
  cloneFrom: Array<string>;
  permissions: string[];
}

interface AddGroupFormProps {
  formRef: RefObject<FormikProps<AddGroupValues>>;
  onSubmit: (values: AddGroupData) => void;
}

export const AddGroupForm = ({
  formRef,
  onSubmit,
}: AddGroupFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { data } = useGetOrganization({
    id: selectedOrganizationId as string,
  });
  const initialValues = useMemo(
    () => ({
      application: null,
      groupName: "",
      cloneFrom: [],
      permissions: [],
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        application: object().required(t("Required")).nullable(true),
        groupName: string()
          .required(t("Required"))
          .test("group-duplicated-name", t("NameDuplicated"), (value = "") => {
            const lowerCaseValue = value.trim().toLowerCase();
            return !data?.securityGroups.some(
              (group) => group.name.toLowerCase() === lowerCaseValue
            );
          }),
        permissions: array().min(1, t("OnePermissionRequired")),
      }),
    [t, data]
  );

  const onFormSubmit = useCallback(
    (values: AddGroupValues) => {
      const data = {
        orgId: selectedOrganizationId as string,
        name: values.groupName,
        application: values.application?.id as string,
        roles: [],
        permissions: values.permissions,
        groupType: "APPLICATION" as GroupType,
      };
      return onSubmit(data);
    },
    [selectedOrganizationId, onSubmit]
  );

  const cloneOptions = useMemo(
    () =>
      data?.securityGroups.map((group) => ({
        application: group.application,
        applicationLabel: group.applicationLabel,
        label: group.name,
        value: group.id,
      })) ?? [],
    [data]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched }) => (
        <Form>
          <FormGridContainer>
            <Grid item xs={6}>
              <AppSelect
                label={t("Application")}
                name="application"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField name="groupName" label={t("Name")} fullWidth />
            </Grid>
          </FormGridContainer>
          {values.application && (
            <>
              <CheckboxGroup
                name="cloneFrom"
                label={t("CloneFrom")}
                options={cloneOptions.filter(
                  (option) => option.application === values.application?.id
                )}
                columns={4}
              />
              <InputLabel
                error={touched.permissions && Boolean(errors.permissions)}
              >
                {t("Permissions")}
              </InputLabel>
              <UpdatePermissions
                cloneFrom={values.cloneFrom}
                groups={data?.securityGroups ?? []}
              />
              <PermissionsField
                applicationName={values.application?.name ?? ""}
                name="permissions"
                columns={3}
              />
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};
