import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import {
  GetOrganizationProducts,
  OrganizationProductAssigmentData,
  ServerError,
} from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseOrganizationDeleteProductProps {
  organizationId: string;
}

export const useOrganizationDeleteProduct = ({
  organizationId,
}: UseOrganizationDeleteProductProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const deleteProduct = useAuthApi<
    OrganizationProductAssigmentData,
    GetOrganizationProducts
  >("post", endpoints.ORGANIZATION_PRODUCT_UNASSIGN(organizationId));

  const { isLoading, isSuccess, mutate } = useMutation(deleteProduct, {
    mutationKey: "DeleteProduct",
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Delete Organization Product unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Delete Organization Product", "ERROR");
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        [QueryKeys.GET_ORGANIZATION_PRODUCTS, organizationId],
        { data }
      );
    },
  });

  const onDeleteProduct = (data: OrganizationProductAssigmentData) =>
    mutate(data);

  return {
    isLoading,
    isSuccess,
    onDeleteProduct,
  };
};
