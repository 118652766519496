import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";

import { useAppDialog } from "../../../../hooks/useAppDialog";
import { useAppSnackbar } from "../../../../hooks/useAppSnackbar";
import useForceLogout from "../../../../hooks/useForceLogout";

interface Props {
  userId: string;
}

const ForceLogout = ({ userId }: Props) => {
  const { t } = useTranslation();

  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();

  const { setSnackbarData } = useAppSnackbar();

  const { doForceLogout, isForceLogoutLoading } =
    useForceLogout({ userId });

  const onSubmit = async () => {
    doForceLogout({ userId }).then(() => {
      closeDialog();

      setSnackbarData({
        severity: "success",
        message: t("PasswordForcingSuccessful"),
      });
    });
  };

  return (
    <>
      <Button onClick={openDialog}>{t("ForceLogout")}</Button>
      <Dialog
        title={t("ForceLogout")}
        {...dialogProps}
        actionText={t("Confirm")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isForceLogoutLoading}
        maxWidth="xs"
      >
        <Typography
          sx={{
            fontSize: "14px",
          }}
        >
          {t("SureToForceLogin")}
        </Typography>
      </Dialog>
    </>
  );
};

export default ForceLogout;