import React, { Dispatch, useRef, useState } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Box, Stack } from "@mui/material";
import { useField } from "formik";

interface Props {
  editPhotoCallback: Dispatch<FormData | null>;
  confirmDeleteImg: boolean;
  setConfirmDeleteImg: Dispatch<boolean>;
}

const EditUserPhoto: React.FC<Props> = ({
  editPhotoCallback,
  confirmDeleteImg,
  setConfirmDeleteImg,
}) => {
  const [imagePickedByUser, setImagePickedByUser] = useState(null);
  const [field] = useField("profilePhotoUrl");
  const { value: imageFromServer } = field;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImagePickedByUser(file);
      setConfirmDeleteImg(false);
      const formData = new FormData();
      formData.append("image", file);
      editPhotoCallback(formData);
    } else {
      setImagePickedByUser(null);
    }
  };

  const isServerImgDefault: boolean = imageFromServer?.includes("default");

  const reset = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    inputRef!.current!.value = "";
    setImagePickedByUser(null);
    editPhotoCallback(null);
  };

  return (
    <Stack direction="row">
      <Box
        sx={{
          width: "220px",
          height: "220px",
          display: "flex",
          flexShrink: 0,
          backgroundColor: "#c1c7d0",
          borderRadius: "100%",
          position: "relative",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <>
          {confirmDeleteImg || (!imagePickedByUser && !imageFromServer) ? (
            <AddAPhotoIcon
              sx={{
                fontSize: "80px",
                color: "#777b85",
              }}
            />
          ) : (
            <Box
              component="img"
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "100%",
                objectFit: "cover",
                flexShrink: 0,
                cursor: "pointer",
              }}
              src={
                imagePickedByUser
                  ? URL.createObjectURL(imagePickedByUser)
                  : imageFromServer
              }
              onClick={() => {
                inputRef?.current?.click();
              }}
            />
          )}

          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={inputRef}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: 2,
              opacity: 0,
              cursor: "pointer",
              background: "grey",
            }}
          />
        </>
      </Box>
      {!confirmDeleteImg
        ? (!isServerImgDefault || imagePickedByUser) && (
            <DeleteIcon
              fontSize="small"
              color="primary"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                reset();
                setConfirmDeleteImg(true);
              }}
            />
          )
        : null}
    </Stack>
  );
};
export default EditUserPhoto;
