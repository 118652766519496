import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { EarningValues, EditEarningForm } from "./EditEarningForm";
import { Earning } from "./Payment";

interface EditEarningProps {
  earning: Earning;
}

export const EditEarning = ({ earning }: EditEarningProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<EarningValues>>(null);

  const onSubmit = useCallback(() => {
    return formRef.current?.handleSubmit();
  }, [formRef]);

  const onEditClick = useCallback(() => {
    openDialog();
  }, [openDialog]);

  useEffect(() => {
    closeDialog();
  }, [closeDialog]);

  const editEarning = useCallback(
    (earning) => {
      //TODO: implement edit earning funtion
      closeDialog();
    },
    [closeDialog]
  );

  return (
    <>
      <EditButton onClick={onEditClick} />
      <Dialog
        title={t("EditEarning")}
        loading={false}
        buttonColor="primary"
        actionText={t("Save")}
        onSubmit={onSubmit}
        maxWidth="xs"
        {...dialogProps}
      >
        <EditEarningForm
          formRef={formRef}
          onSubmit={editEarning}
          earning={earning}
        />
      </Dialog>
    </>
  );
};
