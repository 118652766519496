import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardProps as MuiCardProps,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps as MuiCardMediaProps,
  Typography,
} from "@mui/material";

import { BeforeAfter } from "../BeforeAfterClaim";

type BeforeAfterCardProps = MuiCardProps & {
  renderImage: BeforeAfter;
  minStatusSelectWidth: number;
  sxCardMediaProps: MuiCardMediaProps;
  index?: number;
  onImageClick?(selectedImage: string): void;
};

export const BeforeAfterCard = ({
  renderImage,
  minStatusSelectWidth,
  sxCardMediaProps,
  index = -1,
  onImageClick = () => null,
  ...props
}: BeforeAfterCardProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState(renderImage.status);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
  };

  return (
    <Card {...props}>
      <Grid container>
        <Stack direction="row" spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" p={1} align="center">
              {t("Before").toUpperCase()}
            </Typography>
            <CardActionArea
              onClick={() => onImageClick(renderImage.beforeImgUrl)}
            >
              <CardMedia
                component="img"
                image={renderImage.beforeImgUrl}
                alt={`beforeImage-${index}`}
                sx={{ ...sxCardMediaProps }}
              />
            </CardActionArea>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" p={1} align="center">
              {t("After").toUpperCase()}
            </Typography>
            <CardActionArea
              onClick={() => onImageClick(renderImage.afterImgUrl)}
            >
              <CardMedia
                component="img"
                image={renderImage.afterImgUrl}
                alt={`afterImage-${index}`}
                sx={{ ...sxCardMediaProps }}
              />
            </CardActionArea>
          </Grid>
        </Stack>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <CardActions>
          {/* TODO: Change by custom Select element when Api is available to integrate it with formik context */}
          <FormControl
            fullWidth
            sx={{ minWidth: minStatusSelectWidth, marginLeft: 4 }}
          >
            <InputLabel id="before-after-status-label">
              {t("Status")}
            </InputLabel>
            <Select
              labelId="before-after-status-label"
              id="before-after-select"
              inputProps={{
                id: "before-after-select",
                "data-testid": "before-after-select",
              }}
              label={t("Status")}
              onChange={handleChange}
              value={selectedStatus}
            >
              <MenuItem value="noDifferences">
                {t("NoDifferences").toUpperCase()}
              </MenuItem>
              <MenuItem value="withDifferences">
                {t("WithDifferences")}
              </MenuItem>
            </Select>
          </FormControl>
        </CardActions>
      </Stack>
    </Card>
  );
};
