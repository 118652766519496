import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface useDeleteOrganizationGroupProps {
  organizationId: string;
  groupId: string;
}

export const useDeleteOrganizationGroup = ({
  organizationId,
  groupId,
}: useDeleteOrganizationGroupProps) => {
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const deleteOrganizationGroup = useAuthApi(
    "delete",
    endpoints.ORGANIZATION_GROUP(organizationId, groupId)
  );

  const { isSuccess, isLoading, mutate } = useMutation(
    ["deleteOrganization", { organizationId, groupId }],
    () => deleteOrganizationGroup(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          QueryKeys.GET_ORGANIZATION,
          organizationId,
        ]);
        auditFormEvent(
          "Organization Deleted",
          "Delete Organization Group",
          "FORM_SENT"
        );
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ??
          "Delete Organization Group unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Delete Organization Group", "ERROR");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    deleteGroup: mutate,
  };
};
