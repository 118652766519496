import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { Form, Formik } from "formik";
import { object, string } from "yup";

import { TextField } from "common/components/TextField/TextField";
import { useForgotPassword } from "hooks/useForgotPassword";

interface ResetPasswordFormProps {
  onCancel: () => void;
}

export const ResetPasswordForm = ({
  onCancel,
}: ResetPasswordFormProps): JSX.Element => {
  const { t } = useTranslation();
  const validationSchema = object().shape({
    email: string().email(t("EmailValidationError")).required(t("Required")),
  });
  const { isLoading, isError, isSuccess, onResetPassword } =
    useForgotPassword();

  return (
    <Formik
      initialValues={{ email: "" }}
      onSubmit={onResetPassword}
      validationSchema={validationSchema}
    >
      <Form>
        {!isError && !isSuccess && <Box sx={{ pt: 7.5 }} />}
        {isError && (
          <Alert variant="filled" severity="error" sx={{ marginY: 2 }}>
            <AlertTitle>{t("UnknownErrorTitle")}</AlertTitle>
            {t("UnknownErrorCopy")}
          </Alert>
        )}
        {isSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginY: 2 }}>
            <AlertTitle>{t("PasswordResetSuccessTitle")}</AlertTitle>
            {t("PasswordResetSuccessCopy")}
          </Alert>
        )}
        <TextField label={t("Email")} fullWidth id="email" name="email" />
        <Box sx={{ pb: 7.5, display: "flex", justifyContent: "flex-end" }}>
          <Button size="medium" onClick={onCancel} data-testid="login-button">
            {t("Cancel")}
          </Button>
        </Box>
        <LoadingButton
          size="medium"
          loading={isLoading}
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          data-testid="submit-button"
        >
          {t("SubmitYourEmail")}
        </LoadingButton>
      </Form>
    </Formik>
  );
};
