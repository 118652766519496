import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetFeatureFlagsResponse } from "types/FeatureFlag";
import { useAuthApi } from "./useApi";

export const useGetUtilityFeatureFlags = () => {
  const getFeatureFlags = useAuthApi<never, GetFeatureFlagsResponse>(
    "get",
    endpoints.UTILITY_FEATURE_FLAGS
  );
  const { isLoading, data } = useQuery([QueryKeys.GET_FEATURE_FLAGS], () =>
    getFeatureFlags()
  );

  return {
    isLoading,
    applications: data?.data.applications ?? [],
    deciders: data?.data.deciders ?? [],
  };
};
