import { Route } from "react-router-dom";

import FeatureFlags from "pages/FeatureFlags/FeatureFlags";
import { devPaths } from "./paths";

export const devRoutes = () => {
  if (process.env.REACT_APP_ACTIVATE_DEVTOOL_ROUTES === "true") {
    return (
      <>
        <Route path={devPaths.featureFlags} element={<FeatureFlags />} />
      </>
    );
  }

  return null;
};
