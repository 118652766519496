import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";

import { paths, toolsPaths, turkPipelinesPaths } from "navigation/paths";

const toolsPathNames = {
  [paths.TOOLS]: "Tools",
  [toolsPaths.FEATURE_FLAGS]: "FeatureFlags",
  [toolsPaths.TURK_PIPELINES]: "TurkPipelines",
  [turkPipelinesPaths.RECEIPTS_REVIEW]: "ReceiptsReview",
  [turkPipelinesPaths.ACTOR_REVIEW]: "ActorReview",
  [turkPipelinesPaths.IMAGE_QUALITY]: "ImageQuality",
  [turkPipelinesPaths.VEHICLE_ANGLE]: "VehicleAngle",
  [turkPipelinesPaths.BEFORE_AFTER]: "BeforeAfter",
} as const;

export const ToolsBreadcrumbs = (): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnameArray = useMemo(
    // remove first one empty and reverse
    () => [...pathname.split("/").slice(1)].reverse(),
    [pathname]
  );
  let pathHistory = "";
  const generatePathname = (path: string) => {
    pathHistory += "/" + path;
    return pathHistory;
  };

  return (
    <Breadcrumbs aria-label="breadcrumb" sx={{ py: 1, fontSize: "0.75rem" }}>
      {pathnameArray
        .slice(1)
        .reverse()
        .map((path) => (
          <Link
            key={path}
            to={generatePathname(path)}
            component={RouterLink}
            underline="none"
          >
            <Typography
              sx={{
                color: "primary.light",
                textTransform: "uppercase",
                fontSize: "0.75rem",
              }}
            >
              {t(toolsPathNames[path] as unknown as TemplateStringsArray)}
            </Typography>
          </Link>
        ))}
      <Typography
        sx={{
          color: "text.primary",
          textTransform: "uppercase",
          fontSize: "0.75rem",
        }}
      >
        {t(
          (toolsPathNames[
            pathnameArray[0]
          ] as unknown as TemplateStringsArray) || pathnameArray[0]
        )}
      </Typography>
    </Breadcrumbs>
  );
};
