import {
  RefObject,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { TabContext, TabPanel } from "@mui/lab";
import { Grid, InputAdornment, MenuItem, Tab } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

import { CurrencySelect } from "common/components/CurrencySelect/CurrencySelect";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { LocaleSelect } from "common/components/LocaleSelect/LocaleSelect";
import { OrganizationSelect } from "common/components/OrganizationSelect/OrganizationSelect";
import { Select } from "common/components/Select/Select";
import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { TextField } from "common/components/TextField/TextField";
import { useValidationSchema } from "hooks/useValidationSchema";
import { GreyBox } from "styles/App.styles";
import { AddressContactType } from "types/common";
import { OrganizationUpdateParams } from "types/Organization";
import { HoursOfOperationFormFields } from "../EditHoursOfOperation/HoursOfOperationFormFields";
import { AddressContactFormFields } from "../EditLocation/AddressContactFormFields";
import {
  AddOrganizationValues,
  getInitialValues,
  valuesToAPI,
} from "./formUtils";

interface AddOrganizationFormProps {
  formRef: RefObject<FormikProps<AddOrganizationValues>>;
  onSubmit: (values: OrganizationUpdateParams) => void;
}

type TabLabel = AddressContactType | "OpenSchedule";

export const AddOrganizationForm = ({
  formRef,
  onSubmit,
}: AddOrganizationFormProps): JSX.Element => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema("AddOrganization");
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const [tabValue, setTabValue] = useTabValue<TabLabel>("Billing");
  const onChangeSameAsBilling = useCallback(
    (_: SyntheticEvent, value: boolean) => {
      setSameAsBilling(value);
    },
    []
  );
  const initialValues: AddOrganizationValues = useMemo(
    () => getInitialValues(),
    []
  );

  const onSubmitForm = (values: AddOrganizationValues) => {
    const valuesToSend = valuesToAPI(values);
    valuesToSend.hoursOfOperation["zoneId"] = values.Location.timezone.timezone;

    onSubmit(valuesToSend);
  };

  return (
    <Formik
      onSubmit={onSubmitForm}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const hasErrorOnBilling =
          formik.errors.Billing && formik.touched.Billing;
        const hasErrorOnLocation =
          formik.errors.Location && formik.touched.Location;
        const hasErrorOnOpenSchedule = Boolean(formik.errors.hoursOfOperation);
        return (
          <Form>
            <FormGridContainer>
              <Grid item xs={4}>
                <TextField name="name" label="Name" fullWidth />
              </Grid>
              <Grid item xs={4}>
                <Select name="type" label={t("Type")} fullWidth>
                  <MenuItem value="ROOFTOP">{t("Rooftop")}</MenuItem>
                  <MenuItem value="GROUP">{t("Group")}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <OrganizationSelect name="parentId" label={t("Parent")} />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label={t("Website")}
                  name="websiteUrl"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <LocaleSelect name="locale" label={t("Locale")} fullWidth />
              </Grid>
              <Grid item xs={4}>
                <CurrencySelect
                  name="currency"
                  label={t("Currency")}
                  fullWidth
                />
              </Grid>
            </FormGridContainer>
            <TabContext value={tabValue}>
              <Tabs onChange={setTabValue} sx={{ mt: 1 }}>
                <Tab
                  value="Billing"
                  iconPosition="end"
                  label={t("Billing")}
                  icon={
                    hasErrorOnBilling ? (
                      <ErrorOutlineIcon color="error" />
                    ) : undefined
                  }
                />
                <Tab
                  value="Location"
                  iconPosition="end"
                  label={t("Location")}
                  icon={
                    hasErrorOnLocation ? (
                      <ErrorOutlineIcon color="error" />
                    ) : undefined
                  }
                />
                <Tab
                  value="OpenSchedule"
                  iconPosition="end"
                  label={t("OpenSchedule")}
                  icon={
                    hasErrorOnOpenSchedule ? (
                      <ErrorOutlineIcon color="error" />
                    ) : undefined
                  }
                />
              </Tabs>
              <GreyBox elevation={0} sx={{ pb: 1, mt: 2 }}>
                <TabPanel value="Billing">
                  <AddressContactFormFields
                    sameAsBilling={false}
                    onChangeSameAsBilling={onChangeSameAsBilling}
                    type="Billing"
                    {...formik}
                    billingValues={formik.values.Billing}
                    needsReset={false}
                  />
                </TabPanel>
                <TabPanel value="Location">
                  <AddressContactFormFields
                    sameAsBilling={sameAsBilling}
                    onChangeSameAsBilling={onChangeSameAsBilling}
                    type="Location"
                    {...formik}
                    billingValues={formik.values.Billing}
                    needsReset={false}
                  />
                </TabPanel>
                <TabPanel value="OpenSchedule">
                  <HoursOfOperationFormFields name="hoursOfOperation" />
                </TabPanel>
              </GreyBox>
            </TabContext>
          </Form>
        );
      }}
    </Formik>
  );
};
