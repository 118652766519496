import { List, ListItemButton, ListItemText, Skeleton } from "@mui/material";

interface ListSkeletonProps {
  withDivider?: boolean;
  width: number;
}

export const ListSkeleton = ({
  withDivider = false,
  width,
}: ListSkeletonProps): JSX.Element => {
  return (
    <List sx={{ py: 0 }}>
      <ListItemButton divider={withDivider}>
        <ListItemText>
          <Skeleton variant="text" width={width} />
        </ListItemText>
      </ListItemButton>
      <ListItemButton divider={withDivider}>
        <ListItemText>
          <Skeleton variant="text" width={width - 50} />
        </ListItemText>
      </ListItemButton>
      <ListItemButton divider={withDivider}>
        <ListItemText>
          <Skeleton variant="text" width={width - 50} />
        </ListItemText>
      </ListItemButton>
      <ListItemButton>
        <ListItemText>
          <Skeleton variant="text" width={width - 80} />
        </ListItemText>
      </ListItemButton>
    </List>
  );
};
