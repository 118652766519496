import { useQuery } from "react-query";

import { useAuthApi } from "./useApi";

interface Props {
  id: string;
  application: string;
  applicationLabel: string;
  name: string;
  type: string;
  permissions: string[];
  organization: {
    id: string;
    label: string;
  }[];
}

const useOrgSecurityGroups = (organizationId: string) => {
  const getOrganization = useAuthApi<never, Props[]>(
    "get",
    `/orgs/${organizationId}/securityGroups`
  );

  const { isLoading, isError, data } = useQuery("securityGroups", () =>
    getOrganization()
  );

  return {
    isLoading,
    isError,
    data,
  };
};

export default useOrgSecurityGroups;
