import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Grid, Paper, Rating, Tab, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { EditButton } from "common/components/EditButton/EditButton";
import { Tabs } from "common/components/Tabs/Tabs";
import { skill } from "../EditUserForm";

interface UserSkillDescriptionProps {
  selectedSkill: skill;
}

export const UserSkillDescription = ({
  selectedSkill,
}: UserSkillDescriptionProps): JSX.Element => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "date",
      headerName: t("Date").toUpperCase(),
      flex: 1,
      sortable: false,
    },
    {
      field: "workOrder",
      headerName: t("WorkOrder").toUpperCase(),
      flex: 2,
      sortable: false,
    },
    {
      field: "rating",
      headerName: t("Rating").toUpperCase(),
      flex: 2,
      sortable: false,
    },
    {
      field: "reviewer",
      headerName: t("Reviewer").toUpperCase(),
      flex: 2,
      sortable: false,
    },
    {
      field: "comments",
      headerName: t("Comments").toUpperCase(),
      flex: 2,
      sortable: false,
    },
  ];

  return (
    <>
      <Typography mb={2} variant="h6">
        {selectedSkill.name}
      </Typography>
      <Typography mb={4}>{selectedSkill.description}</Typography>
      <Grid container direction={"row"} sx={{ paddingBottom: 2 }}>
        <Typography mr={3}>{t("Rating")}</Typography>
        <Rating name="rating" value={selectedSkill.rate} readOnly />
        <EditButton onClick={() => null} />
      </Grid>
      <TabContext value="History">
        <Tabs value="History">
          <Tab label={t("RatingHistory")} value="History" />
        </Tabs>
        <TabPanel value="History">
          <Box sx={{ height: "500px", display: "flex", pt: 2 }}>
            <Paper elevation={2} sx={{ flex: 1, display: "flex" }}>
              <DataGrid rows={selectedSkill.ratingHistory} columns={columns} />
            </Paper>
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
};
