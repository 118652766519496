import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import {
  CreateApiTokenData,
  GetOrganizationApiTokensResponse,
  ServerError,
} from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";

export const usePostOrganizationApiToken = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const postApiToken = useAuthApi<
    CreateApiTokenData,
    GetOrganizationApiTokensResponse
  >("post", endpoints.ORGANIZATION_CREATE_API_TOKENS);

  const { isLoading, isSuccess, mutate } = useMutation(postApiToken, {
    onSuccess: () => {
      auditFormEvent(
        "Api token added",
        "Add Organization Api Token",
        "FORM_SENT"
      );
      setSnackbarData({ severity: "success", message: t("ApiTokenAdded") });
      return queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION_API_TOKENS,
        selectedOrganizationId,
      ]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Add Organization Api Token Unknown Error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add Organization Api Token", "ERROR");
    },
  });

  const postOrganizationApiToken = (data: CreateApiTokenData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    postOrganizationApiToken,
  };
};
