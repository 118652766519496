import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { MedicalCardFields } from "./MedicalCardFields";

export interface MedicalCardValues {
  certType: "MEDICAL_CARD";
  nationalRegistryNumber: string;
  expirationDateUTC: string | null;
}

interface MedicalCardAddFormProps {
  formRef: RefObject<FormikProps<MedicalCardValues>>;
  onSubmit: (values: MedicalCardValues) => void;
}

export const MedicalCardAddForm = ({
  formRef,
  onSubmit,
}: MedicalCardAddFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: MedicalCardValues = useMemo(
    () => ({
      certType: "MEDICAL_CARD",
      nationalRegistryNumber: "",
      expirationDateUTC: null,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        nationalRegistryNumber: string().required(t("Required")),
        expirationDateUTC: string().required(t("Required")).nullable(true),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <MedicalCardFields />
      </Form>
    </Formik>
  );
};
