import { RefObject, useCallback, useMemo } from "react";
import { Form, Formik, FormikProps } from "formik";

import { PostOrganizationLocationData } from "types/Location";
import {
  FormValues,
  formValuesToApi,
  getEmptyInitialValues,
  useValidation,
} from "../../Forms/formUtils";
import { LocationFields } from "../../Forms/LocationFields";

interface AddLocationFormProps {
  formRef: RefObject<FormikProps<FormValues>>;
  onSubmit: (value: Omit<PostOrganizationLocationData, "orgId">) => void;
}

export const AddLocationForm = ({
  formRef,
  onSubmit,
}: AddLocationFormProps): JSX.Element => {
  const initialValues: FormValues = useMemo(() => getEmptyInitialValues(), []);

  const validationSchema = useValidation();

  const onSubmitForm = useCallback(
    (values: FormValues) => {
      return onSubmit(formValuesToApi(values));
    },
    [onSubmit]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      innerRef={formRef}
    >
      <Form>
        <LocationFields />
      </Form>
    </Formik>
  );
};
