import { useCallback, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from "@mui/material";
import { useField } from "formik";

type CheckboxGroupProps = {
  options: Array<{
    label: string;
    value: string;
  }>;
  label: string;
  name: string;
  columns: number;
};

export const CheckboxGroup = (props: CheckboxGroupProps): JSX.Element => {
  const [field, { touched, error }, { setValue }] = useField(props.name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const onChange = useCallback(
    (value: string) => {
      if (field.value.includes(value)) {
        setValue(
          field.value.filter((mappedValue: string) => mappedValue !== value)
        );
      } else {
        setValue([...field.value, value]);
      }
    },
    [field.value, setValue]
  );

  return (
    <FormControl error={hasError} sx={{ width: "100%" }}>
      <FormLabel>{props.label}</FormLabel>
      <FormGroup>
        <Grid container>
          {props.options.map((option) => (
            <Grid item key={option.value} xs={12 / props.columns}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={option.value}
                    name={option.value}
                    onChange={() => onChange(option.value)}
                    checked={field.value.includes(option.value)}
                  />
                }
                label={option.label}
              />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
      <FormHelperText>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
