import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js/min";
import { object, string } from "yup";

import {
  DaySchedule,
  emptyOpenScheduleState,
  formToSchedule,
  scheduleToForm,
} from "components/OrganizationOverview/EditHoursOfOperation/formUtils";
import { hoursOfOperationSchema } from "components/OrganizationOverview/EditHoursOfOperation/validation";
import { COUNTRY_CODE_DEFAULT } from "constants/constants";
import { PhoneInput } from "types/common";
import {
  OrganizationLocation,
  PostOrganizationLocationData,
} from "types/Location";
import { Locality } from "types/Organization";
import { parsePhoneString, phoneNumberToString } from "utils/apiPhone";

const tabs = ["General", "Contact", "OpenSchedule"] as const;
export type TabValue = typeof tabs[number];

export interface FormValues {
  general: {
    label: string;
    destinationPhone: PhoneInput;
    generalInstructions: string;
    pickupInstructions: string;
    dropoffInstructions: string;
    address: {
      street1: string;
      street2: string;
      city: string;
      state: null | Locality;
      postalCode: string;
    };
    tags: Array<string>;
  };
  contact: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    workEmail: string;
    mobilePhone: PhoneInput;
  };
  hoursOfOperation: DaySchedule;
}

export const ApiDataToInitialValues = (
  location?: OrganizationLocation
): FormValues | null => {
  if (!location) return null;
  const {
    label,
    locationNotes,
    destinationPhone,
    tags,
    address: { street1, street2, city, state, postalCode },
    contact: { firstName, lastName, jobTitle, workEmail, mobilePhone },
    businessHours,
  } = location;

  return {
    general: {
      label,
      destinationPhone: parsePhoneString(destinationPhone),
      generalInstructions: locationNotes?.generalInstructions ?? "",
      pickupInstructions: locationNotes?.pickupInstructions ?? "",
      dropoffInstructions: locationNotes?.dropOffInstructions ?? "",
      address: {
        street1,
        street2,
        city,
        state,
        postalCode,
      },
      tags,
    },
    contact: {
      firstName,
      lastName,
      jobTitle: jobTitle ?? "",
      workEmail,
      mobilePhone: parsePhoneString(mobilePhone),
    },
    hoursOfOperation: scheduleToForm(businessHours ?? null),
  };
};

export const getEmptyInitialValues = (): FormValues => ({
  general: {
    label: "",
    destinationPhone: {
      countryCode: COUNTRY_CODE_DEFAULT,
      phoneNumber: "",
    },
    generalInstructions: "",
    pickupInstructions: "",
    dropoffInstructions: "",
    address: {
      street1: "",
      street2: "",
      city: "",
      state: null,
      postalCode: "",
    },
    tags: [],
  },
  contact: {
    firstName: "",
    lastName: "",
    jobTitle: "",
    workEmail: "",
    mobilePhone: {
      countryCode: COUNTRY_CODE_DEFAULT,
      phoneNumber: "",
    },
  },
  hoursOfOperation: emptyOpenScheduleState(),
});

export const formValuesToApi = (
  values: FormValues
): Omit<PostOrganizationLocationData, "orgId"> => ({
  ...values.general,
  destinationPhone: phoneNumberToString(values.general.destinationPhone),
  address: {
    ...values.general.address,
    state: values.general.address.state?.shortName ?? "",
  },
  contact: {
    ...values.contact,
    mobilePhone: phoneNumberToString(values.contact.mobilePhone),
  },
  hoursOfOperation: formToSchedule(values),
  tags: values.general.tags,
});

export const useValidation = () => {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      object().shape({
        general: object().shape({
          label: string().required(t("Required")),
          destinationPhone: object().shape({
            phoneNumber: string()
              .required(t("Required"))
              .when("countryCode", (countryCode: CountryCode) => {
                return string().test(
                  "phone-test",
                  t("InvalidPhoneNumber"),
                  (value = "") => {
                    if (value === "") return false;
                    return isValidPhoneNumber(value, countryCode);
                  }
                );
              }),
          }),
          address: object().shape({
            street1: string().required(t("Required")),
            city: string().required(t("Required")),
            state: object().nullable().required(t("Required")),
            postalCode: string().required(t("Required")),
          }),
        }),
        contact: object().shape({
          firstName: string().required(t("Required")),
          lastName: string().required(t("Required")),
          workEmail: string()
            .required(t("Required"))
            .email(t("EmailValidationError")),
          mobilePhone: object().shape({
            phoneNumber: string()
              .required(t("Required"))
              .when("countryCode", (countryCode: CountryCode) => {
                return string().test(
                  "phone-test",
                  t("InvalidPhoneNumber"),
                  (value = "") => {
                    if (value === "") return false;
                    return isValidPhoneNumber(value, countryCode);
                  }
                );
              }),
          }),
        }),
        ...hoursOfOperationSchema,
      }),
    [t]
  );

  return validationSchema;
};
