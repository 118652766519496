import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { useAuthApi } from "./useApi";
import { useSelectedOrganization } from "./useSelectedOrganization";

interface UseGetSelectedOrganizationApiTokensProps {
  params?: {
    outputPermissions: boolean;
  };
}

export const useGetSelectedOrganizationApiToken = ({
  params,
}: UseGetSelectedOrganizationApiTokensProps) => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const getOrganizationApiTokens = useAuthApi<void, any>(
    "get",
    endpoints.ORGANIZATION_API_TOKENS(selectedOrganizationId as string),
    { params }
  );

  const { isLoading, isSuccess, data } = useQuery(
    [QueryKeys.GET_ORGANIZATION_API_TOKENS, selectedOrganizationId, { params }],
    () => getOrganizationApiTokens()
  );

  return {
    isLoading,
    isSuccess,
    // TODO: maybe pagination?
    apiTokens: data?.data.apiTokens ?? [],
  };
};
