import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { endpoints } from "constants/constants";
import { ResetPasswordData, ServerError } from "types/Api";
import { useApi } from "./useApi";

const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,20}$/;

export const useResetPassword = () => {
  const resetPassword = useApi<ResetPasswordData, unknown>(
    "post",
    endpoints.RESET_PASSWORD
  );
  const { isLoading, isError, isSuccess, mutate, error } =
    useMutation(resetPassword);

  const onResetPassword = (data: ResetPasswordData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    isError,
    onResetPassword,
    regex,
    error: error as AxiosError<ServerError>,
  };
};
