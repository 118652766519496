import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { useField } from "formik";

import { ANY_COLLECTION } from "constants/constants";
import { useGetUtilityFeatureFlags } from "hooks/useGetUtilityFeatureFlags";

type DeciderSelectProps = SelectProps & {
  name: string;
  collectionName: string;
};

export const DeciderSelect = ({
  name,
  collectionName,
  ...props
}: DeciderSelectProps): JSX.Element => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const { deciders, isLoading } = useGetUtilityFeatureFlags();
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const onChange = useCallback(
    ({ target }) => {
      setValue(target.value);
    },
    [setValue]
  );
  const filteredDeciders = useMemo(() => {
    return deciders.filter((decider) =>
      [ANY_COLLECTION, collectionName].some((collection) =>
        decider.supportedCollections.includes(collection)
      )
    );
  }, [deciders, collectionName]);

  return (
    <FormControl
      disabled={props.disabled}
      error={hasError}
      size={props.size}
      fullWidth={props.fullWidth}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        {...props}
        label={props.label}
        value={value ?? ""}
        onBlur={onBlur}
        displayEmpty
        disabled={props.disabled || isLoading}
        onChange={onChange}
        renderValue={
          isLoading
            ? () => (
                <>
                  <CircularProgress size={16} sx={{ marginRight: 2 }} />
                  {t("Loading")}
                </>
              )
            : undefined
        }
      >
        {filteredDeciders.map((decider) => (
          <MenuItem key={decider.classPath} value={decider.classPath}>
            {decider.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
