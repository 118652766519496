export const payment = {
  paymentMethod: "ACH",
  bankAccount: "123334003",
  bankRouting: "1324340011",
  pexCard: {
    pexId: "1234567890",
    pexCard: "00415",
    issueDate: new Date(2022, 1, 1),
    status: "ACTIVE",
  },
};
