import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteProductDriverPool } from "hooks/useDeleteProductDriverPool";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";

interface ProductDriverPoolDeleteProps {
  driverPoolId: string;
}

export const ProductDriverPoolDelete = ({
  driverPoolId,
}: ProductDriverPoolDeleteProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { deleteDriverPool, isSuccess, isLoading } = useDeleteProductDriverPool(
    {
      organizationId: selectedOrganizationId as string,
      driverPoolId,
    }
  );

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );
  const onDeleteConfirm = useCallback(() => {
    deleteDriverPool();
  }, [deleteDriverPool]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteDriverPoolTitle")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>
          {t("DeleteCopy")}
          <br />
          TODO: not yet implemented
        </Typography>
      </Dialog>
    </>
  );
};
