import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, IconButtonProps } from "@mui/material";

export const DeleteButton = (props: IconButtonProps) => (
  <IconButton sx={{ ...props.sx, ml: 1 }} {...props}>
    <DeleteIcon
      fontSize="small"
      color={props.disabled ? "disabled" : "primary"}
    />
  </IconButton>
);
