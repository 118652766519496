import { Route, Routes } from "react-router-dom";

import { Dashboard } from "pages/Dashboard/Dashboard";
import { IndexRedirection } from "pages/Dashboard/IndexRedirection";
import { Login } from "pages/Login/Login";
import { ApiTokens } from "pages/Organizations/ApiTokens/ApiTokens";
import { Groups as OrganizationGroups } from "pages/Organizations/Groups/Groups";
import { Locations as OrganizationLocations } from "pages/Organizations/Locations/Locations";
import { Organizations } from "pages/Organizations/Organizations";
import { Overview } from "pages/Organizations/Overview/Overview";
import { Products as OrganizationProducts } from "pages/Organizations/Products/Products";
import { Users as OrganizationUsers } from "pages/Organizations/Users/Users";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";
import { FeatureFlags } from "pages/Tools/FeatureFlags/FeatureFlags";
import { Tools } from "pages/Tools/Tools";
import { ToolsMenu } from "pages/Tools/ToolsMenu";
import { ActorReview } from "pages/Tools/TurkPipelines/ActorReview";
import { BeforeAfter } from "pages/Tools/TurkPipelines/BeforeAfter";
import { ActorReviewClaim } from "pages/Tools/TurkPipelines/ClaimForms/ActorReviewClaim";
import { BeforeAfterClaim } from "pages/Tools/TurkPipelines/ClaimForms/BeforeAfterClaim";
import { ImageQualityClaim } from "pages/Tools/TurkPipelines/ClaimForms/ImageQualityClaim";
import { ReceiptReviewClaim } from "pages/Tools/TurkPipelines/ClaimForms/ReceiptReviewClaim";
import { VehicleAngleClaim } from "pages/Tools/TurkPipelines/ClaimForms/VehicleAngleClaim";
import { ImageQuality } from "pages/Tools/TurkPipelines/ImageQuality";
import { ReceiptsReview } from "pages/Tools/TurkPipelines/ReceiptsReview";
import { TurkPipelinesMenu } from "pages/Tools/TurkPipelines/TurkPipelinesMenu";
import { VehicleAngle } from "pages/Tools/TurkPipelines/VehicleAngle";
import { Users } from "pages/Users/Users";
import { devRoutes } from "./devRoutes";
import {
  organizationsPaths,
  paths,
  toolsPaths,
  turkPipelinesPaths,
} from "./paths";

export const Navigation = (): JSX.Element => {
  return (
    <Routes>
      {devRoutes()}
      <Route path={paths.LOGIN} element={<Login />} />
      <Route path={paths.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={"/"} element={<Dashboard />}>
        <Route index element={<IndexRedirection />} />
        <Route path={paths.USERS} element={<Users />} />
        <Route path={paths.TOOLS} element={<Tools />}>
          <Route index element={<ToolsMenu />} />
          <Route path={toolsPaths.FEATURE_FLAGS} element={<FeatureFlags />} />
          <Route path={toolsPaths.TURK_PIPELINES}>
            <Route index element={<TurkPipelinesMenu />} />
            <Route
              path={turkPipelinesPaths.RECEIPTS_REVIEW}
              element={<ReceiptsReview />}
            />
            <Route
              path={`${turkPipelinesPaths.RECEIPTS_REVIEW}/:jobId`}
              element={<ReceiptReviewClaim />}
            />
            <Route
              path={turkPipelinesPaths.ACTOR_REVIEW}
              element={<ActorReview />}
            />
            <Route
              path={`${turkPipelinesPaths.ACTOR_REVIEW}/:jobId`}
              element={<ActorReviewClaim />}
            />

            <Route
              path={turkPipelinesPaths.VEHICLE_ANGLE}
              element={<VehicleAngle />}
            />
            <Route
              path={`${turkPipelinesPaths.VEHICLE_ANGLE}/:jobId`}
              element={<VehicleAngleClaim />}
            />
            <Route
              path={turkPipelinesPaths.IMAGE_QUALITY}
              element={<ImageQuality />}
            />
            <Route
              path={`${turkPipelinesPaths.IMAGE_QUALITY}/:jobId`}
              element={<ImageQualityClaim />}
            />
            <Route
              path={turkPipelinesPaths.BEFORE_AFTER}
              element={<BeforeAfter />}
            />
            <Route
              path={`${turkPipelinesPaths.BEFORE_AFTER}/:jobId`}
              element={<BeforeAfterClaim />}
            />
          </Route>
        </Route>
        <Route path={paths.ORGANIZATIONS}>
          <Route index element={<IndexRedirection />} />
          <Route path=":organizationId" element={<Organizations />}>
            <Route path={organizationsPaths.OVERVIEW} element={<Overview />} />
            <Route
              path={organizationsPaths.USERS}
              element={<OrganizationUsers />}
            />
            <Route
              path={organizationsPaths.GROUPS}
              element={<OrganizationGroups />}
            />
            <Route
              path={organizationsPaths.PRODUCTS}
              element={<OrganizationProducts />}
            />
            <Route
              path={organizationsPaths.API_TOKENS}
              element={<ApiTokens />}
            />
            <Route
              path={organizationsPaths.LOCATIONS}
              element={<OrganizationLocations />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
