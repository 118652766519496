import { Ref, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { CountryCode, isValidPhoneNumber } from "libphonenumber-js/min";
import { array, object, string } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import {
  CURRENCY_DEFAULT,
  DEFAULT_STATUS,
  DEFAULT_TIME_ZONE,
  LOCALE_DEFAULT,
} from "constants/constants";
import { useGetTimezones } from "hooks/useGetTimezones";
import { useGetUser } from "hooks/useGetUser";
import { useGetUtilityLocales } from "hooks/useGetUtilityLocales";
import { contact } from "mocks/api-responses/userContact";
import { exclusions } from "mocks/api-responses/userExclusions";
import { payment } from "mocks/api-responses/userPayment";
import { userSkills } from "mocks/api-responses/userSkills";
import { EditUserData, NameValue } from "types/Api";
import { Certification } from "types/Certifications";
import { Status } from "types/common";
import { SecurityGroup } from "types/Group";
import { OrganizationFlat, Timezone } from "types/Organization";
import { parsePhoneString, phoneNumberToString } from "utils/apiPhone";
import EditUserPhoto from "../../EditUserPhoto/EditUserPhoto";
import useEditPhoto from "../../EditUserPhoto/useEditPhoto";
import { FormControlLabelSwitch } from "../../FormControlLabel/FormControlLabelSwitch";
import { LocaleSelect } from "../../LocaleSelect/LocaleSelect";
import { PhoneInput } from "../../PhoneInput/PhoneInput";
import { Select } from "../../Select/Select";
import { TextField } from "../../TextField/TextField";
import { TimezoneSelect } from "../../TimezoneSelect/TimezoneSelect";
import { EditUserFormTabs } from "./EditUserFormTabs/EditUserFormTabs";

//TODO: change this structure when data comes from API
export interface skill {
  name: string;
  checked: boolean;
  description: string;
  rate: number;
  ratingHistory: Array<{
    id: number;
    date: Date;
    workOrder: number;
    rating: number;
    reviewer: string;
    comments: string;
  }>;
}

export interface exclusion {
  organizationId: string;
  organizationName: string;
  checked: boolean;
  exclusionDate: Date;
  excludedBy: string;
  reason: string;
  children: Array<exclusion>;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  status: Status;
  currency: string;
  timezone: Timezone;
  phone: {
    countryCode: CountryCode;
    phoneNumber: string;
  };
  contact: {
    nickName: string;
    dateOfBirth: Date;
    age: number;
    ssn: string;
    gender: string;
    mailingAddress: string;
    metroArea: string;
  };
  allowSMS: boolean;
  organizations: Array<OrganizationFlat>;
  defaultOrganization: OrganizationFlat;
  groups: Array<SecurityGroup>;
  skills: Array<{
    categoryName: string;
    categoryValues: Array<skill>;
    children: Array<{
      categoryName: string;
      categoryValues: Array<{
        categoryName: string;
        categoryValues: Array<skill>;
      }>;
    }>;
  }>;
  exclusions: Array<exclusion>;
  payment: {
    paymentMethod: string;
    bankAccount: string;
    bankRouting: string;
    pexCard: {
      pexId: string;
      pexCard: string;
      issueDate: Date;
      status: string;
    };
  };
  locale: string;
  metadata: unknown;
  certifications: Array<Certification>;
  tags: Array<string>;
}

interface EditUserProps {
  userId: string;
  onSubmit: (data: EditUserData) => void;
  formRef: Ref<FormikProps<FormValues>>;
}

export const EditUserForm = ({
  formRef,
  userId,
  onSubmit,
}: EditUserProps): JSX.Element => {
  const { t } = useTranslation();
  const { user, isLoading } = useGetUser({
    userId,
    params: {
      outputGroups: true,
      outputOrganizations: true,
      outputDefaultOrg: true,
    },
  });
  const { locales } = useGetUtilityLocales();
  const { timezones } = useGetTimezones();

  const initialValues = useMemo(() => {
    return {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      jobTitle: user?.jobTitle ?? "",
      email: user?.email ?? "",
      currency: user?.currency ?? CURRENCY_DEFAULT,
      status: user?.status ?? DEFAULT_STATUS,
      timezone:
        timezones?.find((zone) => zone?.timezone === String(user?.timezone)) ??
        DEFAULT_TIME_ZONE,
      phone: parsePhoneString(user?.mobilePhone),
      allowSMS: user?.mobilePhone?.allowSMS ?? false,
      organizations: user?.organizations ?? [],
      groups: user?.securityGroups ?? [],
      defaultOrganization: user?.defaultOrganization as OrganizationFlat,
      locale:
        locales.find((locale: NameValue) => locale?.value === user?.locale)
          ?.value || LOCALE_DEFAULT,
      profilePhotoUrl: user?.profilePhotoUrl,
      metadata: user?.metadata ?? null,
      skills: userSkills, //TODO: implement API value when it is available
      exclusions: exclusions, //TODO: implement API value when it is available
      contact: contact, // TODO: implement API value when it is available
      payment: payment, // TODO: implement API value when it is available
      certifications: [], // TODO: implement actual API value
      tags: [], // TODO: implement actual API value
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const validationSchema = useMemo(
    () =>
      object().shape({
        firstName: string().required(t("Required")),
        lastName: string().required(t("Required")),
        currency: string().required(t("Required")),
        locale: string().required(t("Required")),
        timezone: object().required(t("Required")).nullable(),
        email: string()
          .required(t("Required"))
          .email(t("EmailValidationError")),
        phone: object().shape({
          phoneNumber: string()
            .required(t("Required"))
            .when("countryCode", (countryCode: CountryCode) => {
              return string().test(
                "phone-test",
                t("InvalidPhoneNumber"),
                (value = "") => {
                  if (value === "") return false;
                  return isValidPhoneNumber(value, countryCode);
                }
              );
            }),
        }),
        organizations: array().test(
          t("OrgsMustHaveGroupsSelected"),
          t("OrgsMustHaveGroupsSelected"),
          (organizations, ctx) => {
            if (organizations) {
              return organizations.every((org) => {
                return ctx.parent.groups.find((group) => {
                  return org.id === group.organization.id;
                });
              });
            } else {
              return true;
            }
          }
        ),
      }),
    [t]
  );

  const {
    loadPhotoForEndpoint,
    confirmDeleteImg,
    setConfirmDeleteImg,
    savePhotoToServer,
    deletePhotoFromServer,
  } = useEditPhoto(userId);

  const onFormSubmit = useCallback(
    ({
      firstName,
      lastName,
      phone,
      allowSMS,
      email,
      status,
      timezone,
      defaultOrganization,
      organizations,
      // metadata,
      // currency,
      locale,
      jobTitle,
      groups,
    }: FormValues) => {
      const data: EditUserData = {
        firstName,
        lastName,
        jobTitle,
        // status,
        locale: locales.find((item) => item?.value === locale)?.value || "",
        // currency,
        // metadata,
        timezone:
          timezones?.find((zone) => zone?.timezone === timezone?.timezone)
            ?.timezone || undefined,
        defaultOrganizationId: defaultOrganization?.id || "",
        userName: email,
        userId,
        securityGroups: groups.map((group) => group.id),
        mobilePhone: {
          number: phoneNumberToString(phone) as string,
          allowSMS,
        },
        status,
      };

      const promisesToWaitFor: Promise<void>[] = [];
      if (confirmDeleteImg) {
        const promise = deletePhotoFromServer();
        promisesToWaitFor.push(promise);
      } else {
        const promise = savePhotoToServer();
        promisesToWaitFor.push(promise);
      }

      Promise.race(promisesToWaitFor).then(() => {
        onSubmit(data);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, onSubmit, confirmDeleteImg]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onFormSubmit}
      innerRef={formRef}
    >
      <Form>
        <FormGridContainer>
          <FormGridContainer item xs={9}>
            <Grid item xs={4}>
              <TextField name="firstName" fullWidth label={t("FirstName")} />
            </Grid>
            <Grid item xs={4}>
              <TextField name="jobTitle" label={t("JobTitle")} fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TimezoneSelect name="timezone" fullWidth label={t("TimeZone")} />
            </Grid>
            <Grid item xs={4}>
              <TextField name="lastName" fullWidth label={t("LastName")} />
            </Grid>
            <Grid item xs={4}>
              <TextField name="email" fullWidth disabled label={t("Email")} />
            </Grid>
            <Grid item xs={4}>
              <Select name="status" label={t("Status")} fullWidth>
                <MenuItem value="ACTIVE">{t("ACTIVE")}</MenuItem>
                <MenuItem value="INACTIVE">{t("INACTIVE")}</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={8.5}>
                  <PhoneInput name={`phone`} />
                </Grid>
                <Grid item xs={3.5}>
                  <FormControlLabelSwitch
                    name={`allowSMS`}
                    label={t("AllowSMS")}
                    labelPlacement="top"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: (theme) => theme.palette.text.secondary,
                        transform: "scale(0.75)",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <LocaleSelect name="locale" label={t("Locale")} fullWidth />
            </Grid>
          </FormGridContainer>
          <Grid
            item
            xs="auto"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EditUserPhoto
              editPhotoCallback={loadPhotoForEndpoint}
              confirmDeleteImg={confirmDeleteImg}
              setConfirmDeleteImg={setConfirmDeleteImg}
            />
          </Grid>
        </FormGridContainer>

        <EditUserFormTabs loading={isLoading} />
      </Form>
    </Formik>
  );
};
