import { useQueryClient } from "react-query";

import { QueryKeys } from "constants/constants";
import { Tokens } from "types/Tokens";

export const useAuth = () => {
  const queryClient = useQueryClient();

  const getData = () => queryClient.getQueryData<Tokens>(QueryKeys.TOKENS);

  const getToken = () => getData()?.token;
  const getSessionId = () => getData()?.sessionId;
  const getRefreshToken = () => getData()?.refreshToken;

  return {
    getToken,
    getSessionId,
    getRefreshToken,
  };
};
