import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDriverPoolDeleteActor } from "hooks/useDriverPoolDeleteActor";

interface DriverPoolActorDeleteProps {
  actorId: string;
}

export const DriverPoolActorDelete = ({
  actorId,
}: DriverPoolActorDeleteProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { deleteDriverPoolActor, isSuccess, isLoading } =
    useDriverPoolDeleteActor({
      actorId,
    });

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );
  const onDeleteConfirm = useCallback(() => {
    deleteDriverPoolActor();
  }, [deleteDriverPoolActor]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteActorTitle")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>
          {t("DeleteCopy")}
          <br />
          TODO: not yet implemented
        </Typography>
      </Dialog>
    </>
  );
};
