import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUtilityLocaleResponse } from "types/Api";
import { useAuthApi } from "./useApi";

export const useGetUtilityLocales = () => {
  const getLocales = useAuthApi<never, GetUtilityLocaleResponse>(
    "get",
    endpoints.UTILITY_LOCALES
  );

  const { isLoading, data } = useQuery([QueryKeys.GET_LOCALES], () =>
    getLocales()
  );

  return {
    isLoading,
    locales: data?.data?.locales ?? [],
  };
};
