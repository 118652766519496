import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";

import { endpoints, QueryKeys } from "constants/constants";
import { paths } from "navigation/paths";
import { clearAllTokens } from "utils/authLocalStorage";
import { LOGOUT_PARAM } from "../pages/Login/LoginForm/LoginForm";
import { useAuthBaseApi } from "./useApi";

interface UseLogout {
  enabled: boolean;
}

export const useLogout = ({ enabled }: UseLogout) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const logout = useAuthBaseApi<null, unknown>("get", endpoints.LOGOUT);

  const { isLoading } = useQuery([QueryKeys.LOGOUT], () => logout(), {
    enabled,
    onSuccess: () => {
      navigate(`${paths.LOGIN}?${LOGOUT_PARAM}=true`);
      queryClient.setQueryData([QueryKeys.TOKENS], null);
      queryClient.setQueryData([QueryKeys.USER], null);
      queryClient.setQueryData([QueryKeys.SELECTED_ORGANIZATION], null);
      queryClient.removeQueries([QueryKeys.GET_ORGANIZATIONS_FLAT]);
      clearAllTokens();
    },
  });

  return {
    isLoading,
  };
};
