import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { OrganizationLocation } from "types/Location";

interface InstructionsProp {
  location: OrganizationLocation;
}

export const Instructions = ({
  location: { locationNotes },
}: InstructionsProp): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box
      paddingY={2}
      pl={2}
      sx={{ overflow: "auto", height: "100%", maxHeight: "100%" }}
    >
      {locationNotes?.generalInstructions && (
        <>
          <Typography variant="body2">{t("General")}</Typography>
          <Typography variant="caption">
            {locationNotes.generalInstructions}
          </Typography>
        </>
      )}
      {locationNotes?.dropOffInstructions && (
        <>
          <Typography variant="body2" pt={2}>
            {t("Dropoff")}
          </Typography>
          <Typography variant="caption" pb={2}>
            {locationNotes.dropOffInstructions}
          </Typography>
        </>
      )}
      {locationNotes?.pickupInstructions && (
        <>
          <Typography variant="body2" pt={2}>
            {t("Pickup")}
          </Typography>
          <Typography variant="caption">
            {locationNotes.pickupInstructions}
          </Typography>
        </>
      )}
    </Box>
  );
};
