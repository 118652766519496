import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, Grid, InputLabel, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { FormikErrors, useField } from "formik";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { daysOfWeek } from "constants/constants";
import { DayTimes } from "./formUtils";

interface HoursOfOperationFormFieldsProps {
  name: string;
}

const WIDTH = "60px"; // TODO: Check for i18n

export const HoursOfOperationFormFields = ({
  name,
}: HoursOfOperationFormFieldsProps): JSX.Element => {
  const [{ value }, { error }, { setValue }] = useField(name);
  const { t } = useTranslation();
  const onChangeTimePicker = useCallback(
    (day: string, type: string, time?: number) => {
      let dayOfWeekObj = value[day][0];
      if (!dayOfWeekObj) {
        dayOfWeekObj = { start: null, end: null };
      }
      dayOfWeekObj[type] = time ?? null;

      setValue({ ...value, [day]: [dayOfWeekObj] });
    },
    [setValue, value]
  );

  return (
    <>
      <Grid container>
        <Grid item width={WIDTH} alignSelf="flex-end" pb={4.5}>
          <InputLabel>{t("Open")}</InputLabel>
        </Grid>
        <Grid item xs>
          <FormGridContainer>
            {daysOfWeek.map((dayOfWeek, index) => (
              <Grid item xs={12 / 7} key={index}>
                <InputLabel
                  sx={{
                    transform: (theme) =>
                      `scale(0.75) translateY(${theme.spacing(2)})`,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  {dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
                </InputLabel>
                <TimePicker
                  value={value[dayOfWeek][0]?.start ?? null}
                  onChange={(value: Date | null) => {
                    onChangeTimePicker(dayOfWeek, "start", value?.getTime());
                  }}
                  renderInput={(params) => (
                    <TextField
                      error
                      name={`${name}.${dayOfWeek}.start`}
                      sx={{ paddingTop: 0 }}
                      {...params}
                    />
                  )}
                />
                <FormHelperText error>
                  {(error?.[dayOfWeek] as FormikErrors<DayTimes>)?.start
                    ? t("InvalidTime")
                    : " "}
                </FormHelperText>
              </Grid>
            ))}
          </FormGridContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item width={WIDTH} alignSelf="flex-end" pb={4.5}>
          <InputLabel>{t("Close")}</InputLabel>
        </Grid>
        <Grid item xs>
          <FormGridContainer>
            {daysOfWeek.map((dayOfWeek, index) => (
              <Grid item xs={12 / 7} key={index}>
                <TimePicker
                  value={value[dayOfWeek][0]?.end ?? null}
                  onChange={(value: Date | null) => {
                    onChangeTimePicker(dayOfWeek, "end", value?.getTime());
                  }}
                  renderInput={(params) => (
                    <TextField
                      name={`${name}.${dayOfWeek}.end`}
                      sx={{ paddingTop: 0 }}
                      {...params}
                    />
                  )}
                />
                <FormHelperText error sx={{ ml: "14px" }}>
                  {error?.[dayOfWeek] ? t("InvalidTime") : ""}
                </FormHelperText>
              </Grid>
            ))}
          </FormGridContainer>
        </Grid>
      </Grid>
    </>
  );
};
