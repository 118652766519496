import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Domain, PeopleOutline, SettingsOutlined } from "@mui/icons-material";
import { AppBar, Container, Grid, Tab, Tabs } from "@mui/material";

import { AppLogo } from "common/components/AppLogo/AppLogo";
import { HeaderButtons } from "common/components/HeaderButtons/HeaderButtons";
import { paths } from "navigation/paths";

const tabs = [paths.ORGANIZATIONS, paths.USERS, paths.TOOLS] as const;
type TabType = typeof tabs[number];

export const AppHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const formatedValue: TabType = useMemo(() => {
    if (pathname === "/") return paths.ORGANIZATIONS;
    return tabs.find((path) =>
      pathname.includes(path.toLowerCase())
    ) as TabType;
  }, [pathname]);

  const onChange = useCallback(
    (_, value: TabType) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <AppBar>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <AppLogo />
          </Grid>
          <Grid item>
            <Tabs
              centered
              value={formatedValue}
              onChange={onChange}
              sx={{ ".MuiTab-root": { minHeight: "68px" } }}
            >
              <Tab
                value={paths.ORGANIZATIONS}
                label={t("Organizations")}
                icon={<Domain />}
                iconPosition="start"
              />
              <Tab
                value={paths.USERS}
                label={t("Users")}
                icon={<PeopleOutline />}
                iconPosition="start"
              />
              <Tab
                value={paths.TOOLS}
                label={t("Tools")}
                icon={<SettingsOutlined />}
                iconPosition="start"
              />
            </Tabs>
          </Grid>
          <Grid item>
            <HeaderButtons />
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
};
