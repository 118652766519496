import { RefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import {
  CountryCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js/min";
import { array, boolean, object, string } from "yup";

import {
  COUNTRY_CODE_DEFAULT,
  DEFAULT_TIME_ZONE,
  DEFAULT_USER_PASSWORD,
} from "constants/constants";
import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { PhoneInput as PhoneInputType } from "types/common";
import { SecurityGroup } from "types/Group";
import {
  OrganizationFlat,
  OrganizationFull,
  Timezone,
} from "types/Organization";
import { PostNewUsers } from "types/User";
import { FormControlLabelCheckbox } from "../FormControlLabel/FormControlLabelCheckbox";
import { FormControlLabelSwitch } from "../FormControlLabel/FormControlLabelSwitch";
import { FormGridContainer } from "../FormGridContainer/FormGridContainer";
import { GroupsMultiSelect } from "../GroupsMultiSelect/GroupsMultiSelect";
import { OrganizationSelect } from "../OrganizationSelect/OrganizationSelect";
import { PhoneInput } from "../PhoneInput/PhoneInput";
import { TextField } from "../TextField/TextField";
import { TimezoneSelect } from "../TimezoneSelect/TimezoneSelect";

export interface AddNewUserValues {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: PhoneInputType;
  email: string;
  sendWelcomeMessage: boolean;
  allowSMS: boolean;
  securityGroups: Array<SecurityGroup>;
  defaultOrganizationId: OrganizationFlat | OrganizationFull | null;
  timezone: Timezone | null;
}

interface AddNewUserFormProps {
  formRef: RefObject<FormikProps<AddNewUserValues>>;
  onSubmit: (data: PostNewUsers) => void;
  group?: SecurityGroup;
}

export const AddNewUserForm = ({
  formRef,
  onSubmit,
  group,
}: AddNewUserFormProps): JSX.Element => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { data } = useGetOrganization(
    { id: selectedOrganizationId as string },
    false
  );

  const initialValues: AddNewUserValues = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      sendWelcomeMessage: true,
      phone: {
        countryCode: COUNTRY_CODE_DEFAULT,
        phoneNumber: "",
      },
      allowSMS: true,
      defaultOrganizationId: data ?? null,
      securityGroups: group ? [group] : [],
      timezone: DEFAULT_TIME_ZONE,
    }),
    [data, group]
  );

  const onFormSubmit = useCallback(
    (values: AddNewUserValues) => {
      onSubmit({
        newUsers: [
          {
            userName: values.email,
            defaultOrganizationId: values.defaultOrganizationId?.id as string,
            firstPassword: DEFAULT_USER_PASSWORD,
            firstName: values.firstName,
            lastName: values.lastName,
            jobTitle: values.jobTitle,
            mobilePhone: {
              number: parsePhoneNumber(
                values.phone.phoneNumber,
                values.phone.countryCode
              ).number,
              allowSMS: values.allowSMS,
            },
            email: values.email,
            sendWelcome: values.sendWelcomeMessage,
            securityGroups: values.securityGroups.map((group) => group.id),
            timeZone: values.timezone?.zoneId ?? "",
          },
        ],
      });
    },
    [onSubmit]
  );

  const addUserSchema = useMemo(
    () =>
      object({
        firstName: string().required(t("Required")),
        lastName: string().required(t("Required")),
        jobTitle: string(),
        phone: object().shape({
          phoneNumber: string()
            .required(t("Required"))
            .when("countryCode", (countryCode: CountryCode) => {
              return string().test(
                "phone-test",
                t("InvalidPhoneNumber"),
                (value = "") => {
                  if (value === "") return false;
                  return isValidPhoneNumber(value, countryCode);
                }
              );
            }),
        }),
        timezone: object().required(t("Required")).nullable(),
        email: string().email(t("PleaseEmail")).required(t("Required")),
        sendWelcomeMessage: boolean().oneOf([true, false]),
        allowSMS: boolean().oneOf([true, false]).required(t("Required")),
        jobTypeRadio: string().oneOf(["Rooftop", "Group"]),
        defaultOrganizationId: object().required(t("Required")).nullable(),
        securityGroups: array().min(1, t("OneGroupRequired")),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addUserSchema}
      innerRef={formRef}
      onSubmit={onFormSubmit}
    >
      {({ values }) => (
        <Form>
          <FormGridContainer>
            <Grid item xs={4}>
              <TextField label={t("FirstName")} fullWidth name="firstName" />
            </Grid>
            <Grid item xs={4}>
              <TextField label={t("LastName")} fullWidth name="lastName" />
            </Grid>
            <Grid item xs={4}>
              <TextField label={t("JobTitle")} fullWidth name="jobTitle" />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={8.5}>
                  <PhoneInput name={`phone`} />
                </Grid>
                <Grid item xs={3.5}>
                  <FormControlLabelSwitch
                    name={`allowSMS`}
                    label={t("AllowSMS")}
                    labelPlacement="top"
                    sx={{
                      ".MuiFormControlLabel-label": {
                        color: (theme) => theme.palette.text.secondary,
                        transform: "scale(0.75)",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField label={t("Email")} fullWidth name="email" />
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <FormControlLabelCheckbox
                label={t("SendWelcomeMessage")}
                name="sendWelcomeMessage"
              />
            </Grid>

            <Grid item xs={4}>
              <TimezoneSelect name="timezone" fullWidth label={t("TimeZone")} />
            </Grid>
            <Grid item xs={4}>
              <OrganizationSelect
                readOnly={Boolean(data)}
                name="defaultOrganizationId"
                label={t("Organizations")}
              />
            </Grid>
            <Grid item xs={4}>
              {values.defaultOrganizationId && (
                <GroupsMultiSelect
                  key={values.defaultOrganizationId.id}
                  initialGroup={initialValues.securityGroups}
                  organizationId={values.defaultOrganizationId.id}
                  name="securityGroups"
                />
              )}
            </Grid>
          </FormGridContainer>
        </Form>
      )}
    </Formik>
  );
};
