import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { number, object } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { PeopleMovementProduct } from "types/Product";

interface PeopleMovementProps {
  product: PeopleMovementProduct;
}

export const ProductPeopleMovement = ({
  product,
}: PeopleMovementProps): JSX.Element => {
  const { t } = useTranslation();

  const onSubmit = useCallback((values) => {
    //TODO: implement update people movement settings when endpoint is avaliable
  }, []);

  const initialValues = useMemo(
    () => ({
      businessMarkupPercentage: product.businessMarkupPercentage,
      productType: "PEOPLE_MOVEMENT",
    }),
    [product]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        businessMarkupPercentage: number().required(t("Required")),
      }),
    [t]
  );

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form>
        <FormGridContainer>
          <Grid item xs={12}>
            <TextField
              label={t("BusinessMarkupPercentage")}
              name="businessMarkupPercentage"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]+(.[0-9]{1})?%?",
              }}
              fullWidth
            />
          </Grid>
        </FormGridContainer>
        <Stack alignItems="flex-end">
          <LoadingButton
            color="primary"
            loading={false}
            variant="contained"
            disableElevation
            disabled // TODO: remove when api endpoint is available to update config
            type="submit"
            data-testid="submit-button"
          >
            {t("Save")}
          </LoadingButton>
        </Stack>
      </Form>
    </Formik>
  );
};
