import mapboxgl, {
  IControl,
  Map,
  MapboxOptions,
  NavigationControl,
} from "mapbox-gl";
import { useEffect, useRef } from "react";
import { useGetUtilitySettings } from "./useGetUtilitySettings";

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const satStyle = "mapbox://styles/mapbox/satellite-v9";
const streetStyle = "mapbox://styles/mapbox/streets-v11";
const satIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge" viewBox="0 0 24 24"><path d="m20.5 3-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM15 19l-6-2.11V5l6 2.11V19z"></path></svg>`;
const mapIcon = `<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge" viewBox="0 0 24 24"><path d="m15.44.59-3.18 3.18c-.78.78-.78 2.05 0 2.83l1.24 1.24-.71.71-1.24-1.25c-.78-.78-2.05-.78-2.83 0L7.3 8.72c-.78.78-.78 2.05 0 2.83l1.24 1.24-.71.71-1.23-1.25c-.78-.78-2.05-.78-2.83 0L.59 15.43c-.78.78-.78 2.05 0 2.83l3.54 3.54c.78.78 2.05.78 2.83 0l3.18-3.18c.78-.78.78-2.05 0-2.83L8.9 14.55l.71-.71 1.24 1.24c.78.78 2.05.78 2.83 0l1.41-1.41c.78-.78.78-2.05 0-2.83L13.84 9.6l.71-.71 1.24 1.24c.78.78 2.05.78 2.83 0l3.18-3.18c.78-.78.78-2.05 0-2.83L18.26.58c-.78-.78-2.04-.78-2.82.01zM6.6 19.32l-1.06 1.06L2 16.85l1.06-1.06 3.54 3.53zm2.12-2.12-1.06 1.06-3.54-3.54 1.06-1.06 3.54 3.54zm9.54-9.54L17.2 8.72l-3.54-3.54 1.06-1.06 3.54 3.54zm2.12-2.12L19.32 6.6l-3.54-3.54L16.85 2l3.53 3.54zM14 21v2c4.97 0 9-4.03 9-9h-2c0 3.87-3.13 7-7 7zm0-4v2c2.76 0 5-2.24 5-5h-2c0 1.66-1.34 3-3 3z"></path></svg>`;

const StyleControl = (): IControl => {
  let sat = false;
  let map: Map | undefined;
  let container: HTMLDivElement;
  const onAdd = (mapbox: Map) => {
    map = mapbox;
    container = document.createElement("div");
    container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
    const button = document.createElement("button");
    button.className = "show-satellite";
    const span = document.createElement("span");
    span.className = "mapboxgl-ctrl-icon";
    span.innerHTML = mapIcon;
    button.appendChild(span);
    button.onclick = () => {
      sat = !sat;
      span.innerHTML = sat ? satIcon : mapIcon;
      map?.setStyle(sat ? satStyle : streetStyle);
    };
    container.appendChild(button);
    return container;
  };

  const onRemove = () => {
    container.parentNode?.removeChild(container);
    map = undefined;
  };
  return { onAdd, onRemove };
};

export const useAppMap = (
  options?: Omit<MapboxOptions, "container" | "style">
) => {
  // const { settings } = useGetUtilitySettings();
  // if (settings) {
  //   mapboxgl.accessToken = settings.mapApiKey;
  // }
  const container = useRef<HTMLElement>();
  const map = useRef<Map>();

  useEffect(() => {
    if (!container.current) return;

    map.current = new Map({
      container: container.current,
      style: streetStyle,
      zoom: 16,
      ...options,
    });

    map.current.addControl(StyleControl());
    map.current.addControl(new NavigationControl({ showCompass: false }));

    return () => {
      map.current?.remove();
    };
  }, [container, options]);

  return {
    map,
    container,
  };
};
