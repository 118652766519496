import { RefObject, useCallback, useMemo } from "react";
import { Form, Formik, FormikProps } from "formik";

import { TextField } from "common/components/TextField/TextField";
import { useGetOrganization } from "hooks/useGetOrganization";
import { useValidationSchema } from "hooks/useValidationSchema";
import { AddGroupData } from "types/Group";

export interface EditOrganizationGroupValues {
  groupName: string;
}

interface EditOrganizationGroupFormProps {
  organizationId: string;
  groupId?: string;
  formRef: RefObject<FormikProps<EditOrganizationGroupValues>>;
  onSubmit: (values: Partial<AddGroupData>) => void;
}

export const EditOrganizationGroupForm = ({
  organizationId,
  groupId,
  onSubmit,
  formRef,
}: EditOrganizationGroupFormProps): JSX.Element => {
  const validationSchema = useValidationSchema("EditOrganizationGroup");
  const { data } = useGetOrganization({
    id: organizationId,
  });
  const initialValues = useMemo(() => {
    const group = data?.securityGroups.find((group) => group.id === groupId);
    return {
      groupName: group?.name ?? "",
    };
  }, [data?.securityGroups, groupId]);
  const onFormSubmit = useCallback(
    (values) => {
      return onSubmit(values);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <TextField name="name" label="Name" fullWidth />
      </Form>
    </Formik>
  );
};
