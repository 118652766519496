import { RefObject, useCallback, useMemo, useState } from "react";
import { Form, Formik, FormikProps } from "formik";
import { object } from "yup";

import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useValidationSchema } from "hooks/useValidationSchema";
import { GreyBox } from "styles/App.styles";
import { AddressContactType } from "types/common";
import { OrganizationFull, OrganizationUpdateParams } from "types/Organization";
import { AddressContactFormFields } from "./AddressContactFormFields";
import { AddressContactValues, formToAPI, getInitialValues } from "./formUtils";

interface AddressContactFormProps {
  type: AddressContactType;
  formRef: RefObject<FormikProps<AddressContactValues>>;
  onSubmit: (values: Partial<OrganizationUpdateParams>) => void;
}

export const AddressContactForm = ({
  type,
  onSubmit,
  formRef,
}: AddressContactFormProps): JSX.Element => {
  const [sameAsBilling, setSameAsBilling] = useState(false);
  const { selectedOrganizationId } = useSelectedOrganization();
  const validationSchema = object().shape({
    [type]: useValidationSchema("AddressContact"),
  });
  const { data } = useGetOrganization({
    id: selectedOrganizationId as string,
  });

  const initialValues: AddressContactValues = useMemo(
    () =>
      ({
        [type]: getInitialValues(data as OrganizationFull, type),
      } as AddressContactValues),
    [data, type]
  );

  const billingValues = useMemo(
    () => getInitialValues(data as OrganizationFull, "Billing"),
    [data]
  );

  const onChangeSameAsBilling = useCallback(
    (_, value) => setSameAsBilling(value),
    []
  );

  const onFormSubmit = useCallback(
    (values: AddressContactValues) => {
      return onSubmit(formToAPI(values, type));
    },
    [onSubmit, type]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(data) => {
        onFormSubmit(data);
      }}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <GreyBox elevation={0}>
          <AddressContactFormFields
            billingValues={billingValues}
            type={type}
            sameAsBilling={sameAsBilling}
            onChangeSameAsBilling={onChangeSameAsBilling}
            needsReset={type === "Location"}
          />
        </GreyBox>
      </Form>
    </Formik>
  );
};
