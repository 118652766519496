export const exclusions = [
  {
    organizationId: "exclusions[0]",
    organizationName: "ABC Autos",
    checked: false,
    exclusionDate: new Date(),
    excludedBy: "Fayling, Jason",
    reason: "Driver smelled like feet",
    children: [],
  },
  {
    organizationId: "exclusions[1]",
    organizationName: "Mascot Autos",
    checked: false,
    exclusionDate: new Date(),
    excludedBy: "Fayling, Jason",
    reason: "Driver smelled like feet",
    children: [
      {
        organizationId: "exclusions[1].children[0]",
        organizationName: "Gomer's of kansas",
        checked: false,
        exclusionDate: new Date(),
        excludedBy: "Fayling, Jason",
        reason: "Driver smelled like feet",
        children: [],
      },
      {
        organizationId: "exclusions[1].children[1]",
        organizationName: "Mascot Motors",
        checked: false,
        exclusionDate: new Date(),
        excludedBy: "Fayling, Jason",
        reason: "Driver smelled like feet",
        children: [
          {
            organizationId: "exclusions[1].children[1].children[0]",
            organizationName: "Anthony Autos",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[1].children[1].children[1]",
            organizationName: "Carmen Cars",
            checked: true,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[1].children[1].children[2]",
            organizationName: "Fayling's fabulous Imports",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[1].children[1].children[3]",
            organizationName: "Kevin's Kars",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[1].children[1].children[4]",
            organizationName: "Liam's Luxury Motors",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[1].children[1].children[5]",
            organizationName: "Thomas Towncars",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
        ],
      },
    ],
  },
  {
    organizationId: "exclusions[2]",
    organizationName: "Southtown Motors",
    checked: false,
    exclusionDate: new Date(),
    excludedBy: "Fayling, Jason",
    reason: "Driver smelled like feet",
    children: [
      {
        organizationId: "exclusions[2].children[0]",
        organizationName: "Southtown Domestic",
        checked: false,
        exclusionDate: new Date(),
        excludedBy: "Fayling, Jason",
        reason: "Driver smelled like feet",
        children: [
          {
            organizationId: "exclusions[2].children[1]",
            organizationName: "Southtown Ford",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
          {
            organizationId: "exclusions[2].children[2]",
            organizationName: "Southtown Chevy",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
        ],
      },
      {
        organizationId: "exclusions[3]",
        organizationName: "Southtown Imports",
        checked: false,
        exclusionDate: new Date(),
        excludedBy: "Fayling, Jason",
        reason: "Driver smelled like feet",
        children: [
          {
            organizationId: "exclusions[3].children[0]",
            organizationName: "Southtown Honda",
            checked: false,
            exclusionDate: new Date(),
            excludedBy: "Fayling, Jason",
            reason: "Driver smelled like feet",
            children: [],
          },
        ],
      },
    ],
  },
  {
    organizationId: "exclusions[4]",
    organizationName: "Zariff Autos",
    checked: false,
    exclusionDate: new Date(),
    excludedBy: "Fayling, Jason",
    reason: "Driver smelled like feet",
    children: [],
  },
];
