import { RefObject, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";

import { DeciderSelect } from "common/components/DeciderSelect/DeciderSelect";
import { FormControlLabelSwitch } from "common/components/FormControlLabel/FormControlLabelSwitch";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { GreyBox } from "styles/App.styles";
import { FeatureFlagData } from "types/FeatureFlag";
import { FormValues } from "../FeatureFlagFields/FeatureFlagFields";
import { useValidationSchema } from "../FeatureFlagFields/useValidationSchema";

interface AddFeatureFlagFormProps {
  formRef: RefObject<FormikProps<FormValues>>;
  collectionName: string;
  onSubmit: (
    values: Omit<FeatureFlagData, "applicationCollectionName">
  ) => void;
}

export const AddFeatureFlagForm = ({
  formRef,
  onSubmit,
  collectionName,
}: AddFeatureFlagFormProps): JSX.Element => {
  const { t } = useTranslation();
  const validationSchema = useValidationSchema();
  const initialValues = useMemo(
    () => ({
      name: "",
      description: "",
      enabled: false,
      deciderClassPath: "",
      userDecider: false,
    }),
    []
  );
  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const { userDecider, ...data } = values;
      return onSubmit({
        ...values,
        deciderClassPath: userDecider ? data.deciderClassPath : null,
      });
    },
    [onSubmit]
  );

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <FormGridContainer>
            <Grid item xs={12}>
              <TextField name="name" fullWidth label={t("Name")} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                name="description"
                fullWidth
                label={t("Description")}
                rows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <GreyBox elevation={0}>
                <FormControlLabelSwitch
                  name="userDecider"
                  label="User Decider"
                  labelPlacement="end"
                  sx={{ mb: 2 }}
                />
                <DeciderSelect
                  collectionName={collectionName}
                  disabled={!values.userDecider}
                  label={t("Decider")}
                  name="deciderClassPath"
                  fullWidth
                />
              </GreyBox>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
              pt={2}
            >
              <FormControlLabelSwitch
                name="enabled"
                label={t("Enabled")}
                labelPlacement="start"
              />
            </Grid>
          </FormGridContainer>
        </Form>
      )}
    </Formik>
  );
};
