import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import {
  AccountMembership,
  AccountMembershipLoading,
} from "./AccountMembership";
import { Actors } from "./Actors";
import { Tags } from "./Tags";

interface EditUserFormTabsProps {
  loading: boolean;
}

const tabsIndex = ["AccountMembership", "Actors", "Tags", "History"] as const;
type TabLabel = typeof tabsIndex[number];

export const EditUserFormTabs = ({
  loading,
}: EditUserFormTabsProps): JSX.Element => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useTabValue<TabLabel>("AccountMembership");

  return (
    <TabContext value={tabValue}>
      <Tabs onChange={setTabValue}>
        <Tab label={t("AccountMembership")} value="AccountMembership" />
        {/*<Tab label={t("Actors")} value="Actors" />*/}
        {/*<Tab label={t("Tags")} value="Tags" />*/}
        {/*<Tab label={t("History")} value="History" disabled />*/}
      </Tabs>
      <TabPanel value="AccountMembership" sx={{ alignItems: "flex-start" }}>
        {loading ? <AccountMembershipLoading /> : <AccountMembership />}
      </TabPanel>
      <TabPanel value="Actors">
        <Actors />
      </TabPanel>
      <TabPanel value="Tags">
        <Tags />
      </TabPanel>
    </TabContext>
  );
};
