import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { format, utcToZonedTime } from "date-fns-tz";

import { OrganizationLocation } from "types/Location";

const Field = ({
  label,
  value,
}: {
  label: string;
  value: JSX.Element | string;
}) => (
  <>
    <Grid item xs={3} textAlign="right">
      <Typography variant="body2">{label}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Typography variant="body2">{value}</Typography>
    </Grid>
  </>
);

interface GeneralProps {
  location: OrganizationLocation;
}

export const General = ({
  location: { tags, address, id, contact, label },
}: GeneralProps): JSX.Element => {
  const { t } = useTranslation();
  const localTime = useMemo(
    () =>
      format(utcToZonedTime(new Date(), address.timezone.zoneId), "hh:mm aa"),
    [address.timezone.zoneId]
  );

  return (
    <Box
      paddingY={2}
      sx={{ overflow: "auto", height: "100%", maxHeight: "100%" }}
    >
      <Grid container spacing={2}>
        <Field label={"Label"} value={label} />
        <Field label={"ID"} value={id} />
        <Field
          label={t("Address")}
          value={
            <>
              {address.street1} {address.street2 ?? ""} <br />
              {address.city}, {address.state.shortName} {address.postalCode}
            </>
          }
        />
        <Field
          label={t("Contact")}
          value={
            <>
              {contact.firstName} {contact.lastName}
              <br />
              {contact.jobTitle && (
                <>
                  {contact.jobTitle}
                  <br />
                </>
              )}
              {contact.mobilePhone?.number && (
                <>
                  {contact.mobilePhone.number}
                  <br />
                </>
              )}
              <a href={`mailto:${contact.workEmail}`}>{contact.workEmail}</a>
            </>
          }
        />
        <Field label={t("CurrentTime")} value={localTime} />
        <Field label={t("TimeZone")} value={address.timezone.zoneId} />
        <Field
          label={t("Tags")}
          value={
            <>
              {tags.map((tag) => (
                <Chip label={tag} key={tag} sx={{ mr: 1 }} />
              ))}
            </>
          }
        />
      </Grid>
    </Box>
  );
};
