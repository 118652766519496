import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseDeleteUserProps {
  params?: {
    hardDelete: boolean;
  };
}

export const useDeleteUsers = ({ params }: UseDeleteUserProps) => {
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const deleteUser = useAuthApi<{ userIds: string[] }, unknown>(
    "put",
    endpoints.USERS,
    {
      params,
    }
  );

  const { isLoading, isSuccess, mutateAsync } = useMutation(deleteUser, {
    onSuccess: () => {
      return queryClient.invalidateQueries([QueryKeys.GET_USERS]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Delete User unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Delete User", "ERROR");
    },
  });

  return {
    isLoading,
    isSuccess,
    onDeleteUsers: mutateAsync,
  };
};
