import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationLocationsResponse } from "types/Api";
import { useAuthApi } from "./useApi";
import { useSelectedOrganization } from "./useSelectedOrganization";

interface UseGetSelectedOrganizationLocationsProps {
  params?: {
    s?: string;
    includeChildren?: boolean;
    startIndex?: number;
    pageSize?: number;
  };
}

export const useGetSelectedOrganizationLocations = ({
  params,
}: UseGetSelectedOrganizationLocationsProps) => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const getLocations = useAuthApi<never, GetOrganizationLocationsResponse>(
    "get",
    endpoints.ORGANIZATION_LOCATIONS(selectedOrganizationId as string),
    { params }
  );

  const { isLoading, data } = useQuery(
    [
      QueryKeys.GET_ORGANIZATION_LOCATIONS,
      selectedOrganizationId,
      { ...params },
    ],
    () => getLocations()
  );

  return {
    isLoading,
    data: data?.data,
  };
};
