import { useCallback } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { useField } from "formik";

type FormControlLabelCheckboxProps = Omit<FormControlLabelProps, "control"> & {
  name: string;
};

export const FormControlLabelCheckbox = (
  props: FormControlLabelCheckboxProps
): JSX.Element => {
  const [field, , { setValue }] = useField(props.name);
  const onChange = useCallback((_, checked) => setValue(checked), [setValue]);

  return (
    <FormControlLabel
      {...field}
      {...props}
      checked={field.value}
      onChange={onChange}
      control={<Checkbox checked={field.value} />}
    />
  );
};
