import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { Background, BackgroundGrid, LoginWidget } from "./Login.styles";
import { LoginForm } from "./LoginForm/LoginForm";
import { ResetPasswordForm } from "./ResetPasswordForm/ResetPasswordForm";

export const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const [showLoginForm, setShowLoginForm] = useState(true);
  const setLoginForm = useCallback(
    () => setShowLoginForm(true),
    [setShowLoginForm]
  );
  const setResetPasswordForm = useCallback(
    () => setShowLoginForm(false),
    [setShowLoginForm]
  );

  return (
    <Background>
      <LoginWidget elevation={6}>
        <Grid container>
          <BackgroundGrid item xs={4} />
          <Grid item xs={8} sx={{ p: 7.5 }}>
            <Typography variant="h4">
              {t(showLoginForm ? "AdminLogin" : "RecoverPassword")}
            </Typography>
            {showLoginForm ? (
              <LoginForm onResetPassword={setResetPasswordForm} />
            ) : (
              <ResetPasswordForm onCancel={setLoginForm} />
            )}
          </Grid>
        </Grid>
      </LoginWidget>
    </Background>
  );
};
