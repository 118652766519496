import { useMemo } from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material/TextField";
import { useField } from "formik";

type TextFieldProps = MuiTextFieldProps & {
  name: string;
  readOnly?: boolean;
};

export const TextField = ({ readOnly = false, ...props }: TextFieldProps) => {
  const [field, { touched, error }] = useField(props.name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  return (
    <MuiTextField
      {...props}
      {...field}
      inputProps={{
        readOnly,
        ...props.inputProps,
      }}
      error={hasError}
      helperText={hasError ? error : " "}
    />
  );
};
