import { ListItemButton, styled } from "@mui/material";

export const ListItemButtonWithDialog = styled(ListItemButton)(() => ({
  display: "flex",
  justifyContent: "space-between",
  ".MuiIconButton-root": {
    opacity: 0,
    pointerEvents: "none",
  },
  ":hover .MuiIconButton-root": {
    opacity: 1,
    pointerEvents: "all",
  },
}));
