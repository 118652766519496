import { useTranslation } from "react-i18next";
import { Grid, MenuItem } from "@mui/material";

import { DatePicker } from "common/components/DatePicker/DatePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { Select } from "common/components/Select/Select";
import { StateSelect } from "common/components/StateSelect/StateSelect";
import { TextField } from "common/components/TextField/TextField";

interface MedicalWasteTransportLicenseFieldsProps {
  namePrefix?: string;
}

export const MedicalWasteTransportLicenseFields = ({
  namePrefix = "",
}: MedicalWasteTransportLicenseFieldsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormGridContainer>
      <Grid item xs={6}>
        <TextField
          name={`${namePrefix}licenseNumber`}
          fullWidth
          label={t("LicenseNumber")}
        />
      </Grid>
      <Grid item xs={6}>
        <StateSelect
          name={`${namePrefix}issueState`}
          fullWidth
          label={t("IssueState")}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker name={`${namePrefix}issueDate`} label={t("IssueDate")} />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          name={`${namePrefix}expirationDate`}
          label={t("ExpirationDate")}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          name={`${namePrefix}classification`}
          fullWidth
          label={t("Classification")}
        >
          <MenuItem value="A">A</MenuItem>
          <MenuItem value="B">B</MenuItem>
          <MenuItem value="C">C</MenuItem>
        </Select>
      </Grid>
    </FormGridContainer>
  );
};
