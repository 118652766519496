import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import FlagIcon from "@mui/icons-material/Flag";
import { Stack } from "@mui/material";

import { BigIconButtonWithNotification } from "components/BigIconButtonWithNotification/BigIconButtonWithNotification";
import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { permissions } from "constants/constants";
import { toolsPaths } from "navigation/paths";

export const ToolsMenu = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onFeatureFlagsClick = useCallback(
    () => navigate(toolsPaths.FEATURE_FLAGS),
    [navigate]
  );

  return (
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      <FeatureGate
        fallback={
          <BigIconButtonWithNotification
            label={t("FeatureFlags")}
            Icon={FlagIcon}
            disabled
          />
        }
        name={permissions.FEATUREFLAG_VIEW}
      >
        <BigIconButtonWithNotification
          label={t("FeatureFlags")}
          onClick={onFeatureFlagsClick}
          Icon={FlagIcon}
        />
      </FeatureGate>
    </Stack>
  );
};
