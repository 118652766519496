import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import { Box, InputAdornment, TextField } from "@mui/material";
import { useField } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { useGetOrganizationUsers } from "hooks/useGetOrganizationUsers";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { useUsersDataGrid } from "hooks/useUsersDataGrid";
import { User } from "types/User";

interface OrganizationUserSelectProps {
  name: string;
  label: string;
}

export const OrganizationUserSelect = ({
  name,
  label,
}: OrganizationUserSelectProps): JSX.Element => {
  const [user, setUser] = useState<User>();
  const { t } = useTranslation();
  const [{ value }, { error, touched }, { setValue, setTouched }] =
    useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const onClick = useCallback(() => {
    setTouched(true);
    openDialog();
  }, [setTouched, openDialog]);

  const onSubmit = useCallback(() => {
    setValue(user);
    closeDialog();
  }, [setValue, closeDialog, user]);

  return (
    <>
      <TextField
        label={label}
        onClick={onClick}
        fullWidth
        value={
          value
            ? `${value.firstName} ${value.lastName} (${value.workEmail})`
            : ""
        }
        error={hasError}
        helperText={hasError ? error : " "}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
      />
      <Dialog
        {...dialogProps}
        title={t("AddUser")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="lg"
      >
        <SelectUserDataGrid setUser={setUser} />
      </Dialog>
    </>
  );
};

interface SelectUserDataGridProps {
  setUser: (user: User) => void;
}

const SelectUserDataGrid = ({
  setUser,
}: SelectUserDataGridProps): JSX.Element => {
  const { page, pageSize, search, UsersDataGrid, usersDataGridProps } =
    useUsersDataGrid();
  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading, usersResponse } = useGetOrganizationUsers({
    organizationId: selectedOrganizationId as string,
    params: {
      s: search,
      startRecord: page * pageSize,
      pageSize,
      outputOrganizations: false,
      outputGroups: false,
      outputDefaultOrg: false,
    },
  });

  return (
    <Box sx={{ height: 500, flex: 1, display: "flex" }}>
      <UsersDataGrid
        isLoading={isLoading}
        data={usersResponse}
        hideEdit
        {...usersDataGridProps}
        disableSelectionOnClick={false}
        setUser={setUser}
      />
    </Box>
  );
};
