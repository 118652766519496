import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertTitle, Box, Button, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { object, string } from "yup";

import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";
import { useLogin } from "hooks/useLogin";
import { getFromLocalStorage } from "utils/localStorage";
import PasswordField from "../../../common/components/PasswordField/PasswordField";

export const RESET_PARAM = "reset";
export const LOGOUT_PARAM = "logout";
export const AUTH_ERROR_PARAM = "autherror";

interface LoginFormProps {
  onResetPassword: () => void;
}

export const LoginForm = ({ onResetPassword }: LoginFormProps): JSX.Element => {
  const { isError, isLoading, onLogin } = useLogin();
  const [searchParams] = useSearchParams();
  const isResetSuccess = useMemo(
    () => searchParams.get(RESET_PARAM),
    [searchParams]
  );
  const isLogoutSuccess = useMemo(
    () => searchParams.get(LOGOUT_PARAM),
    [searchParams]
  );
  const isAuthError = useMemo(
    () => searchParams.get(AUTH_ERROR_PARAM),
    [searchParams]
  );
  const { t } = useTranslation();
  const validationSchema = object().shape({
    username: string()
      .min(2, t("MinUsernameValidationError"))
      .required(t("Required")),
    password: string()
      .min(8, t("MinPasswordValidationError"))
      .required(t("Required")),
  });

  const initialValues = useMemo(
    () => ({
      username: (getFromLocalStorage("username") as string) || "",
      password: "",
      application: "SKAI_ADMIN",
    }),
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onLogin}
      validationSchema={validationSchema}
    >
      <Form>
        {!isError && !isAuthError && !isResetSuccess && !isLogoutSuccess && (
          <Box sx={{ pt: 7.5 }} />
        )}
        {isError && (
          <Alert variant="filled" severity="error" sx={{ marginY: 2 }}>
            <AlertTitle>{t("AuthorizationError")}</AlertTitle>
            {t("ApiErrorMessage")}
          </Alert>
        )}
        {isAuthError && (
          <Alert variant="filled" severity="error" sx={{ marginY: 2 }}>
            <AlertTitle>{t("AuthErrorTitle")}</AlertTitle>
            {t("AuthErrorCopy")}
          </Alert>
        )}
        {isResetSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginY: 2 }}>
            <AlertTitle>{t("ResetSuccessTitle")}</AlertTitle>
            {t("ResetSuccessCopy")}
          </Alert>
        )}
        {isLogoutSuccess && (
          <Alert variant="filled" severity="success" sx={{ marginY: 2 }}>
            <AlertTitle>{t("LogoutSuccessTitle")}</AlertTitle>
            {t("LogoutSuccessCopy")}
          </Alert>
        )}
        <FormGridContainer>
          <Grid item xs={12}>
            <TextField
              label={t("Username")}
              fullWidth
              id="username"
              name="username"
            />
          </Grid>
          <Grid item xs={12}>
            <PasswordField
              label={t("Password")}
              fullWidth
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
            />
          </Grid>
        </FormGridContainer>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pb: 7.5 }}>
          <Button
            size="medium"
            onClick={onResetPassword}
            data-testid="reset-password-button"
          >
            {t("ForgotYourPassword")}
          </Button>
        </Box>
        <LoadingButton
          size="medium"
          color="primary"
          variant="contained"
          fullWidth
          loading={isLoading}
          type="submit"
          data-testid="submit-button"
        >
          {t("SignIn")}
        </LoadingButton>
      </Form>
    </Formik>
  );
};
