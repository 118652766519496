import { Ref, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { SetUserPasswordRequestData } from "types/Api";
import PasswordField from "../../../PasswordField/PasswordField";

export interface FormValues {
  newPass: string;
}

interface SetPasswordFormProps {
  formRef: Ref<FormikProps<FormValues>>;
  onSubmit: (data: SetUserPasswordRequestData) => void;
}

export const SetPasswordForm = ({
  formRef,
  onSubmit,
}: SetPasswordFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      newPass: "",
    }),
    []
  );

  const onFormSubmit = useCallback(
    ({ newPass }) => onSubmit({ newPassword: newPass }),
    [onSubmit]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        newPass: string().required(t("Required")),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      <Form>
        <PasswordField label={t("Password")} name="newPass" />
      </Form>
    </Formik>
  );
};
