import { SyntheticEvent, useCallback, useState } from "react";
import { TabList, TabListProps } from "@mui/lab";
import { Box } from "@mui/material";

export const useTabValue = <T,>(
  initialValue: T
): [T, (_: SyntheticEvent, value: T) => void] => {
  const [value, setValue] = useState(initialValue);

  const setTabValue = useCallback(
    (_: SyntheticEvent, value: T) => setValue(value),
    [setValue]
  );

  return [value, setTabValue];
};

export const Tabs = ({ children, ...props }: TabListProps): JSX.Element => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <TabList
        {...props}
        sx={{
          ".MuiButtonBase-root": { minHeight: "48px", maxHeight: "48px" },
          ...props.sx,
        }}
      >
        {children}
      </TabList>
    </Box>
  );
};
