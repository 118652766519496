import { AddressContactType } from "types/common";
import {
  Locality,
  OrganizationFull,
  OrganizationUpdateParams,
} from "types/Organization";
import { parsePhoneString, phoneNumberToString } from "utils/apiPhone";
import { Phone } from "../../../utils/apiPhone";

export interface Fields {
  addressId: string;
  timezone: any;
  address: string;
  address2: string;
  city: string;
  country: Locality | null;
  state: Locality | null;
  zip: string;
  firstname: string;
  lastname: string;
  jobTitle: string;
  email: string;
  allowSMS: boolean;
  phone: Phone | null;
}

export type AddressContactValues = { Billing: Fields } | { Location: Fields };

const isBilling = (type: AddressContactType) => type === "Billing";

export const getInitialValues = (
  {
    billingAddress,
    mailingAddress,
    billingContact,
    mailingContact,
  }: OrganizationFull,
  type: AddressContactType
): {
  addressId: string;
  timezone: any;
  zip: string;
  country: Locality;
  allowSMS: boolean;
  firstname: string;
  address: string;
  address2: string;
  city: string;
  phone: Phone;
  email: string;
  state: Locality;
  jobTitle: string;
  lastname: string;
} => {
  const address = isBilling(type) ? billingAddress : mailingAddress;
  const contact = isBilling(type) ? billingContact : mailingContact;

  return {
    addressId: address.id,
    timezone: address.timezone,
    address: address.street1 ?? "",
    address2: address.street2 ?? "",
    city: address.city ?? "",
    country: address.country ?? "",
    state: address.state ?? null,
    zip: address.postalCode ?? "",
    firstname: contact.firstName ?? "",
    lastname: contact.lastName ?? "",
    jobTitle: contact.jobTitle ?? "",
    phone: parsePhoneString(contact.mobilePhone),
    allowSMS: contact.mobilePhone?.allowSMS ?? false,
    email: contact.workEmail ?? "",
  };
};

export const formToAPI = (
  formValues: AddressContactValues,
  type: AddressContactType
):
  | Partial<Pick<OrganizationUpdateParams, "billingAddress" | "billingContact">>
  | Partial<
      Pick<OrganizationUpdateParams, "mailingAddress" | "mailingContact">
    > => {
  const values = formValues[type];

  const address = {
    street1: values.address,
    street2: values.address2,
    city: values.city,
    state: {
      id: values.state.id,
      name: values.state.name,
      shortName: values.state.shortName,
    },
    postalCode: values.zip,
    country: {
      id: values.country.id,
      name: values.country.name,
      shortName: values.country.shortName,
    },
    timezone: {
      label: values?.timezone?.timezone,
    },
  };

  const contact = {
    firstName: values.firstname,
    lastName: values.lastname,
    jobTitle: values.jobTitle,
    mobilePhone: {
      allowSMS: values.allowSMS,
      number: phoneNumberToString(values.phone),
    },
    email: values.email,
    workEmail: values.email,
  };

  return isBilling(type)
    ? {
        billingAddress: address,
        billingContact: contact,
      }
    : {
        mailingAddress: address,
        mailingContact: contact,
      };
};
