import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import {
  GetOrganizationsFlatRequestParams,
  GetOrganizationsFlatResponse,
} from "types/Api";
import { useAuthApi } from "./useApi";

export const organizationDetailRequest: GetOrganizationsFlatRequestParams = {
  params: {
    outputChildren: false,
    outputGroups: false,
    outputRoles: false,
    outputPermissions: false,
    pageSize: 999999,
  },
};

export const useGetOrganizationsFlat = ({
  params,
}: GetOrganizationsFlatRequestParams) => {
  const getOrganizationsFlat = useAuthApi<never, GetOrganizationsFlatResponse>(
    "get",
    endpoints.ORGANIZATIONS_FLAT,
    { params }
  );
  const { isLoading, data } = useQuery(
    [QueryKeys.GET_ORGANIZATIONS_FLAT, { ...params }],
    () => getOrganizationsFlat()
  );

  return {
    isLoading,
    organizationsFlat: data?.data?.matches ?? [],
  };
};
