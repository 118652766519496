import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { PostOrganizationLocationData } from "types/Location";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";
interface UseUpdateSelectedOrganizationLocationProps {
  locationId: string;
}

export const useUpdateSelectedOrganizationLocation = ({
  locationId,
}: UseUpdateSelectedOrganizationLocationProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const { selectedOrganizationId } = useSelectedOrganization();
  const updateLocation = useAuthApi<
    Partial<PostOrganizationLocationData>,
    unknown
  >(
    "patch",
    endpoints.ORGANIZATION_LOCATION(
      selectedOrganizationId as string,
      locationId
    )
  );
  const { isLoading, isSuccess, mutate } = useMutation(updateLocation, {
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Update Organization Location unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Update Organization Location", "ERROR");
    },
    onSuccess: () => {
      const message = t("LocationUpdated");
      setSnackbarData({ severity: "success", message });
      auditFormEvent(message, "Update Organization Location", "FORM_SENT");
      queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION_LOCATIONS,
        selectedOrganizationId,
      ]);
    },
  });

  const onUpdateLocation = (data: Partial<PostOrganizationLocationData>) =>
    mutate(data);

  return {
    isLoading,
    isSuccess,
    onUpdateLocation,
  };
};
