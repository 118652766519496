import { useMemo } from "react";
import { Box, Link, Typography } from "@mui/material";

interface OverviewFieldProps {
  title: string;
  body?: string | null;
  href?: string;
}
export const OverviewField = ({
  title,
  body,
  href,
}: OverviewFieldProps): JSX.Element => {
  const bodyOrDash = useMemo(() => {
    // TODO: Maybe change this
    if (body === undefined || body === null) return "-";
    return body;
  }, [body]);
  return (
    <Box pb={1}>
      <Typography variant="caption">{title}</Typography>
      {href ? (
        <Link href={href}>
          <Typography variant="body2">{bodyOrDash}</Typography>
        </Link>
      ) : (
        <Typography variant="body2">{bodyOrDash}</Typography>
      )}
    </Box>
  );
};
