import { RefObject, useCallback, useMemo } from "react";
import { Formik, FormikProps } from "formik";

import { useGetSelectedOrganizationLocation } from "hooks/useGetSelectedOrganizationLocation";
import { PostOrganizationLocationData } from "types/Location";
import {
  ApiDataToInitialValues,
  FormValues,
  formValuesToApi,
  useValidation,
} from "../../Forms/formUtils";
import { LocationFields } from "../../Forms/LocationFields";

interface EditLocationFormProps {
  locationId: string;
  formRef: RefObject<FormikProps<FormValues>>;
  onSubmit: (value: Omit<PostOrganizationLocationData, "orgId">) => void;
}

export const EditLocationForm = ({
  locationId,
  formRef,
  onSubmit,
}: EditLocationFormProps): JSX.Element => {
  const { location } = useGetSelectedOrganizationLocation({ locationId });
  const initialValues: FormValues | null = useMemo(
    () => ApiDataToInitialValues(location),
    [location]
  );
  const validationSchema = useValidation();
  const onFormsubmit = useCallback(
    (values: FormValues) => {
      return onSubmit(formValuesToApi(values));
    },
    [onSubmit]
  );

  return initialValues ? (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={onFormsubmit}
      validationSchema={validationSchema}
    >
      <LocationFields />
    </Formik>
  ) : (
    <></>
  );
};
