import ConstructionIcon from "@mui/icons-material/ConstructionOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";

interface SettingIconProps {
  type: string;
}

export const SettingIcon = ({ type }: SettingIconProps): JSX.Element => {
  if (type === "DRIVER_POOL") return <PeopleOutlineIcon fontSize="small" />;
  if (type === "COMMISSION") return <LocalAtmIcon fontSize="small" />;
  if (type === "FEE") return <PercentOutlinedIcon fontSize="small" />;
  if (type === "INTEGRATION") return <SettingsOutlined fontSize="small" />;
  if (type === "BASIC") return <SettingsOutlined fontSize="small" />;
  if (type === "LOCATIONS") return <SettingsOutlined fontSize="small" />;
  if (type === "MANAGED_SERVICES") return <MapOutlinedIcon fontSize="small" />;
  if (type === "BUILD_ME") return <ConstructionIcon fontSize="small" />;
  if (type === "RATE_STRUCTURE")
    return <TimelineOutlinedIcon fontSize="small" />;

  return <></>;
};
