import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationProducts, ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseOrganizationAddProductProps {
  organizationId: string;
}

export const useOrganizationAddProduct = ({
  organizationId,
}: UseOrganizationAddProductProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const queryClient = useQueryClient();
  const addProduct = useAuthApi<any, GetOrganizationProducts>(
    "post",
    endpoints.ORGANIZATION_PRODUCT_ASSIGN(organizationId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(addProduct, {
    mutationKey: "OrganizationAddProduct",
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Add Organization Product unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add Organization Product", "ERROR");
    },
    onSuccess: ({ data }) => {
      queryClient.setQueryData(
        [QueryKeys.GET_ORGANIZATION_PRODUCTS, organizationId],
        { data }
      );
    },
  });

  const onAddProduct = (data: any) => mutate(data);

  return {
    isLoading,
    isSuccess,
    onAddProduct,
  };
};
