import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)(
  ({ theme }) => `
  padding-top: ${theme.spacing(9)};
  background: ${theme.palette.background.default};
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
);
