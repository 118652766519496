import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { EditUserData, GetUserRequestParams, ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

type UseUpdateUserProps = GetUserRequestParams & {
  userId: string;
};

export const useUpdateUser = ({ userId, params }: UseUpdateUserProps) => {
  const { data: loggedUserId } = useQuery<string>([QueryKeys.LOGGED_ID], {
    refetchOnMount: false,
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const updateUser = useAuthApi<EditUserData, unknown>(
    "patch",
    endpoints.USER(userId)
    // { params }
  );

  const { isLoading, isSuccess, mutate } = useMutation(updateUser, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData([QueryKeys.USER, userId, { ...params }], data);
      queryClient.invalidateQueries([QueryKeys.GET_USERS]);
      if (userId === loggedUserId)
        queryClient.invalidateQueries([QueryKeys.LOGGED_USER]);
      auditFormEvent("Settings Updated", "Update User", "FORM_SENT");
      setSnackbarData({
        severity: "success",
        message: t("UserUpdated"),
      });
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ?? "Update User unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Update User", "ERROR");
    },
  });

  const onUpdateUser = (data: EditUserData) => mutate(data);

  return {
    isLoading,
    isSuccess,
    onUpdateUser,
  };
};
