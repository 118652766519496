import { useMutation } from "react-query";

import { endpoints } from "../../../../../constants/constants";
import { useAuthApi } from "../../../../../hooks/useApi";

interface Props {
  userId: string;
  applicationName: string;
}

interface ImpersonationResponse {
  impersonationPassword: string;
}

const useImpersonateUser = ({ userId, applicationName }: Props) => {
  const generateUserCredentials = useAuthApi<unknown, ImpersonationResponse>(
    "post",
    `${endpoints.USER_IMPERSONATE(userId, applicationName)}`
  );

  const { data, isLoading, mutate } = useMutation(() =>
    generateUserCredentials()
  );

  return {
    newPassword: data?.data.impersonationPassword,
    data,
    isLoading,
    createNewUserCredentials: mutate,
  };
};

export default useImpersonateUser;
