import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { usePostOrganizationGroup } from "hooks/usePostOrganizationGroup";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { AddGroupForm, AddGroupValues } from "./AddGroupForm/AddGroupForm";

interface OrganizationAddGroupProps {
  disabled: boolean;
}

export const OrganizationAddGroup = ({
  disabled,
}: OrganizationAddGroupProps): JSX.Element => {
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const formRef = useRef<FormikProps<AddGroupValues>>(null);
  const { isLoading, addGroup, isSuccess } = usePostOrganizationGroup({
    organizationId: selectedOrganizationId as string,
  });

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };
  return (
    <>
      <AddButton variant="contained" onClick={openDialog} disabled={disabled}>
        {t("AddGroup")}
      </AddButton>
      <Dialog
        title={t("NewGroup")}
        loading={isLoading}
        actionText={t("Create")}
        onSubmit={onSubmit}
        {...dialogProps}
      >
        <AddGroupForm formRef={formRef} onSubmit={addGroup} />
      </Dialog>
    </>
  );
};
