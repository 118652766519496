export const userSkills = [
  {
    categoryName: "Food Delivery Skills",
    categoryValues: [
      {
        name: "can transport alcohol",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 4,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can transport cold items",
        checked: false,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 4,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Monterio,Anthony",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can transport hot items",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 4,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
    ],
    children: [],
  },
  {
    categoryName: "Language Skills",
    categoryValues: [
      {
        name: "can speak english",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 4,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can speak spanish",
        checked: false,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 0,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
    ],
    children: [],
  },
  {
    categoryName: "Vehicle Delivery Skills",
    categoryValues: [
      {
        name: "can deliver luxury vehicles",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 4,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can do overnight trips",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 0,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can drive manual transmition",
        checked: false,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 0,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
      {
        name: "can drive trailers",
        checked: true,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
        rate: 0,
        ratingHistory: [
          {
            id: 1,
            date: new Date(),
            workOrder: 345323,
            rating: 4,
            reviewer: "Zarif,Haque",
            comments: "some comments",
          },
        ],
      },
    ],
    children: [],
  },
  {
    categoryName: "Vehicle Knowledge Skills",
    categoryValues: [],
    children: [
      {
        categoryName: "Walkthrough tutorials",
        categoryValues: [
          {
            categoryName: "Audi",
            categoryValues: [
              {
                name: "2017-2022",
                checked: true,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 4,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 4,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
              {
                name: "2010-2016",
                checked: true,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 0,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 4,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
              {
                name: "2009 and older",
                checked: false,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 0,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 4,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
            ],
          },
          {
            categoryName: "Bulk",
            categoryValues: [
              {
                name: "2017-2022",
                checked: true,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 4,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 4,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
              {
                name: "2010-2016",
                checked: false,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 0,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 4,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
              {
                name: "2009 and older",
                checked: false,
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque vitae tincidunt eros. Curabitur vel lacus ut ligula auctor luctus et id justo. Nullam suscipit molestie enim dictum aliquet. Integer laoreet quam at mi accumsan dapibus. Vestibulum volutpat mollis auctor. Suspendisse accumsan gravida nulla a mattis. Maecenas maximus auctor odio at cursus.",
                rate: 0,
                ratingHistory: [
                  {
                    id: 1,
                    date: new Date(),
                    workOrder: 345323,
                    rating: 0,
                    reviewer: "Zarif,Haque",
                    comments: "some comments",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
