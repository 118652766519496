import { useMutation } from "react-query";

import { endpoints } from "constants/constants";
import { AuditData, EventName, EventType, PageName } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAuth } from "./useAuth";

const getData = (
  name: EventName,
  message: string,
  type: EventType,
  pageName: PageName,
  eventLevel?: string,
  sessionId?: string
): AuditData => ({
  EventLevel: eventLevel ?? "INFO",
  EventName: name,
  Message: message,
  EventStatus: type === "ERROR" ? "FAIL" : "SUCCESS",
  EventType: type,
  "x-section": pageName,
  "x-sessionId": sessionId ?? "",
  //
  EventCategory: "UI",
  AppName: "skai-web-admin-react",
  EventSource: "web-ui",
  "x-url": window?.location?.href ?? "",
  "x-userAgent": window?.navigator?.userAgent ?? "",
  "x-screenDimensionsInner": `[dimensions] innerHeight: ${window?.innerHeight}, innerWidth: ${window?.innerWidth}`,
  "x-screenDimensionsOuter": `[dimensions] outerHeight: ${window?.outerHeight}, outerWidth: ${window?.outerWidth}`,
  "x-locale": window?.navigator?.language ?? "",
  "x-appVersion": process.env.REACT_APP_VERSION
    ? `${process.env.REACT_APP_VERSION}`
    : "",
});

export const usePostAudit = () => {
  const { getSessionId } = useAuth();
  const postAudit = useAuthApi<AuditData, unknown>("post", endpoints.AUDIT);

  const { mutate } = useMutation(postAudit, {
    mutationKey: "postAudit",
    onError: (_, variables) => {
      console.warn(
        `failed to send audit event ${variables?.["x-section"]} ErrorEvent: ${variables?.Message}`
      );
    },
  });

  const postError = (message: string, pageName: PageName, type?: EventType) =>
    mutate(
      getData(
        "ErrorEvent",
        `${pageName} ErrorEvent: ${message}`,
        type ?? "ERROR",
        pageName,
        type ?? "ERROR",
        getSessionId()
      )
    );

  const postForm = (message: string, pageName: PageName, type?: EventType) =>
    mutate(
      getData(
        "FormEvent",
        `${pageName} FormEvent: ${message}`,
        type ?? "FORM_SENT",
        pageName,
        type ?? "FORM_SENT",
        getSessionId()
      )
    );

  return {
    auditErrorEvent: postError,
    auditFormEvent: postForm,
  };
};
