import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { Locality } from "types/Organization";
import { useAuthApi } from "./useApi";

export const useGetCountries = () => {
  const getCountries = useAuthApi<null, Array<Locality>>(
    "get",
    endpoints.UTILITY_COUNTRIES
  );

  const { data, isLoading, isError } = useQuery([QueryKeys.GET_COUNTRIES], () =>
    getCountries()
  );

  return {
    countries: data?.data ?? [],
    isLoading,
    isError,
  };
};
