export const paths = {
  RESET_PASSWORD: "/reset-password",
  LOGIN: "/login",
  ACCOUNT: "/account",
  DASHBOARD: "/",
  ORGANIZATIONS: "organizations",
  TOOLS: "tools",
  USERS: "users",
};

export const organizationsPaths = {
  OVERVIEW: "overview",
  USERS: "users",
  GROUPS: "groups",
  PRODUCTS: "products",
  LOCATIONS: "locations",
  REPORTS: "reports",
  HISTORY: "history",
  API_TOKENS: "api-tokens",
  MISCELLANEOUS: "miscellaneous",
};

export const toolsPaths = {
  FEATURE_FLAGS: "feature-flags",
  TURK_PIPELINES: "turk-pipelines",
};

export const turkPipelinesPaths = {
  RECEIPTS_REVIEW: "receipts-review",
  ACTOR_REVIEW: "actor-review",
  IMAGE_QUALITY: "image-quality",
  VEHICLE_ANGLE: "vehicle-angle",
  BEFORE_AFTER: "before-after",
};

export const devPaths = {
  featureFlags: "/feature-flags",
};
