import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";

import { QueryKeys } from "constants/constants";
import { maybeStartMSW } from "mocks/develop/worker";
import { Organization } from "types/Organization";
import { getFromLocalStorage } from "utils/localStorage";
import { App } from "./App/App";
import reportWebVitals from "./reportWebVitals";

import "mapbox-gl/dist/mapbox-gl.css";
import "./i18n";

maybeStartMSW();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const token = getFromLocalStorage("authBearerToken");

queryClient.setQueryData(
  [QueryKeys.TOKENS],
  token
    ? {
        token,
        refreshToken: getFromLocalStorage("authRefreshToken"),
        expirationDate: getFromLocalStorage("authBearerTokenExpiration"),
        userId: getFromLocalStorage("userId"),
        sessionId: getFromLocalStorage("sessionId"),
      }
    : null
);

const currentOrganization = getFromLocalStorage(
  "currentOrganization"
) as Organization;
queryClient.setQueryData(
  [QueryKeys.SELECTED_ORGANIZATION],
  currentOrganization?.id || null
);
queryClient.setQueryData([QueryKeys.SNACKBAR], null);
queryClient.setQueryData(
  [QueryKeys.LOGGED_ID],
  getFromLocalStorage("userId") ?? null
);

const container = window.document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router>
        <App />
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
