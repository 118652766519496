import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetStatesResponse } from "../types/Api";
import { useAuthApi } from "./useApi";

export const useGetStates = (countryCode = "US") => {
  const getStates = useAuthApi<never, GetStatesResponse>(
    "get",
    `${endpoints.UTILITY_STATES}/${countryCode}`
  );

  const { data, isLoading, isError } = useQuery(
    [QueryKeys.GET_STATES, countryCode],
    () => getStates()
  );

  return {
    states: data?.data?.regions ?? [],
    isLoading,
    isError,
  };
};
