import { useMutation } from "react-query";

import { endpoints } from "../constants/constants";
import { useAuthApi } from "./useApi";

interface RequestData {
  userId: string;
}

const useForceLogout = ({ userId }: RequestData) => {
  const setRequiredPassChange = useAuthApi<RequestData, unknown>(
    "post",
      `${endpoints.FORCE_LOGOUT(userId)}`
  );

  const { isLoading, mutateAsync } = useMutation(setRequiredPassChange);

  const executeRequest = async (userId: RequestData) => {
    return await mutateAsync(userId);
  };

  return {
    doForceLogout: executeRequest,
    isForceLogoutLoading: isLoading,
  };
};

export default useForceLogout;