const getAge = (birthDate) => {
  const now = new Date();
  const diff = now.getTime() - birthDate.getTime();
  return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
};

export const contact = {
  nickName: "",
  dateOfBirth: new Date(1977, 1, 1),
  age: getAge(new Date(1977, 1, 1)),
  ssn: "xxx-xx-5555",
  gender: "Male",
  mailingAddress: "123 Main Street Atlanta, GA, 30265 United States of America",
  metroArea: "Atlanta",
};
