import { SyntheticEvent, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router";
import LinkIcon from "@mui/icons-material/Link";
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";

import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { organizationsPaths, paths } from "navigation/paths";
import { EditOrganization } from "../OrganizationOverview/EditOrganization/EditOrganization";
import { StatusChip } from "../StatusChip/StatusChip";

const tabs = [
  organizationsPaths.OVERVIEW,
  organizationsPaths.USERS,
  organizationsPaths.GROUPS,
  organizationsPaths.PRODUCTS,
  organizationsPaths.LOCATIONS,
  organizationsPaths.REPORTS,
  organizationsPaths.HISTORY,
  organizationsPaths.API_TOKENS,
  organizationsPaths.MISCELLANEOUS,
] as const;
type TabPath = typeof tabs[number];

export const OrganizationPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const formatedValue: TabPath = useMemo(() => {
    if (["/", `/${paths.ORGANIZATIONS}`].includes(pathname))
      return organizationsPaths.OVERVIEW;
    return tabs.find((path) => pathname.includes(path)) as TabPath;
  }, [pathname]);
  const { selectedOrganizationId } = useSelectedOrganization();
  const { data, isLoading } = useGetOrganization({
    id: selectedOrganizationId as string,
  });
  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(window.location.href);
  }, []);
  const onChange = useCallback(
    (_: SyntheticEvent, value: TabPath) => {
      navigate(`/${paths.ORGANIZATIONS}/${selectedOrganizationId}/${value}`);
    },
    [navigate, selectedOrganizationId]
  );

  const uppercasedName = useMemo(() => data?.name.toUpperCase(), [data]);

  return (
    <Stack direction="column" sx={{ flex: 1, maxWidth: "100%", pt: 1 }}>
      <Stack sx={{ pt: 0 }} direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {data ? (
            <Typography variant="body2">
              {uppercasedName}: {data.id}
            </Typography>
          ) : (
            <Skeleton variant="text" width={300} />
          )}
          <EditOrganization disabled={isLoading} />
          {data && (
            <Tooltip title={t("CopyToClipboard")} placement="top-end">
              <IconButton onClick={copyToClipboard} sx={{ marginTop: -2 }}>
                <LinkIcon fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Box alignSelf="center">
          {data ? (
            <StatusChip status={data.status} />
          ) : (
            <Skeleton variant="text" width={100} />
          )}
        </Box>
      </Stack>
      <Paper
        elevation={0}
        sx={{ flex: 1, mt: 1, maxHeight: "100%", overflow: "hidden" }}
      >
        <Stack
          sx={{
            overflow: "hidden",
            maxHeight: "100%",
            flex: 1,
            height: "100%",
          }}
        >
          <Stack
            sx={{ borderBottom: 1, borderColor: "divider", pr: 2 }}
            direction="row"
          >
            <Tabs
              value={formatedValue}
              onChange={onChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={t("Overview")} value={organizationsPaths.OVERVIEW} />
              <Tab label={t("Users")} value={organizationsPaths.USERS} />
              <Tab label={t("Groups")} value={organizationsPaths.GROUPS} />
              <Tab
                label={t("APITokens")}
                value={organizationsPaths.API_TOKENS}
              />
            </Tabs>
          </Stack>
          <Box
            sx={{
              flex: 1,
              maxHeight: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Outlet />
          </Box>
        </Stack>
      </Paper>
    </Stack>
  );
};
