import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateOrganizationGroup } from "hooks/useUpdateOrganizationGroup";
import {
  EditOrganizationGroupForm,
  EditOrganizationGroupValues,
} from "./EditOrganizationGroupForm/EditOrganizationGroupForm";

interface EditOrganizationGroupProps {
  disabled: boolean;
  organizationId: string;
  groupId?: string;
}

export const EditOrganizationGroup = ({
  disabled,
  organizationId,
  groupId = "",
}: EditOrganizationGroupProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, updateGroup, isSuccess } = useUpdateOrganizationGroup({
    organizationId,
    groupId,
  });
  const formRef = useRef<FormikProps<EditOrganizationGroupValues>>(null);
  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton disabled={disabled} onClick={openDialog} />
      <Dialog
        title={t("EditGroup")}
        {...dialogProps}
        actionText="Save"
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        maxWidth="sm"
      >
        <EditOrganizationGroupForm
          organizationId={organizationId}
          groupId={groupId}
          onSubmit={updateGroup}
          formRef={formRef}
        />
      </Dialog>
    </>
  );
};
