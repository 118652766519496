import { RefObject, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { array, object } from "yup";

import { GroupsMultiSelect } from "common/components/GroupsMultiSelect/GroupsMultiSelect";
import { UserListField } from "common/components/UserListField/UserListField";
import { AddNewUser } from "common/components/UsersDataGrid/AddNewUser";
import {
  AddUsersToOrganizationData,
  SecurityGroupUser,
} from "hooks/useAddUsersToOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { SecurityGroup } from "types/Group";
import { useGetOrganizationUsers } from "../../../hooks/useGetOrganizationUsers";

export interface AddOrganizationUserFormValues {
  groups: Array<SecurityGroup>;
  userIds: Array<string>;
}
interface AddOrganizationUserFormProps {
  formRef: RefObject<FormikProps<AddOrganizationUserFormValues>>;
  onSubmit: (data: AddUsersToOrganizationData) => void;
  group?: SecurityGroup;
}

export function AddOrganizationUserForm({
  formRef,
  onSubmit,
  group,
}: AddOrganizationUserFormProps): JSX.Element {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();

  const initialValues: AddOrganizationUserFormValues = useMemo(
    () => ({
      groups: group ? [group] : [],
      userIds: [],
    }),
    [group]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        groups: array().min(1, t("OneGroupRequired")),
        userIds: array().min(1, t("OneUserRequired")),
      }),
    [t]
  );

  const [orgUsersIds, setOrgUsersIds] = useState<string[]>([]);

  const onFormSubmit = useCallback(
    ({ groups, userIds }: AddOrganizationUserFormValues) => {
      const securityGroupUsers: SecurityGroupUser[] = [];
      userIds.forEach((userId) => {
        if (!orgUsersIds?.includes(userId)) {
          groups.forEach((group) => {
            securityGroupUsers.push({
              userId: userId,
              securityGroupId: group.id,
            });
          });
        }
      });
      return onSubmit({
        securityGroupUsers,
      });
    },
    [onSubmit, orgUsersIds]
  );

  const { usersResponse } = useGetOrganizationUsers({
    organizationId: selectedOrganizationId as string,
    params: {
      startRecord: 0,
      pageSize: 99999,
      outputOrganizations: false,
      outputGroups: false,
      outputDefaultOrg: false,
    },
  });

  useMemo(() => {
    if (usersResponse && usersResponse.matches) {
      const usersIds = usersResponse.matches.map((user) => {
        return user.id;
      });
      setOrgUsersIds(usersIds);
    }
  }, [usersResponse]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={onFormSubmit}
    >
      <Form>
        <Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <GroupsMultiSelect
              organizationId={selectedOrganizationId as string}
              name="groups"
              initialGroup={initialValues?.groups || []}
            />
            <Box mt={1} ml={2}>
              <AddNewUser buttonVariant="outlined" group={group} />
            </Box>
          </Stack>
          <UserListField
            checkboxSelection
            name="userIds"
            orgUsersIds={orgUsersIds}
          />
        </Stack>
      </Form>
    </Formik>
  );
}
