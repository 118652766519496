import {
  COUNTRY_CODE_DEFAULT,
  CURRENCY_DEFAULT,
  LOCALE_DEFAULT,
} from "constants/constants";
import { OrganizationUpdateParams, Type } from "types/Organization";
import {
  emptyOpenScheduleState,
  formToSchedule,
  HourOfOperationValues,
} from "../EditHoursOfOperation/formUtils";
import { Fields, formToAPI } from "../EditLocation/formUtils";
import { EditOrganizationValues } from "../EditOrganization/EditOrganizationForm";

export type AddOrganizationValues = EditOrganizationValues &
  HourOfOperationValues & {
    type: Type;
    Billing: Fields;
    Location: Fields;
  };

export const getInitialValues = (): AddOrganizationValues => ({
  name: "",
  type: "ROOFTOP",
  parentId: null,
  websiteUrl: "",
  currency: CURRENCY_DEFAULT,
  locale: LOCALE_DEFAULT,
  hoursOfOperation: emptyOpenScheduleState(),
  Location: {
    addressId: "",
    timezone: null,
    address: "",
    address2: "",
    city: "",
    country: {
      id: "",
      name: "",
      shortName: "",
    },
    state: {
      id: "",
      name: "",
      shortName: "",
    },
    zip: "",
    firstname: "",
    lastname: "",
    jobTitle: "",
    email: "",
    phone: {
      countryCode: COUNTRY_CODE_DEFAULT,
      phoneNumber: "",
    },
    allowSMS: false,
  },
  Billing: {
    addressId: "",
    timezone: null,
    address: "",
    address2: "",
    city: "",
    country: {
      id: "",
      name: "",
      shortName: "",
    },
    state: {
      id: "",
      name: "",
      shortName: "",
    },
    zip: "",
    firstname: "",
    lastname: "",
    jobTitle: "",
    email: "",
    phone: {
      countryCode: COUNTRY_CODE_DEFAULT,
      phoneNumber: "",
    },
    allowSMS: false,
  },
});

export const valuesToAPI = (values: AddOrganizationValues) => {
  return {
    name: values.name,
    type: values.type,
    currency: values.currency,
    locale: values.locale,
    websiteUrl: values.websiteUrl,
    parentId: values.parentId?.id ?? null,
    ...formToAPI({ Billing: values.Billing }, "Billing"),
    ...formToAPI({ Location: values.Location }, "Location"),
    hoursOfOperation: formToSchedule({
      hoursOfOperation: values.hoursOfOperation,
    }) as any,
  } as OrganizationUpdateParams;
};
