import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { IconButton, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { FormValues } from "../EditUserForm";

interface DeleteCertificationProps {
  index: number;
  onDelete: (index: number) => void;
}

export const DeleteCertification = ({
  index,
  onDelete,
}: DeleteCertificationProps): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();

  const onDeleteButton = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    setFieldValue("certifications", [
      ...values.certifications.slice(0, index),
      ...values.certifications.slice(index + 1),
    ]);
    onDelete(index);
    closeDialog();
  }, [closeDialog, values, setFieldValue, index, onDelete]);

  return (
    <>
      <IconButton onClick={onDeleteButton} sx={{ ml: 1 }}>
        <DeleteIcon fontSize="small" color="primary" />
      </IconButton>
      <Dialog
        title={t("DeleteCertification")}
        loading={false}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
