import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { usePostUser } from "hooks/usePostUser";
import { SecurityGroup } from "types/Group";
import { AddButton } from "../AddButton/AddButton";
import { AddNewUserForm, AddNewUserValues } from "./AddUserForm";

interface AddNewUserProps {
  buttonVariant: "outlined" | "contained";
  group?: SecurityGroup;
}

export const AddNewUser = ({
  buttonVariant,
  group,
}: AddNewUserProps): JSX.Element => {
  const { t } = useTranslation();
  const { onPostUser, isSuccess, isLoading } = usePostUser();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const formRef = useRef<FormikProps<AddNewUserValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton
        variant={buttonVariant}
        onClick={openDialog}
        sx={{ whiteSpace: "nowrap" }}
      >
        {t("NewUser")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("NewUser")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="lg"
      >
        <AddNewUserForm formRef={formRef} onSubmit={onPostUser} group={group} />
      </Dialog>
    </>
  );
};
