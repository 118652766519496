import { HierarchyOrganization, OrganizationFlat } from "types/Organization";

export const findChildren = (
  parent: OrganizationFlat,
  organizationsArray: Array<OrganizationFlat>
): HierarchyOrganization => {
  const children = organizationsArray
    .filter((child) => child?.parent && child.parent?.id === parent.id)
    .map((childRoot) => findChildren(childRoot, organizationsArray));
  return {
    ...parent,
    children,
  };
};

export const createHierarchy = (
  organizationsArray: Array<OrganizationFlat>
): Array<HierarchyOrganization> => {
  const roots = organizationsArray.filter(
    (organization) => !organization?.parent
  );
  return roots.map((root) => findChildren(root, organizationsArray));
};
