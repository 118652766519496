import { useCallback, useMemo } from "react";
import { TextField } from "@mui/material";
import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from "@mui/x-date-pickers";
import { useField } from "formik";

type DateTimePickerProps = Omit<
  MuiDateTimePickerProps<unknown, unknown>, // TODO: check this
  "value" | "onChange" | "renderInput"
> & {
  name: string;
};

export const DateTimePicker = ({
  name,
  ...props
}: DateTimePickerProps): JSX.Element => {
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const onChange = useCallback(
    (date) => {
      setValue(date);
    },
    [setValue]
  );

  return (
    <>
      <MuiDateTimePicker
        {...props}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            error={hasError}
            onBlur={onBlur}
            helperText={hasError ? error : " "}
          />
        )}
      />
    </>
  );
};
