import { useCallback, useState } from "react";
import { Navigate } from "react-router";
import { Grid } from "@mui/material";

import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { permissions } from "constants/constants";
import { paths } from "navigation/paths";
import { Application } from "types/FeatureFlag";
import { ApplicationsList } from "./ApplicationsList/ApplicationsList";
import { FeatureFlagPanel } from "./FeatureFlagPanel/FeatureFlagPanel";
import { FeatureFlagsList } from "./FeatureFlagsList/FeatureFlagsList";

export const FeatureFlags = (): JSX.Element => {
  const [selectedApplication, setSelectedApplication] = useState<Application>();
  const [selectedFeatureFlagName, setSelectedFeatureFlagName] =
    useState<string>();

  const setSelectedApplicationAndClear = useCallback((application) => {
    setSelectedApplication(application);
    setSelectedFeatureFlagName(undefined);
  }, []);

  return (
    <FeatureGate
      name={permissions.FEATUREFLAG_VIEW}
      fallback={<Navigate to={`/${paths.TOOLS}`} />}
    >
      <Grid container spacing={2}>
        <Grid item width="auto">
          <ApplicationsList
            selected={selectedApplication}
            setSelected={setSelectedApplicationAndClear}
          />
        </Grid>
        <Grid item width="auto">
          {selectedApplication && (
            <FeatureFlagsList
              application={selectedApplication}
              selected={selectedFeatureFlagName}
              setSelected={setSelectedFeatureFlagName}
            />
          )}
        </Grid>
        <Grid item xs>
          {selectedFeatureFlagName && selectedApplication && (
            <FeatureFlagPanel
              featureFlagName={selectedFeatureFlagName}
              application={selectedApplication}
              setSelectedFeatureFlagName={setSelectedFeatureFlagName}
            />
          )}
        </Grid>
      </Grid>
    </FeatureGate>
  );
};
