import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import { ListItemIcon, MenuItem } from "@mui/material";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateUser } from "hooks/useUpdateUser";
import { EditUserForm, FormValues } from "./EditUserForm";
import ForceLogout from "./ForceLogout";
import ImpersonateUser from "./ImpersonateUser/ImpersonateUser";
import SetForcePasswordChange from "./SetForcePasswordChange";
import { SetPassword } from "./SetPassword/SetPassword";

interface EditUserProps {
  disabled?: boolean;
  userId: string;
  menu?: boolean;
}

export const EditUser = ({
  disabled = false,
  userId,
  menu = false,
}: EditUserProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onUpdateUser } = useUpdateUser({
    userId,
    params: {
      outputGroups: true,
      outputOrganizations: true,
      outputDefaultOrg: true,
    },
  });
  const formRef = useRef<FormikProps<FormValues>>(null);

  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      {menu ? (
        <MenuItem onClick={openDialog}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          {t("EditSettings")}
        </MenuItem>
      ) : (
        <EditButton disabled={disabled} onClick={openDialog} />
      )}
      <Dialog
        title={`${t("EditUser")} - ID: ${userId}`}
        {...dialogProps}
        actionText={t("Save")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        maxWidth="xl"
        renderFooter={() => (
          <>
            <SetPassword userId={userId} />
            <SetForcePasswordChange userId={userId} />
            <ImpersonateUser userId={userId} />
            <ForceLogout userId={userId} />
          </>
        )}
      >
        <EditUserForm
          onSubmit={onUpdateUser}
          formRef={formRef}
          userId={userId}
        />
      </Dialog>
    </>
  );
};