import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { useAuthApi } from "./useApi";

export interface Application {
  id: string;
  name: string;
}

export const useGetUtilityApps = () => {
  const getApplications = useAuthApi<null, { data: Array<Application> }>(
    "get",
    endpoints.UTILITY_APPLICATIONS
  );

  const { data, isLoading, isError } = useQuery(
    [QueryKeys.GET_APPLICATIONS],
    () => getApplications()
  );

  return {
    apps: data?.data.data ?? [],
    isLoading,
    isError,
  };
};
