import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
import { useField } from "formik";

import { useGetCountries } from "hooks/useGetCountries";
import { Locality } from "types/Organization";

type CountrySelectProps = {
  name: string;
  label: string;
  readOnly?: boolean;
  fullWidth?: boolean;
};

export const CountrySelect = (props: CountrySelectProps): JSX.Element => {
  const { t } = useTranslation();
  const { countries, isLoading } = useGetCountries();
  const [{ value, onBlur }, { touched, error }, { setValue }] = useField(
    props.name
  );
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);

  const getOptionLabel = useCallback((option: Locality) => option.name, []);

  const onChange = useCallback((_, value) => setValue(value), [setValue]);

  const isOptionEqualToValue = useCallback(
    (option, value) => option.id === value.id,
    []
  );

  return (
    <Autocomplete
      id={props.name}
      autoComplete
      onBlur={onBlur}
      options={countries}
      disableClearable
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      loading={isLoading}
      loadingText={t("Loading")}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          name={props.name}
          label={props.label}
          fullWidth
          error={hasError}
          helperText={hasError ? error : " "}
          inputProps={{
            ...params.inputProps,
            autoComplete: "do-not-autofill",
          }}
        />
      )}
    />
  );
};
