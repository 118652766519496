import { useMutation } from "react-query";
import { AxiosError } from "axios";

import { endpoints } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseDriverPoolDeleteActorProps {
  actorId: string;
}

export const useDriverPoolDeleteActor = ({
  actorId,
}: UseDriverPoolDeleteActorProps) => {
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent } = usePostAudit();
  const deleteDriverPoolActor = useAuthApi(
    "delete",
    // TODO change to actual endpoint
    endpoints.ORGANIZATION_GROUP("mock", actorId)
  );

  const { isSuccess, isLoading, mutate } = useMutation(
    ["deleteDriverPoolActor", { actorId }],
    () => deleteDriverPoolActor(),
    {
      // onSuccess: () => {
      // TODO: refetch query or set data depending on endpoint response
      // },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ??
          "Delete Product Driver Pool Actor unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Delete Product Driver Pool Actor", "ERROR");
      },
    }
  );

  return {
    isLoading,
    isSuccess,
    deleteDriverPoolActor: mutate,
  };
};
