import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { array, object } from "yup";

import { UtilityProductSelect } from "common/components/UtilityProductSelect/UtilityProductSelect";
import { ProductType } from "types/Product";

export interface AddProductValues {
  products: Array<ProductType>;
}

export const OrganizationAddProductForm = ({
  formRef,
  onSubmit,
}: any): JSX.Element => {
  const { t } = useTranslation();
  const initialValues = useMemo(() => ({ products: [] }), []);
  const validationSchema = useMemo(
    () =>
      object().shape({
        products: array().min(1, t("OneProductRequired")),
      }),
    [t]
  );

  const onFormSubmit = useCallback(
    (values) => {
      return onSubmit({
        skipOrgCheck: true,
        products: values.products,
      });
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <UtilityProductSelect name="products" />
      </Form>
    </Formik>
  );
};
