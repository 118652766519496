import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";

import { CountrySelect } from "common/components/CountrySelect/CountrySelect";
import { DatePicker } from "common/components/DatePicker/DatePicker";
import { DateTimePicker } from "common/components/DateTimePicker/DateTimePicker";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { StateSelect } from "common/components/StateSelect/StateSelect";
import { TextField } from "common/components/TextField/TextField";

interface DriversLicenseFieldsProps {
  namePrefix?: string;
}

export const DriversLicenseFields = ({
  namePrefix = "",
}: DriversLicenseFieldsProps): JSX.Element => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    issuingCountry: { shortName: string };
    rawAddress: { state: string | null };
  }>();

  useEffect(() => {
    setFieldValue("rawAddress.state", null);
  }, [values.issuingCountry, setFieldValue]);

  return (
    <FormGridContainer>
      <Grid item xs={3}>
        <TextField
          fullWidth
          name={`${namePrefix}licenseNumber`}
          label={t("LicenseNumber")}
        />
      </Grid>
      <Grid item xs={3}>
        <DatePicker name={`${namePrefix}issueDateUTC`} label={t("IssueDate")} />
      </Grid>
      <Grid item xs={3}>
        <DatePicker
          name={`${namePrefix}expirationDateUTC`}
          label={t("ExpirationDate")}
        />
      </Grid>
      <Grid item xs={3}>
        <DateTimePicker
          name={`${namePrefix}certificationTimestampUTC`}
          label={t("CertificationTimestamp")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name={`${namePrefix}firstName`}
          label={t("FirstName")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name={`${namePrefix}middleName`}
          label={t("MiddleName")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name={`${namePrefix}lastName`}
          label={t("LastName")}
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          name={`${namePrefix}dateOfBirthUTC`}
          label={t("DateOfBirth")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField name={`${namePrefix}gender`} fullWidth label={t("Gender")} />
      </Grid>
      <Grid item xs={4}>
        <CountrySelect
          fullWidth
          name={`${namePrefix}issuingCountry`}
          label={t("IssuingCountry")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.addressee`}
          label={t("Addressee")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.freeFormAddress`}
          label={t("FreeFormAddress")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.street1`}
          label={t("Address")}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.street2`}
          label={t("Address2")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.city`}
          label={t("City")}
        />
      </Grid>
      <Grid item xs={4}>
        <StateSelect
          name={`${namePrefix}rawAddress.state`}
          fullWidth
          countryCode={values.issuingCountry?.shortName}
          label={t("State")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          name={`${namePrefix}rawAddress.postalCode`}
          label={t("Zip")}
        />
      </Grid>
    </FormGridContainer>
  );
};
