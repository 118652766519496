import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { FormValues } from "../EditUserForm";
import { AddTag } from "./AddTag/AddTag";

export const Tags = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { t } = useTranslation();
  const onDeleteTag = useCallback(
    (tagToDelete) =>
      setFieldValue(
        "tags",
        values.tags.filter((tag) => tag !== tagToDelete)
      ),
    [values.tags, setFieldValue]
  );

  return (
    <Grid container spacing={2} pt={2}>
      <Grid item xs="auto">
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap overflow="unset" mr={2}>
              {t("Tags", { count: values.tags.length })}
            </Typography>
            <AddTag />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs>
        <Box>
          {values.tags.map((tag) => (
            <Chip
              label={tag}
              key={tag}
              onDelete={() => onDeleteTag(tag)}
              sx={{ mr: 1 }}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
