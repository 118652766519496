import { Fragment, useCallback, useMemo } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { organizationsPaths, paths } from "navigation/paths";
import { HierarchyOrganization } from "types/Organization";

interface OrganizationsCollapsibleMenuProps {
  organizations: Array<HierarchyOrganization>;
  search: string;
  noBorder?: boolean;
  indent?: number;
}
const highlight = (text: string, search: string) =>
  text.replace(new RegExp(search, "gi"), (match) => `<mark>${match}</mark>`);

export const OrganizationsCollapsibleMenu = ({
  organizations,
  search,
  noBorder = false,
  indent = 0,
}: OrganizationsCollapsibleMenuProps): JSX.Element => {
  const { selectedOrganizationId } = useSelectedOrganization();
  const [expandedId, setExpandedId] = useState("");
  const navigate = useNavigate();

  const lowCasedSearch = useMemo(() => search.toLowerCase(), [search]);

  const toggleExpand = useCallback((event, id: string) => {
    event.stopPropagation();
    setExpandedId((isExpanded) => (isExpanded === id ? "" : id));
  }, []);

  const navigateToOrganization = useCallback(
    (item: HierarchyOrganization) => {
      navigate(
        `/${paths.ORGANIZATIONS}/${item.id}/${organizationsPaths.OVERVIEW}`
      );
    },
    [navigate]
  );

  const organizationById = useCallback(
    (id: string) =>
      organizations.find((organization) => organization.id === id),
    [organizations]
  );

  const recursiveMatchId = useCallback(
    (organization: HierarchyOrganization, id: string) => {
      if (!organization.children) return false;
      return organization.children.some(
        (child) => child.id === id || recursiveMatchId(child, id)
      );
    },
    []
  );

  const recursiveSearch = useCallback(
    (organization: HierarchyOrganization, search: string) => {
      if (organization.name.toLowerCase().includes(search)) return true;
      return organization.children.some(
        (child) =>
          child.name.toLowerCase().includes(search) ||
          recursiveSearch(child, search)
      );
    },
    []
  );

  const shouldExpand = useCallback(
    (id: string) => {
      const organization = organizationById(id);
      return (
        Boolean(lowCasedSearch) ||
        id === expandedId ||
        recursiveMatchId(
          organization as HierarchyOrganization,
          selectedOrganizationId as string
        )
      );
    },
    [
      lowCasedSearch,
      expandedId,
      organizationById,
      selectedOrganizationId,
      recursiveMatchId,
    ]
  );

  const filteredOrganizations = useMemo(
    () =>
      lowCasedSearch
        ? organizations.filter((organization) => {
            if (recursiveSearch(organization, lowCasedSearch))
              return organization;
            return null;
          })
        : organizations,
    [organizations, lowCasedSearch, recursiveSearch]
  );

  return (
    <List component="div" disablePadding>
      {filteredOrganizations.map((organization) => (
        <Fragment key={organization.id}>
          {organization.children.length > 0 ? (
            <>
              {noBorder ? (
                <ListItemButton
                  onClick={(event) => {
                    navigateToOrganization(organization);
                    toggleExpand(event, organization.id);
                  }}
                  selected={organization.id === selectedOrganizationId}
                  sx={{ pl: indent }}
                >
                  <ListItemText>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlight(organization.name, search),
                      }}
                    />
                  </ListItemText>
                  <IconButton
                    onClick={(event) => toggleExpand(event, organization.id)}
                  >
                    {shouldExpand(organization.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                </ListItemButton>
              ) : (
                <ListItemButton
                  selected={organization.id === selectedOrganizationId}
                  onClick={(event) => {
                    navigateToOrganization(organization);
                    toggleExpand(event, organization.id);
                  }}
                >
                  <ListItemText>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlight(organization.name, search),
                      }}
                    />
                  </ListItemText>
                  <IconButton
                    onClick={(event) => toggleExpand(event, organization.id)}
                  >
                    {shouldExpand(organization.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </IconButton>
                </ListItemButton>
              )}
              <Collapse
                in={shouldExpand(organization.id)}
                timeout="auto"
                unmountOnExit
              >
                <OrganizationsCollapsibleMenu
                  organizations={organization.children}
                  search={search}
                  noBorder={true}
                  indent={indent + 3}
                />
              </Collapse>
            </>
          ) : (
            <ListItemButton
              selected={organization.id === selectedOrganizationId}
              sx={{
                pl: noBorder ? indent : indent + 2,
              }}
              onClick={() => navigateToOrganization(organization)}
            >
              <ListItemText>
                <span
                  dangerouslySetInnerHTML={{
                    __html: highlight(organization.name, search),
                  }}
                />
              </ListItemText>
            </ListItemButton>
          )}
        </Fragment>
      ))}
    </List>
  );
};
