import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  List,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { FeatureGate } from "components/FeatureGate/FeatureGate";
import { OrganizationAddToken } from "components/OrganizationAddToken/OrganizationAddToken";
import { OrganizationDeleteToken } from "components/OrganizationDeleteToken/OrganizationDeleteToken";
import { permissions } from "constants/constants";
import { useGetSelectedOrganizationApiToken } from "hooks/useGetSelectedOrganizationApiTokens";
import { OrganizationApiToken } from "types/Organization";

export const ApiTokens = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectedTokenId, setSelectedTokenId] = useState("");
  const { isLoading, apiTokens } = useGetSelectedOrganizationApiToken({
    params: { outputPermissions: true },
  });

  useEffect(() => {
    if (!isLoading) {
      apiTokens.length && setSelectedTokenId(apiTokens[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, apiTokens.length]);

  return (
    <Grid
      container
      spacing={2}
      sx={{ flex: 1, display: "flex", maxHeight: "100%", pt: 2 }}
    >
      <Grid item xs="auto">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2, pb: 2 }}
        >
          <Typography sx={{ pr: 2 }}>
            {isLoading ? (
              <Skeleton variant="text" width={80} />
            ) : (
              <>{t("Tokens", { count: apiTokens.length })}</>
            )}
          </Typography>
          <FeatureGate name={permissions.APITOKEN_CREATE}>
            <OrganizationAddToken disabled={isLoading} />
          </FeatureGate>
        </Stack>
        {isLoading ? (
          <ListSkeleton withDivider width={200} />
        ) : (
          <List sx={{ py: 0 }}>
            {apiTokens.map((token: OrganizationApiToken) => (
              <ListItemButtonWithDialog
                key={token.id}
                selected={token.id === selectedTokenId}
                divider
                onClick={() => setSelectedTokenId(token.id)}
              >
                <ListItemText>{token.name}</ListItemText>
                <FeatureGate name={permissions.APITOKEN_DELETE}>
                  <OrganizationDeleteToken token={token} />
                </FeatureGate>
              </ListItemButtonWithDialog>
            ))}
          </List>
        )}
      </Grid>
    </Grid>
  );
};
