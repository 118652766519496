import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteOrganizationApiToken } from "hooks/useDeleteOrganizationToken";
import { OrganizationApiToken } from "types/Organization";

interface OrganizationDeleteTokenProps {
  token: OrganizationApiToken;
}

export const OrganizationDeleteToken = ({
  token,
}: OrganizationDeleteTokenProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onDeleteToken } = useDeleteOrganizationApiToken(
    {
      tokenId: token.id,
    }
  );

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    onDeleteToken();
  }, [onDeleteToken]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteToken")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
