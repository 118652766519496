import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import MuiSelect, { SelectProps as MuiSelectProps } from "@mui/material/Select";
import { useField } from "formik";

export type SelectProps = MuiSelectProps & {
  name: string;
};

export const Select = ({ sx, ...props }: SelectProps) => {
  const [field, { error, touched }] = useField(props.name);
  const hasError = useMemo(() => touched && Boolean(error), [error, touched]);

  return (
    <FormControl
      error={hasError}
      sx={sx}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
    >
      <InputLabel>{props.label}</InputLabel>
      <MuiSelect {...props} {...field} />
      <FormHelperText>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
