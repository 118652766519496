import { Box, Grid, Paper, styled } from "@mui/material";

import bg from "assets/images/customer_in_lot2.png";

export const Background = styled(Box)(
  ({ theme }) => `
  height: 100vh;
  width: 100vw;
  padding: ${theme.spacing(2)};
  background: ${theme.palette.grey[900]};
  display: flex;
  justify-content: center;
  align-items: center;
`
);

export const LoginWidget = styled(Paper)`
  overflow: hidden;
  max-width: 1000px;
  width: 1000px;
`;

export const BackgroundGrid = styled(Grid)`
  background-image: url(${bg});
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
`;
