import { array, object, string } from "yup";

import { daysOfWeek } from "constants/constants";

export const hoursOfOperationSchema = {
  hoursOfOperation: object().shape(
    daysOfWeek.reduce(
      (acc, dayOfWeek) => ({
        ...acc,
        [dayOfWeek]: array()
          .of(
            object().shape({
              start: string()
                .nullable()
                .test("startTimeTest", "InvalidTime", (startTime, context) => {
                  if (context.parent.end) {
                    if (!startTime) return false;
                    if (startTime > context.parent.end) return false;
                  }
                  return true;
                }),
              end: string()
                .nullable()
                .test("endTimeTest", "InvalidTime", (endTime, context) => {
                  if (context.parent.start) {
                    if (!endTime) return false;
                    if (endTime < context.parent.start + 3600) return false;
                  }
                  return true;
                }),
            })
          )
          .required(),
      }),
      {}
    )
  ),
};
