import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const History = (): JSX.Element => {
  const { t } = useTranslation();
  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "date",
      headerName: t("Date"),
      flex: 1,
      sortable: false,
    },
    {
      field: "type",
      headerName: t("Type"),
      flex: 1,
      sortable: false,
    },
    {
      field: "message",
      headerName: t("Message"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = [
    {
      id: 0,
      date: "2022-04-11 13:00:00 UTC",
      type: "Push Notification",
      message: "Push Notification Registered",
    },
    {
      id: 1,
      date: "2022-04-11 13:00:00 UTC",
      type: "Push Notification",
      message: "Trip Request Sent",
    },
  ];
  return (
    <Box sx={{ height: 500, display: "flex", pt: 2 }}>
      <Paper elevation={2} sx={{ flex: 1 }}>
        <DataGrid columns={columns} rows={rows} />
      </Paper>
    </Box>
  );
};
