import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { FieldArray, useField } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { FormGridContainer } from "common/components/FormGridContainer/FormGridContainer";
import { TextField } from "common/components/TextField/TextField";

interface SettingsArrayFieldProps {
  name: string;
}

export const SettingsArrayField = ({
  name,
}: SettingsArrayFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [{ value }] = useField<Array<{ key: string; value: string }>>(name);

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {value.map((_, index) => (
            <FormGridContainer key={`setting-${index}`}>
              <Grid item xs>
                <TextField
                  name={`${name}.${index}.key`}
                  label={t("Name")}
                  fullWidth
                />
              </Grid>
              <Grid item xs>
                <TextField
                  name={`${name}.${index}.value`}
                  label={t("Value")}
                  fullWidth
                />
              </Grid>
              <Grid item width="76px" pt={1.5}>
                <DeleteButton onClick={() => arrayHelpers.remove(index)} />
              </Grid>
            </FormGridContainer>
          ))}
          <AddButton
            variant="contained"
            onClick={() => arrayHelpers.push({ key: "", value: "" })}
          >
            {t("AddSetting")}
          </AddButton>
        </>
      )}
    />
  );
};
