import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";

interface UseDeleteSelectedOrganizationLocationProps {
  locationId: string;
}

export const useDeleteSelectedOrganizationLocation = ({
  locationId,
}: UseDeleteSelectedOrganizationLocationProps) => {
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const { selectedOrganizationId } = useSelectedOrganization();
  const deleteLocation = useAuthApi<never, unknown>(
    "delete",
    endpoints.ORGANIZATION_LOCATION(
      selectedOrganizationId as string,
      locationId
    )
  );

  const { isLoading, isSuccess, mutate } = useMutation(() => deleteLocation(), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION_LOCATIONS,
        selectedOrganizationId,
      ]);
      auditFormEvent(
        "Organization Location Deleted",
        "Delete Organization Location",
        "FORM_SENT"
      );
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Delete Organization Location unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Delete Organization Location", "ERROR");
    },
  });

  return {
    isLoading,
    isSuccess,
    onDeleteLocation: mutate,
  };
};
