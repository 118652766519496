import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { EditButton } from "common/components/EditButton/EditButton";
import { TextField } from "common/components/TextField/TextField";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateUtilityFeatureFlagApplicationCollectionName } from "hooks/useUpdateUtilityFeatureFlagApplicationCollectionName";
import { FeatureFlag, FeatureFlagData } from "types/FeatureFlag";
import { RowType } from "../FeatureFlagPanel/FeatureFlagPanel";

interface FormValues {
  value: string;
}
interface EditDataItemValueProps {
  collectionName: string;
  featureFlag: FeatureFlag;
  row: RowType;
}

export const EditDataItemValue = ({
  collectionName,
  featureFlag,
  row,
}: EditDataItemValueProps): JSX.Element => {
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { isLoading, isSuccess, onUpdateFeatureFlag } =
    useUpdateUtilityFeatureFlagApplicationCollectionName({
      collectionName,
      featureFlagName: featureFlag.name,
    });
  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  const initialValues = useMemo(() => ({ value: row.value }), [row]);
  const validationSchema = useMemo(
    () => object().shape({ value: string().required(t("Required")) }),
    [t]
  );
  const onFormSubmit = useCallback(
    (values: FormValues) => {
      const data: FeatureFlagData = {
        ...featureFlag,
        applicationCollectionName: collectionName,
        deciderData: {
          ...featureFlag.deciderData,
          [row.key]: [
            ...featureFlag.deciderData[row.key].slice(0, row.index),
            values.value,
            ...featureFlag.deciderData[row.key].slice(row.index + 1),
          ],
        },
      };
      return onUpdateFeatureFlag(data);
    },
    [row, featureFlag, collectionName, onUpdateFeatureFlag]
  );

  return (
    <>
      <EditButton onClick={openDialog} />
      <Dialog
        title={t("EditValue")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
        maxWidth="sm"
        {...dialogProps}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onFormSubmit}
          innerRef={formRef}
          validationSchema={validationSchema}
        >
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField name="value" label={t("Value")} fullWidth />
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Dialog>
    </>
  );
};
