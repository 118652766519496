import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useField } from "formik";

import {
  APITokenPermissions,
  useGetPermissions,
} from "hooks/useGetPermissions";

interface PermissionsFieldProps {
  name: string;
  applicationName: string;
  columns: number;
}

type APITokenPermissionTypes = "VIEW" | "DELETE" | "CREATE" | "UPDATE";

export const PermissionsField = ({
  name,
  columns,
  applicationName,
}: PermissionsFieldProps): JSX.Element => {
  const { t } = useTranslation();
  const [field, { touched, error }, { setValue }] = useField(name);
  const hasError = useMemo(() => touched && Boolean(error), [touched, error]);
  const { permissions, isLoading } = useGetPermissions(applicationName);

  const getFilteredPermissionsByType = (
    type: APITokenPermissionTypes
  ): APITokenPermissions[] => {
    return permissions.filter((p) => {
      return p.type === type;
    });
  };

  const getPermissionGroup = (label: string, type: APITokenPermissionTypes) => {
    return (
      <Grid
        item
        xs={6}
        sx={{
          backgroundColor: "#eaeaea!important",
        }}
      >
        <Stack>
          <Box>{label}</Box>
          {getFilteredPermissionsByType(type).map(
            ({ id, label, name, type }) => (
              <Stack key={name} direction="row">
                <Checkbox
                  value={id}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setValue([...field.value, id]);
                    } else {
                      const clone = [...field.value];
                      const index = clone.indexOf(id);
                      clone.splice(index, 1);
                      setValue(clone);
                    }
                  }}
                />
                <Typography
                  key={name}
                  style={{
                    margin: "5px 0",
                    fontSize: "13px",
                  }}
                >
                  {name}
                </Typography>
              </Stack>
            )
          )}
        </Stack>
      </Grid>
    );
  };

  if (applicationName === "")
    return <Typography>{t("NoApplicationSelected")}</Typography>;

  if (!permissions.length && !isLoading)
    return (
      <>
        <Typography>{t("NoPermissionsAvailable")}</Typography>

        <FormHelperText sx={{ pb: 2 }} error>
          {hasError ? error : " "}
        </FormHelperText>
      </>
    );

  if (isLoading)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12 / columns}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12 / columns}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
        <Grid item xs={12 / columns}>
          <Skeleton variant="rectangular" height={200} />
        </Grid>
      </Grid>
    );

  return (
    <FormControl error={hasError} sx={{ pt: 0, mt: 0 }}>
      <Grid container spacing={2}>
        {getPermissionGroup("DELETE", "DELETE")}
        {getPermissionGroup("VIEW", "VIEW")}
        {getPermissionGroup("CREATE", "CREATE")}
        {getPermissionGroup("UPDATE", "UPDATE")}
      </Grid>
      <FormHelperText error>{hasError ? error : " "}</FormHelperText>
    </FormControl>
  );
};
