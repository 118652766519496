import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";

interface UseDeleteUtilityFeatureFlagProps {
  featureFlagName: string;
  collectionName: string;
}
export const useDeleteUtilityFeatureFlag = ({
  featureFlagName,
  collectionName,
}: UseDeleteUtilityFeatureFlagProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const deleteFeatureFlag = useAuthApi<never, unknown>(
    "delete",
    endpoints.UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION_FLAG_NAME(
      collectionName,
      featureFlagName
    )
  );
  const { isSuccess, isLoading, mutateAsync } = useMutation(
    () => deleteFeatureFlag(),
    {
      onSuccess: () => {
        auditFormEvent(
          "Feature Flag Deleted",
          "Delete Feature Flag",
          "FORM_SENT"
        );
        setSnackbarData({
          severity: "success",
          message: t("FeatureFlagDeleted"),
        });
        return queryClient.invalidateQueries([
          QueryKeys.GET_FEATURE_FLAGS,
          collectionName,
        ]);
      },
      onError: (data: AxiosError<ServerError>) => {
        const message =
          data.response?.data.message ?? "Delete Feature Flag unknown error";
        setSnackbarData({ severity: "error", message });
        auditErrorEvent(message, "Delete Feature Flag", "ERROR");
      },
    }
  );

  return {
    isSuccess,
    isLoading,
    onDeleteFeatureFlag: mutateAsync,
  };
};
