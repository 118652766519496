import { RefObject, useCallback, useEffect, useMemo } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, FormHelperText, Input, LinearProgress } from "@mui/material";
import { Form, Formik, FormikProps, useField } from "formik";
import { mixed, object } from "yup";

import { usePostUser } from "hooks/usePostUser";

import "./ImportUsers.scss";

export interface ImportUsersValues {
  file?: File;
}

interface ImportUsersProps {
  formRef: RefObject<FormikProps<ImportUsersValues>>;
  closeDialog(): void;
}

export const ImportUsersForm = ({
  formRef,
  closeDialog,
}: ImportUsersProps): JSX.Element => {
  const { t } = useTranslation();
  const { isSuccess, isLoading } = usePostUser();
  const initialValues: ImportUsersValues = {
    file: undefined,
  };

  const validationSchema = useMemo(
    () =>
      object({
        file: mixed().required(t("Required")),
      }),
    [t]
  );

  const onSubmit = useCallback((values: ImportUsersValues) => {
    //TODO: convert file to JSON format and do the API request
  }, []);

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  const FileDragAndDropUpload = () => {
    const fileTypes = ["xlsx", "xls"];
    const [field, { error }, { setValue, setError }] = useField({
      name: "file",
      type: "file",
    });
    const hasError = useMemo(() => Boolean(error), [error]);
    return (
      <>
        <Box>
          <FileUploader
            {...field}
            handleChange={(uploadedFile: File) => setValue(uploadedFile)}
            types={fileTypes}
            classes="DropArea"
            onTypeError={(error: string) => setError(error)}
            onSizeError={(error: string) => setError(error)}
          >
            <Box>
              <CloudUploadIcon fontSize="large" />
            </Box>
            <Box className="UploaderWrapper">
              <Input
                disabled
                fullWidth
                disableUnderline
                defaultValue={t("DragAndDrop")}
                sx={{ marginTop: 6, marginLeft: 1 }}
              />
            </Box>
          </FileUploader>
          <FormHelperText error>{hasError ? error : " "}</FormHelperText>
        </Box>
        <Box>
          {field.value ? (
            <Input
              disabled
              disableUnderline
              fullWidth
              value={`${t("FileName")} ${field.value.name}`}
            />
          ) : (
            <Input
              disabled
              disableUnderline
              fullWidth
              value={t("NoFileUploaded")}
            />
          )}
        </Box>
      </>
    );
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
    >
      <Form>
        <Box>
          <FileDragAndDropUpload />
          {isLoading && (
            <LinearProgress
              sx={{ height: 10, borderRadius: 5, marginTop: 3 }}
            />
          )}
        </Box>
      </Form>
    </Formik>
  );
};
