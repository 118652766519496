import { useTranslation } from "react-i18next";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Grid, Paper, Tab } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { CurrentLocation } from "./CurrentLocation";
import { History } from "./History";

const tabsIndex = ["CurrentLocation", "History"] as const;
type TabLabel = typeof tabsIndex[number];

export const Devices = (): JSX.Element => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useTabValue<TabLabel>("CurrentLocation");

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "model",
      headerName: t("Model"),
      flex: 1,
      sortable: false,
    },
    {
      field: "os",
      headerName: t("Os").toUpperCase(),
      flex: 1,
      sortable: false,
    },
    {
      field: "lastSeen",
      headerName: t("LastSeen"),
      flex: 1,
      sortable: false,
    },
  ];

  const rows = [
    {
      id: 0,
      model: "Samsung SM-S908U",
      os: "Android 12",
      lastSeen: "2022-04-11 13:00:00 UTC",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ height: 300, display: "flex", pb: 2 }}>
          <Paper elevation={2} sx={{ flex: 1 }}>
            <DataGrid rows={rows} columns={columns} />
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TabContext value={tabValue}>
          <Tabs onChange={setTabValue}>
            <Tab label={t("CurrentLocation")} value="CurrentLocation" />
            <Tab label={t("History")} value="History" />
          </Tabs>
          <TabPanel value="CurrentLocation">
            <CurrentLocation />
          </TabPanel>
          <TabPanel value="History">
            <History />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
