import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetTimezoneResponse } from "../types/Api";
import { useAuthApi } from "./useApi";

export const useGetTimezones = () => {
  const getTimezones = useAuthApi<null, GetTimezoneResponse>(
    "get",
    endpoints.UTILITY_TIMEZONES
  );

  const { data, isLoading, isError } = useQuery([QueryKeys.GET_TIMEZONES], () =>
    getTimezones()
  );

  return {
    timezones: data?.data?.timezones,
    isLoading,
    isError,
  };
};
