import { RefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";
import { object, string } from "yup";

import { Locality } from "types/Organization";
import { DriversLicenseFields } from "./DriversLicenseFields";

export interface DriversLicenseValues {
  licenseNumber: string;
  firstName: string;
  middleName: string;
  lastName: string;
  licenseClasses: Array<string>;
  dateOfBirthUTC: string | null;
  gender: string;
  rawAddress: {
    addressee: string;
    freeFormAddress: string;
    street1: string;
    street2: string;
    city: string;
    state: Locality | null;
    postalCode: string;
  };
  issuingCountry: Locality | null;
  issueDateUTC: string | null;
  expirationDateUTC: string | null;
  certificationTimestampUTC: string | null;
}

interface DriversLicenseAddFormProps {
  formRef: RefObject<FormikProps<DriversLicenseValues>>;
  onSubmit: (values: DriversLicenseValues) => void;
}

export const DriversLicenseAddForm = ({
  formRef,
  onSubmit,
}: DriversLicenseAddFormProps): JSX.Element => {
  const { t } = useTranslation();
  const initialValues: DriversLicenseValues = useMemo(
    () => ({
      certType: "DRIVERS_LICENSE",
      licenseNumber: "",
      firstName: "",
      middleName: "",
      lastName: "",
      licenseClasses: [],
      dateOfBirthUTC: null,
      gender: "",
      rawAddress: {
        addressType: "GLOBAL_ADDRESS", // TODO: Maybe change this
        addressee: "",
        freeFormAddress: "",
        street1: "",
        street2: "",
        city: "",
        state: null,
        postalCode: "",
      },
      issuingCountry: null,
      issueDateUTC: null,
      expirationDateUTC: null,
      certificationTimestampUTC: null,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        licenseNumber: string().required(t("Required")),
        firstName: string().required(t("Required")),
        lastName: string().required(t("Required")),
        dateOfBirthUTC: string().required(t("Required")).nullable(true),
        rawAddress: object().shape({
          addressee: string().required(t("Required")),
          street1: string().required(t("Required")),
          city: string().required(t("Required")),
          state: object().required(t("Required")).nullable(true),
          postalCode: string().required(t("Required")),
        }),
        issuingCountry: object().required(t("Required")).nullable(true),
        issueDateUTC: string().required(t("Required")).nullable(true),
        expirationDateUTC: string().required(t("Required")).nullable(true),
        certificationTimestampUTC: string()
          .required(t("Required"))
          .nullable(true),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <DriversLicenseFields />
      </Form>
    </Formik>
  );
};
