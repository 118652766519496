import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetFeatureFlagsCollectionNameResponse } from "types/FeatureFlag";
import { useAuthApi } from "./useApi";

interface UseGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagNameProps {
  collectionName: string;
  featureFlagName: string;
}

export const useGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagName =
  ({
    collectionName,
    featureFlagName,
  }: UseGetUtilityFeatureFlagsApplicationCollectionNameFeatureFlagNameProps) => {
    const getFeatureFlagsApplicationCollectionNameFeatureFlagName = useAuthApi<
      never,
      GetFeatureFlagsCollectionNameResponse
    >(
      "get",
      endpoints.UTILITY_FEATURE_FLAGS_APPLICATION_COLLECTION_FLAG_NAME(
        collectionName,
        featureFlagName
      )
    );

    const { isLoading, data } = useQuery(
      [QueryKeys.GET_FEATURE_FLAGS, collectionName, featureFlagName],
      () => getFeatureFlagsApplicationCollectionNameFeatureFlagName()
    );

    return {
      isLoading,
      featureFlag: data?.data.featureFlags[0],
    };
  };
