import { useRef } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import { FormikProps } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { ImportUsersForm, ImportUsersValues } from "./ImportUsersForm";

export const ImportUsers = (): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();

  const formRef = useRef<FormikProps<ImportUsersValues>>(null);

  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={openDialog}
      >
        {t("Import")}
      </Button>
      <Dialog
        {...dialogProps}
        title={t("ImportUsers")}
        loading={false}
        onSubmit={onSubmit}
        actionText={t("Import")}
        maxWidth="sm"
      >
        <ImportUsersForm closeDialog={closeDialog} formRef={formRef} />
      </Dialog>
    </>
  );
};
