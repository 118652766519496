import { useState } from "react";

import { useGetSelectedOrganizationLocations } from "hooks/useGetSelectedOrganizationLocations";
import { LocationsPanel } from "./LocationsPanel";

export const Locations = (): JSX.Element => {
  const [search, setSearch] = useState("");

  const { data, isLoading } = useGetSelectedOrganizationLocations({
    params: {
      startIndex: 0,
      pageSize: 25,
      s: search,
    },
  });

  return (
    <LocationsPanel
      locations={data?.data ?? []}
      setSearch={setSearch}
      loading={isLoading}
    />
  );
};
