import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, Paper, Stack, TextField } from "@mui/material";

import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import {
  organizationDetailRequest,
  useGetOrganizationsFlat,
} from "hooks/useGetOrganizationsFlat";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { createHierarchy } from "utils/organizationHierarchy";
import { AddOrganization } from "../OrganizationOverview/AddOrganization/AddOrganization";
import { OrganizationsCollapsibleMenu } from "../OrganizationsCollapsibleMenu/OrganizationsCollapsibleMenu";

export const OrganizationsSidebar = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchedOrganization, setSearchedOrganization] = useState("");
  const { selectedOrganizationId } = useSelectedOrganization();

  const { organizationsFlat, isLoading } = useGetOrganizationsFlat(
    organizationDetailRequest
  );

  const onChange = useCallback(
    (event) => setSearchedOrganization(event.target.value),
    []
  );

  const organizations = useMemo(
    () => createHierarchy(organizationsFlat),
    [organizationsFlat]
  );

  useEffect(() => setSearchedOrganization(""), [selectedOrganizationId]);

  const listContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listContainer.current && organizations.length) {
      const selectedElement = listContainer.current.querySelector(
        ".Mui-selected"
      ) as HTMLElement;
      const listBound = listContainer.current.getBoundingClientRect();
      setTimeout(() => {
        const selectedBound = selectedElement.getBoundingClientRect();

        if (selectedBound.top > listBound.top + listBound.height)
          selectedElement.scrollIntoView({ behavior: "auto", block: "center" });
      }, 200); // HACK: magic number: after search collapse animation duration
    }
  }, [organizations, selectedOrganizationId]);

  return (
    <Paper
      elevation={0}
      sx={{ paddingY: 2, maxHeight: "100%", overflow: "hidden" }}
    >
      <Stack
        sx={{
          flex: 1,
          maxHeight: "100%",
          overflow: "hidden",
        }}
      >
        <Box sx={{ paddingX: 2 }}>
          <TextField
            fullWidth
            placeholder={t("StartTypingToSearch")}
            label={t("SearchOrganizations")}
            type="search"
            onChange={onChange}
            value={searchedOrganization}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <AddOrganization />
        </Box>
        <Box sx={{ overflow: "auto" }} ref={listContainer}>
          {isLoading ? (
            <ListSkeleton width={250} />
          ) : (
            <OrganizationsCollapsibleMenu
              organizations={organizations}
              search={searchedOrganization}
            />
          )}
        </Box>
      </Stack>
    </Paper>
  );
};
