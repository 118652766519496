import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { FormikProps } from "formik";

import { useAppDialog } from "hooks/useAppDialog";
import { useUserSetPassword } from "hooks/useUserSetPassword";
import { FormValues, SetPasswordForm } from "./SetPasswordForm";

interface EditUserProps {
  disabled?: boolean;
  userId: string;
}

export const SetPassword = ({
  disabled = false,
  userId,
}: EditUserProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onSetPassword } = useUserSetPassword({
    userId,
  });
  const formRef = useRef<FormikProps<FormValues>>(null);
  const onSubmit = () => {
    return formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <Button disabled={disabled} onClick={openDialog}>
        {t("SetPassword")}
      </Button>
      <Dialog
        title={t("SetPassword")}
        {...dialogProps}
        actionText={t("Save")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        maxWidth="xs"
      >
        <SetPasswordForm onSubmit={onSetPassword} formRef={formRef} />
      </Dialog>
    </>
  );
};
