import { Organization } from "types/Organization";

export type LocalStorageKey =
  | "userId"
  | "username"
  | "authBearerToken"
  | "authRefreshToken"
  | "authBearerTokenExpiration"
  | "sessionId"
  | "currentOrganization"
  | "allowedOrganizations";

export const getFromLocalStorage = (
  key: LocalStorageKey
): string | Organization[] | Organization | null => {
  const output = window.localStorage.getItem(key);
  switch (key) {
    case "allowedOrganizations":
      return output ? (JSON.parse(output) as unknown as Organization[]) : null;
    case "currentOrganization":
      return output ? (JSON.parse(output) as unknown as Organization) : null;
    default:
      return output ? (output as string) : null;
  }
};

export function getSelectedOrganizationFromLocalStorage(
  organizationId: string
): Organization | null | undefined {
  const list = getFromLocalStorage("allowedOrganizations") as Organization[];
  return list?.length && list?.length > 0
    ? list.find((item) => item?.id === organizationId)
    : null;
}

export function getPermissionsByOrgId(selectedOrg: unknown) {
  if (typeof selectedOrg === "string") {
    return getSelectedOrganizationFromLocalStorage(selectedOrg)?.permissions;
  }
  return [];
}

export function getFeaturesByOrgId(selectedOrg: unknown) {
  if (typeof selectedOrg === "string") {
    return getSelectedOrganizationFromLocalStorage(selectedOrg)?.featureFlags;
  }
  return [];
}

export const setOnLocalStorage = (
  key: LocalStorageKey,
  value: string | Organization[] | Organization
): void =>
  window.localStorage.setItem(
    key,
    typeof value === "string" ? value : JSON.stringify(value)
  );

export const clearFromLocalStorage = (key: LocalStorageKey): void =>
  window.localStorage.removeItem(key);

export const isSetOnLocalStorage = (key: LocalStorageKey): boolean =>
  !!getFromLocalStorage(key);
