import { useTranslation } from "react-i18next";
import { Paper, Typography } from "@mui/material";

import { useGetUsers } from "hooks/useGetUsers";
import { useUsersDataGrid } from "hooks/useUsersDataGrid";

export const Users = (): JSX.Element => {
  const { t } = useTranslation();
  const { page, search, pageSize, UsersDataGrid, usersDataGridProps } =
    useUsersDataGrid();

  const { isLoading, usersResponse } = useGetUsers({
    params: {
      s: search,
      startRecord: page * pageSize,
      pageSize,
      outputOrganizations: false,
      outputGroups: false,
    },
  });

  return (
    <>
      <Typography
        sx={{
          color: (theme) => theme.palette.text.primary,
          textTransform: "uppercase",
          fontSize: "0.75rem",
          py: 1,
        }}
      >
        {t("Users")}
      </Typography>
      <Paper
        sx={{ p: 2, pb: 2, mb: 2, width: "100%", display: "flex", flex: 1 }}
        elevation={0}
      >
        <UsersDataGrid
          deleteUser="app"
          isLoading={isLoading}
          data={usersResponse}
          showTitle={false}
          {...usersDataGridProps}
          addUser="new"
        />
      </Paper>
    </>
  );
};
