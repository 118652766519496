import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";

interface UseDeleteOrganizationApiTokenProps {
  tokenId: string;
}

export const useDeleteOrganizationApiToken = ({
  tokenId,
}: UseDeleteOrganizationApiTokenProps) => {
  const { t } = useTranslation();
  const { selectedOrganizationId } = useSelectedOrganization();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const deleteApiToken = useAuthApi(
    "delete",
    endpoints.ORGANIZATION_API_TOKEN(selectedOrganizationId as string, tokenId)
  );

  const { isLoading, isSuccess, mutate } = useMutation(() => deleteApiToken(), {
    onSuccess: () => {
      auditFormEvent(
        "Settings Updated",
        "Delete Organization Api Token",
        "FORM_SENT"
      );
      setSnackbarData({ severity: "success", message: t("ApiTokenDeleted") });
      return queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION_API_TOKENS,
        selectedOrganizationId,
      ]);
    },
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Delete Organization Api Token Unknown Error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Delete Organization Api Token", "ERROR");
    },
  });

  return {
    isLoading,
    isSuccess,
    onDeleteToken: mutate,
  };
};
