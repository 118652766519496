import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RotateLeft, RotateRight } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  CardProps as MuiCardProps,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SxProps as MuiCardMediaProps,
  Tooltip,
} from "@mui/material";

import { ImageQuality } from "../ImageQualityClaim";

type ImageQualityCardProps = MuiCardProps & {
  renderImage: ImageQuality;
  minStatusSelectWidth: number;
  sxCardMediaProps: MuiCardMediaProps;
  index?: number;
  disableActionArea?: boolean;
  onImageClick?(image: ImageQuality): void;
};

export const ImageQualityCard = ({
  renderImage,
  minStatusSelectWidth,
  sx,
  index = -1,
  disableActionArea = false,
  sxCardMediaProps,
  onImageClick = () => null,
  ...props
}: ImageQualityCardProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedStatus, setSelectedStatus] = useState(renderImage.status);
  const [rotation, setRotation] = useState(0);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value as string);
  };

  const rotate = (rightLeftSide: number) => {
    let newRotation = rotation + 90 * rightLeftSide;
    if (newRotation <= -360 || newRotation >= 360) {
      newRotation = 0;
    }
    setRotation(newRotation);
  };

  return (
    <Card {...props}>
      <CardActionArea
        disabled={disableActionArea}
        onClick={() => onImageClick(renderImage)}
        sx={{ overflow: "hidden" }}
      >
        <CardMedia
          component="img"
          image={renderImage.url}
          alt={`qualityImage-${index}`}
          sx={{
            ...sxCardMediaProps,
            transform: `rotate(${rotation}deg)`,
          }}
        />
      </CardActionArea>
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <CardActions>
          <Tooltip title={t("RotateRight")}>
            <IconButton aria-label="turn-right" onClick={() => rotate(1)}>
              <RotateRight />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("RotateLeft")}>
            <IconButton aria-label="turn-left" onClick={() => rotate(-1)}>
              <RotateLeft />
            </IconButton>
          </Tooltip>
          {/* TODO: Change by custom Select element when Api is available to integrate it with formik context */}
          <FormControl
            fullWidth
            sx={{ minWidth: minStatusSelectWidth, marginLeft: 4 }}
          >
            <InputLabel id="image-quality-status-label">
              {t("Status")}
            </InputLabel>
            <Select
              labelId="image-quality-status-label"
              id="image-quality-select"
              label={t("Status")}
              inputProps={{
                id: "image-quality-input",
                "data-testid": "image-quality-select",
              }}
              onChange={handleChange}
              value={selectedStatus}
            >
              <MenuItem value="APPROVED">
                {t("Approved").toUpperCase()}
              </MenuItem>
              <MenuItem value="ImageBlurry">{t("ImageBlurry")}</MenuItem>
              <MenuItem value="ImageDark">{t("ImageDark")}</MenuItem>
              <MenuItem value="ImageBright">{t("ImageBright")}</MenuItem>
              <MenuItem value="ImageCutoff">{t("ImageCutoff")}</MenuItem>
              <MenuItem value="Inappropiate">{t("Inappropiate")}</MenuItem>
            </Select>
          </FormControl>
        </CardActions>
      </Stack>
    </Card>
  );
};
