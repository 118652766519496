import { createTheme, ThemeOptions } from "@mui/material";

import RedHatDisplayBoldTTF from "assets/fonts/RedHatDisplay/RedHatDisplay-Bold.ttf";
import RedHatDisplayLightTTF from "assets/fonts/RedHatDisplay/RedHatDisplay-Light.ttf";
import RedHatDisplayRegularTTF from "assets/fonts/RedHatDisplay/RedHatDisplay-Regular.ttf";
import RedHatDisplaySemiBoldTTF from "assets/fonts/RedHatDisplay/RedHatDisplay-SemiBold.ttf";
import { NoRowsOverlay } from "common/components/NoRowsOverlay/NoRowsOverlay";
import { Breakpoints } from "constants/constants";

import "@mui/x-data-grid/themeAugmentation";
import "@mui/lab/themeAugmentation";

const fonts = {
  RED_HAT_DISPLAY: "Red Hat Display",
};

// from: https://bareynol.github.io/mui-theme-creator/
export const baseThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#2B76E2",
      light: "#6fa4ff",
      dark: "#004baf",
    },
    secondary: {
      main: "#ebce53",
      light: "#f2df91",
      dark: "#b69d1f",
      contrastText: "#ffffff",
    },
    text: {
      disabled: "rgba(0,0,0,0.32)",
    },
    info: {
      main: "#2196F3",
      dark: "#0b79d0",
      light: "#64b6f7",
    },
    warning: {
      main: "#ed6c02",
      dark: "#c77700",
      light: "#ffb547",
    },
    success: {
      main: "#4caf51",
      dark: "#3b873e",
      light: "#7bc67e",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: `"${fonts.RED_HAT_DISPLAY}", "Helvetica", "Arial", sans-serif`,
  },
};

const baseTheme = createTheme({
  ...baseThemeOptions,
});

const themeOptions: ThemeOptions = {
  palette: {
    background: {
      default: baseTheme.palette.grey[100],
    },
  },
  breakpoints: {
    values: {
      xs: baseTheme.breakpoints.values.xs,
      sm: Breakpoints.SM,
      md: Breakpoints.MD,
      lg: Breakpoints.LG,
      xl: Breakpoints.XL,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: ${fonts.RED_HAT_DISPLAY};
          font-display: swap;
          font-weight: 300;
          src: url(${RedHatDisplayLightTTF}) format("ttf");
        }
        @font-face {
          font-family: ${fonts.RED_HAT_DISPLAY};
          font-display: swap;
          font-weight: 400;
          src: url(${RedHatDisplayRegularTTF}) format("ttf");
        }
        @font-face {
          font-family: ${fonts.RED_HAT_DISPLAY};
          font-display: swap;
          font-weight: 600;
          src: url(${RedHatDisplaySemiBoldTTF}) format("ttf");
        }
        @font-face {
          font-family: ${fonts.RED_HAT_DISPLAY};
          font-display: swap;
          font-weight: 700;
          src: url(${RedHatDisplayBoldTTF}) format("ttf");
        }
      `,
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[100],
          borderBottom: `1px solid ${baseTheme.palette.grey[300]}`,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          boxShadow: baseTheme.shadows[0],
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        input: {
          background: baseTheme.palette.common.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: baseTheme.palette.common.white,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          ":empty": { display: "none" },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        density: "compact",
        disableVirtualization: true,
        disableColumnMenu: true,
        disableSelectionOnClick: true,
        components: {
          NoRowsOverlay,
        },
      },
    },
  },
};

export const theme = createTheme(baseTheme, themeOptions);
