import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetUserRequestParams, GetUserResponse } from "types/Api";
import { User } from "../types/User";
import { useAuthApi } from "./useApi";

type UseGetUser = GetUserRequestParams & {
  userId: string;
};

export const useGetUser = ({ userId, params }: UseGetUser) => {
  const getUser = useAuthApi<never, GetUserResponse>(
    "get",
    endpoints.USER(userId),
    { params }
  );

  const { isLoading, isSuccess, data } = useQuery(
    [QueryKeys.USER, userId, { ...params }],
    () => getUser()
  );

  return {
    isLoading,
    isSuccess,
    user: data?.data?.user as unknown as User,
  };
};
