import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import {
  debounce,
  FormHelperText,
  InputAdornment,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import { useField } from "formik";

import { useGetUsers } from "hooks/useGetUsers";

interface UserListFieldProps {
  name: string;
  checkboxSelection?: boolean;
  orgUsersIds?: string[];
}

export const UserListField = ({
  name,
  checkboxSelection = false,
  orgUsersIds,
}: UserListFieldProps): JSX.Element => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(25);
  const [usersSelected, setUsersSelected] = useState<string[]>([]);

  const [, { error }, { setValue }] = useField(name);

  const { t } = useTranslation();
  const { isLoading, usersResponse } = useGetUsers({
    params: {
      s: search,
      startRecord: page * pageSize,
      pageSize,
      outputOrganizations: false,
      outputGroups: false,
    },
  });

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Name"),
        sortable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          `${params.row.firstName} ${params.row.lastName}`,
      },
      {
        field: "mobilePhone",
        headerName: t("Phone"),
        sortable: false,
        flex: 1,
        valueGetter: (params: GridValueGetterParams) =>
          params.value?.number ?? "",
      },
      {
        field: "email",
        headerName: t("Email"),
        flex: 1,
        sortable: false,
      },
    ],
    [t]
  );

  const onSelectionModelChange = useCallback(
    (values) => {
      setValue(values);
      setUsersSelected(values);
    },
    [setValue, usersSelected]
  );

  const rowCount = useMemo(
    () => usersResponse?.totalRecords ?? 0,
    [usersResponse?.totalRecords]
  );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onPageSizeChange = useCallback((pageSize) => {
    setPage(0);
    setPageSize(pageSize);
  }, []);

  const onPageChange = useCallback((page) => {
    setPage(page);
  }, []);

  useMemo(() => {
    if (orgUsersIds) {
      setUsersSelected(orgUsersIds);
    }
  }, [orgUsersIds]);

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: 2 }}
      >
        <TextField
          className="Users-search"
          fullWidth
          sx={{ paddingTop: 0, maxWidth: 300 }}
          placeholder={t("StartTypingToSearchUsers")}
          label={t("SearchUser")}
          type="search"
          onChange={debounce(onChange, 300)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText error>{error ?? " "}</FormHelperText>
      </Stack>
      <Paper elevation={2} sx={{ height: "400px" }}>
        <DataGrid
          columns={columns}
          rows={usersResponse?.matches ?? []}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          rowCount={rowCount}
          loading={isLoading}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={usersSelected}
          paginationMode="server"
          disableSelectionOnClick={false}
          isRowSelectable={(value) => {
            return !orgUsersIds?.includes(value.id.toString());
          }}
        />
      </Paper>
    </Stack>
  );
};
