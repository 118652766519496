import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { AddButton } from "common/components/AddButton/AddButton";
import { useAppDialog } from "hooks/useAppDialog";
import { usePostOrganization } from "hooks/usePostOrganization";
import { AddOrganizationForm } from "./AddOrganizationForm";
import { AddOrganizationValues } from "./formUtils";

export const AddOrganization = (): JSX.Element => {
  const { t } = useTranslation();
  const { openDialog, Dialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, postOrganization } = usePostOrganization();

  const formRef = useRef<FormikProps<AddOrganizationValues>>(null);

  const onSubmit = () => {
    formRef.current?.handleSubmit();
  };

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <AddButton
        sx={{ mb: 2, mt: 1.5 }}
        variant="contained"
        fullWidth
        onClick={openDialog}
      >
        {t("AddOrganization")}
      </AddButton>
      <Dialog
        {...dialogProps}
        title={t("NewOrganization")}
        loading={isLoading}
        onSubmit={onSubmit}
        actionText={t("Save")}
      >
        <AddOrganizationForm formRef={formRef} onSubmit={postOrganization} />
      </Dialog>
    </>
  );
};
