import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { EditButton } from "common/components/EditButton/EditButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useUpdateSelectedOrganizationLocation } from "hooks/useUpdateSelectedOrganizationLocation";
import { FormValues } from "../Forms/formUtils";
import { EditLocationForm } from "./EditLocationForm/EditLocationForm";

interface EditLocationProps {
  locationId: string;
  disabled?: boolean;
}

export const EditLocation = ({
  locationId,
  disabled = false,
}: EditLocationProps): JSX.Element => {
  const { t } = useTranslation();
  const formRef = useRef<FormikProps<FormValues>>(null);
  const { Dialog, dialogProps, openDialog, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onUpdateLocation } =
    useUpdateSelectedOrganizationLocation({ locationId });

  const onSubmit = useCallback(() => {
    formRef.current?.handleSubmit();
  }, [formRef]);

  useEffect(() => {
    if (isSuccess) closeDialog();
  }, [isSuccess, closeDialog]);

  return (
    <>
      <EditButton disabled={disabled} onClick={openDialog} />
      <Dialog
        title={t("EditLocation")}
        {...dialogProps}
        actionText={t("Save")}
        buttonColor="primary"
        onSubmit={onSubmit}
        loading={isLoading}
        maxWidth="xl"
      >
        <EditLocationForm
          locationId={locationId}
          formRef={formRef}
          onSubmit={onUpdateLocation}
        />
      </Dialog>
    </>
  );
};
