import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { DeleteButton } from "common/components/DeleteButton/DeleteButton";
import { useAppDialog } from "hooks/useAppDialog";
import { useDeleteSelectedOrganizationLocation } from "hooks/useDeleteSelectedOrganizationLocation";

interface DeleteLocationProps {
  disabled?: boolean;
  locationId: string;
}

export const DeleteLocation = ({
  disabled = false,
  locationId,
}: DeleteLocationProps): JSX.Element => {
  const { t } = useTranslation();
  const { Dialog, openDialog, dialogProps, closeDialog } = useAppDialog();
  const { isLoading, isSuccess, onDeleteLocation } =
    useDeleteSelectedOrganizationLocation({ locationId });
  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      openDialog();
    },
    [openDialog]
  );

  const onDeleteConfirm = useCallback(() => {
    onDeleteLocation();
  }, [onDeleteLocation]);

  useEffect(() => {
    if (isSuccess) {
      closeDialog();
    }
  }, [isSuccess, closeDialog]);

  return (
    <>
      <DeleteButton disabled={disabled} onClick={onDeleteClick} />
      <Dialog
        title={t("DeleteLocation")}
        loading={isLoading}
        buttonColor="error"
        actionText={t("Delete")}
        onSubmit={onDeleteConfirm}
        maxWidth="xs"
        {...dialogProps}
      >
        <Typography>{t("DeleteCopy")}</Typography>
      </Dialog>
    </>
  );
};
