import { Organization } from "types/Organization";

export const validateDefaultOrg = (allowedContexts: Array<Organization>) => {
  const defaultOrgFound = allowedContexts.find(
    (organization) => organization.name === "SkAI"
  );
  if (defaultOrgFound) {
    return defaultOrgFound;
  } else {
    return allowedContexts[0];
  }
};
