import { Grid, GridProps } from "@mui/material";

export const FormGridContainer = ({
  children,
  ...props
}: GridProps): JSX.Element => (
  <Grid container columnSpacing={2} {...props}>
    {children}
  </Grid>
);
