import { useEffect, useMemo, useState } from "react";
import { Grid, List, ListItemText } from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ProductDriverPoolAdd } from "components/ProductDriverPoolAdd/ProductDriverPoolAdd";
import { ProductDriverPoolDelete } from "components/ProductDriverPoolDelete/ProductDriverPoolDelete";
import { VehicleMovementProduct } from "types/Product";
import { ProductDriverPoolActors } from "./ProductDriverPoolActors";

interface ProductDriverPoolProps {
  product: VehicleMovementProduct;
}

export const ProductDriverPool = ({
  product,
}: ProductDriverPoolProps): JSX.Element => {
  const [selectedPoolName, setSelectedPoolName] = useState("");

  const pools = useMemo(() => product.driverPools ?? [], [product]);

  useEffect(() => {
    setSelectedPoolName(pools?.[0]?.name ?? "");
  }, [pools]);

  const selectedPool = useMemo(
    () => pools.find((pool) => pool.name === selectedPoolName),
    [pools, selectedPoolName]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs="auto">
        <ProductDriverPoolAdd driverPools={pools} />
        <List>
          {pools.map((pool) => (
            <ListItemButtonWithDialog
              key={pool.name}
              selected={pool.name === selectedPoolName}
            >
              <ListItemText sx={{ mr: 2 }}>{pool.name}</ListItemText>
              <ProductDriverPoolDelete driverPoolId={pool.name} />
            </ListItemButtonWithDialog>
          ))}
        </List>
      </Grid>
      <Grid item xs sx={{ display: "flex", flex: 1 }}>
        {selectedPool && (
          <ProductDriverPoolActors actorIds={selectedPool.actors} />
        )}
      </Grid>
    </Grid>
  );
};
