import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";

import { endpoints, QueryKeys } from "constants/constants";
import { ServerError } from "types/Api";
import { PostOrganizationLocationData } from "types/Location";
import { useAuthApi } from "./useApi";
import { useAppSnackbar } from "./useAppSnackbar";
import { usePostAudit } from "./usePostAudit";
import { useSelectedOrganization } from "./useSelectedOrganization";

export const usePostSelectedOrganizationLocation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { setSnackbarData } = useAppSnackbar();
  const { auditErrorEvent, auditFormEvent } = usePostAudit();
  const { selectedOrganizationId } = useSelectedOrganization();
  const postLocation = useAuthApi<PostOrganizationLocationData, unknown>(
    "post",
    endpoints.ORGANIZATION_LOCATIONS(selectedOrganizationId as string)
  );
  const { isLoading, isSuccess, mutate } = useMutation(postLocation, {
    onError: (data: AxiosError<ServerError>) => {
      const message =
        data.response?.data.message ??
        "Add Organization Location unknown error";
      setSnackbarData({ severity: "error", message });
      auditErrorEvent(message, "Add Organization Location", "ERROR");
    },
    onSuccess: () => {
      const message = t("LocationAdded");
      setSnackbarData({ severity: "success", message });
      auditFormEvent(message, "Add Organization Location", "FORM_SENT");
      queryClient.invalidateQueries([
        QueryKeys.GET_ORGANIZATION_LOCATIONS,
        selectedOrganizationId,
      ]);
    },
  });

  const onPostLocation = (data: Omit<PostOrganizationLocationData, "orgId">) =>
    mutate({ ...data, orgId: selectedOrganizationId as string });

  return {
    isLoading,
    isSuccess,
    onPostLocation,
  };
};
