import { TurkPipelineDataGrid as ImageQualityDataGrid } from "./TurkPipelinesDataGrid";

interface ImageQualityValues {
  id: number;
  account: string;
  jobId: string;
  priority: string;
  items: number;
  created: Date;
  assigned: Date | null;
  deadline: Date;
  assignedTo: string | null;
  status: string;
}

export const ImageQuality = (): JSX.Element => {
  //TODO get data from API when endpoint is available
  const rows: Array<ImageQualityValues> = [
    {
      id: 0,
      account: "Kavak.",
      jobId: "J0001",
      priority: "HIGH",
      items: 10,
      created: new Date(2022, 5, 11, 10),
      assigned: new Date(2022, 5, 11, 10, 5),
      deadline: new Date(2022, 5, 11, 10, 10),
      assignedTo: "Jason Fayling",
      status: "IN PROGRESS",
    },
    {
      id: 1,
      account: "Kavak.",
      jobId: "J0002",
      priority: "HIGH",
      items: 10,
      created: new Date(2022, 5, 11, 10),
      assigned: null,
      deadline: new Date(2022, 5, 11, 10, 5),
      assignedTo: null,
      status: "NOT STARTED",
    },
  ];
  return <ImageQualityDataGrid rows={rows} />;
};
