import Box from "@mui/material/Box";

import { FeatureFlagEnum } from "constants/constants";
import { Organization } from "types/Organization";
import { getFromLocalStorage } from "utils/localStorage";
import styles from "./FeatureFlags.module.scss";

interface IProps {
  flags: FeatureFlagEnum[];
}

const Flags = ({ flags }: IProps) => (
  <ul>
    {flags.map((flag) => (
      <li key={flag}>{flag}</li>
    ))}
  </ul>
);

const FeatureFlags = () => {
  const organizations = getFromLocalStorage(
    "allowedOrganizations"
  ) as Organization[];

  return (
    <Box paddingX={3}>
      <h2>Feature Flag Enum</h2>
      <Flags flags={Object.values(FeatureFlagEnum)} />
      <h2>Enabled Feature Flags</h2>
      <table className={styles.table}>
        <thead>
          <th>organizationId</th>
          <th>organizationName</th>
          <th>featureFlags</th>
        </thead>
        {(organizations || []).map((o) => (
          <tr key={o.id}>
            <td>{o.id}</td>
            <td>{o.name}</td>
            <td>
              <Flags flags={o.featureFlags} />
            </td>
          </tr>
        ))}
      </table>
    </Box>
  );
};

export default FeatureFlags;
