import {
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Folder from "@mui/icons-material/Folder";
import FolderOpen from "@mui/icons-material/FolderOpen";
import { TabContext, TabPanel } from "@mui/lab";
import {
  Box,
  Collapse,
  Grid,
  List,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tab,
  Typography,
} from "@mui/material";

import { ListItemButtonWithDialog } from "common/components/ListItemButtonWithDialog/ListItemButtonWithDialog";
import { ListSkeleton } from "common/components/ListSkeleton/ListSkeleton";
import { Tabs, useTabValue } from "common/components/Tabs/Tabs";
import { EditOrganizationGroup } from "components/EditOrganizationGroup/EditOrganizationGroup";
import { OrganizationAddGroup } from "components/OrganizationAddGroup/OrganizationAddGroup";
import { OrganizationGroupDelete } from "components/OrganizationGroupDelete/OrganizationGroupDelete";
import OrganizationGroupPermissions from "components/OrganizationGroupPermissions/OrganizationGroupPermissions";
import { OrganizationGroupUsers } from "components/OrganizationGroupUsers/OrganizationGroupUsers";
import { useGetOrganization } from "hooks/useGetOrganization";
import { useSelectedOrganization } from "hooks/useSelectedOrganization";
import { SecurityGroup } from "types/Group";

export const Groups = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(0);
  const toggleExpand = useCallback((event: MouseEvent, index: number) => {
    event.stopPropagation();
    setExpandedIndex((isExpanded) => (isExpanded === index ? -1 : index));
  }, []);
  const [tabValue, setTabValue] = useTabValue<"Users" | "Permissions">("Users");

  const { selectedOrganizationId } = useSelectedOrganization();
  const { isLoading: isLoadingOrganization, data } = useGetOrganization({
    id: selectedOrganizationId as string,
  });

  const groups = useMemo(() => data?.securityGroups ?? [], [data]);

  const groupByApplication = useMemo(
    () =>
      groups.reduce((acc, { application, ...rest }) => {
        acc[application] = acc[application] || [];
        acc[application].push(rest);
        return acc;
      }, {}),
    [groups]
  );

  const parentGroups: Array<{
    name: string;
    securityGroups: Array<SecurityGroup>;
  }> = useMemo(
    () =>
      Object.keys(groupByApplication)
        .sort()
        .map((key) => {
          return { name: key, securityGroups: groupByApplication[key].sort() };
        }),
    [groupByApplication]
  );
  const selectedGroup = useMemo(
    () => groups.find((group) => group.id === selectedGroupId),
    [groups, selectedGroupId]
  );

  const isEditable = useMemo(
    () => selectedGroup?.type === "APPLICATION",
    [selectedGroup]
  );

  useEffect(() => {
    data?.securityGroups?.length &&
      setSelectedGroupId(data.securityGroups[0]?.id ?? "");
  }, [data?.securityGroups]);

  useEffect(() => {
    setSelectedGroupId(parentGroups[0]?.securityGroups[0]?.id ?? "");
  }, [parentGroups]);

  return (
    <Grid
      container
      pt={2}
      spacing={2}
      sx={{ flex: 1, maxHeight: "calc(100% + 16px)" }}
    >
      <Grid item xs="auto" sx={{ maxHeight: "100%" }}>
        <Stack direction="column" sx={{ maxHeight: "100%" }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ pl: 2, pb: 2 }}
          >
            <Typography noWrap mr={2}>
              {isLoadingOrganization ? (
                <Skeleton variant="text" width={80} />
              ) : (
                <>{t("Groups", { count: parentGroups.length })}</>
              )}
            </Typography>
            <OrganizationAddGroup disabled={isLoadingOrganization} />
          </Stack>
          {isLoadingOrganization ? (
            <ListSkeleton withDivider width={200} />
          ) : (
            <List sx={{ py: 0, overflow: "auto" }}>
              {parentGroups.map((parent, index) => (
                <Fragment key={parent.name}>
                  <ListItemButtonWithDialog
                    onClick={(event) => toggleExpand(event, index)}
                  >
                    <ListItemIcon sx={{ minWidth: "36px" }}>
                      {expandedIndex === index ? (
                        <FolderOpen fontSize="small" />
                      ) : (
                        <Folder fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText sx={{ mr: 2 }}>{parent.name}</ListItemText>
                  </ListItemButtonWithDialog>
                  <Collapse in={expandedIndex === index} timeout="auto">
                    <List sx={{ py: 0, overflow: "auto" }}>
                      {parent.securityGroups.map((group) => (
                        <ListItemButtonWithDialog
                          key={group.id}
                          selected={group.id === selectedGroupId}
                          divider={index !== groups.length - 1}
                          onClick={() => setSelectedGroupId(group.id)}
                        >
                          <ListItemText sx={{ mr: 2 }}>
                            {group.name}
                          </ListItemText>
                          {group.type === "APPLICATION" && (
                            <OrganizationGroupDelete groupId={group.id} />
                          )}
                        </ListItemButtonWithDialog>
                      ))}
                    </List>
                  </Collapse>
                </Fragment>
              ))}
            </List>
          )}
        </Stack>
      </Grid>
      <Grid item xs sx={{ display: "flex", maxHeight: "100%" }}>
        <Box sx={{ pr: 2, width: "100%" }}>
          <Stack
            direction="column"
            sx={{
              width: "100%",
              maxHeight: "100%",
              height: "100%",
            }}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="body2">
                {selectedGroup ? (
                  <>
                    <span style={{ textTransform: "uppercase" }}>
                      {selectedGroup?.name}
                    </span>
                    : {selectedGroup?.id}
                  </>
                ) : (
                  <Skeleton variant="text" width={300} />
                )}
              </Typography>
              {isEditable && (
                <EditOrganizationGroup
                  disabled={!data}
                  organizationId={selectedOrganizationId as string}
                  groupId={selectedGroupId}
                />
              )}
            </Stack>
            <TabContext value={tabValue}>
              <Tabs onChange={setTabValue}>
                <Tab value="Users" label={t("Users")} />
                <Tab value="Permissions" label={t("Permissions")} />
              </Tabs>
              <TabPanel value="Users">
                <Box sx={{ flex: 1, display: "flex", paddingY: 2 }}>
                  {selectedGroup && (
                    <OrganizationGroupUsers
                      group={selectedGroup}
                      organizationId={selectedOrganizationId as string}
                    />
                  )}
                </Box>
              </TabPanel>
              <TabPanel value="Permissions">
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                  }}
                >
                  <Box pt={2}>
                    <OrganizationGroupPermissions
                      name={selectedGroup?.name}
                      application={selectedGroup?.application}
                    />
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};
