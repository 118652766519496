import { useQuery } from "react-query";

import { endpoints, QueryKeys } from "constants/constants";
import { GetOrganizationResponse, UseGetOrganizationProps } from "types/Api";
import { useAuthApi } from "./useApi";

export const useGetOrganization = (
  props: UseGetOrganizationProps,
  refetchOnMount = true
) => {
  const getOrganization = useAuthApi<never, GetOrganizationResponse>(
    "get",
    endpoints.ORGANIZATION(props.id)
  );

  const { isLoading, isError, data } = useQuery(
    [QueryKeys.GET_ORGANIZATION, props.id],
    () => (props.id ? getOrganization() : null),
    { refetchOnMount }
  );

  return {
    isLoading,
    isError,
    data: data?.data?.organization ?? null,
  };
};
